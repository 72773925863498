import { useState, useEffect } from 'react'
import React from 'react'
import ReactSelect from 'react-select'
import axios from 'axios'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import md5 from 'md5'
import Paper from '@mui/material/Paper'
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CircularProgress from '@mui/material/CircularProgress'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import Headroom from "react-headroom";
import DialogTitle from '@mui/material/DialogTitle';
import Breadcrumbs from "../components/Common/Breadcrumb";
import { TabPanel, TabContext, TabList } from '@mui/lab'
import Tab from '@mui/material/Tab'
import {Modal, Box, Button, Container, Stack, SvgIcon, Typography, Grid, Snackbar, TextField } from '@mui/material';
import Moment from 'moment'
import Avatar from '@mui/material/Avatar'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import { useAlert } from 'react-alert'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InputMask from 'react-input-mask'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
// import { shallowEqual, useSelector, useDispatch } from 'react-redux'

import { useLocation, Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';


function PaymentMethod(props) {
  document.title = "Register Practice | LuxBilling";
 // API Authanticatiion
 const token = localStorage.getItem('Token')
 const auth = `Bearer ${token}`
 const headers = {
   Authorization: auth
 }
    const fields = [
        'city',
        'createdBy',
        'createdDate',
        'id',
        'deleted',
        'state',
        'updatedBy',
        'updatedDate',
        'zipCode',
        'taxId',
        'phoneNo',
        'fax',
        'website',
        'emailAddress',
        'practiceName',
        'orgBusiness',
        'address',
        'billingEmailAddress',
    'npi',
    ]
    const defValues = {
        practiceName: '',
        orgBusiness: '',
        taxId: '',
        address: '',
        phoneNo: '',
        fax: '',
        emailAddress: '',
        city: '',
        website: '',
        createdBy: '',
        createdDate: '',
        id: 0,
        deleted: '',
        state: '',
        updatedBy: '',
        updatedDate: '',
        zipCode: '',
        billingEmailAddress: '',
        npi:'',
    }
    const [submitting, setSubmitting] = useState(false)
  const columnIDpayment = props.columnIDpayment
    const navigate = useNavigate();
    const location = useLocation()
    const PracticeId = location.state.PracticeId
    const validationSchema = Yup.object({
        practiceName: Yup.string().required('Name is required'),
    })
    const {
        register,
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        context: undefined,
        criteriaMode: 'firstError',
        shouldFocusError: true,
        shouldUnregister: false,
        shouldUseNativeValidation: false,
        delayError: undefined,
        resolver: yupResolver(validationSchema),
        defaultValues: defValues,
    })
  const [refresh, setRefresh] = useState(false)

    const [isLoading, setLoading] = useState(false)
    const [groupName, setGroupName] = React.useState(false);
    const handleOpengroupName = () => setGroupName(true);
    const handleClosegroupName = () => setGroupName(false);
    const [checkno, setcheckno] = useState("");
    const [payer, setpayer] = useState("");
    const [checkamount, setcheckamount] = useState("");
    const [checkdate, setcheckdate] = React.useState(Moment().format('YYYY-MM-DD'))
    const alert = useAlert()
    const [refreshpayment, setRefreshpayment] = useState(false);
    function applyRefreshpayment() {
      setRefreshpayment(!refreshpayment)
    }
    const [PaymentMethod, setPaymentMethod] = useState(null);
    console.log('PaymentMethod',PaymentMethod)
    useEffect(() => {
      if (columnIDpayment > 0) {
    
        let isComponentMounted = true
        const fetchData = async () =>
          axios
            .get(`${process.env.REACT_APP_API_URL}/claims/PaymentById?claimId=${columnIDpayment}`, {
              headers
            })
            .then((response) => {
              console.log('response.data: ', response)
              if (response) {
                setPaymentMethod(response)
              }
  
            })
            .catch((error) => {
              console.error('There was an error!', error)
            })
        fetchData()
        return () => {
          isComponentMounted = false
        }
      }
      // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, [columnIDpayment])
  
    const saveData = data => {
        console.log('testttttt')
        var postData = PaymentMethod
      
          
        
        // if(apptypeError)
        axios
          .post(`${process.env.REACT_APP_API_URL}/claims/addpayment`, postData, { headers })
          .then(response => {
            alert.success('Save successfully.', {
              type: 'success',
              onClose: () => {
                // setTotalcount(Totalcount - 1)
            
                setRefresh(true)
            
              }
            })
          })
          .catch(error => {
           
            
         
            setRefresh(true)
          })
        setRefresh(true)
    
      }
      const AddGroup = (event) => {
        console.log('data',event)
       
       
        const postData = {
          checkno: checkno,
          payer : payer,
          checkamount: checkamount,
          checkdate:checkdate
         
         
        };
       
        axios
        .post(
          `${process.env.REACT_APP_API_URL}/claims/AddERA`,
          postData,
          { headers }
        )
        .then(response => {
          alert.success("Record saved successfully.", {
            type: "success",
            onClose: () => {
         
              applyRefreshpayment()
              
            }
          });
        })
        .catch(error => {
          // setSubmitting(false);
          // alert.error(`Error ${error.message}`, {
          //   type: "error"
          // });
        });
    
      }
      const [patient, setPatient] = React.useState(false);
      const [searchText, setSearchText] = useState('');
      const [patientNamee, setPatientNamee] = useState('');
      const [patientData, setPatientData] = React.useState([]);
      const onChangeHandle = async (value) => {
        // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions
        console.log(value);
        setLoading(true);
    
        const sendData = {
          id: 0,
          checkno: value,
         
         
        };
        axios
          // .post(`${process.env.NEXT_PUBLIC_API_URL}/Patients/searchPatient`, sendData,
          // { headers },
          .post(
            `${process.env.REACT_APP_API_URL}/claims/SearchERA`,
            sendData,
            { headers },
          )
    
          .then((response) => {
            setPatientData(response);
            setPatient(true);
            setLoading(false);
    
    
          })
          .catch((error) => {
            console.error('There was an error!', error);
            setLoading(false);
    
          });
      };
      const patientListStyle = {
        cursor: 'pointer',
        hover: {
          background: '#3b3b3b !important',
          color: 'rgba(5, 152, 236, 0.637) !important'
        },
        color: 'green',
        selection: {
          background: '#D7624E',
          color: 'white'
        }
      };
      const modalstyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'white',
        border: '2px solid #356F60',
        boxShadow: 24,
        overflow: 'hidden',
        // p: 4,
        borderRadius: '8px',
        overflow:'auto',
      };
    return (
        <React.Fragment>
            <div className="page-content" >
            <Modal
        open={groupName}
        onClose={handleClosegroupName}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalstyle}>
          <Grid container spacing={1}>
        
         
          <Grid item xs={12} sm={12} xl={12} > 
        <Headroom >
            <Grid
                  container
                  style={{ backgroundColor: '#356F60', position: 'sticky'}}
                >
                  <Grid item xs={12} sm={12} xl={12}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'left',
                        justifyContent: 'space-between',
                        height: '2rem'
                      }}
                    >
                      <DialogTitle
                        style={{
                          flex: 1,
                          // textAlign: 'left',
                        }}
                      >
                        <h1
                          style={{
                            fontWeight: 'bold',
                            // textAlign: 'left',
                            marginTop:'-10px', 
                            color: '#fff',
                            textDecoration: 'underline',
                            fontSize:'1rem'

                          }}
                        >
                          Check Add

                        </h1>
                      </DialogTitle>
                      <CancelOutlinedIcon
                       onClick={handleClosegroupName}
                        fontSize="large"
                        color="red"
                        style={{
                          color: 'red',
                          paddingRight: '5px',
                          cursor: 'pointer',
                          float:'right'
                          // marginLeft: 'auto',
                          // marginRight: '1.5rem'
                        }}
                      ></CancelOutlinedIcon>
                    </div>
                  </Grid>
                </Grid>
              </Headroom>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
          <Grid item xs={2} sm={2} xl={2}>
                <Typography style={{ fontSize: '14px',marginTop:'5px' }}>
                    Check No
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4} xl={4}>
                <Controller
                        name="FullName"
                        // {...register("userName")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                            size="small"
                            placeholder="Please enter No"
                            {...field}
                            fullWidth
                            value={checkno}
                            onChange={ev => {
                              console.log('ee:', ev.target.value)
                             setcheckno(ev.target.value)
                              
                              }
                            }
                           
                          />}
                      />
                
                </Grid>
                <Grid item xs={2} sm={2} xl={2}>
                <Typography style={{ fontSize: '14px',marginTop:'5px' }}>
             Payer
                  </Typography>
                </Grid>
                <Grid item xs={3} sm={3} xl={3}>
                <Controller
                        name="FullName"
                        // {...register("userName")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                         
                            size="small"
                            placeholder="Please enter Payer"
                            {...field}
                            fullWidth
                            value={payer}
                            onChange={ev => {
                              console.log('ee:', ev.target.value)
                             setpayer(ev.target.value)
                              
                              }
                            }
                           
                          />}
                      />
                
                </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
          <Grid item xs={2} sm={2} xl={2}>
                <Typography style={{ fontSize: '14px',marginTop:'5px' }}>
                    Check Date
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4} xl={4}>
                <Controller
                        name="FullName"
                        // {...register("userName")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                          type='date'
                            size="small"
                           
                            {...field}
                            fullWidth
                            value={checkdate}
                            onChange={ev => {
                              console.log('ee:', ev.target.value)
                             setcheckdate(ev.target.value)
                              
                              }
                            }
                           
                          />}
                      />
                
                </Grid>
                <Grid item xs={2} sm={2} xl={2}>
                <Typography style={{ fontSize: '14px',marginTop:'5px' }}>
             Check Amount
                  </Typography>
                </Grid>
                <Grid item xs={3} sm={3} xl={3}>
                <Controller
                        name="FullName"
                        // {...register("userName")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                         
                            size="small"
                            placeholder="Please enter Amount"
                            {...field}
                            fullWidth
                            value={checkamount}
                            onChange={ev => {
                              console.log('ee:', ev.target.value)
                             setcheckamount(ev.target.value)
                              
                              }
                            }
                           
                          />}
                      />
                
                </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>


              






            
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

                <Grid item xs={11} sm={11} xl={11} style={{marginTop:'10px'}}>
                <Button
          className="Button"
                    onClick={AddGroup}
                    // disabled={submitting}
                fullWidth
                 style={{borderRadius:'10px',color:'white',height:'40px',borderColor:"#EA7772",fontSize:'14px',fontWeight:'bold',float:'right',marginBottom:'10px'}}
                  type="submit"
                 >
                   Save
                   </Button>
                </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

          </Grid>
         
        </Box>
      </Modal>
            <Grid container >
              {PaymentMethod != null && PaymentMethod.length > 0
                  ? PaymentMethod.map((row, index) => {
                    

                    return (
                      <Paper
                        variant='outlined'
                        square
                        style={{
                          backgroundColor: '#F0F6F8',
                          borderRadius: '15px',
                          marginTop: '5px',
                          borderColor: '#377562',
                          maxWidth: '100%',
                          padding: '.9rem',
                          marginBottom: '1rem',
                          marginLeft: '0 .2rem'
                        }}
                      >
                        <Grid container spacing={2} item xs={12} sm={12} xl={12}>
                        
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography style={{ fontSize: "16px", textDecoration: "underline" }}>
                          Procedure code:{row.code}
                        </Typography>
                        <TextField
                                  size='small'
                                  value={row.code}
                                  
                                  style={{ width: '99%' }}
                                  // value={charges}

                                />
                      

 


                        </Grid>

                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Typography style={{ fontSize: "16px", textDecoration: "underline" }}>
                              Diagnosis code:
                            </Typography>
                            <TextField
                                  size='small'
                                  value={row.dxPointer1}
                                  
                                  style={{ width: '99%' }}
                                  // value={charges}

                                />

                          </Grid>

                          <Grid item xs={12} sm={6} md={4} lg={3} >
                            <Typography style={{ fontSize: "16px", textDecoration: "underline"}}>
                              DOS
                            </Typography>


                            <TextField
                                // type='date'
                                  size='small'
                                  value={row.dosTo}
                                  
                                  style={{ width: '99%' }}
                                  // value={charges}

                                />
                          </Grid>

                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Typography style={{ fontSize: "16px", textDecoration: "underline"}}>
                              Charge $
                            </Typography>
                            <TextField
                          
                                  size='small'
                                  value={row.charges}
                                  
                                  style={{ width: '99%' }}
                                  // value={charges}

                                />

                          </Grid>
                          <Grid item xs={3} >
   <Typography style={{ fontSize: "16px", textDecoration: "underline" }}>
     Check#:
   </Typography>
 
 
 
   <ClickAwayListener onClickAway={() => setPatient(false)}>
                 <TextField
                   {...register('patientName')}
                   size="small"
                   autoComplete="off"
                   style={{ width:'99%' }}
                   placeholder="Enter Patient Name"
                  
                   // inputProps={{
                   //   style: {
                   //     minHeight: '38px',
                   //     padding: '0 1px'
                   //   }
                   // }}
                   
                   //  value={patientNamee}
                   styles={{ control: provided => ({ ...provided, width: '99%' }) }}
                   onKeyUp={(ev) => {
                     console.log('ee:', ev.target.value);
                     setSearchText(ev.target.value);
                     setPatientData([]);
 
                     // dont fire API if the user delete or not entered anything
                     if (ev.target.value !== null && ev.target.value !== '' && ev.target.value.length > 0) {
                       onChangeHandle(ev.target.value);
                     } else {
                       setPatient(false);
 
                     }
                   }}
                   // error={errors.patientName}
 
                 />
               </ClickAwayListener>
               {isLoading && (
                 <CircularProgress size={20} style={{ marginLeft: '-40px', }} />
               )}
               {/* <p style={{ color: "red", fontSize: "14px" }}>
                 {errors.patientName?.message}
               </p> */}
               {patient ? (
                 <div
                   className="col-xs-6"
                   style={{
                     height: 150,
                     overflowY: 'scroll',
                     padding: '14px 16px',
                     fontSize: '0.875rem',
                     color: '#67748e',
                     borderRadius: '5px',
                     background: 'white',
                     textAlign: 'justify',
                     textJustify: 'inter-word',
                     position: 'absolute',
                     zIndex: '99',
                     width: '39%',
                     border: '1px solid #6cb5f9'
                   }}
                 >
                   {patient ? (
                     patientData.length > 0 ? (
                       patientData.map((s, index) => {
                         var pat = `${patientData[index].checkno}`
                         // var patLower = pat.toLowerCase();
                         var i = pat.indexOf(searchText)
                         console.log('i:', i)
                         var p1 = pat.substring(0, i)
                         var p = pat.substring(i, searchText.length)
                         console.log('p:', p)
                         var p2 = pat.substring(searchText.length, pat.length)
 
                         return (
                           <>
                             <p
                               style={patientListStyle}
                               onClick={(e) => {
                                 setValue(
                                   'checkno',
                                   `${patientData[index].checkno}`
                                 );
                                 
                                 setValue('payer', `${patientData[index].payer}`)
                                 setValue('checkdate', `${patientData[index].checkdate}`)
                                 setValue('checkamount', `${patientData[index].checkamount}`)
 
 
                                 setPatientData([]);
                                 setPatient(false);
                                 setPatientNamee(
                                   `${patientData[index].checkno}`
                                 );
                                 // setPatientNamee(
                                 //   `${patientData[index].lastName}, ${patientData[index].firstName}`
                                 // );
                                 // setselectpatientid(patientData[index].id)
                               }}
                               onBlur={(e) => {
                                 setPatientData([]);
                                 setPatient(false);
                               }}
                               onChange={(e) => {
                                 setPatientData([]);
                                 setPatient(false);
                                 // setPatientNamee('');
                               }}
                             >
                               <span>{p1}</span>
                               <span style={{ backgroundColor: 'yellow' }}>{p}</span>
                               <span>{p2}</span>
                               {/* {s.patientId} */}
                               {/* <Divider variant="hard" /> */}
                             </p>
                           </>
                         );
                       })
                     ) : (
                       <Typography>No Record found</Typography>
                     )
                   ) : null}
                 </div>
               ) : (
                 ''
               )}
 
 
  
                          </Grid>
                          <Grid item xs={0.5} style={{marginTop:'25px'}}>
                          <AddCircleOutlineRoundedIcon 
                              onClick={handleOpengroupName}
                            fontSize="large"
                            color="green"
                            style={{
                              color: 'green',
                              paddingRight: '5px',
                              cursor: 'pointer',
                            }}
                          >
            
                          </AddCircleOutlineRoundedIcon>
            
                          </Grid>
                          <Grid item xs={3} >
              <Typography style={{ fontSize: "16px", textDecoration: "underline" }}>
                Payer:
              </Typography>
            
            
              <Controller
                                    name="payer"
                                    {...register("payer")}
                                    control={control}
                                    render={({ field }) =>
                                      <TextField
                                        size="small"
                                        placeholder="Please enter payer"
                                        {...field}
                                        fullWidth
                                        // value={groupname}
                                        // onChange={ev => {
                                        //   console.log('ee:', ev.target.value)
                                        //  setgroupname(ev.target.value)
                                          
                                        //   }
                                        // }
                                        
                                      />}
                                  />
                          </Grid>
                          <Grid item xs={3} >
              <Typography style={{ fontSize: "16px", textDecoration: "underline" }}>
                Check Date:
              </Typography>
            
            
              <Controller
                                    name="checkdate"
                                    {...register("checkdate")}
                                    control={control}
                                    render={({ field }) =>
                                      <TextField
                                      type='date'
                                        size="small"
                                        placeholder="Please enter No"
                                        {...field}
                                        fullWidth
                                        // value={groupname}
                                        // onChange={ev => {
                                        //   console.log('ee:', ev.target.value)
                                        //  setgroupname(ev.target.value)
                                          
                                        //   }
                                        // }
                                        
                                      />}
                                  />
                          </Grid>
                          <Grid item xs={2.5} >
              <Typography style={{ fontSize: "16px", textDecoration: "underline" }}>
                Check Amount:
              </Typography>
            
            
              <Controller
                                    name="checkamount"
                                    {...register("checkamount")}
                                    control={control}
                                    render={({ field }) =>
                                      <TextField
                                      type='test'
                                        size="small"
                                        placeholder="Please Enter Check amount"
                                        {...field}
                                        fullWidth
                                        // value={groupname}
                                        // onChange={ev => {
                                        //   console.log('ee:', ev.target.value)
                                        //  setgroupname(ev.target.value)
                                          
                                        //   }
                                        // }
                                        
                                      />}
                                  />
                          </Grid>
                                      
                          <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography style={{ fontSize: "16px", textDecoration: "underline" }}>
              Allowed Amount
              </Typography>
            
              <Controller
                                    name="FullName"
                                    // {...register("userName")}
                                    control={control}
                                    render={({ field }) =>
                                      <TextField
                                    
                                        size="small"
                                        placeholder="Please enter Allow Amount"
                                        {...field}
                                        fullWidth
                                        // value={groupname}
                                        // onChange={ev => {
                                        //   console.log('ee:', ev.target.value)
                                        //  setgroupname(ev.target.value)
                                          
                                        //   }
                                        // }
                                        
                                      />}
                                  />
            
            
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Typography style={{ fontSize: "16px", textDecoration: "underline" }}>
                            Paid Amount
                            </Typography>
                          
                            <Controller
                                                  name="FullName"
                                                  // {...register("userName")}
                                                  control={control}
                                                  render={({ field }) =>
                                                    <TextField
                                                  
                                                      size="small"
                                                      placeholder="Please enter Paid Amount"
                                                      {...field}
                                                      fullWidth
                                                      // value={groupname}
                                                      // onChange={ev => {
                                                      //   console.log('ee:', ev.target.value)
                                                      //  setgroupname(ev.target.value)
                                                        
                                                      //   }
                                                      // }
                                                      
                                                    />}
                                                />
                          
                          
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Typography style={{ fontSize: "16px", textDecoration: "underline" }}>
                            Adjustment Amount
                            </Typography>
                          
                            <Controller
                                                  name="FullName"
                                                  // {...register("userName")}
                                                  control={control}
                                                  render={({ field }) =>
                                                    <TextField
                                                  
                                                      size="small"
                                                      placeholder="Please enter Adjustment Amount"
                                                      {...field}
                                                      fullWidth
                                                      // value={groupname}
                                                      // onChange={ev => {
                                                      //   console.log('ee:', ev.target.value)
                                                      //  setgroupname(ev.target.value)
                                                        
                                                      //   }
                                                      // }
                                                      
                                                    />}
                                                />
                          
                          
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Typography style={{ fontSize: "16px", textDecoration: "underline" }}>
                            Write Off
                            </Typography>
                          
                            <Controller
                                                  name="FullName"
                                                  // {...register("userName")}
                                                  control={control}
                                                  render={({ field }) =>
                                                    <TextField
                                                  
                                                      size="small"
                                                      placeholder="Please enter Write OFF Amount"
                                                      {...field}
                                                      fullWidth
                                                      // value={groupname}
                                                      // onChange={ev => {
                                                      //   console.log('ee:', ev.target.value)
                                                      //  setgroupname(ev.target.value)
                                                        
                                                      //   }
                                                      // }
                                                      
                                                    />}
                                                />
                          
                          
                          </Grid>

                      
                        </Grid>
                      </Paper>
                    )
                  })
                  : 'No Record Found'}
 <Grid item xs={11} lg={11} sm={11}>
                                      <Button
                                       className='Button'
                                        onClick={handleSubmit(saveData)}
                                        variant='gradient'
                                      
                                        style={{
                                          marginTop: '15px',
                                          marginBottom: '10px',
                                          float: 'right',
                                          width: '87px',
                                          height: '28px',
                                          // backgroundColor: '#56CA00',
                                          color: 'white',
                                          fontSize: '12px',
                                          marginRight: '-50px'
                                        }}
                                      // disabled={submitting}
                                      >
                                        <SaveOutlinedIcon
                                          fontSize='medium'
                                          style={{
                                            color: 'white',
                                            paddingRight: '5px',
                                            paddingBottom: '2px'
                                          }}
                                        ></SaveOutlinedIcon>
                                        Save
                                      </Button>
                                    </Grid>
                                    <Grid item xs={1} lg={1} sm={1}>

                                    </Grid>

              </Grid>
            </div>
        </React.Fragment>

    )

}

export default PaymentMethod