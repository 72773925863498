import { useState, useEffect } from 'react'
import React from 'react'
import ReactSelect from 'react-select'
import axios from 'axios'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { styled } from '@mui/material/styles'
import md5 from 'md5'
import Multiselect from 'multiselect-react-dropdown'
import Breadcrumbs from "../components/Common/Breadcrumb";
import { TabPanel, TabContext, TabList } from '@mui/lab'
import Tab from '@mui/material/Tab'
import { Box, Button, Container, Stack, SvgIcon, Typography, Grid, Snackbar, TextField } from '@mui/material';
import Moment from 'moment'
import Avatar from '@mui/material/Avatar'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import { useAlert } from 'react-alert'
import InputMask from 'react-input-mask';
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
// import { shallowEqual, useSelector, useDispatch } from 'react-redux'

import { useLocation, Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';


const ProviderRegistration = (props) => {
  document.title = "Register Provider | LuxBilling";

    const fields = [
        'city',
        'contactNo',
        'createdBy',
        'createdDate',
        'dOB',
        'email',
        'fullName',
        'gender',
        'id',
        'inactive',
        'active',
        'isattendingphysician',
        'isbillingphysician',
        'isStaff',
        'isVerified',
        'lastLogin',
        'password',
        'profilePhoto',
        'state',
        'updatedBy',
        'updatedDate',
        'userType',
        'zipCode',
        'shortName',
        'phoneNo',
        'fax',
        'emailAddress',
        'clientCreatedDate',
        'clientModifiedDate',
        'defaultFacility',
        'defaultPOS',
        'nPI',
        'address',
        'practiceid',
        'lname',
        'fname',
        'mi',
        'title',
        'type',
        'sSN',
        'homephone',
        'Cellphone',
        'phoneno',
        'faxno',
        'uPin',
        'firstName',
        'lastName',
        'practiceId',
        'linkedProviders',
        'taxId',
        'taxoNomy',
        'psychiatristPhysician',
        'attendingPhysician',
        'billingPhysician',
        'UserPractice',
        'base64'
    ]

    const defValues = {
        lastName: '',
        firstName: '',
        mi: '',
        type: '',
        title: '',
        active: '',
        sSN: '',
        address: '',
        phoneNo: '',
        fax: '',
        emailAddress: '',
        city: '',
        homephone: '',
        Cellphone: '',
        faxno: '',
        clientcreateddate: '',
        contactNo: '',
        createdBy: '',
        createdDate: '',
        clientCreatedDate: '',
        clientModifiedDate: '',
        practiceId: 0,
        dOB: '',
        email: '',
        uPin: '',
        fullName: '',
        gender: '',
        id: 0,
        inactive: '',
        lastLogin: '',
        password: '',
        profilePhoto: '',
        state: '',
        street: '',
        token: '',
        updatedBy: '',
        updatedDate: '',
        zipCode: '',
        nPI: '',
        practiceId: '',
        linkedProviders: '',
        taxId: '',
        taxoNomy: '',
        psychiatristPhysician: '',
        attendingPhysician: '',
        billingPhysician: '',
        UserPractice:'',
        inactive: false,
        base64:''
    }
    const [practiceID, setPracticeID] = useState("");
    const [isLoading, setisLoading] = useState(true)
    const [submitting, setSubmitting] = useState(false)
   // API Authanticatiion
   const token = localStorage.getItem('Token')
   const auth = `Bearer ${token}`
   const headers = {
     Authorization: auth
   }
    const [selectProviderError, setselectProviderError] = useState(false)
    const [PracticeidError, setPracticeidError] = useState(false)
    const navigate = useNavigate();
    const location = useLocation()
    const _providerId = location.state.providerid
    const alert = useAlert()
    // const location = useLocation()
    const zipCodeListStyle = {
        cursor: 'pointer',
        '&:hover': {
            background: '#3b3b3b !important',
            color: 'rgba(5, 152, 236, 0.637) !important'
        }
    }
    // useEffect(() => {
    //     console.log('providerIdpassed=', props.router.query.pid);
    // }, [props.router.query]);

    const today = new Date()
    var startDate = new Date()
    startDate.setFullYear(startDate.getFullYear() - 100)
    const validationSchema = Yup.object({
        // lastName: Yup.string().required('Last Name is required'),
        // emailAddress: Yup.string().email('Email is invalid'),
        firstName: Yup.string().required('First Name is required'),
        zipCode: Yup.string()
        .required('Zip Code is required')
        .matches(/^\d{5}(-\d{4})?$/, 'Zip Code must be either 5 digits or 5 digits followed by a hyphen and 4 digits'),
    // taxId: Yup.string()
    // .required('Tax ID is required')
    // .matches(/^\d{2}-\d{7}$/, 'Tax ID must be in the format XX-XXXXXXX and exactly 9 digits')
        // dOB: Yup.date()
        //     .required('DOB is required')
        //     .nullable()
        //     .transform(v => (v instanceof Date && !isNaN(v) ? v : null))
        //     .max(today, "You can't be born in the future!")
        //     .min(startDate, "You can't be that much old!")
        //nPI: Yup.string().required('NPI is required'),
        // password: Yup.string()
        //   .required('Password is required')
        //   .min(3, 'Password must be at least 3 characters')
        //   .max(15, 'Password must not exceed 15 characters'),
    })
    const [rows, setRows] = useState(null)
    const gridRowStyle = {
        boxShadow: 2,
        border: 2,
        borderRadius: 1,
        background: 'white',
        fontSize: '13.6px !important',
        color: '#8392ab !important',
        borderColor: 'rgba(5, 152, 236, 0.637) !important',
        '& .MuiDataGrid-cell:hover': {
            color: 'rgba(5, 152, 236, 0.637) !important',
            fontSize: '14.6px !important'
        },
        '& .super-app-theme--header': {
            backgroundColor: '#FFFFC2',
            borderRadius: 1.8
        }
    }
    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            cellClassName: 'gridCell',
            sortable: false,
            headerClassName: 'super-app-theme--header',
            flex: 0.5
        },
        {
            field: 'name',
            headerName: 'Name',
            cellClassName: 'gridCell',
            headerClassName: 'super-app-theme--header',
            flex: 0.3
        }
    ]
    const {
        register,
        control,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors }
    } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        context: undefined,
        criteriaMode: 'firstError',
        shouldFocusError: true,
        shouldUnregister: false,
        shouldUseNativeValidation: false,
        delayError: undefined,
        resolver: yupResolver(validationSchema),
        defaultValues: defValues
    })

    const onSubmit = data => {
        console.log('selectedafterclickSave', selectedProviderIds)
        var selectedIds = []
        var unSelectedIds = []
        selectedProviderIds.forEach(field => {
            selectedIds.push(field.id)
        })
        providersList.forEach(item => {
            var found = false
            selectedProviderIds.forEach(field => {
                if (field.id == item.id) found = true
            })
            if (!found) unSelectedIds.push(item.id)
        })
        console.log('selected', selectedIds)
        console.log('unselected', unSelectedIds)


        const postData = {...data , base64: base64Image, }
        postData.linkedProvidersId = selectedIds
        postData.unlinkedProvidersId = unSelectedIds
        // postData.userType = data.userType ? data.userType.value : ''
        // postData.gender = data.gender ? data.gender.value : ''
        console.log('Data: ', postData)
        console.log(JSON.stringify(postData, null, 2))
        if (postData.type == '' || postData.type == null) {
            setselectProviderError(true)
            return
        }
        if (postData.practiceId == '' || postData.practiceId == null) {
            setPracticeidError(true)
            return
        }
        setSubmitting(true)
        axios
            .post(`${process.env.REACT_APP_API_URL}/Providers/addProvider`, postData, { headers })
            .then(response => {
                setSubmitting(false)
                setselectProviderError(false)
                alert.success('Record saved successfully.', {
                    type: 'success',
                    onClose: () => {
                        navigate('/Provider')
                    }
                })
            })
            .catch(error => {
                setSubmitting(false)
                alert.success(`Error ${error.message}`, {
                    type: 'error'
                })
            })
    }

    useEffect(() => {
        // POST request using axios inside useEffect React hook
        if (_providerId !== undefined && _providerId != null && _providerId > 0) {
            let isComponentMounted = true
            const fetchData = async () =>
                axios
                    .get(
                        `${process.env.REACT_APP_API_URL}/Providers/getProviderById?id=${_providerId !== undefined ? _providerId : 0
                        }`,
                        { headers }
                    )
                    .then(response => {
                        if (isComponentMounted) {
                            fields.forEach(field => setValue(field, response[field]))
                        }
                        setPracticeID(response.practiceId)
                        setPhone(response.phoneNo)
                        setProviderType(response.type)
                        setssn(response.sSN)
                        setFax(response.fax)
                        setProviderChildList(response.linkedProviders)
                        // setLastName("Hardcoded")
                        var dateYMD = Moment(response.dOB).format('YYYY-MM-DD')
                        setdob(dateYMD)
                        //   if (response.type == 'Supervisor') {
                        //     axios
                        //       .get(`${process.env.REACT_APP_API_URL}/Providers/getProvidersByType?type=Provider`, { headers })
                        //       .then(response => {
                        //         console.log('providersdata: ', response)

                        //         var prov = []
                        //         response.forEach(res => {
                        //           prov.push({
                        //             name: res.name,
                        //             id: res.id
                        //           })
                        //         })
                        //         console.log(prov)
                        //         setProvidersList(prov)
                        //       })
                        //       .catch(error => {
                        //         console.error('There was an error!', error)
                        //       })
                        //   }
                    })
                    .catch(error => {
                        console.error('There was an error!', error)
                    })
            fetchData()
            return () => {
                isComponentMounted = false
            }
        } else {
            return
        }
        // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, [])
    // useEffect(() => {
    //   axios
    //     .get(`${process.env.REACT_APP_API_URL}/Providers/getProvidersByType?type=Provider`, { headers })
    //     .then(response => {
    //       setisLoading(false)
    //       console.log('providersdata: ', response)
    //       var prov = []
    //       response.forEach(res => {
    //         prov.push({
    //           name: res.name,
    //           id: res.id
    //         })
    //       })
    //       console.log(prov)
    //       setProvidersList(prov)
    //     })
    //     .catch(error => {
    //       console.error('There was an error!', error)
    //     })
    // }, [])
    ///Auto Compelet
    const [open, setOpen] = React.useState(false)
    const [options, setOptions] = React.useState([])
    const loading = open && options.length === 0
    const [providerType, setProviderType] = React.useState('')
    const [providerChildList, setProviderChildList] = React.useState([])
    const [providersList, setProvidersList] = React.useState([])
    const [selectedProviderIds, setselectedProviderIds] = React.useState([])
    const [lastName, setLastName] = React.useState('')
    ////Upload Image code
    const [images, setImages] = React.useState(null)
    //// Delete Pic Code
    const [base64Image, setBase64Image] = useState('');
    const deleteimg = () => {
        setImages(null)
        setValue('photoUrl', '')
    }

    const onImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImages(URL.createObjectURL(file)); // Preview the image
            convertBase64(file); // Convert to base64 and store
        }
    };

    // Convert file to base64 and store it in state
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => resolve(fileReader.result);
            fileReader.onerror = (error) => reject(error);
        }).then((base64) => {
            console.log('Base64 Image:', base64); // Debugging purpose
            setBase64Image(base64); // Store base64 in state
        });
    };

    
    
    const Input = styled('input')({
        display: 'none'
    })


    React.useEffect(() => {
        if (!open) {
            setOptions([])
        }
    }, [open])
    const [dOB, setdob] = React.useState()
    const [phone, setPhone] = React.useState()
    const [ssn, setssn] = React.useState()
    const [Fax, setFax] = React.useState()
    ///Modal
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '2px solid #4CB200',
        boxShadow: 24,
        p: 4
    }
    function handleCancel() {
        navigate('/Provider')
    }
    //   const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const [practicesList, setpracticesList] = useState([]);
    const Emailaddress = localStorage.getItem("Emailaddress")
    useEffect(() => {
        // POST request using axios inside useEffect React hook
        axios
          .get(`${process.env.REACT_APP_API_URL}/Practices/getPractices`, {
            headers
          })
          .then(response => {
            var prac = [];
            response.forEach(field => {
              prac.push({
                label: field.practiceName,
                value: field.id
              });
            });
            setpracticesList(prac);
            // response.forEach((field) => {
            //   practicesList.push({
            //     label: field.practiceName,
            //     value: field.id,
            //   });
            // });
          })
          .catch(error => {});
    
        // empty dependency array means this effect will only run once (like componentDidMount in classes)
      }, []);
    return (
        <React.Fragment>
            <div className="page-content" >
                <Container >
                   <Typography variant='h5' color='black' fontWeight="bold">Provider Registration</Typography>
                    <Grid container columnSpacing={1}>
                        <Grid container style={{ marginTop: '30px' }}>
                        <Grid item xs={3}>
    <Grid container style={{ marginLeft: '30px' }}>
        <Grid item xs={12}>
        <label htmlFor="icon-button-file">
                    <input
                        id="icon-button-file"
                        type="file"
                        onChange={onImageChange}
                        style={{ display: 'none' }}
                    />
                    <Avatar
                        sx={{ bgcolor: '#FCBE13' }}
                        src={images || base64Image} // Display base64 or image URL
                        style={{
                            height: 120,
                            width: 120,
                            borderRadius: '100px',
                        }}
                    >
                        {!images && !base64Image && (
                            <AddAPhotoIcon fontSize="large" style={{ color: 'black' }} />
                        )}
                    </Avatar>
                </label>
        </Grid>

        {images && (
            <Button
                onClick={deleteimg}
                variant="gradient"
                style={{
                    marginLeft: '20px',
                    width: '70px',
                    height: '35px',
                    color: 'black',
                    fontSize: '14px',
                }}
            >
                <DeleteOutlinedIcon
                    fontSize="medium"
                    style={{ color: 'red', marginBottom: '5px' }}
                />
                Remove
            </Button>
        )}
    </Grid>
</Grid>

                            <Grid item xs={9}>
                                <Grid container columnSpacing={1}>
                                    {providerType == 'Organization' ? (
                                        <Grid item xs={8} style={{ fontWeight: 500 }}>
                                            <Typography color='black' variant='caption' fontSize='15px'>
                                                Organization Name
                                            </Typography>
                                        </Grid>
                                    ) : (
                                        <>
                                            {' '}
                                            <Grid item xs={4} style={{ fontWeight: 500 }}>
                                                <Typography color='black' variant='caption' fontSize='15px'>
                                                    Last Name
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4} style={{ fontWeight: 500 }}>
                                                <Typography color='black' variant='caption' fontSize='15px'>
                                                    First Name
                                                </Typography>
                                            </Grid>
                                        </>
                                    )}
                                    <Grid item xs={4} style={{ fontWeight: 500 }}>
                                        <Typography color='black' variant='caption' fontSize='15px'>
                                            Title
                                        </Typography>
                                    </Grid>
                                    {providerType == 'Organization' ? (
                                        <Grid item xs={8} style={{ fontWeight: 500 }}>
                                            <Controller
                                                name='firstName'
                                                {...register('firstName')}
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        size='small'
                                                        placeholder='Please enter Full Name'
                                                        {...field}
                                                        fullWidth
                                                    />
                                                )}
                                            />

                                        </Grid>
                                    ) : (
                                        <>
                                            <Grid item xs={4}>
                                                <Controller
                                                    name='lastName'
                                                    {...register('lastName')}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            // isClearable
                                                            size='small'
                                                            placeholder='Please enter last name'
                                                            {...field}
                                                            fullWidth
                                                        // error={errors.lastName}
                                                        />
                                                    )}
                                                />
                                                {/* <p style={{ color: "red", fontSize: "14px" }}>{errors.lastName?.message}</p> */}
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Controller
                                                    name='firstName'
                                                    {...register('firstName')}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            // isClearable
                                                            size='small'
                                                            placeholder='Please enter First name'
                                                            {...field}
                                                            fullWidth
                                                            error={errors.firstName}
                                                        />
                                                    )}
                                                />
                                                <p style={{ color: 'red', fontSize: '14px' }}>{errors.firstName?.message}</p>
                                            </Grid>
                                        </>
                                    )}
                                    <Grid item xs={4}>
                                        <Controller
                                            name='title'
                                            {...register('title')}
                                            control={control}
                                            render={({ field }) => (
                                                <ReactSelect
                                                    // isClearable
                                                    {...field}
                                                    options={[
                                                        {
                                                            value: 'Dr.',
                                                            label: 'Dr.'
                                                        },
                                                    ]}
                                                    value={{ label: getValues('title') }}
                                                    onChange={value => setValue('title', value.label)}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4} style={{ fontWeight: 500 }}>
                                        <Typography color='black' variant='caption' fontSize='15px'>
                                            Type
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} style={{ fontWeight: 500 }}>
                                        <Typography color='black' variant='caption' fontSize='15px'>
                                            DOB
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={4} style={{ fontWeight: 500 }}>
                                        <Typography htmlFor='phone-number' color='black' variant='caption' fontSize='15px'>
                                            SSN
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            name='type'
                                            {...register('type')}
                                            control={control}
                                            render={({ field }) => (
                                                <ReactSelect
                                                    // isClearable
                                                    {...field}
                                                    options={[
                                                        {
                                                            value: 'Supervisor',
                                                            label: 'Supervisor'
                                                        },
                                                        {
                                                            value: 'Individual',
                                                            label: 'Individual'
                                                        },
                                                        {
                                                            value: 'Organization',
                                                            label: 'Organization'
                                                        }
                                                    ]}
                                                    value={{ label: getValues('type') }}
                                                    onChange={value => {
                                                        setselectProviderError(false)
                                                        setValue('type', value.label)
                                                        setProviderType(value.label)
                                                    }}
                                                />
                                            )}
                                        />
                                        {selectProviderError ? (
                                            <p style={{ color: 'red', fontSize: '13px', marign: '0px' }}>Please select a type</p>
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            name='mi'
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    size='small'
                                                    type='date'
                                                    fullWidth
                                                    placeholder='Enter DOB'
                                                    value={dOB ? dOB : ''}
                                                    onChange={e => {
                                                        setValue('dOB', e.target.value)
                                                        setdob(e.target.value)
                                                    }}
                                                />
                                            )}
                                        />


                                    </Grid>
                                    <Grid item xs={4}>
                                        <InputMask
                                            mask='999-99-9999'
                                            disabled={false}
                                            value={ssn}
                                            style={{ height: '30px' }}
                                            onChange={e => {
                                                setValue('ssn', e.target.value)
                                                setssn(e.target.value)
                                            }}
                                        >
                                            {() => <TextField id='outlined-basic' fullWidth size='small' placeholder='Enter SSN' />}
                                        </InputMask>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4} style={{ fontWeight: 500 }}>
                            <Typography color='black' variant='caption' fontSize='15px'>
                                Address
                            </Typography>
                        </Grid>
                        <Grid item xs={4} style={{ fontWeight: 500 }}>
                            <Typography color='black' variant='caption' fontSize='15px'>
                                Email
                            </Typography>
                        </Grid>
                        <Grid item xs={4} style={{ fontWeight: 500 }}>
                            <Typography color='black' variant='caption' fontSize='15px'>
                                Phone No
                            </Typography>
                        </Grid>

                        <Grid item xs={4}>
                            <Controller
                                name='address'
                                {...register('address')}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        size='small'
                                        placeholder='Please enter Address'
                                        {...field}
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                name='emailAddress'
                                {...register('emailAddress')}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        size='small'
                                        placeholder='Please enter EmailAddress'
                                        {...field}
                                        fullWidth

                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <InputMask
                                mask='+1 999-999-9999'
                                disabled={false}
                                value={phone}
                                style={{ height: '30px' }}
                                onChange={e => {
                                    setValue('phoneNo', e.target.value)
                                    setPhone(e.target.value)
                                }}
                            >
                                {() => <TextField id='outlined-basic' fullWidth size='small' placeholder='Enter Phone No' />}
                            </InputMask>
                        </Grid>
                        <Grid item xs={4} style={{ fontWeight: 500 }}>
                            <Typography color='black' variant='caption' fontSize='15px'>
                                Zip Code
                            </Typography>
                        </Grid>
                        <Grid item xs={4} style={{ fontWeight: 500 }}>
                            <Typography color='black' variant='caption' fontSize='15px'>
                                City
                            </Typography>
                        </Grid>
                        <Grid item xs={4} style={{ fontWeight: 500 }}>
                            <Typography color='black' variant='caption' fontSize='15px'>
                                State
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                name='zipcode'
                                {...register('zipCode')}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        // isClearable
                                        size='small'
                                        placeholder='Please enter ZipCode'
                                        {...field}
                                        fullWidth
                                        error={errors.zipCode}

                                    />
                                )}
                            />
                            <p style={{ color: 'red', fontSize: '14px' }}>{errors.zipCode?.message}</p>

                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                name='title'
                                {...register('city')}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        // isClearable
                                        size='small'
                                        placeholder='Please enter City'
                                        {...field}
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4}>
                        <Controller
                name='state'
                margin='dense'
                {...register('state')}
                control={control}
                render={({ field }) => (
                  <ReactSelect
                    style={{ marginTop: 0, marginBottom: 0 }}

                    {...field}
                    options={[
                      { value: 'AL', label: 'ALABAMA' },
                      { value: 'AK', label: 'ALASKA' },
                      { value: 'AS', label: 'AMERICAN SAMOA' },
                      { value: 'AZ', label: 'ARIZONA' },
                      { value: 'AR', label: 'ARKANSAS' },
                      { value: 'CA', label: 'CALIFORNIA' },
                      { value: 'CO', label: 'COLORADO' },
                      { value: 'CT', label: 'CONNECTICUT' },
                      { value: 'DE', label: 'DELAWARE' },
                      { value: 'DC', label: 'DISTRICT OF COLUMBIA' },
                      { value: 'FL', label: 'FLORIDA' },
                      { value: 'GA', label: 'GEORGIA' },
                      { value: 'GU', label: 'GUAM' },
                      { value: 'HI', label: 'HAWAII' },
                      { value: 'ID', label: 'IDAHO' },
                      { value: 'IL', label: 'ILLINOIS' },
                      { value: 'IN', label: 'INDIANA' },
                      { value: 'IA', label: 'IOWA' },
                      { value: 'KS', label: 'KANSAS' },
                      { value: 'KY', label: 'KENTUCKY' },
                      { value: 'LA', label: 'LOUISIANA' },
                      { value: 'ME', label: 'MAINE' },
                      { value: 'MD', label: 'MARYLAND' },
                      { value: 'MA', label: 'MASSACHUSETTS' },
                      { value: 'MI', label: 'MICHIGAN' },
                      { value: 'MN', label: 'MINNESOTA' },
                      { value: 'MS', label: 'MISSISSIPPI' },
                      { value: 'MO', label: 'MISSOURI' },
                      { value: 'MT', label: 'MONTANA' },
                      { value: 'NE', label: 'NEBRASKA' },
                      { value: 'NV', label: 'NEVADA' },
                      { value: 'NH', label: 'NEW HAMPSHIRE' },
                      { value: 'NJ', label: 'NEW JERSEY' },
                      { value: 'NM', label: 'NEW MEXICO' },
                      { value: 'NY', label: 'NEW YORK' },
                      { value: 'NC', label: 'NORTH CAROLINA' },
                      { value: 'ND', label: 'NORTH DAKOTA' },
                      { value: 'MP', label: 'NORTHERN MARIANA IS' },
                      { value: 'OH', label: 'OHIO' },
                      { value: 'OK', label: 'OKLAHOMA' },
                      { value: 'OR', label: 'OREGON' },
                      { value: 'PA', label: 'PENNSYLVANIA' },
                      { value: 'PR', label: 'PUERTO RICO' },
                      { value: 'PI', label: 'RHODE ISLAND' },
                      { value: 'SC', label: 'SOUTH CAROLINA' },
                      { value: 'SD', label: 'SOUTH DAKOTA' },
                      { value: 'TN', label: 'TENNESSEE' },
                      { value: 'TX', label: 'TEXAS' },
                      { value: 'UT', label: 'UTAH' },
                      { value: 'VT', label: 'VERMONT' },
                      { value: 'VA', label: 'VIRGINIA' },
                      { value: 'VI', label: 'VIRGIN ISLANDS' },
                      { value: 'WA', label: 'WASHINGTON' },
                      { value: 'WV', label: 'WEST VIRGINIA' },
                      { value: 'WI', label: 'WISCONSIN' },
                      { value: 'WY', label: 'WYOMING' },

                    ]}
                    value={{ label: getValues('state') }}
                    onChange={value => setValue('state', value.label)}
                    size='small'
                  />
                )}
              />
                        </Grid>
                        <Grid item xs={4} style={{ fontWeight: 500 }}>
                            <Typography color='black' variant='caption' fontSize='15px'>
                                Fax
                            </Typography>
                        </Grid>
                        <Grid item xs={4} style={{ fontWeight: 500 }}>
                            <Typography color='black' variant='caption' fontSize='15px'>
                                NPI
                            </Typography>
                        </Grid>
                        <Grid item xs={4} style={{ fontWeight: 500 }}>
                            <Typography color='black' variant='caption' fontSize='15px'>
                                UPIN
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <InputMask
                                mask=' 999-9999999'
                                disabled={false}
                                value={Fax}
                                style={{ height: '30px' }}
                                onChange={e => {
                                    setValue('fax', e.target.value)
                                    setFax(e.target.value)
                                }}
                            >
                                {() => <TextField id='outlined-basic' fullWidth size='small' placeholder='Enter Fax' />}
                            </InputMask>
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                name='title'
                                {...register('nPI')}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        // isClearable
                                        size='small'
                                        placeholder='Please enter NPI'
                                        {...field}
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                name='title'
                                {...register('uPin')}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        // isClearable
                                        size='small'
                                        placeholder='Please enter UPIN'
                                        {...field}
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4} style={{ fontWeight: 500 }}>
                            <Typography color='black' variant='caption' fontSize='15px'>
                                Tax ID
                            </Typography>
                        </Grid>
                        <Grid item xs={4} style={{ fontWeight: 500 }}>
                            <Typography color='black' variant='caption' fontSize='15px'>
                                Taxonomy
                            </Typography>
                        </Grid>
                        <Grid item xs={4} style={{ fontWeight: 500 }}>
                            <Typography color='black' variant='caption' fontSize='15px'>
                            Practice
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                name='taxId'
                                {...register('taxId')}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        // isClearable
                                        size='small'
                                        placeholder='Please enter TaxID'
                                        {...field}
                                        fullWidth
                                        error={errors.taxId}

                                    />
                                )}
                            />
                      <p style={{ color: 'red', fontSize: '14px' }}>{errors.taxId?.message}</p>
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                name='taxoNomy'
                                {...register('taxoNomy')}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        // isClearable
                                        size='small'
                                        placeholder='Please enter Taxonomy'
                                        {...field}
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4} style={{ width: '300px' }}>
                        <Controller
                    name="practiceId"
                    {...register("practiceId")}
                    control={control}
                    render={({ field }) =>
                      <ReactSelect
                        {...field}
                        fullWidth
                        value={practicesList.filter(function(option) {
                          return (
                            option.value === getValues("practiceId")
                          );
                        })}
                        onChange={e => {
                            setValue("practiceId", e.value);
                            setPracticeID(e.value);
                            setPracticeidError(false)
                            // setDefaultPracticeErrorId(e.value);
                            // setDefaultPracticeError(false);
                          }}
                        options={practicesList}
                      />
                      // <ReactSelect
                      //   onChange={(e) => setUserPractice(e.value)}
                      //   options={practicesList}
                      // />
                    }
                  />
  {PracticeidError ? (
                                            <p style={{ color: 'red', fontSize: '13px', marign: '0px' }}>Please select a Practice</p>
                                        ) : null}
                        </Grid>
                        <Grid item xs={4} style={{ fontWeight: 500 }}>
                            <Typography color='black' variant='caption' fontSize='15px'>
                            Attending Physician
                            </Typography>
                        </Grid>
                        <Grid item xs={8} style={{ fontWeight: 500 }}>
                            <Typography color='black' variant='caption' fontSize='15px'>
                                Billing Physician
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <input
                                name='attendingPhysician'
                                size='small'
                                type='checkbox'
                                {...register('attendingPhysician')}
                                id='attendingPhysician'
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <input
                                name='billingPhysician'
                                type='checkbox'
                                {...register('billingPhysician')}
                                id='billingPhysician'
                            />
                        </Grid>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={12} style={{ fontWeight: 500 }}></Grid>
                        {providerType == 'Supervisor' ? (
                            <Grid item xs={4} style={{ fontWeight: 500 }}>
                                <Typography color='black' variant='caption' fontSize='15px'>
                                    Providers / Team
                                </Typography>
                                <Multiselect
                                    displayValue='name'
                                    isObject={true}
                                    onRemove={function unlinkProviderToSupervisor(list, item) {
                                        setselectedProviderIds(list)
                                        console.log('mlist', selectedProviderIds)
                                    }}
                                    onSelect={function linkProviderToSupervisor(list, item) {
                                        setselectedProviderIds(list)
                                        console.log('mlist', selectedProviderIds)
                                    }}
                                    options={providersList}
                                    selectedValues={providerChildList}
                                    showCheckbox
                                />
                            </Grid>
                        ) : (
                            <></>
                        )}
                        <Grid item xs={12} lg={12} sm={12}>
                            <Button
                            className= "Button"
                                onClick={handleCancel}
                                variant='gradient'
                                disabled={submitting}
                                style={{
                                    marginTop: '30px',
                                    marginBottom: '10px',
                                    float: 'right',
                                    marginLeft: '20px',
                                    width: '90px',
                                    height: '35px',
                                    // backgroundColor: '#A574FD',
                                    color: 'white',
                                    fontSize: '14px'
                                }}
                            >
                                <CancelOutlinedIcon
                                    fontSize='medium'
                                    style={{ color: 'white', paddingRight: '5px' }}
                                ></CancelOutlinedIcon>
                                Cancel
                            </Button>
                            <Button
                            className= "Button"
                                onClick={handleSubmit(onSubmit)}
                                variant='gradient'
                                disabled={submitting}
                                style={{
                                    marginTop: '30px',
                                    marginBottom: '10px',
                                    float: 'right',
                                    marginLeft: 'auto',
                                    width: '80px',
                                    height: '35px',
                                    backgroundColor: '#A574FD',
                                    color: 'white',
                                    fontSize: '14px'
                                }}
                            >
                                <SaveOutlinedIcon
                                    fontSize='medium'
                                    style={{
                                        color: 'white',
                                        paddingRight: '5px',
                                        paddingBottom: '2px'
                                    }}
                                ></SaveOutlinedIcon>
                                Save
                            </Button>
                        </Grid>
                    </Grid>

                </Container>
            </div>
        </React.Fragment>

    )

}

export default ProviderRegistration