import { useCallback, useMemo, useState, useEffect } from 'react';
import React from 'react';
import { Box, Button, Container, Stack, SvgIcon, Typography, Grid, Snackbar, TextField } from '@mui/material';

import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';
import ReactSelect from 'react-select';
import InputMask from 'react-input-mask';
// import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import TextareaAutosize from '@mui/material/TextareaAutosize';
// import AddIcon from 'assets/add.png'
// import { Grid, Snackbar, TextField } from '@mui/material'
// import { useRouter, withRouter } from 'next/router'
import { useAlert } from 'react-alert'
import { useNavigate } from 'react-router-dom';
// Using FormControl for Radio Button
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Moment from 'moment'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import VOBPAGE1 from "./Vobpage1";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
// import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import PermIdentity from '@mui/icons-material/PermIdentity'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'





const Demographics = (props) => {
  document.title = "Demographics | LuxBillingEHR";

  const fields = [
    'id',
    'accountNumber',
    'lastName',
    'firstName',
    'midInitial',
    'title',
    'alias',
    'ssn',
    'dob',
    'gender',
    'status',
    'cellNo',
    'language',
    'maritalStatus',
    'homePhone',
    'cellPhone',
    'workPhone',
    'emailAddress',
    'city',
    'state',
    'zipCode',
    'address',
    'photoUrl',
    'comments',
    'practiceId',
    'isActive',
    'updatedBy',
    'updatedDate',
    'createdBy',
    'createdDate',
    'providerId',
    'facilityId',
    'careTeam_id',
    'estimatedMonthlyTime',
    'assignedTo',
    'dueDate',
    'careManagementStatus',
    'intakeStatus',
    'diagnosis1',
    'diagnosis2',
    'lastCommunicatedDate',
    'patientStarted',
    'salesRep',
    'socialWorker',
    'referralSource',
    'dischargeDate',
    'hmo',
    'ppo',
    'medicareStatus',
    'frequencyOfContact',
    'careManager',
    'leadCareManager',
    'intakeCoordinator',
    'inactiveDate',
    'initialVisitDate',
    'carePlanStatus',
    'additionalContactInfo',
    'pcpDoctorInfo',
    'careManagerSupervisor'
  ]
  // const defValues = {
  //     id: 0,
  //     accountNumber: '',
  //     diagnosis1: '',
  //     lastCommunicatedDate: '',
  //     patientStarted: '',
  //     lastName: '',
  //     firstName: '',
  //     midInitial: '',
  //     title: '',
  //     alias: '',
  //     ssn: '',
  //     dob: '',
  //     gender: '',
  //     status: '',
  //     cellNo: '',
  //     language: '',
  //     maritalStatus: '',
  //     homePhone: '',
  //     cellPhone: '',
  //     workPhone: '',
  //     emailAddress: '',
  //     city: '',
  //     state: '',
  //     zipCode: '',
  //     address: '',
  //     photoUrl: '',
  //     comments: '',
  //     practiceId: '',
  //     isActive: '',
  //     updatedBy: '',
  //     updatedDate: '',
  //     createdBy: '',
  //     createdDate: '',
  //     providerId: '',
  //     facilityId: '',
  //     careTeam_id: '',
  //     estimatedMonthlyTime: '',
  //     assignedTo: '',
  //     dueDate: '',
  //     careManagementStatus: '',
  //     intakeStatus: '',
  //     diagnosis2: '',
  //     salesRep: '',
  //     socialWorker: '',
  //     referralSource: '',
  //     dischargeDate: '',
  //     hmo: '',
  //     ppo: '',
  //     medicareStatus: '',
  //     frequencyOfContact: '',
  //     careManager: '',
  //     leadCareManager: '',
  //     intakeCoordinator: '',
  //     inactiveDate: '',
  //     initialVisitDate: '',
  //     carePlanStatus: '',
  //     additionalContactInfo: '',
  //     pcpDoctorInfo: '',
  //     careManagerSupervisor: ''
  // }
  var defaultValues = {
    priIns: {
      id: 0,
      insuranceId: '',
      patientId: '',
      groupId: '',
      memberId: '',
      practiceId: '',
      payerId: '',
      type: 'primary',
      copay: '',
      deductible: '',
      subsRelation: '',
      subsFirtName: '',
      subsLastName: '',
      guarranterName: '',
      guarantorAddress: '',
      guarantorSuite: '',
      guarantorCity: '',
      guarantorState: '',
      guarantorZip: '',
      inactive: '',
      startDate: '',
      endDate: '',
      createdDate: '',
      updatedDate: '',
      createdBy: '',
      updatedBy: '',
      clientCreatedDate: '',
      clientUpdatedDate: '',
    },
    id: 0,
    accountNumber: '',
    lastName: '',
    firstName: '',
    midInitial: '',
    title: '',
    alias: '',
    ssn: '',
    dob: '',
    gender: '',
    status: '',
    cellNo: '',
    language: '',
    maritalStatus: '',
    homePhone: '',
    cellPhone: '',
    workPhone: '',
    emailAddress: '',
    city: '',
    state: '',
    zipCode: '',
    address: '',
    photoUrl: '',
    program: '',
    smokingStatus: '',
    comments: '',
    practiceId: '',
    facilityId: '',
    providerId: '',
    isActive: '',
    updatedBy: '',
    updatedDate: '',
    createdBy: '',
    createdDate: '',
    base64: '',
    secIns: {
      id: 0,
      insuranceId: '',
      patientId: '',
      groupId: '',
      memberId: '',
      practiceId: '',
      payerId: '',
      type: 'secondary',
      copay: '',
      deductible: '',
      subsRelation: '',
      subsFirtName: '',
      subsLastName: '',
      guarranterName: '',
      guarantorAddress: '',
      guarantorSuite: '',
      guarantorCity: '',
      guarantorState: '',
      guarantorZip: '',
      inactive: '',
      startDate: '',
      endDate: '',
      createdDate: '',
      updatedDate: '',
      createdBy: '',
      updatedBy: '',
      clientCreatedDate: '',
      clientUpdatedDate: '',
    },
  }
  function resetAfterAPICall(value) {
    var data = {
      id: value.id,
      accountNumber: value.accountNumber,
      lastName: value.lastName,
      firstName: value.firstName,
      midInitial: value.midInitial,
      title: value.title,
      alias: value.alias,
      ssn: value.ssn,
      dob: value.dob,
      gender: value.gender,
      status: value.status,
      cellNo: value.cellNo,
      language: value.language,
      maritalStatus: value.maritalStatus,
      homePhone: value.homePhone,
      cellPhone: value.cellPhone,
      workPhone: value.workPhone,
      emailAddress: value.emailAddress,
      city: value.city,
      state: value.state,
      zipCode: value.zipCode,
      address: value.address,
      photoUrl: value.photoUrl,
      program: value.program,
      smokingStatus: value.smokingStatus,
      comments: value.comments,
      practiceId: value.practiceId,
      facilityId: value.facilityId,
      careTeam_id: value.careTeam_id,
      providerId: value.providerId,
      isActive: value.isActive,
      updatedBy: value.updatedBy,
      updatedDate: value.updatedDate,
      createdBy: value.createdBy,
      createdDate: value.createdDate,
      base64: value.base64,
      priIns: {},
      secIns: {},
    }
    if (
      value.priIns !== undefined &&
      value.priIns !== null &&
      value.priIns.id > 0
    ) {
      var _priIns = {
        id: value.priIns.id,
        insuranceId: value.priIns.insuranceId,
        planName: value.priIns.planName,
        patientId: value.priIns.patientId,
        groupId: value.priIns.groupId,
        memberId: value.priIns.memberId,
        practiceId: value.priIns.practiceId,
        payerId: value.priIns.payerId,
        type: value.priIns.typerimary,
        copay: value.priIns.copay,
        deductible: value.priIns.deductible,
        subsRelation: value.priIns.subsRelation,
        subsFirtName: value.priIns.subsFirtName,
        subsLastName: value.priIns.subsLastName,
        guarranterName: value.priIns.guarranterName,
        guarantorAddress: value.priIns.guarantorAddress,
        guarantorSuite: value.priIns.guarantorSuite,
        guarantorCity: value.priIns.guarantorCity,
        guarantorState: value.priIns.guarantorState,
        guarantorZip: value.priIns.guarantorZip,
        inactive: value.priIns.inactive,
        startDate: Moment(value.priIns.startDate).format('YYYY-MM-DD'),
        endDate: Moment(value.priIns.endDate).format('YYYY-MM-DD'),
        createdDate: value.priIns.createdDate,
        updatedDate: value.priIns.updatedDate,
        createdBy: value.priIns.createdBy,
        updatedBy: value.priIns.updatedBy,
        clientCreatedDate: value.priIns.clientCreatedDate,
        clientUpdatedDate: value.priIns.clientUpdatedDate,
      }
      data.priIns = _priIns
    } else {
      data.priIns = defaultValues.priIns
    }
    if (
      value.secIns != undefined &&
      value.secIns != null &&
      value.secIns.id > 0
    ) {
      var _secIns = {
        id: value.secIns.id,
        insuranceId: value.secIns.insuranceId,
        planName: value.secIns.planName,
        patientId: value.secIns.patientId,
        groupId: value.secIns.groupId,
        memberId: value.secIns.memberId,
        practiceId: value.secIns.practiceId,
        payerId: value.secIns.payerId,
        type: value.secIns.type,
        copay: value.secIns.copay,
        deductible: value.secIns.deductible,
        subsRelation: value.secIns.subsRelation,
        subsFirtName: value.secIns.subsFirtName,
        subsLastName: value.secIns.subsLastName,
        guarranterName: value.secIns.guarranterName,
        guarantorAddress: value.secIns.guarantorAddress,
        guarantorSuite: value.secIns.guarantorSuite,
        guarantorCity: value.secIns.guarantorCity,
        guarantorState: value.secIns.guarantorState,
        guarantorZip: value.secIns.guarantorZip,
        inactive: value.secIns.inactive,
        startDate: Moment(value.secIns.startDate).format('YYYY-MM-DD'),
        endDate: Moment(value.secIns.endDate).format('YYYY-MM-DD'),
        createdDate: value.secIns.createdDate,
        updatedDate: value.secIns.updatedDate,
        createdBy: value.secIns.createdBy,
        updatedBy: value.secIns.updatedBy,
        clientCreatedDate: value.secIns.clientCreatedDate,
        clientUpdatedDate: value.secIns.clientUpdatedDate,
      }
      data.secIns = _secIns
    } else {
      data.secIns = defaultValues.secIns
    }
    const deepCopy = JSON.parse(JSON.stringify(data))
    reset(deepCopy)
  }
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const navigate = useNavigate();

  const alert = useAlert()
  const patientId = props.patientid
  // console.log('patient Id', patientId)
  const [zipDownloaded, setzipDownloaded] = useState(false)
  const [openPracMenu, setOpenPracMenu] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [fetchedData, setfetchedData] = useState(false)
  const [CareTeamList, setCareTeamList] = useState([])
  const [lastName, setLastName] = useState('')
  const [fName, setFirstName] = useState('')
  const [apiError, setApiError] = useState('')
  const [assigned, setassignedTo] = useState('')
  const [insuranceNames, setinsuranceNames] = React.useState([])
  const [openInsurances, setOpenInsurances] = useState(0)
  const [open, setOpen] = React.useState(false)
  const [zipCodeList, setZipCodeList] = React.useState([])
  const today = new Date()
  var startDate = new Date()
  startDate.setFullYear(startDate.getFullYear() - 150)
  const validationSchema = Yup.object({
    // emailAddress: Yup.string().required('Email is required'),
    // emailAddress: Yup.string()
    //   .required('Email is invalid')
    //   .email('Email is invalid'),
      firstName: Yup.string()
      .required('First Name is required'),
      // .matches(/^[A-Za-z]+$/, 'First Name must only contain alphabetic characters'),
    lastName: Yup.string()
      .required('Last Name is required'),
      // .matches(/^[A-Za-z]+$/, 'Last Name must only contain alphabetic characters'),
      address: Yup.string()
      .required('Address is required'),
      // .matches(/^[A-Za-z0-9\s,.-]+$/, 'Address can only contain alphanumeric characters, spaces, commas, periods, and hyphens'),
    gender: Yup.string().required('Gender is required'),
    zipCode: Yup.string()
    .required('Zip Code is required')
    .matches(/^\d{5}$/, 'Zip Code must be exactly 5 digits'),
    status: Yup.string().required('Status is required'),
    city: Yup.string()
    .required('City is required')
    .matches(/^[A-Za-z\s-]+$/, 'City can only contain letters, spaces, and hyphens'),
    // .matches(/^[A-Za-z\s]+$/, 'City must only contain alphabetic characters'),
    state: Yup.string().required('State is required'),
    priIns: Yup.object({
      planName: Yup.string().required('Insurance  is required'),
    memberId: Yup.string().required('Member ID is required'),
    subsRelation: Yup.string().required('Relation is required'),
    // groupId: Yup.string().required('GroupId is required')
    // .matches(/^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]+$/, 'GroupId must contain both letters and numbers')
    }),
    
    // secIns: Yup.object({
    //   planName: Yup.string().required('Insurance  is required'),
    // memberId: Yup.string().required('Member ID is required'),
    // subsRelation: Yup.string().required('Relation is required'),
    // groupId: Yup.string().required('GroupId is required')
    // .matches(/^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]+$/, 'GroupId must contain both letters and numbers')
    // }),
    
    dob: Yup.date()
      .required('dob is required')
      .nullable()
      .transform(v => (v instanceof Date && !isNaN(v) ? v : null))
      .max(today, "You can't be born in the future!")
      .min(startDate, "You can't be that much old!")
  })
  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues
  })

  function handleCancel() {
    // if (props.callingFrom == "Patient") {
    //   props.handleClosepatientreg()
    // }
    // else if (props.callingFrom == "dashboard") {
    //   props.CloseModal()
    // }
    // else {
    //   navigate('/Patient')
    // }
    if (props.Authorization === 'Authorization') {
      // handleClosepatientreg()
      props.handleClosepatientreg.props.children()
    } else {
      navigate('/Patient');

    }

  }
  const [phone, setPhone] = React.useState()
  const [duedate, setDuedate] = React.useState()
  const [inactiveDate, setinactiveDate] = React.useState()
  const [initialVisitDate, setinitialvisitDate] = React.useState()

  const [LastCommunicatedDate, setLastCommunicatedDate] = React.useState()
  const [DischargeDate, setDischargeDate] = React.useState()
  const [Dob, setDob] = React.useState(Moment().format('YYYY-MM-DD'))
  const [cellPhone, setcellPhone] = React.useState()
  const [workPhone, setworkPhone] = React.useState()
  const [disable, setdisable] = React.useState(false)

  const [email, setemail] = React.useState()
  const [insuranceError, setInsuranceError] = React.useState(false);
  console.log(insuranceError,'mmmmmmmmmmmmmmm')
  const [ssN, setssn] = React.useState()
  const onSubmit = data => {
    console.log('data', data)
    if (data.dob != undefined && data.dob != null) data.dob = data.dob.toLocaleString()
    if (!data.priIns.planName) {
      setInsuranceError(true);
      return; 
    } 
    setSubmitting(true)
    setdisable(true)
    const postData = data
    console.log('check Data', postData)
    if (Self == true) {
      postData.priIns.subsLastName = postData.lastName
      postData.priIns.subsFirtName = postData.firstName
    }

    if (Selected == true) {
      postData.secIns.subsLastName = postData.lastName
      postData.secIns.subsFirtName = postData.firstName

    }
    if (patientId !== undefined && patientId != null) postData.id = parseInt(patientId)
    axios
      .post(`${process.env.REACT_APP_API_URL}/Patients/addPatient`, postData, {
        headers
      })
      .then(response => {

        if (response.id) {
          console.log('check Data', response)
          setSubmitting(false)
          // setselectProviderError(false)

          alert.success('Record saved successfully', {
            type: 'success',
            onClose: () => {
              if (props.callingFrom == 'Patient') {
                // handleClosepatientreg()
                props.handleClosepatientdem()
              }
              else if (props.callingFrom == true) {
                props.handleClosepatientreg()

              }
              else {
                navigate('/Patient');

              }
              setdisable(false);

            }
          })
        }
        else if (props.callingFrom == "dashboard") {
          props.CloseModal()
        }



        else {
          setSubmitting(false)
          setdisable(false);
          navigate('/Patient')
        }
      })
      .catch(error => {
        setSubmitting(false)
        setdisable(false);
        alert.success(`Error ${error.message}`, {
          type: 'error'
        })
      })
  }
  const onchangeinsurce = async (value) => {
    // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions
    console.log('getInsuranceByName: ', value)

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/Insurances/getInsuranceByName?Name=${value}`,
        { headers }
      )
      .then((response) => {
        setOpenInsurances(true)
        setinsuranceNames(response)
      })
      .catch((error) => {
        console.error('There was an error!', error)
      })
  }

  const [Selected, setSelected] = useState(false);
  const [PfirstName, setPfirstName] = useState('')
  const [PLastName, setpLastName] = useState('')
  // const [SfirstName ,setSfirstName]=useState('')
  // const [SLastName,setSLastName]=useState('')
  const [Self, setSelf] = useState(false);

  useEffect(() => {
    if (patientId > 0) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/Patients/getPatientById?id=${patientId !== undefined ? patientId : 0}`,
          { headers }
        )
        .then(response => {
          console.log('response.data: 2', response)
          // fields.forEach(field => {
          //     setValue(field, response[field])
          // })
          resetAfterAPICall(response)
          setzipDownloaded(true)
          setPhone(response.homePhone)
          setcellPhone(response.cellPhone)
          setworkPhone(response.workPhone)
          setPfirstName(response.firstName)
          // setSfirstName(response.firstName)
          // setSLastName(response.lastName)
          setpLastName(response.lastName)
          setssn(response.ssn)
          var dateYMD = Moment(response.dob).format('YYYY-MM-DD')
          setDob(dateYMD)
          // console.log('dateYMD', dateYMD)
          var duedateYMD = Moment(response.dueDate).format('YYYY-MM-DD')
          setDuedate(duedateYMD)
          setemail(response.emailAddress)
          console.log("emailAddressis ", response.emailAddress)

        })

        .catch(error => {
          console.error('There was an error!', error)
        })
    }
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [patientId])
  const [tabValueIns, settabValueIns] = React.useState(0)
  const handleChangeInsurances = (event, newValue) => {
    settabValueIns(newValue)
  }
  const zipCodeListStyle = {
    cursor: 'pointer',
    '&:hover': {
      background: '#3b3b3b !important',
      color: 'rgba(5, 152, 236, 0.637) !important',
    },
  }
  const [radioValue, setradioValue] = useState('3');
  const optionChange5 = (event) => {
    console.log("new change---- ", event)
    //  setprescribedMedicines(true); 
    //  setprescribedMedicines(event.target.value);
    // console.log("Value is " , event)
  };
 
  return (
    <React.Fragment >
      <div className="page-content" style={{ marginTop: '-100px' }}>
        <Container >
          <Grid container columnSpacing={1}>
            <Grid item xs={4}>
              <Typography component='label' color='black' >
                Email address
              </Typography>
            </Grid>
            <Grid item xs={4} >
              <Typography component='label' color='black' >
                Last Name
              </Typography>
              <label style={{ fontWeight: "bold", color: "red", fontSize: '15px' }}>*</label>
            </Grid>
            <Grid item xs={4} >
              <Typography component='label' color='black'>
                First Name
              </Typography>
              <label style={{ fontWeight: "bold", color: "red", fontSize: '15px' }}>*</label>
            </Grid>
            <Grid item xs={4}>
              <Controller
                name='emailAddress'
                {...register('emailAddress')}
                control={control}
                render={({ field }) => (
                  <TextField
                    size='small'
                    fullWidth
                    {...field}
                    style={{backgroundColor:'white'}}
                    name="email"
                    type="email"
                  // error={errors.emailAddress}
                  />

                )}
              />



              {/* <p style={{ color: 'red', fontSize: '14px' }}>{errors.emailAddress?.message}</p> */}
            </Grid>
            <Grid item xs={4}>
              <Controller
                name='lastName'
                {...register('lastName')}
                control={control}
                render={({ field }) => (
                  <TextField
                    size='small'
                    fullWidth
                    style={{backgroundColor:'white'}}
                    {...field}
                    type="text"
                    value={PLastName ? PLastName : ''}
                    onChange={e => {
                      setValue('lastName', e.target.value)
                      setpLastName(e.target.value)
                    }}
                    error={errors.lastName}
                  />

                )}
              />
              <p style={{ color: 'red', fontSize: '14px' }}>{errors.lastName?.message}</p>
            </Grid>
            <Grid item xs={4}>
              <Controller
                name='firstName'
                {...register('firstName')}
                control={control}
                render={({ field }) => (
                  <TextField
                    size='small'
                    style={{backgroundColor:'white'}}
                    fullWidth
                    {...field}
                    type="text"
                    value={PfirstName ? PfirstName : ''}
                    onChange={e => {
                      setValue('firstName', e.target.value)
                      setPfirstName(e.target.value)
                    }}

                    error={errors.firstName}

                  />

                )}
              />

              <p style={{ color: 'red', fontSize: '14px' }}>{errors.firstName?.message}</p>
            </Grid>
            <Grid item xs={4} >
              <Typography component='label' color='black' >
                DOB
              </Typography>
              <label style={{ fontWeight: "bold", color: "red", fontSize: '15px' }}>*</label>
            </Grid>
            <Grid item xs={4} >
              <Typography component='label' color='black' >
                Gender
              </Typography>
              <label style={{ fontWeight: "bold", color: "red", fontSize: '15px' }}>*</label>
            </Grid>

            <Grid item xs={4} >
              <Typography htmlFor='phone-number' component='label' color='black' >
                SSN
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Controller
                name='dob'
                control={control}
                render={({ field }) => (
                  <TextField
                    type='date'
                    size='small'
                    fullWidth
                    {...field}
                    style={{backgroundColor:'white'}}
                    placeholder='Enter dob'
                    value={Dob ? Dob : ''}
                    onChange={e => {
                      setValue('dob', e.target.value)
                      setDob(e.target.value)
                    }}
                    error={errors.dob}
                  />
                )}
              />
              <p style={{ color: 'red', fontSize: '14px' }}>{errors.dob?.message}</p>
            </Grid>
            <Grid item xs={4} style={{ paddingBottom: '0px' }}>
              <Controller
                name='gender'
                margin='dense'
                {...register('gender')}
                control={control}
                render={({ field }) => (
                  <ReactSelect
                    style={{ marginTop: 0, marginBottom: 0 }}

                    {...field}
                    options={[
                      { value: 'Male', label: 'Male' },
                      { value: 'Female', label: 'Female' },
                      { value: 'Other', label: 'Other' }
                    ]}
                    value={{ label: getValues('gender') }}
                    onChange={value => setValue('gender', value.label)}
                    size='small'
                    error={errors.gender}
                  />
                )}
              />
               <p style={{ color: 'red', fontSize: '14px' }}>{errors.gender?.message}</p>
            </Grid>
            <Grid item xs={4}>
              <InputMask
                mask='999-99-9999'
                disabled={false}
                value={ssN}
                style={{ height: '30px' }}
                onChange={e => {
                  setValue('ssn', e.target.value)
                  setssn(e.target.value)
                }}
              >
                {() => <TextField id='outlined-basic' fullWidth size='small' placeholder='Enter SSN'  style={{backgroundColor:'white'}}  />}
              </InputMask>
            </Grid>
            <Grid item xs={4} >
              <Typography component='label' color='black' >
                Cell Phone
              </Typography>
            </Grid>
            <Grid item xs={4} >
              <Typography component='label' color='black' >
                Address
              </Typography>
              <label style={{ fontWeight: "bold", color: "red", fontSize: '15px' }}>*</label>
            </Grid>
            <Grid item xs={4} >
              <Typography component='label' color='black' >
                Status
              </Typography>
              <label style={{ fontWeight: "bold", color: "red", fontSize: '15px' }}>*</label>
            </Grid>
            <Grid item xs={4}>
              <InputMask
                mask='+1 999-999-9999'
                disabled={false}
                value={cellPhone}
                style={{ height: '30px' }}
                onChange={e => {
                  setValue('cellPhone', e.target.value)
                  setcellPhone(e.target.value)
                }}
              >
                {() => <TextField id='outlined-basic'  style={{backgroundColor:'white'}}fullWidth size='small' placeholder='Enter Cell Phone' />}
              </InputMask>
            </Grid>
            <Grid item xs={4}>
              <Controller
                name='address'
                {...register('address')}
                control={control}
                render={({ field }) => (
                  <TextField
                    // isClearable
                    size='small'
                    style={{backgroundColor:'white'}}
                    placeholder='Enter Address'
                    {...field}
                    fullWidth
                  error={errors.address}
                  />
                )}
              />
              <p style={{ color: 'red', fontSize: '14px' }}>{errors.address?.message}</p>
            </Grid>
            <Grid item xs={4}>
              <Controller
                name='status'
                margin='dense'
                {...register('status')}
                control={control}
                render={({ field }) => (
                  <ReactSelect
                    style={{ marginTop: 0, marginBottom: 0 }}

                    {...field}
                    options={[
                      { value: 'Active', label: 'Active' },
                      { value: 'InActive', label: 'InActive' },
                      { value: 'Discharge', label: 'Discharge' }
                    ]}
                    value={{ label: getValues('status') }}
                    onChange={value => setValue('status', value.label)}
                    size='small'
                    error={errors.status}
                  />
                )}
              />
               <p style={{ color: 'red', fontSize: '14px' }}>{errors.status?.message}</p>
            </Grid>

            <Grid item xs={4} >
              <Typography component='label' color='black'  >
                City
              </Typography>
              <label style={{ fontWeight: "bold", color: "red", fontSize: '15px' }}>*</label>
            </Grid>
            <Grid item xs={4} >
              <Typography component='label' color='black' >
                State
              </Typography>
              <label style={{ fontWeight: "bold", color: "red", fontSize: '15px' }}>*</label>
            </Grid>
            <Grid item xs={4} >
              <Typography component='label' color='black'  >
                Zip Code
              </Typography>
              <label style={{ fontWeight: "bold", color: "red", fontSize: '15px' }}>*</label>
            </Grid>

            <Grid item xs={4}>
              <Controller
                name='city'
                {...register('city')}
                control={control}
                render={({ field }) => (
                  <TextField
                    // isClearable
                    size='small'
                    style={{backgroundColor:'white'}}
                    placeholder='Please enter City'
                    {...field}
                    fullWidth
                    error={errors.city}
                  />
                )}
              />
              <p style={{ color: 'red', fontSize: '14px' }}>{errors.city?.message}</p>
            </Grid>
            <Grid item xs={4}>

              <Controller
                name='state'
                margin='dense'
                {...register('state')}
                control={control}
                render={({ field }) => (
                  <ReactSelect
                    style={{ marginTop: 0, marginBottom: 0 }}

                    {...field}
                    options={[
                      { value: 'AL', label: 'ALABAMA' },
                      { value: 'AK', label: 'ALASKA' },
                      { value: 'AS', label: 'AMERICAN SAMOA' },
                      { value: 'AZ', label: 'ARIZONA' },
                      { value: 'AR', label: 'ARKANSAS' },
                      { value: 'CA', label: 'CALIFORNIA' },
                      { value: 'CO', label: 'COLORADO' },
                      { value: 'CT', label: 'CONNECTICUT' },
                      { value: 'DE', label: 'DELAWARE' },
                      { value: 'DC', label: 'DISTRICT OF COLUMBIA' },
                      { value: 'FL', label: 'FLORIDA' },
                      { value: 'GA', label: 'GEORGIA' },
                      { value: 'GU', label: 'GUAM' },
                      { value: 'HI', label: 'HAWAII' },
                      { value: 'ID', label: 'IDAHO' },
                      { value: 'IL', label: 'ILLINOIS' },
                      { value: 'IN', label: 'INDIANA' },
                      { value: 'IA', label: 'IOWA' },
                      { value: 'KS', label: 'KANSAS' },
                      { value: 'KY', label: 'KENTUCKY' },
                      { value: 'LA', label: 'LOUISIANA' },
                      { value: 'ME', label: 'MAINE' },
                      { value: 'MD', label: 'MARYLAND' },
                      { value: 'MA', label: 'MASSACHUSETTS' },
                      { value: 'MI', label: 'MICHIGAN' },
                      { value: 'MN', label: 'MINNESOTA' },
                      { value: 'MS', label: 'MISSISSIPPI' },
                      { value: 'MO', label: 'MISSOURI' },
                      { value: 'MT', label: 'MONTANA' },
                      { value: 'NE', label: 'NEBRASKA' },
                      { value: 'NV', label: 'NEVADA' },
                      { value: 'NH', label: 'NEW HAMPSHIRE' },
                      { value: 'NJ', label: 'NEW JERSEY' },
                      { value: 'NM', label: 'NEW MEXICO' },
                      { value: 'NY', label: 'NEW YORK' },
                      { value: 'NC', label: 'NORTH CAROLINA' },
                      { value: 'ND', label: 'NORTH DAKOTA' },
                      { value: 'MP', label: 'NORTHERN MARIANA IS' },
                      { value: 'OH', label: 'OHIO' },
                      { value: 'OK', label: 'OKLAHOMA' },
                      { value: 'OR', label: 'OREGON' },
                      { value: 'PA', label: 'PENNSYLVANIA' },
                      { value: 'PR', label: 'PUERTO RICO' },
                      { value: 'PI', label: 'RHODE ISLAND' },
                      { value: 'SC', label: 'SOUTH CAROLINA' },
                      { value: 'SD', label: 'SOUTH DAKOTA' },
                      { value: 'TN', label: 'TENNESSEE' },
                      { value: 'TX', label: 'TEXAS' },
                      { value: 'UT', label: 'UTAH' },
                      { value: 'VT', label: 'VERMONT' },
                      { value: 'VA', label: 'VIRGINIA' },
                      { value: 'VI', label: 'VIRGIN ISLANDS' },
                      { value: 'WA', label: 'WASHINGTON' },
                      { value: 'WV', label: 'WEST VIRGINIA' },
                      { value: 'WI', label: 'WISCONSIN' },
                      { value: 'WY', label: 'WYOMING' },

                    ]}
                    value={{ label: getValues('state') }}
                    onChange={value => setValue('state', value.label)}
                    size='small'
                    error={errors.state}
                  />
                )}
              />
              <p style={{ color: 'red', fontSize: '14px' }}>{errors.state?.message}</p>
            </Grid>
            <Grid item xs={4}>
              <Controller
                name='zipCode'
                {...register('zipCode')}
                control={control}
                render={({ field }) => (
                  <TextField
                    // isClearable
                    size='small'
                    style={{backgroundColor:'white'}}
                    placeholder='Please enter zipCode'
                    {...field}
                    fullWidth
                    error={errors.zipCode}
                  />
                )}
              />
              <p style={{ color: 'red', fontSize: '14px' }}>{errors.zipCode?.message}</p>
            </Grid>
            <Grid item xs={12} >
              <Typography component='label' color='black' >
                Comments
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name='comments'
                {...register('comments')}
                control={control}
                render={({ field }) => (
                  <TextareaAutosize
                    aria-label='minimum height'
                    minRows={1}
                    placeholder='Enter comments'
                    style={{
                      width: '100%',
                      borderRadius: '8px',
                      border: '2px solid #black',
                      height: '100px', // set a fixed height here
                      overflow: 'scroll'
                    }}
                    {...field}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            spacing={1}
            alignContent="left"
            justifyContent="left"
            style={{
              width: '100% !important',
              marginLeft: '0px !important',
            }}
          >
            {/* <p style={{ color: 'red', fontSize: '18px' }}>{errors.priIns?.planName?.message}</p> */}
            {/* {insuranceError && <p style={{ color: 'red', fontSize: '18px'}}>Insurance is required</p>} */}
            {errors.priIns && (
        <p style={{ color: 'red', fontSize: '18px' }}>Insurance is required</p>
      )}
            <Accordion
              style={{
                width: '100%',
                marginBottom: '10px',
                border: '1px solid #347310',
                borderRadius: '20px',
                marginTop: '10px',
                marginLeft: '5px',
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Insurance</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <TabContext value={tabValueIns}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList
                        onChange={handleChangeInsurances}
                        aria-label="lab API tabs example"
                      >
                        <Tab
                          value={0}
                          icon={<PermIdentity />}
                          label="PRIMARY INSURANCE"
                        />
                        <Tab
                          value={1}
                          icon={<AssignmentTurnedInIcon />}
                          label="SECONDARY INSURANCE"
                        />
                      </TabList>
                    </Box>
                    <TabPanel value={0}>
                      {submitting && <div>Saving Form...</div>}
                      <Grid
                        container
                        spacing={1}
                        alignContent="center"
                        justifyContent="center"
                      >
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                Insurance <b style={{ color: "red", fontSize: '15px' }}>*</b>
                              </Typography>
                            
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                Payer
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                Group Id
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                Member Id <b style={{ color: "red", fontSize: '15px' }}>*</b>
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Controller
                                name='Primary Insurance'
                                {...register('priIns.planName')}
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    type="input"
                                    fullWidth
                                    size='small'
                                    placeholder="Primary Insurance"
                                    onKeyUp={(ev) => {
                                      // dont fire API if the user delete or not entered anything
                                      if (ev.target.value !== '' && ev.target.value !== null) {
                                        onchangeinsurce(ev.target.value);
                                      } else {
                                        setinsuranceNames([]);
                                        setOpenInsurances(false);
                                      }
                                    }}
                                    error={!!errors.priIns?.planName}
                                  helperText={errors.priIns?.planName?.message}
                                  />
                                )}
                              />
                      {/* <p style={{ color: 'red', fontSize: '14px' }}>{errors.priIns.planName?.message}</p> */}
                              {openInsurances ? (
                                <div
                                  className="col-xs-3"
                                  style={{
                                    padding: '14px 16px',
                                    fontSize: '0.875rem',
                                    color: '#67748e',
                                    borderRadius: '5px',
                                    background: 'white',
                                    textAlign: 'justify',
                                    textJustify: 'inter-word',
                                    position: 'absolute',
                                    zIndex: '99',
                                    width: '25%',
                                    border: '1px solid #6cb5f9',
                                  }}
                                >
                                  {insuranceNames.map((s, index) => (
                                    <p
                                      style={zipCodeListStyle}
                                      onClick={(ev) => {
                                        console.log('evvv', ev)
                                        console.log(
                                          'Selected: ',
                                          zipCodeList[index]
                                        )
                                        setValue(
                                          'priIns.insuranceId',
                                          insuranceNames[index].id
                                        )
                                        setValue(
                                          'priIns.payerId',
                                          insuranceNames[index].payerid
                                        )
                                        setValue(
                                          'priIns.planName',
                                          insuranceNames[index].name
                                        )
                                        setOpenInsurances(false)
                                        setinsuranceNames([])
                                      }}
                                      onBlur={(ev) => {
                                        setinsuranceNames([])
                                        setOpenInsurances(false)
                                      }}
                                      onChange={(ev) => {
                                        setinsuranceNames([])
                                        setOpenInsurances(false)
                                      }}
                                    >
                                      {s.name}
                                    </p>
                                  ))}
                                </div>
                              ) : (
                                ''
                              )}
                            </Grid>

                            {false ? (
                              <TextField
                                type="hidden"
                                placeholder="Insurance"
                                {...register('priIns.insuranceId')}
                                error={errors.insuranceId}
                                label="Insurance"
                                fullWidth
                                margin="dense"
                              />
                            ) : null}
                            <Grid item xs={6} sm={3} xl={3}>
                              <Controller
                                name='payerId'
                                {...register('priIns.payerId')}
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    size='small'
                                    type="input"
                                    placeholder="payerId"
                                    {...field}
                                    fullWidth
                                  />
                                )}
                              />

                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Controller
                                name='payerId'
                                {...register('priIns.groupId')}
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    size='small'
                                    type="input"
                                    placeholder="groupId"
                                    {...field}
                                    fullWidth
                                    // inputProps={{
                                    //   inputMode: 'numeric',
                                    //   pattern: '[0-9]*',
                                    //   onInput: (e) => {
                                    //     const inputValue = e.target.value.trim();
                                    //     if (inputValue === '' || /^[0-9]*$/.test(inputValue)) {
                                    //       e.target.value = inputValue !== '' ? Math.max(0, parseInt(inputValue)).toString().slice(0, 10) : ''; // Allow only positive integers
                                    //     } else {
                                    //       e.target.value = ''; // Set value to empty if non-numeric input
                                    //     }
                                    //     field.onChange(e);
                                    //   },
                                    // }}
                                    error={!!errors.priIns?.groupId}
                                      helperText={errors.priIns?.groupId?.message}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Controller
                                name='memberId'
                                {...register('priIns.memberId')}
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    size='small'
                                    type="text"
                                    placeholder="Enter Member Id"
                                    {...field}
                                    fullWidth
                                  // inputProps={{
                                  //   inputMode: 'numeric',
                                  //   pattern: '[0-9]*',
                                  //   onInput: (e) => {
                                  //     const inputValue = e.target.value.trim();
                                  //     if (inputValue === '' || /^[0-9]*$/.test(inputValue)) {
                                  //       e.target.value = inputValue !== '' ? Math.max(0, parseInt(inputValue)).toString().slice(0, 10) : ''; // Allow only positive integers
                                  //     } else {
                                  //       e.target.value = ''; // Set value to empty if non-numeric input
                                  //     }
                                  //     field.onChange(e);
                                  //   },
                                  // }}
                                  error={!!errors.priIns?.memberId}
                                  helperText={errors.priIns?.memberId?.message}
                                  />
                                )}
                              />

                            </Grid>
                            {/* <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                Copay
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                Deductible
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                Start Date
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                End Date
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Controller
                                name='priIns.copay'
                                {...register('priIns.copay')}
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    size='small'
                                    type="input"
                                    placeholder="Please enter a Copay"
                                    {...field}
                                    fullWidth
                                  />
                                )}
                              />

                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Controller
                                name='priIns.deductible'
                                {...register('priIns.deductible')}
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    size='small'
                                    type="input"
                                    placeholder="Please enter a Deductible"
                                    {...field}
                                    fullWidth
                                  />
                                )}
                              />

                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Controller
                                name='priIns.startDate'
                                {...register('priIns.startDate')}
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    size='small'
                                    type="date"
                                    placeholder="Please enter a Deductible"
                                    {...field}
                                    fullWidth
                                  />
                                )}
                              />

                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Controller
                                name='priIns.endDate'
                                {...register('priIns.endDate')}
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    size='small'
                                    type="date"
                                    placeholder="Please enter a Deductible"
                                    {...field}
                                    fullWidth
                                  />
                                )}
                              />

                            </Grid> */}
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                Relation <b style={{ color: "red", fontSize: '15px' }}>*</b>
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                {Self ? 'First Name' : 'Subscriber First Name'}
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                {Self ? 'Last Name' : 'Subscriber Last Name'}

                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                Type
                              </Typography>
                            </Grid>
                            {/* <Grid item xs={6} sm={3} xl={3}>
                              <Controller
                                name='priIns.subsRelation'
                                margin='dense'
                                {...register('priIns.subsRelation')}
                                control={control}
                                render={({ field }) => (
                                  <ReactSelect
                                    style={{ marginTop: 0, marginBottom: 0 }}
                                    {...field}
                                    options={[
                                      { value: 'Self', label: 'Self' },
                                      { value: 'Spouse', label: 'Spouse' },
                                      { value: 'Mother', label: 'Mother' },
                                      { value: 'Father', label: 'Father' },
                                      { value: 'Other', label: 'Other' }

                                    ]}
                                    // value={{ label: getValues('priIns.subsRelation') }}
                                    
                                    onChange={(selectedOption) => {
                                      setValue('priIns.subsRelation', selectedOption.value);
                                      setSelf(selectedOption.value === "Self");
                                     
                                    }}
                                    value={{ label: getValues('secIns.subsRelation')|| '' }}
                                    size='small'
                                  />
                                )}
                              />
                            </Grid> */}

                            <Grid item xs={6} sm={3} xl={3}>
                              <Controller
                                name='priIns.subsRelation'
                                margin='dense'
                                {...register('priIns.subsRelation')}
                                control={control}
                                render={({ field }) => (
                                  <ReactSelect
                                    style={{ marginTop: 0, marginBottom: 0 }}
                                    {...field}
                                    options={[
                                      { value: 'Self', label: 'Self' },
                                      { value: 'Spouse', label: 'Spouse' },
                                      { value: 'Mother', label: 'Mother' },
                                      { value: 'Father', label: 'Father' },
                                      { value: 'Other', label: 'Other' }
                                    ]}

                                    value={{ label: getValues('priIns.subsRelation') }}
                                    onChange={(value) => {
                                      setValue('priIns.subsRelation', value.label);
                                      setSelf(value.value === 'Self');
                                    }}
                                    size='small'
                                    error={!!errors.priIns?.subsRelation}
                                  />
                                )}
                              />
                              <p style={{ color: 'red', fontSize: '14px' }}>{errors.priIns?.subsRelation?.message}</p>
                            </Grid>
                            {Self ? (
                              <>
                                <Grid item xs={6} sm={3} xl={3}>
                                  <Controller
                                    name='priIns.subsFirstName'
                                    {...register('priIns.subsFirtName')}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField
                                        size='small'
                                        fullWidth
                                        {...field}
                                        value={PfirstName ? PfirstName : ''}
                                        InputProps={{
                                          readOnly: true,
                                        }}

                                      />

                                    )}
                                  />


                                </Grid>
                                <Grid item xs={6} sm={3} xl={3}>
                                  <Controller
                                    name='priIns.subsLastName'
                                    {...register('priIns.subsLastName')}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField
                                        size='small'
                                        fullWidth
                                        {...field}
                                        value={PLastName ? PLastName : ''}
                                        InputProps={{
                                          readOnly: true,
                                        }}

                                      />

                                    )}
                                  />
                                </Grid>
                              </>
                            ) : (
                              <>
                                <Grid item xs={6} sm={3} xl={3}>
                                  <Controller
                                    name='priIns.subsFirstName'
                                    {...register('priIns.subsFirtName')}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField
                                        size='small'
                                        type="input"
                                        placeholder="Enter Subscriber First Name"
                                        {...field}
                                        fullWidth
                                        
                                      />
                                    )}
                                  />

                                </Grid>
                                <Grid item xs={6} sm={3} xl={3}>
                                  <Controller
                                    name='priIns.subsLastName'
                                    {...register('priIns.subsLastName')}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField
                                        size='small'
                                        type="input"
                                        placeholder="Enter Subscriber Last Name"
                                        {...field}
                                        fullWidth
                                      />
                                    )}
                                  />

                                </Grid>

                              </>
                            )}


                            <Grid item xs={6} sm={3} xl={3}>
                              <Controller
                                name='priIns.type'
                                {...register('priIns.type')}
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    size='small'
                                    type="input"
                                    {...field}
                                    fullWidth
                                  />
                                )}
                              />

                            </Grid>

                          </Grid>
                        </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel value={1}>
                      {submitting && <div>Saving Form...</div>}
                      <Grid
                        container
                        spacing={1}
                        alignContent="center"
                        justifyContent="center"
                      >
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                Insurance
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                Payer
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                Group Id
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                Member Id
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Controller
                                name='secIns.planName'
                                {...register('secIns.planName')}
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    type="input"
                                    fullWidth
                                    size='small'
                                    placeholder="Primary Insurance"
                                    onKeyUp={(ev) => {
                                      // dont fire API if the user delete or not entered anything
                                      if (ev.target.value !== '' && ev.target.value !== null) {
                                        onchangeinsurce(ev.target.value);
                                      } else {
                                        setinsuranceNames([]);
                                        setOpenInsurances(false);
                                      }
                                    }}
                                    error={!!errors.secIns?.planName}
                                    helperText={errors.secIns?.planName?.message}
                                  />
                                )}
                              />

                              {openInsurances ? (
                                <div
                                  className="col-xs-3"
                                  style={{
                                    padding: '14px 16px',
                                    fontSize: '0.875rem',
                                    color: '#67748e',
                                    borderRadius: '5px',
                                    background: 'white',
                                    textAlign: 'justify',
                                    textJustify: 'inter-word',
                                    position: 'absolute',
                                    zIndex: '99',
                                    width: '25%',
                                    border: '1px solid #6cb5f9',
                                  }}
                                >
                                  {insuranceNames.map((s, index) => (
                                    <p
                                      style={zipCodeListStyle}
                                      onClick={(ev) => {
                                        console.log('evvv', ev)
                                        console.log(
                                          'Selected: ',
                                          zipCodeList[index]
                                        )
                                        setValue(
                                          'secIns.insuranceId',
                                          insuranceNames[index].id
                                        )
                                        setValue(
                                          'secIns.payerId',
                                          insuranceNames[index].payerid
                                        )
                                        setValue(
                                          'secIns.planName',
                                          insuranceNames[index].name
                                        )
                                        setOpenInsurances(false)
                                        setinsuranceNames([])
                                      }}
                                      onBlur={(ev) => {
                                        setinsuranceNames([])
                                        setOpenInsurances(false)
                                      }}
                                      onChange={(ev) => {
                                        setinsuranceNames([])
                                        setOpenInsurances(false)
                                      }}
                                    >
                                      {s.name}
                                    </p>
                                  ))}
                                </div>
                              ) : (
                                ''
                              )}
                            </Grid>

                            {false ? (
                              <TextField
                                type="hidden"
                                placeholder="Insurance"
                                {...register('secIns.insuranceId')}
                                error={errors.insuranceId}
                                label="Insurance"
                                fullWidth
                                margin="dense"
                              />
                            ) : null}
                            <Grid item xs={6} sm={3} xl={3}>
                              <Controller
                                name='secIns.payerId'
                                {...register('secIns.payerId')}
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    size='small'
                                    type="input"
                                    placeholder="payerId"
                                    {...field}
                                    fullWidth
                                  />
                                )}
                              />

                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Controller
                                name='secIns.groupId'
                                {...register('secIns.groupId')}
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    size='small'
                                    type="input"
                                    placeholder="groupId"
                                    {...field}
                                    fullWidth
                                    // inputProps={{
                                    //   inputMode: 'numeric',
                                    //   pattern: '[0-9]*',
                                    //   onInput: (e) => {
                                    //     const inputValue = e.target.value.trim();
                                    //     if (inputValue === '' || /^[0-9]*$/.test(inputValue)) {
                                    //       e.target.value = inputValue !== '' ? Math.max(0, parseInt(inputValue)).toString().slice(0, 10) : ''; // Allow only positive integers
                                    //     } else {
                                    //       e.target.value = ''; // Set value to empty if non-numeric input
                                    //     }
                                    //     field.onChange(e);
                                    //   },
                                    // }}
                                    error={!!errors.secIns?.groupId}
                                    helperText={errors.secIns?.groupId?.message}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Controller
                                name='secIns.memberId'
                                {...register('secIns.memberId')}
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    size='small'
                                    type="text"
                                    placeholder="Enter Member Id"
                                    {...field}
                                    fullWidth
                                  // inputProps={{
                                  //   inputMode: 'numeric',
                                  //   pattern: '[0-9]*',
                                  //   onInput: (e) => {
                                  //     const inputValue = e.target.value.trim();
                                  //     if (inputValue === '' || /^[0-9]*$/.test(inputValue)) {
                                  //       e.target.value = inputValue !== '' ? Math.max(0, parseInt(inputValue)).toString().slice(0, 10) : ''; // Allow only positive integers
                                  //     } else {
                                  //       e.target.value = ''; // Set value to empty if non-numeric input
                                  //     }
                                  //     field.onChange(e);
                                  //   },
                                  // }}
                                  error={!!errors.secIns?.memberId}
                                  helperText={errors.secIns?.memberId?.message}
                                  />
                                )}
                              />

                            </Grid>
                            {/* <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                Copay
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                Deductible
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                Start Date
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                End Date
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Controller
                                name='secIns.copay'
                                {...register('secIns.copay')}
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    size='small'
                                    type="input"
                                    placeholder="Please enter a Copay"
                                    {...field}
                                    fullWidth
                                  />
                                )}
                              />

                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Controller
                                name='secIns.deductible'
                                {...register('secIns.deductible')}
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    size='small'
                                    type="input"
                                    placeholder="Please enter a Deductible"
                                    {...field}
                                    fullWidth
                                  />
                                )}
                              />

                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Controller
                                name='secIns.startDate'
                                {...register('secIns.startDate')}
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    size='small'
                                    type="date"
                                    placeholder="Please enter a Deductible"
                                    {...field}
                                    fullWidth
                                  />
                                )}
                              />

                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Controller
                                name='secIns.endDate'
                                {...register('secIns.endDate')}
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    size='small'
                                    type="date"
                                    placeholder="Please enter a Deductible"
                                    {...field}
                                    fullWidth
                                  />
                                )}
                              />

                            </Grid> */}
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                Relation
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                {Selected ? 'First Name' : 'Subscriber First Name'}

                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                {Selected ? 'Last Name' : 'Subscriber Last Name'}

                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                Type
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Controller
                                name='secIns.subsRelation'
                                margin='dense'
                                {...register('secIns.subsRelation')}
                                control={control}
                                render={({ field }) => (
                                  <ReactSelect
                                    style={{ marginTop: 0, marginBottom: 0 }}
                                    {...field}
                                    options={[
                                      { value: 'Self', label: 'Self' },
                                      { value: 'Spouse', label: 'Spouse' },
                                      { value: 'Mother', label: 'Mother' },
                                      { value: 'Father', label: 'Father' },
                                      { value: 'Other', label: 'Other' }
                                    ]}

                                    value={{ label: getValues('secIns.subsRelation') }}
                                    onChange={(value) => {
                                      setValue('secIns.subsRelation', value.label);
                                      setSelected(value.value === 'Self');
                                    }}
                                    // error={!!errors.secIns?.subsRelation}
                                    size='small'
                                  />
                                )}
                              />
                            </Grid>

                            {Selected ? (
                          <>
                          <Grid item xs={6} sm={3} xl={3}>
                            <Controller
                              name='secIns.subsFirtName'
                              {...register('secIns.subsFirtName')}
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  size='small'
                                  fullWidth
                                  {...field}
                                  value={PfirstName ? PfirstName : ''}
                                  InputProps={{
                                    readOnly: true,
                                  }}

                                />

                              )}
                            />


                          </Grid>
                          <Grid item xs={6} sm={3} xl={3}>
                            <Controller
                              name='secIns.subsLastName'
                              {...register('secIns.subsLastName')}
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  size='small'
                                  fullWidth
                                  {...field}
                                  value={PLastName ? PLastName : ''}
                                  InputProps={{
                                    readOnly: true,
                                  }}


                                />

                              )}
                            />
                          </Grid>
                        </>
                            ) : (
                              <>
                                <Grid item xs={6} sm={3} xl={3}>
                                  <Controller
                                    name='secIns.subsFirtName'
                                    {...register('secIns.subsFirtName')}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField
                                        size='small'
                                        type="input"
                                        placeholder="Enter Subscriber First Name"
                                        {...field}
                                        fullWidth
                                      />
                                    )}
                                  />

                                </Grid>
                                <Grid item xs={6} sm={3} xl={3}>
                                  <Controller
                                    name='secIns.subsLastName'
                                    {...register('secIns.subsLastName')}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField
                                        size='small'
                                        type="input"
                                        placeholder="Enter Subscriber Last Name"
                                        {...field}
                                        fullWidth
                                      />
                                    )}
                                  />

                                </Grid>
                              </>
                            )}
                            <Grid item xs={6} sm={3} xl={3}>
                              <Controller
                                name='secIns.type'
                                {...register('secIns.type')}
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    size='small'
                                    type="input"
                                    {...field}
                                    fullWidth
                                  />
                                )}
                              />

                            </Grid>

                          </Grid>
                        </Grid>
                      </Grid>
                    </TabPanel>
                  </TabContext>
                </div>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} lg={12} sm={12} style={{ marginTop: '1.5rem' }}>
            {props.callingFrom == 'Patient' || props.callingFrom == true ? (<></>) : (<>

              <Button
                className="Button"
                onClick={handleCancel}
                variant='gradient'
                disabled={submitting}
                style={{
                  marginTop: '0px',
                  marginBottom: '10px',
                  float: 'right',
                  marginLeft: '10px',
                  width: '90px',
                  height: '35px',
                  // backgroundColor: '#A574FD',
                  color: 'white',
                  fontSize: '14px'
                }}
              >
                <CancelOutlinedIcon
                  fontSize="medium"
                  style={{ color: "white", paddingRight: "5px" }}
                ></CancelOutlinedIcon>
                Cancel
              </Button>
            </>)}

            <Button
              className="Button"
              onClick={handleSubmit(onSubmit)}
              variant='gradient'
              disabled={disable == true ? true : false}
              style={{
                marginTop: '0px',
                marginBottom: '10px',
                float: 'right',
                marginLeft: 'auto',
                width: '90px',
                height: '35px',
                backgroundColor: '#A574FD',
                color: 'white',
                fontSize: '14px'
              }}
            >
              <SaveOutlinedIcon
                fontSize="medium"
                style={{ color: "white", paddingRight: "5px" }}
              ></SaveOutlinedIcon>
              Save
            </Button>
          </Grid>
        </Container>
      </div>
    </React.Fragment>
  );
};


export default Demographics;
