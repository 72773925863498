import React, { useCallback, useMemo, useState, useEffect } from "react";
import {
  Box,
  Button,
  Stack,
  SvgIcon,
  Typography,
  Grid,
  Snackbar,
  TextField,
} from "@mui/material";
import axios from "axios";
import { Container } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
// import { useRouter, withRouter } from 'next/router'
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import Moment from 'moment'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//Import Breadcrumb

// const history = useHistory();
const Letter = (props) => {
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const patientId = (props.callingFrom =="update"?props.patientid:props.patientId)
  // const Formdid = props.formDid;
  const [columnID, setcolumnID] = useState(props.columnID !== undefined ? props.columnID : 0)
  const [Formdid, setFormdid] = useState(props.formDid !== undefined ? props.formDid : 0)
  const [AutpattientID, setAutpattientID] = useState(props.AutpattientID)
  const [patientName, setpatientName] = useState(props.patientName);
  const pname =props.PatientName
  const [patName, setPatName] = useState(pname);
  // const [PatientName, setPateintName]= useState(pname)
  const [idd, setIdd] = useState('');
  const [patId, setPatId] = useState(patientId);
  const [oPhyName, setOPhyName] = useState('');
  const [phyAddress, setPhyAddress] = useState('');
  const [phySig, setPhySig] = useState('');
  const [phyNpiNmbr, setPhyNpiNmbr] = useState('');
  const [phyDate, setPhyDate] = React.useState(Moment().format('YYYY-MM-DD'));
  const [patDob, setPatDob] = React.useState(Moment().format('YYYY-MM-DD'))
  const DOB = props.callingFrom=="Patients"? props.DOB: patDob; 
  console.log('dob',patDob)
  const fields = [
    "id",
    "patientId",
    "patientName",
    "orderingPhysicianName",
    "physiciansAddress",
    "physiciansSignatures",
    "physiciansNpiNumber",
    "physicianDate",
    "necessityPatient",
    "patDOB",
    "inactive",
    "practiceId",
    "createdBy",
    "createdDate",
    "updatedBy",
    "updatedDate",
    "clientCreatedDate",
    "clientUpdatedDate",
  ];
  const defValues = {
    id: 0,
    patientId:"",
    patientName:"",
    orderingPhysicianName: "",
    physiciansAddress: "",
    physiciansSignatures: "",
    physiciansNpiNumber: "",
    physicianDate: "",
    necessityPatient: "",
    patDOB: DOB,
    inactive: true,
    practiceId: 0,
    createdBy: "",
    createdDate: "",
    updatedBy: "",
    updatedDate: "",
    clientCreatedDate: "",
    clientUpdatedDate: "",
  };
  document.title = "Letter | LuxBillingEHR";
 
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);
  const alert = useAlert();
  

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    defaultValues: defValues,

  });
  const [submitting, setSubmitting] = useState(false);
  const [disable, setdisable] = React.useState(false)
  useEffect(() => {
 
    axios
        .get(
            `${process.env.REACT_APP_API_URL}/AutherizationRequest/MedicalNecessityById?Id=${Formdid}&authId=${columnID}`, 
            { headers }
        )
        .then(response => {
            fields.forEach(field => {
                setValue(field, response[field])
            })

            console.log('response.dataaaaa: ', response)
            setIdd(response.id)
            setPatId(response.patientId)
            setOPhyName(response.orderingPhysicianName)
            setPhyAddress(response.physiciansAddress)
            setPhySig(response.physiciansSignatures)
            setPhyNpiNmbr(response.physiciansNpiNumber)
            setPatName(response.patientName)
            var PhyDate = Moment(response.physicianDate).format('YYYY-MM-DD')
            setPhyDate(PhyDate)
            var PatdOB33 = Moment(response.patDOB).format('YYYY-MM-DD')
            setPatDob(PatdOB33)
            
        })

        .catch(error => {
            console.error('There was an error!', error)
        })

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
}, [columnID,Formdid,patName,patientId])

  const onSubmit = (data) => {
    setSubmitting(true);
    setdisable(true)

    const postData = data;
    {props.callingFrom=="Patient"?postData.patientName = patientName:postData.patientName = patName }
    {props.callingFrom=="Patient"?postData.patientId = AutpattientID:postData.patientId = patientId }
    {props.callingFrom=="Patient"?postData.id=idd:postData.id=idd}
    // postData.patientId = patientId;
    // postData.necessityPatient=patiname
    // data.id = Formdid;
    postData.formId = Formdid;
    postData.authId= columnID;
    postData.patDOB=DOB; 
    console.log("check Data", postData);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/AutherizationRequest/AddMedicalNecessity`,
        postData,
        {
          headers,
        }
      )
      .then((response) => {
        if (response.id) {
          console.log("check Data", response);
          setSubmitting(false);
          toast.success('Record saved successfully', { 
            position: "bottom-center",
            // autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
            onClose: () => {
              if(props.callingFrom=='Patient')
              {
                props.updateHealthHistory(response.id, response.authId)
                props.handleClose2()
                console.log('checkid',response.id)
                setdisable(false);

              }
              else if(props.callingFrom=="update")
              {
                props.updateHealthHistory(response.id, response.authId, response.patientName)
                props.closevobpage()
              }
              else if(props.callingFrom =="dashboard")
              {
                props.updateHealthHistory(response.id, response.authId, response.patientName)
                props.closevobpage()}
              else{ 
                props.closeLetter();
              }
              
              setdisable(false);
            },
          });
        } else {
          setSubmitting(false);
          setdisable(false);
        }
      })
      .catch((error) => {
        setSubmitting(false);
        setdisable(false);
        alert.success(`Error ${error.message}`, {
          type: "error",
        });
      });
  };
  const [value, setvalue] = React.useState("");

  const handleChange = (event) => {
    setvalue(event.value);
  };



 
  return (
    <div>
      <Container>
        <Grid container columnSpacing={1} style={{ color: "black" }}>
          <Grid item xs={2}></Grid>
          <Grid item xs={8}>
            {/* <h1
              style={{
                marginTop: "1rem",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              LETTER OF MEDICAL NECESSITY
            </h1> */}
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={8}>
            <h5 style={{ marginTop: "10px", textAlign: "center", marginTop:'2rem' }}>
              Referring MD’s Written Order for Oral Appliance for OSA
            </h5>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={2}>
            <h6 style={{ marginTop: "20px" }}>Patient:</h6>
          </Grid>
          <Grid item xs={3} style={{ marginTop: "10px", marginLeft: "-100px" }}>
          <Controller
              name="necessityPatient"
              // {...register("necessityPatient")}
              control={control}
              render={({ field }) => (
                <TextField
                  size="small"
                  variant="standard"
                  {...field}
                  fullWidth
                  value={props.callingFrom=='Patient'?patientName:patName}
              onChange={e => {
                setValue('patientName', e.target.value)
                setPatName(e.target.value)
                setpatientName(e.target.value)
              }}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={2}>
            <h6 style={{ marginTop: "20px" }}>DOB:</h6>
          </Grid>
          <Grid item xs={3} style={{ marginTop: "10px", marginLeft: "-100px" }}>
            <Controller
              name="patDOB"
              {...register("patDOB")}
              control={control}
              render={({ field }) => (
                <TextField
                  type="date"
                  size="small"
                  variant="standard"
                  {...field}
                  fullWidth
                  value={DOB}
                  onChange={e => {
                    setValue('patDOB', e.target.value)
                    setPatDob(e.target.value)
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}></Grid>
          
          <Grid item xs={2}></Grid>
          <Grid item xs={8}>
            <h5 style={{ marginTop: "30px", textAlign: "center" }}>
              Diagnosis: Obstructive Sleep Apnea, Adult Pediatric G47.33
            </h5>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <h6 style={{ marginTop: "30px", textAlign: "center" }}>
              Oral Appliance Type: Custom fabricated mandibular advancement
              device: E0486 - ORAL DEVICE/APPLIANCE USED TO REDUCE UPPER AIRWAY
              COLLAPSIBILITY, ADJUSTABLE OR NON-ADJUSTABLE, CUSTOM FABRICATED,
              INCLUDES FITTING AND ADJUSTMENTs
            </h6>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <h6 style={{ marginTop: "30px", textAlign: "center" }}>
              A custom-fabricated Oral Appliance for OSA is defined as one that
              is individually made for a specific patient (no other patient
              would be able to use this item) starting with basic materials. It
              involves substantial work to produce, usually by a specialized
              lab. It may involve the incorporation of some prefabricated
              components. It involves more than trimming, bending, or making
              other modifications to a substantially prefabricated item.
            </h6>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <h6 style={{ marginTop: "30px", textAlign: "center" }}>
              The above-named patient was diagnosed as indicated. Treatment of
              this condition is thus ordered as a medical necessity for the
              lifetime of the patient.
            </h6>
          </Grid>
          <Grid item xs={1}></Grid>

          <Grid item xs={2}></Grid>
          <Grid item xs={4}>
            <h5 style={{ marginTop: "20px" }}>
              Ordering Physician NAME (Printed):
            </h5>
          </Grid>
          <Grid item xs={4} style={{ marginTop: "10px", marginLeft: "-15px" }}>
            <Controller
              name="orderingPhysicianName"
              {...register("orderingPhysicianName")}
              control={control}
              render={({ field }) => (
                <TextField
                  size="small"
                  variant="standard"
                  {...field}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={3}>
            <h5 style={{ marginTop: "20px" }}>Physician's Address:</h5>
          </Grid>
          <Grid item xs={6} style={{ marginTop: "10px", marginLeft: "-60px" }}>
            <Controller
              name="physiciansAddress"
              {...register("physiciansAddress")}
              control={control}
              render={({ field }) => (
                <TextField
                  size="small"
                  variant="standard"
                  {...field}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={3}>
            <h5 style={{ marginTop: "20px" }}>Physician's Signature:</h5>
          </Grid>
          <Grid item xs={3} style={{ marginTop: "10px", marginLeft: "-50px" }}>
            <Controller
              name="physiciansSignatures"
              {...register("physiciansSignatures")}
              control={control}
              render={({ field }) => (
                <TextField
                  size="small"
                  variant="standard"
                  {...field}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={1}>
            <h5 style={{ marginTop: "20px" }}>Date:</h5>
          </Grid>
          <Grid item xs={2} style={{ marginTop: "10px", marginLeft: "-20px" }}>
            <Controller
              name="physicianDate"
              {...register("physicianDate")}
              control={control}
              render={({ field }) => (
                <TextField
                  type="date"
                  size="small"
                  variant="standard"
                  {...field}
                  fullWidth
                  value={phyDate}
                  onChange={e => {
                    setValue('physicianDate', e.target.value)
                    setPhyDate(e.target.value)
                  }}

                />
              )}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={3}>
            <h5 style={{ marginTop: "20px" }}>Physician’s NPI Number:</h5>
          </Grid>
          <Grid item xs={4} style={{ marginTop: "10px", marginLeft: "-30px" }}>
            <Controller
              name="physiciansNpiNumber"
              {...register("physiciansNpiNumber")}
              control={control}
              render={({ field }) => (
                <TextField
                  size="small"
                  variant="standard"
                  {...field}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={8}>
            <h5
              style={{
                marginTop: "30px",
                textAlign: "center",
                marginBottom: "50px",
              }}
            >
              Please fax this completed form to:
            </h5>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={12} lg={12} sm={12}>
            <Button
            className="Button"
              onClick={handleSubmit(onSubmit)}
              variant="gradient"
              disabled={disable == true?true:false }
              style={{
                marginTop: "0px",
                marginBottom: "10px",
                float: "right",
                marginLeft: "auto",
                width: "80px",
                height: "35px",
                // backgroundColor: "#A574FD",
                color: "white",
                fontSize: "14px",
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Letter;
