import { Container } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { useNavigate } from 'react-router-dom';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Box, Button, Stack, SvgIcon, Typography, Grid, Snackbar, TextField } from '@mui/material';
import axios from 'axios'
import { DataGrid } from '@mui/x-data-grid'
import { useLocation, Link } from "react-router-dom";
import VOBPAGE from "../Pages/Vobpage";
import VOBPAGE1 from "./Vobpage1";
import OSAPAGE from "../Pages/Osapage";
import Letter from "../Pages/Letter";
import Card from '@mui/material/Card';
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import DeleteIcon from "@mui/icons-material/Delete";

import Modal from "@mui/material/Modal";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Headroom from "react-headroom";
import Prior_auth from './Prior_auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined'
import DialogTitle from '@mui/material/DialogTitle';
import Disclaimer from './Disclaimer';
import DentistUse from './DentistUse';


const Forms = (props) => {


  console.log('patient id in props', props.PatientId)
  const [selectedFormName, setSelectedFormName] = useState(''); // Add this state to store the selected formName
 
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const location = useLocation();
  // const patientId = location.state.patientId;
  const patientId = props.PatientId;
  const [formnewid, setFormnewId] = useState()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openmaxheight = Boolean(anchorEl);
  const handleClickmaxheight = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [formdataid, setFormDataId] = useState('')


  const [rows, setRows] = useState(null)
  // const [newrows, setNewRows] = useState()
  console.log('check id', formdataid)
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'gridCell',
      sortable: false,
      flex: 0.1,
    },
    {
      field: 'formName',
      headerName: 'Form',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'gridCell',
      sortable: false,
      flex: 0.5,
      renderCell: (cellValues) => (
        <Button
          size="small"
          variant="text"
          color="info"
          onClick={(event) => {
            setFormDataId(cellValues.row.id);
            setSelectedFormName(cellValues.row.formName);
            openPatientForm(cellValues.row.formName); // Open the patient form
            setFormnewId(cellValues.row.formId)
          }}
        >
          {`${cellValues.row.formName}`}
        </Button>

      ),

    },
    // {
    //   field: 'taxId',
    //   headerName: 'Tax Id',
    //   headerClassName: 'super-app-theme--header',
    //   cellClassName: 'gridCell',
    //   sortable: false,
    //   flex: 0.3,
    // },
    // { 
    //   field: 'address',
    //   headerClassName: 'super-app-theme--header',
    //   headerName: 'Address',
    //   cellClassName: 'gridCell',
    //   sortable: false,
    //   flex: 0.5,
    // },
    {
      field: 'formId',
      headerClassName: 'super-app-theme--header',
      headerName: 'Form ID',
      cellClassName: 'gridCell',
      sortable: false,
      flex: 0.15,
    },
    {
      field: 'createdBy',
      headerClassName: 'super-app-theme--header',
      headerName: 'Created By',
      cellClassName: 'gridCell',
      sortable: false,
      flex: 0.2,
    },
    {
      field: 'createdDate',
      headerClassName: 'super-app-theme--header',
      headerName: 'Created Date',
      sortable: true,
      flex: 0.2,
    },
    {
      field: "Approve",
      headerName: "Action",
      sortable: true,
      headerClassName: "super-app-theme--header",
      // minWidth: 50,
      flex: 0.1,
      renderCell: (cell) => (
        <DeleteIcon
          style={{
            marginRight: "5px",
            float: "right",
            marginTop: "5px",
            color: "red",
            cursor: "pointer",
          }}
          onClick={(cellValues) => {
            // console.log('Cell: ', cell)
            confirmAlert({
              message: "Do you want to Delete the record.",
              closeOnEscape: true,
              closeOnClickOutside: true,
              overlayClassName: "overlay-custom-class-name",
              buttons: [
                {
                  label: "Yes",
                  onClick: () => {
                    const postData = {
                      id: cell.row.id,
                      inactive: true,
                    };

                    axios
                      .post(
                        `${process.env.REACT_APP_API_URL}/AutherizationRequest/UpdatePateintForm`,
                        postData,
                        {
                          headers,
                        }
                      )
                      .then((response) => {
                        setRefresh(!refresh);
                        // setOpenNote(false)
                        // console.log('response.data: ', response.data)
                      })
                      .catch((error) => {
                        console.error("There was an error!", error);
                      });
                  },
                },
                {
                  label: "No",
                },
              ],
            });
          }}
        ></DeleteIcon>
      ),
    },



  ]

  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 2,
    background: 'drak',
    fontSize: '13.4px !important',
    color: 'drak !important',
    borderColor: '#326C1D',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#377562',
      borderRadius: 0.1,
      color: 'white',
      fontSize: '16px !important',

    },

  }
  const openPatientForm = (formName) => {
    // navigate('/VOBPAGE1', { state: { formid: id } }) 
    setVobpage(true);
    setSelectedFormName(formName);
    // setFormDataId(patientId);
  }
  useEffect(() => {
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/AutherizationRequest/FormByPatientId?PatientId=${patientId}`, {
        headers
      }
      )
      .then((response) => {
        setLoading(false)

        // console.log("row ki id" , response.id)
        setRows(response)

        // setFormDataId(response.patientId)
        // console.log('formIddded', response.formId)

      })

      .catch((error) => {
        console.error('There was an error!', error)
      })

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [refresh])
  const [vobpage, setVobpage] = React.useState(false);
  const openvobpage = () => {
    setVobpage(true);
    // reset()
  };
  const closevobpage = () => {
    setVobpage(false);
    setSelectedFormName('');
    // reset()
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "60%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    // height: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    overflow: "auto",
    overflows: "hide",
    // zIndex: "10",
  };
  function applyRefresh() {
    setRefresh(!refresh)
    // setOpen(false)
  }
  const formTitles = {
    VOB_CheckList: "DME VOB/ Prior-auth checklist (E0486)",
    Prior_Request: "VOB/ Prior-auth Request",
    OSA_Screener: "Snoring and obstructive sleep apnea (OSA) Screener",
    Necessity_Letter: "LETTER OF MEDICAL NECESSITY",
    Auth_Approval_Form: "Prior-auth & VOB",
    Patient_Disclaimer: "Patient Responsibility Disclaimer",
    Dentist_Use: "DENTIST USE ONLY - Oral examination"
  };

  return (
    <div >

      <Grid container>
        <Grid item xs={12}>
          <Grid item spacing={1} xs={12} sm={12} md={12} lg={12}>
            <Button
              className="Button"
              sx={{ mb: 2, marginRight: '20px' }}
              onClick={event => {
                applyRefresh()
              }}
              style={{
                marginBottom: '10px',
                float: 'right',
                marginLeft: '20px',
                width: '120px',
                height: '35px',
                // backgroundColor: '#A574FD',
                color: 'white',
                fontSize: '14px'
              }}
            >
              <CachedOutlinedIcon
                fontSize='medium'
                style={{
                  color: 'white',
                  marginRight: '1px',
                  paddingRight: '0px',
                  paddingBottom: '2px'
                }}
              ></CachedOutlinedIcon>
              Refresh
            </Button>
          </Grid>
          <>
            {isLoading ? (
              <div style={{ margin: '10rem', textAlign: 'center' }}>
                <CircularProgress />
              </div>
            ) : (
              <Box mb={1}>
                <Grid container xs={12} sm={12} md={12} lg={12} style={{ height: 390, width: '100%' }}>

                  <div style={{ height: 400, width: '100%' }}>
                    {rows === null || rows.length === 0 ? null : (
                      <DataGrid
                        rows={rows}
                        rowHeight={28}
                        columns={columns}
                        headerHeight={28}
                        pagination
                        autoPageSize
                        sx={gridRowStyle}
                      />
                    )}

                  </div>
                </Grid>
              </Box>
            )}

          </>
          {/* )} */}
        </Grid>
      </Grid>
      <Modal
        open={vobpage}
        onClose={closevobpage}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Headroom>
            <Grid
              container
              style={{ backgroundColor: "#356F60", position: "sticky" }}
            >
              <Grid item xs={12}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    height: '3rem'
                  }}
                >
                  <DialogTitle
                    style={{
                      flex: 1,
                      textAlign: 'center',
                    }}
                  >
                    <h1
                      style={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                        color: '#fff',
                        textDecoration: 'underline'

                      }}
                    >
                      {formTitles[selectedFormName]}
                    </h1>
                  </DialogTitle>
                  <CancelOutlinedIcon
                    onClick={closevobpage}
                    fontSize="large"
                    color="red"
                    style={{
                      color: 'red',
                      paddingRight: '5px',
                      cursor: 'pointer',
                      // marginLeft: 'auto',
                      // marginRight: '1.5rem'
                    }}
                  ></CancelOutlinedIcon>
                </div>
              </Grid>
            </Grid>

            <Grid>
              <Card bordered={false} style={{ overflow: 'auto', height: '80vh' }}>
                {selectedFormName === 'VOB_CheckList' && (
                  <VOBPAGE callingFrom={"update"} patientid={patientId} formId={formdataid} formDid={formnewid} closevobpage={closevobpage} />

                )}
                {selectedFormName === 'Prior_Request' && (
                  <VOBPAGE1 callingFrom={"update"} patientid={patientId} formId={formdataid} formDid={formnewid} closevobpage={closevobpage} />
                )}
                {selectedFormName === 'OSA_Screener' && (
                  <OSAPAGE callingFrom={"update"} patientid={patientId} formId={formdataid} formDid={formnewid} closevobpage={closevobpage} />
                )}
                {selectedFormName === 'Necessity_Letter' && (
                  <Letter callingFrom={"update"} patientid={patientId} formId={formdataid} formDid={formnewid} closevobpage={closevobpage} />
                )}
                {selectedFormName === 'Auth_Approval_Form' && (
                  <Prior_auth callingFrom={"update"} patientid={patientId} formId={formdataid} formDid={formnewid} closevobpage={closevobpage} />
                )}
                {selectedFormName === 'Patient_Disclaimer' && (
                  <Disclaimer callingFrom={"update"} patientid={patientId} formId={formdataid} formDid={formnewid} closevobpage={closevobpage} />
                )}
                {selectedFormName === 'Dentist_Use' && (
                  <DentistUse callingFrom={"update"} patientid={patientId} formId={formdataid} formDid={formnewid} closevobpage={closevobpage} />
                )}
              </Card>
              <ToastContainer
                position="top-center"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
            </Grid>
          </Headroom>

        </Box>
      </Modal>


    </div>
  )
}

export default Forms