import React, { useState, useEffect } from "react";
import axios from 'axios'
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import LanguageDropdown from "../../components/Common/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../../components/Common/TopbarDropdown/NotificationDropdown";
import { useNavigate } from 'react-router-dom';
//i18n
import { withTranslation } from "react-i18next";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
//import images
import logoSm from "../../assets/images/logo-removebg-preview.png";
import logoDark from "../../assets/images/logo-removebg-preview.png";
import logoLight from "../../assets/images/logo-removebg-preview.png";

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions";
import ProfileMenu from "../../components/Common/TopbarDropdown/ProfileMenu";
import AppsDropdown from "../../components/Common/TopbarDropdown/AppsDropdown";
import { Box, Button, Container, Stack, SvgIcon, Typography, Grid, Snackbar, TextField } from '@mui/material';
// import Select from 'react-select'
import { useSelector, shallowEqual } from 'react-redux'
import MenuItem from '@mui/material/MenuItem'
import ReactSelect from "react-select";
import { Controller, useForm } from "react-hook-form";
import Card from '@mui/material/Card';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Menu from '@mui/material/Menu'
import Badge from '@mui/material/Badge'
import Divider from '@mui/material/Divider'
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications'
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';

// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Avatar from '@mui/material/Avatar'
import Chip from '@mui/material/Chip';
import Icon from '@mui/material/Icon'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';


const Header = (props) => {
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const userName = localStorage.getItem("UserName")
  const Emailaddress = localStorage.getItem("Emailaddress")
  
  const [Practice, setPractice] = useState(false)
  const [PracticeName, setPracticeName] = useState([])
  const [PracticeName1, setPracticeName1] = useState('')
  
  const navigate = useNavigate();
  useEffect(() => {
    setPractice(true)
    axios
       .get(`${process.env.REACT_APP_API_URL}/UserPractices/getUserPractices?userEmail=${Emailaddress}`,
          { headers }
        )
        .then(response => {
          setPractice(false)
          setPracticeName(response)
          setPracticeName1(response[0].practiceName)
          console.log('response',response)
        })

        .catch(error => {
          console.error('There was an error!', error)
        })
  }, [])
//   const PracticeName = localStorage.getItem("Practice_Name")
// console.log("testtt",PracticeName)
  const [search, setsearch] = useState(false);
  const [practicesList, setpracticesList] = useState([]);
  // const [practiceID, setPracticeID] = useState("");
  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }

  const [currentPracticeId, setcurrentPracticeId] = useState(localStorage.getItem('default_paractice'))
  // const [userRedux, setassignedToRedux] = useState([])
  // const usersRedx = useSelector(state => state.users.NAMES, shallowEqual)
  // const [reduxLoaded, setreduxLoaded] = useState(false)

  // useEffect(() => {
  //   var userRedux = []

  //   userRedux.push({
  //     label: '---All---',
  //     value: -1
  //   })

  //   usersRedx.forEach(field => {
  //     userRedux.push({
  //       label: `${field.fullName}`,
  //       value: field.id
  //     })
  //   })

  //   // if (userRedux > 1) setcurrentPracticeId(localStorage.getItem('medAssistUserId'))
  //   setassignedToRedux(userRedux)
  //   setreduxLoaded(true)
  // }, [usersRedx])


  // useEffect(() => {
  //   // Fetch user options or create them as needed
  //   const usersData = [
  //     { label: '---All---', value: -1 },
  //     // Add other user options as needed

  //   ];

  //   setUserOptions(usersData);
  //   setReduxLoaded(true);
  // }, []);
  const fields = [
    "defaultPracticeId",
  ];
  const defValues = {
    defaultPracticeId: "",
  };
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors }
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    defaultValues: defValues
  });
  const [isExpanded, setIsExpanded] = useState(false); // State to control expansion

  const handleToggle = () => {
    setIsExpanded(!isExpanded); // Toggle the expansion state
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

 
  const [openPracMenu, setOpenPracMenu] = useState(false)
  const handleOpenPracMenu = (event) => setOpenPracMenu(event.currentTarget)
  function handleClosePracMenu(practiceId) {
    
    // console.warn('clickeeedd', practiceId)
    setOpenPracMenu(false)
    if (practiceId !== 0) {
      axios
        .get(
          `${
            process.env.REACT_APP_API_URL
          }/Practices/switchPractice?emailAddress=${localStorage.getItem(
            'Emailaddress'
          )}&PracticeId=${practiceId}`,
          { headers }
        )
        .then((response) => {
          setcurrentPracticeId(practiceId)
          // localStorage.getItem('default_paractice')
          console.warn('______________',practiceId)
          console.warn(response)
          localStorage.setItem('Token', response.token)
          localStorage.setItem('default_paractice', response.defaultPracticeId)
          // localStorage.setItem('Emailaddress', response.data.email)
          // navigate("/Patient");
          window.location.reload()
        })
        .catch((error) => {
          console.error('There was an error!', error)
        })
    }
  }
  const renderPractices = () => (
    <Menu
      anchorEl={openPracMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={Boolean(openPracMenu)}
      onClose={() => handleClosePracMenu(0)}
      sx={{ mt: 2,  }}
      PaperProps={{
        style: {
          backgroundColor: '#1B2C3F', 
        },
      }}
    
    >
      {PracticeName.map((ele, i) =>

      parseInt(ele.practiceId, 10) ===
      parseInt(currentPracticeId, 10) ? (
        <>
        <MenuItem
          // title={[ele.practiceName]}
          style={{ width: '400px',backgroundColor:'#1B2C3F',color:'#408470' }}
          onClick={() => handleClosePracMenu(ele.practiceId)}
          
      >
         <Badge
              overlap='circular'
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <Person2RoundedIcon  style={{ color: '#FFF', borderRadius:'50%', border:'2px dashed gray' }}  />
            </Badge>
            <Typography style={{marginLeft:'10px'}}> { ele.practiceName}</Typography>
            {/* { ele.practiceName} */}
      
      </MenuItem>
      <Divider style={{ backgroundColor: 'white' }}></Divider>
      </>
        ) : (
          <>
          <MenuItem
            // title={[ele.practiceName]}
            style={{ width: '400px',backgroundColor:'#1B2C3F',color:'white' }}
            onClick={() => handleClosePracMenu(ele.practiceId)}
            selected={currentPracticeId==ele.practiceId?true:false} 
            >
            <Badge
                overlap='circular'
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                <Person2RoundedIcon  style={{ color: '#FFF', borderRadius:'50%', border:'2px dashed gray' }}  />
              </Badge>
              <Typography style={{marginLeft:'10px'}}> { ele.practiceName}</Typography>
          </MenuItem>
          <Divider style={{ backgroundColor: 'white' }}></Divider>
          </>
        )
        )}
            
            </Menu>
     
  )
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
  //   <React.Fragment>
  //   <header id="page-topbar">
     
  //       <div className="navbar-header">
  //          <div className="d-flex">
  //            <div className="navbar-brand-box text-center" style={{width:'205px'}}>
  //              <Link to="/" className="logo logo-dark">
  //                <span className="logo-sm">
  //                  <img src={logoSm} alt="logo-sm-dark" height="22" />
  //                </span>
  //                <span className="logo-lg">
  //                  <img src={logoDark} alt="logo-dark" height="24" />
  //                </span>
  //              </Link>

  //              <Link to="/" className="logo logo-light">
               
  //                <Container>
  //                  <Grid container alignItems="center">
  //                    <Grid item xs={6}>
  //                      <img src={logoLight} alt="logo-light" style={{ width: '55%', marginLeft: '-100px' }} />
  //                    </Grid>
  //                    <Grid item xs={6}>
  //                      <h4 style={{ color: 'white', marginLeft: '-66px', marginTop: '7px' }}>LuxBilling</h4>
  //                    </Grid>
  //                 </Grid>
  //                </Container>


  //             </Link>
              
  //          </div>
    

  //         <button
  //           type="button"
  //           className="btn btn-sm px-3 font-size-24 header-item waves-effect"
  //           id="vertical-menu-btn"
  //           onClick={() => {
  //             tToggle();
  //           }}
  //         >
  //           <i className="ri-menu-2-line align-middle"></i>
  //         </button>

  //         <form className="app-search d-none d-lg-block">
  //           <div className="position-relative">
  //             <input
  //               type="text"
  //               className="form-control"
  //               placeholder="Search..."
  //             />
  //             <span className="ri-search-line"></span>
  //           </div>
  //         </form>
  //       </div>

  //       <div className="d-flex">
  //         <div className="dropdown d-inline-block d-lg-none ms-2">
  //           <button
  //             onClick={() => {
  //               setsearch(!search);
  //             }}
  //             type="button"
  //             className="btn header-item noti-icon "
  //             id="page-header-search-dropdown"
  //           >
  //             <i className="ri-search-line" />
  //           </button>
  //           <div
  //             className={
  //               search
  //                 ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
  //                 : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
  //             }
  //             aria-labelledby="page-header-search-dropdown"
  //           >
  //             <form className="p-3">
  //               <div className="form-group m-0">
  //                 <div className="input-group">
  //                   <input
  //                     type="text"
  //                     className="form-control"
  //                     placeholder="Search ..."
  //                     aria-label="Recipient's username"
  //                   />
  //                   <div className="input-group-append">
  //                     <button className="btn btn-primary" type="submit">
  //                       <i className="ri-search-line" />
  //                     </button>
  //                   </div>
  //                 </div>
  //               </div>
  //             </form>
  //           </div>
  //         </div>

  //         <LanguageDropdown />
  //         <AppsDropdown />

  //         <div className="dropdown d-none d-lg-inline-block ms-1">
  //           <button
  //             type="button"
  //             onClick={() => {
  //               toggleFullscreen();
  //             }}
  //             className="btn header-item noti-icon"
  //             data-toggle="fullscreen"
  //           >
  //             <i className="ri-fullscreen-line" />
  //           </button>
  //         </div>

  //         <NotificationDropdown />

  //         <ProfileMenu />

  //         <div
  //           className="dropdown d-inline-block"
  //           onClick={() => {
  //             props.showRightSidebarAction(!props.showRightSidebar);
  //           }}
  //         >
  //           <button
  //             type="button"
  //             className="btn header-item noti-icon right-bar-toggle waves-effect"
  //           >
  //             <i className="mdi mdi-cog"></i>
  //           </button>
  //         </div>
  //       </div>
  //     </div>
  //   </header>
  // </React.Fragment>
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box text-center" style={{width:'205px'}}>
              {/* <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoSm} alt="logo-sm-dark" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="logo-dark" height="24" />
                </span>
              </Link> */}

              <Link to="/" className="logo logo-light">
               
                <Container>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <img src={logoLight} alt="logo-light" style={{ width: '55%', marginLeft: '-100px' }} />
                    </Grid>
                    <Grid item xs={6}>
                      <h4 style={{ color: 'white', marginLeft: '-66px', marginTop: '7px' }}>LuxBilling</h4>
                    </Grid>
                  </Grid>
                </Container>


              </Link>
            </div>
          </div>
{/* <div style={{width:'80px'}}>
   <button
            type="button"
            className="btn btn-sm px-3 font-size-24 header-item waves-effect"
            id="vertical-menu-btn"
            onClick={() => {
              tToggle();
            }}
          >
            <i className="ri-menu-2-line align-middle"></i>
          </button>
          </div> */}
          <div className="d-flex" >
   
              <Grid style={{display: 'flex',alignItems:'center', width:'auto', backgroundColor:'#0d7f6a ', borderRadius:'7px', height:'47px'}}>
              <Grid style={{paddingLeft:'0.5rem', paddingRight:'0.5rem'}}>
                 <MenuIcon
               
                style={{ color: '#fff', border:'2px solid lightgray', borderRadius:'50%'}}
                size="small"
                onClick={handleOpenPracMenu}
              >
                
              </MenuIcon>
              {PracticeName.map((ele, i) =>
                  parseInt(ele.practiceId) ===
                  parseInt(
                    currentPracticeId
                  ) ? (
                    <>
                    <Typography
                    textAlign="center"
                      variant="button"
                      fontWeight="medium"
                      marginRight="2px"
                      color ='white'
                      marginLeft='8px'
                    >  
                    {ele.practiceName}
                    </Typography>
                    
                  </>
                  ) : (
                    ''
                  )
                )}
              
              {renderPractices()}
                </Grid>
              </Grid>
          
             {/* <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)} 
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              sx={{ '& .MuiMenu-paper': {width: 180, height: 148, mt: 7, backgroundColor: '#28243D'} }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left'

              }}
            >
          {PracticeName.map((ele, index) =>
         
              
                <Box sx={{ display: 'flex', alignItems: 'center', paddingLeft:'0.3rem', color:'#fff',zIndex:'1000' }} >
                <Badge
                overlap='circular'
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                <Person2RoundedIcon  style={{ color: '#FFF', marginLeft:'3px', borderRadius:'50%', border:'2px dashed gray' }}  />
              </Badge>
              <MenuItem 
              onClick={handleClose} 
              selected={currentPracticeId==ele.practiceId?true:false} 
              
              >{ele.practiceName}</MenuItem>
                </Box>
              
               
               )}
            </Menu>  */}
           
            
          </div>
          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                onClick={() => {
                  setsearch(!search);
                }}
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
              >
                <i className="ri-search-line" />
              </button>
              <div
                className={
                  search
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="ri-search-line" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            {/* <LanguageDropdown /> */}
            {/* <AppsDropdown /> */}

            {/* <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen();
                }}
                className="btn header-item noti-icon"
                data-toggle="fullscreen"
              >
                <i className="ri-fullscreen-line" />
              </button>
            </div> */}





            <NotificationDropdown />

            <ProfileMenu  />

            {/* <div
              className="dropdown d-inline-block"
              onClick={() => {
                props.showRightSidebarAction(!props.showRightSidebar);
              }}
            >
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle waves-effect"
              >
                <i className="mdi mdi-cog"></i>
              </button>
            </div> */}
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));








