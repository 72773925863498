import React, { useCallback, useMemo, useState, useEffect } from "react";
import { Container } from "reactstrap";
import { Button, Grid, TextField } from "@mui/material";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { useAlert } from "react-alert";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import RadioGroup from "@mui/material/RadioGroup";
import Moment from 'moment'
import Select from "react-select";
import SearchIcon from '@mui/icons-material/Search'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./Button.css"
import ClickAwayListener from '@mui/material/ClickAwayListener';

// const history = useHistory();
const DentistUse = (props) => {
  
  // const patiname = props.PatientName;
  // const Formdidi = props.formDid;
  const [Formdid, setFormdid] = useState(props.formDid !== undefined ? props.formDid : 0)
  const [columnID, setcolumnID] = useState(props.columnID !== undefined ? props.columnID : 0)
  const [AutpattientID, setAutpattientID] = useState(props.AutpattientID)
  console.log('test', AutpattientID)
  // const patientId = props.patientId;
  const patientId = (props.callingFrom =="update"?props.patientid:props.patientId)
  const [patientName, setpatientName] = useState(props.patientName);
  console.log('paaa',patientName)
  const pname =props.PatientName
  const [consentDatee, setConsentDate] = React.useState(Moment().format('YYYY-MM-DD'));
  const [idd, setIdd] = useState('');
  const [patId, setPatId] = useState('');
  const [patName, setPatName] = useState(pname);
  const [periodCondition, setPeriodCondition] = useState('');
  const [tonsilsEnlarged, setTonsilsEnlarged] = useState('');
  const [grindingOfTeeth, setGrindingOfTeeth] = useState('');
  const [leftMovements, setLeftMovements] = useState('');
  const [maxOp, setMaxOp] = useState('');
  const [maxProt, setMaxProt] = useState('');
  const [rightMovements, setRightMovements] = useState('');
  const [palpitation, setPalpitation] = useState('');
  const [mandiMovement, setMandiMovement] = useState('');
  const [smoothMovement, setSmoothMovement] = useState('');
  const [luxation, setLuxation] = useState('');
  const [tenPlus, setTenPlus] = useState('');
  const [feelFatigued, setFeelFatigued] = useState('');
  const [notUsingCPAP, setNotUsingCPAP] = useState('');
  const [headaches, setHeadaches] = useState('');
  const [inapprop, setInapprop] = useState('');
  const [ahi, setAhi] = useState('');
  const [provName, setProvName] = useState('');
  const [provSig, setProvSig] = useState('');
  const [careProvDate, setCareProvDate] = React.useState('');
  const [consentSig, setConsentSig] = useState('');
  const [prName, setPrName] = useState('');

  const fields = [
    "id",
    "patientName",
    "patientId",
    "insuranceName",
    // "insurancId",
    // "address",
    // "city",
    // "state",
    // "zipCode",
    // "phone",
    // "heartProblems",
    // "heartProblemsDetails",
    // "highBloodPressure",
    // "highBloodPressureDetails",
    // "diabetes",
    // "diabetesDetail",
    // "thyroidSyndrome",
    // "thyroidSyndromeDeatils",
    // "prescribedMedicines",
    // "prescribedMedicinesDetails",
    // "attachList",
    // "alcoholConsumption",
    // "smokingLevel",
    // "sleepiness",
    // "impairedCognition",
    // "insomnia",
    // "ischemicHeartDisease",
    // "inappropriate",
    // "excessive",
    // "moodDisorders",
    // "hypertension",
    // "historyOfStroke",
    // "cpap",
    // "cpapDaysLimit",
    // "deviceCausing",
    // "deviceIrritated",
    // "intestinalissues",
    // "other",
    // "latexAllergy",
    // "previousSleepStudy",
    // "ahiScore",
    // "oralSleepAppliance",
    // "oralSleepYear",
    // "sittingAndReading",
    // "watchingTV",
    // "sittingInactivePlace",
    // "passengerCar",
    // "layingToRest",
    // "sittingAndTalking",
    // "sittingQuietly",
    // "consumed",
    // "carStationary",
    // "patientSignature",
    // "sleepinessDate",
    "periodontalCondition",
    "tonsilsEnlarged",
    "grindingOfTeeth",
    "palpitation",
    "mandibularMovement",
    "smoothMovement",
    "luxation",
    "epworthOfTenPlus",
    "feelingFatigued",
    "notUsingCPAP",
    "headaches",
    "inappropriateDaytimeNapping",
    "ahi",
    "providerCareName",
    "providerCareSignature",
    "careProviderDate",
    "orderingPhysicianName",
    "necessityPatient",
    "patDOB",
    "inactive",
    "practiceId",
    "maxOpening",
    "maxProtrusion",
    "maxLateralLeftMovements",
    "maxLateralRightMovements",
    "consentDate",
    "consentSignature",
    "printName",
    "total",
  ];
  const defValues = {
    id: 0,
    patientId: patientId,
    patientName: "",
    insuranceName: "",
    // insurancId: 0,
    // address: "",
    // city: "",
    // state: "",
    // zipCode: "",
    // phone: "",
    // heartProblems: "",
    // heartProblemsDetails: "",
    // highBloodPressure: "",
    // highBloodPressureDetails: "",
    // diabetes: "",
    // diabetesDetail: "",
    // thyroidSyndrome: "",
    // thyroidSyndromeDeatils: "",
    // prescribedMedicines: true,
    // prescribedMedicinesDetails: "",
    // attachList: "",
    // alcoholConsumption: "",
    // smokingLevel: "",
    // sleepiness: false,
    // impairedCognition: "",
    // insomnia: "",
    // ischemicHeartDisease: "",
    // inappropriate: "",
    // excessive: false,
    // moodDisorders: "",
    // hypertension: "",
    // historyOfStroke: "",
    // cpap: "",
    // cpapDaysLimit: "",
    // deviceCausing: "",
    // deviceIrritated: "",
    // intestinalissues: "",
    // other: "",
    // latexAllergy: "",
    // previousSleepStudy: "",
    // ahiScore: "",
    // oralSleepAppliance: "",
    // oralSleepYear: "",
    // sittingAndReading: "",
    // watchingTV: "",
    // sittingInactivePlace: "",
    // passengerCar: "",
    // layingToRest: "",
    // sittingAndTalking: "",
    // sittingQuietly: "",
    // consumed: "",
    // carStationary: "",
    // patientSignature: "",
    // sleepinessDate: "",
    periodontalCondition: "",
    tonsilsEnlarged: "",
    grindingOfTeeth: "",
    palpitation: "",
    mandibularMovement: "",
    smoothMovement: "",
    luxation: "",
    epworthOfTenPlus: "",
    feelingFatigued: "",
    notUsingCPAP: "",
    headaches: "",
    inappropriateDaytimeNapping: "",
    ahi: "",
    providerCareName: "",
    providerCareSignature: "",
    careProviderDate: "",
    orderingPhysicianName: "",
    necessityPatient: "",
    patDOB: "",
    inactive: true,
    practiceId: 0,
    maxOpening: 0,
    maxProtrusion: 0,
    maxLateralLeftMovements: 0,
    maxLateralRightMovements: 0,
    consentDate: "",
    consentSignature: "",
    printName: "",
    total: "",

  };


  const navigate = useNavigate();
 
  const alert = useAlert();
 
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    // resolver: yupResolver(validationSchema),
    defaultValues: defValues,
  });

  const [submitting, setSubmitting] = useState(false);
  const [careProvDateError, setCareProvDateError] = useState(false);
  useEffect(() => {

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/AutherizationRequest/DentistById?Id=${Formdid}&authId=${columnID}`,
        { headers }
      )
      .then(response => {
        fields.forEach(field => {
          setValue(field, response[field])
        })

        console.log('response.dataaaaa: ', response)
        setIdd(response.id)
        setPatId(response.patientId)
        setPatName(response.patientName)
        setInapprop(response.inappropriateDaytimeNapping)
        setPeriodCondition(response.periodontalCondition)
        setTonsilsEnlarged(response.tonsilsEnlarged)
        setGrindingOfTeeth(response.grindingOfTeeth)
        setPalpitation(response.palpitation)
        setMandiMovement(response.mandibularMovement)
        setSmoothMovement(response.smoothMovement)
        setLuxation(response.luxation)
        setTenPlus(response.epworthOfTenPlus)
        setFeelFatigued(response.feelingFatigued)
        setNotUsingCPAP(response.notUsingCPAP)
        setHeadaches(response.headaches)
        setProvName(response.providerCareName)
        setProvSig(response.providerCareSignature)
        // var PattDob = Moment(response.patDOB).format('YYYY-MM-DD')
        setMaxOp(response.maxOpening)
        setMaxProt(response.maxProtrusion)
        setLeftMovements(response.maxLateralLeftMovements)
        setRightMovements(response.maxLateralRightMovements)
        setConsentSig(response.consentSignature)
        setPrName(response.printName)
        setAhi(response.ahi)
        // var PattDob2 = Moment(response.sleepinessDate).format('YYYY-MM-DD');
        // setDatee(PattDob2);
        if (response.status === 204 || !response) {
          setCareProvDate(''); 
        } else {
         
          const careProvDate = response.careProviderDate 
            ? Moment(response.careProviderDate).format('YYYY-MM-DD') 
            : ''; // Set to empty if careProviderDate is missing
  
          setCareProvDate(careProvDate); 
        }
    

        var conscDob = Moment(response.consentDate.format('YYYY-MM-DD'));
        setConsentDate(conscDob);
      })

      .catch(error => {
        console.error('There was an error!', error)
      })

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [Formdid,patId,columnID])
  const onSubmit = (data) => {
    setSubmitting(true);
    const postData = data;
    console.log('postData', postData)
    postData.authId = columnID
    if (!data.careProviderDate) {
      setCareProvDateError(true);
      setSubmitting(false);
      return;
    }
    {props.callingFrom=="Patient"?postData.patientName = patientName:postData.patientName = pname }
    {props.callingFrom=="Patient"?postData.patientId = AutpattientID:postData.patientId = patientId }
    {props.callingFrom=="Patient"?postData.id=idd:postData.id=idd}
    // postData.patientName= patientName
    // postData.patientId = AutpattientID
    // postData.address = Address;
    console.log("check Data", postData);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/AutherizationRequest/AddDentist`,
        postData,
        {
          headers,
        }
      )
      .then((response) => {
        if (response.id) {
          console.log("check Data", response);
          setSubmitting(false);
          toast.success('Record saved successfully', { 
            position: "bottom-center",
            // autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
            onClose: () => {
              if(props.callingFrom =='Patient') 
              {
                props.updateHealthHistory(response.id, response.authId)

                props.closedentist()
                console.log('checkid',response.id)
              }
              else if(props.callingFrom=="update")
              {
                // props.updateHealthHistory(response.id, response.authId, response.patientName)
                props.closevobpage() 
              }
              else if(props.callingFrom =="dashboard")
              {
                props.updateHealthHistory(response.id, response.authId, response.patientName)
                // props.closevobpage()
              }
             
              else {
                props.closedentist()

              }
            
            },
          });
        } else {
          setSubmitting(false);
        }
      })
      .catch((error) => {
        setSubmitting(false);
        alert.success(`Error ${error.message}`, {
          type: "error",
        });
      });
  };


  return (
    <Container>
      <Grid container columnSpacing={1}>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          {/* <h1
            style={{
              fontWeight: "bold",
              textAlign: "center",
              color: "black",
            }}
          >
            Snoring and obstructive sleep apnea (OSA)
          </h1> */}
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          {/* <h1
            style={{
              fontWeight: "bold",
              textAlign: "center",
              color: "black",
            }}
          >
            Screener{" "}
          </h1> */}
        </Grid>
        <Grid item xs={4}></Grid>
       
        <Grid item xs={1}></Grid>
        <Grid item xs={8} style={{ marginTop: "30px" }}>
          <h6>
            Incisor relationship Class 1 Class 2 Div I Class 2 Div II Class 3
            Overjet ....... mm
          </h6>
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={11} style={{ marginTop: "10px" }}>
          <h6>Overbite ....... mm</h6>
        </Grid>
        <Grid item xs={1}></Grid>

        <Grid item xs={3} style={{ marginTop: "10px" }}>
          <h6>OH/Periodontal condition?</h6>
        </Grid>
        <Grid item xs={3}>
          <div>
            <input
              type="radio"
              value="Good"
              name="periodontalCondition"
              {...register("periodontalCondition")}
              style={{ marginLeft: "20px" }}
            />{" "}
            Good
            <input
              type="radio"
              value="Fair"
              name="periodontalCondition"
              {...register("periodontalCondition")}
              style={{ marginLeft: "20px" }}
            />{" "}
            Fair
            <input
              type="radio"
              value="Poor"
              name="periodontalCondition"
              {...register("periodontalCondition")}
              style={{ marginLeft: "20px" }}
            />{" "}
            Poor
          </div>
        </Grid>
        <Grid item xs={5}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={3} style={{ marginTop: "10px" }}>
          <h6>Tonsils - enlarged/inflamed</h6>
        </Grid>
        <Grid item xs={3}>
          <div>
            <input
              type="radio"
              checked={tonsilsEnlarged ? true : false}
              onClick={(e) => {
                console.log('checked value', e)
                setValue("tonsilsEnlarged", true);
                setTonsilsEnlarged(true);
              }}
              style={{ marginLeft: "20px" }}
            />{" "}
            Yes
            <input
              type="radio"
              checked={tonsilsEnlarged ? false : true}
              onClick={(e) => {
                setValue("tonsilsEnlarged", false);
                setTonsilsEnlarged(false);
              }}
              style={{ marginLeft: "20px" }}
            />{" "}
            No
          </div>

        </Grid>
        <Grid item xs={5}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={4} style={{ marginTop: "10px" }}>
          <h6>Bruxism/clenching/grinding of teeth</h6>
        </Grid>
        <Grid item xs={3}>
          <div>
            <input
              type="radio"
              checked={grindingOfTeeth ? true : false}
              onClick={(e) => {
                console.log('checked value', e)
                setValue("grindingOfTeeth", true);
                setGrindingOfTeeth(true);
              }}
              style={{ marginLeft: "20px" }}
            />{" "}
            Yes
            <input
              type="radio"
              checked={grindingOfTeeth ? false : true}
              onClick={(e) => {
                setValue("grindingOfTeeth", false);
                setGrindingOfTeeth(false);
              }}
              style={{ marginLeft: "20px" }}
            />{" "}
            No
          </div>
        </Grid>

        <Grid container style={{ marginLeft: "80px" }}>
          <Grid item xs={1}>
            {" "}
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={11} style={{ marginTop: "10px" }}>
            <h5 style={{ fontWeight: "bold" }}>TMJ assessment:</h5>
          </Grid>


          <Grid item xs={1}></Grid>
          <Grid item xs={3} style={{ marginTop: "15px" }}>
            <h6>Max lateral movements </h6>
          </Grid>
          <Grid item xs={0.5} style={{ marginTop: "10px" }}>
            <h5>L</h5>
          </Grid>
          <Grid item xs={1} style={{ marginLeft: "-20px" }}>
            <Controller
              name="maxLateralLeftMovements"
              {...register("maxLateralLeftMovements")}
              control={control}
              render={({ field }) => (
                <TextField
                  type='text'
                  size="small"
                  variant="standard"
                  {...field}
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    onInput: (e) => {
                      const inputValue = e.target.value.trim();
                      if (inputValue === '' || /^[0-9]*$/.test(inputValue)) {
                        e.target.value = inputValue !== '' ? Math.max(0, parseInt(inputValue)).toString().slice(0, 10) : ''; // Allow only positive integers
                      } else {
                        e.target.value = ''; // Set value to empty if non-numeric input
                      }
                      field.onChange(e);
                    },
                  }}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={0.5} style={{ marginTop: "15px" }}>
            <h6>mm</h6>
          </Grid>

          <Grid item xs={0.5} style={{ marginTop: "10px" }}>
            <h5>R</h5>
          </Grid>
          <Grid item xs={1} style={{ marginLeft: "-20px" }}>
            <Controller
              name="maxLateralRightMovements"
              {...register("maxLateralRightMovements")}
              control={control}
              render={({ field }) => (
                <TextField
                  type='text'
                  size="small"
                  variant="standard"
                  {...field}
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    onInput: (e) => {
                      const inputValue = e.target.value.trim();
                      if (inputValue === '' || /^[0-9]*$/.test(inputValue)) {
                        e.target.value = inputValue !== '' ? Math.max(0, parseInt(inputValue)).toString().slice(0, 10) : ''; // Allow only positive integers
                      } else {
                        e.target.value = ''; // Set value to empty if non-numeric input
                      }
                      field.onChange(e);
                    },
                  }}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={0.5} style={{ marginTop: "15px" }}>
            <h6>mm</h6>
          </Grid>
          <Grid item xs={3}></Grid>

          <Grid item xs={1}></Grid>
          <Grid item xs={3} style={{ marginTop: "15px" }}>
            <h6>Max opening </h6>
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={1} style={{ marginLeft: "-20px" }}>
            <Controller
              name="maxOpening"
              {...register("maxOpening")}
              control={control}
              render={({ field }) => (
                <TextField
                  type='text'
                  size="small"
                  variant="standard"
                  {...field}
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    onInput: (e) => {
                      const inputValue = e.target.value.trim();
                      if (inputValue === '' || /^[0-9]*$/.test(inputValue)) {
                        e.target.value = inputValue !== '' ? Math.max(0, parseInt(inputValue)).toString().slice(0, 10) : ''; // Allow only positive integers
                      } else {
                        e.target.value = ''; // Set value to empty if non-numeric input
                      }
                      field.onChange(e);
                    },
                  }}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={0.5} style={{ marginTop: "15px" }}>
            <h6>mm</h6>
          </Grid>
          <Grid item xs={6}></Grid>


          <Grid item xs={1}></Grid>
          <Grid item xs={3} style={{ marginTop: "15px" }}>
            <h6>Max protrusion </h6>
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={1} style={{ marginLeft: "-20px" }}>
            <Controller
              name="maxProtrusion"
              {...register("maxProtrusion")}
              control={control}
              render={({ field }) => (
                <TextField
                  type='text'
                  size="small"
                  variant="standard"
                  {...field}
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    onInput: (e) => {
                      const inputValue = e.target.value.trim();
                      if (inputValue === '' || /^[0-9]*$/.test(inputValue)) {
                        e.target.value = inputValue !== '' ? Math.max(0, parseInt(inputValue)).toString().slice(0, 10) : ''; // Allow only positive integers
                      } else {
                        e.target.value = ''; // Set value to empty if non-numeric input
                      }
                      field.onChange(e);
                    },
                  }}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={0.5} style={{ marginTop: "15px" }}>
            <h6>mm</h6>
          </Grid>
          <Grid item xs={6}></Grid>

          <Grid item xs={1}></Grid>
          <Grid item xs={3} style={{ marginTop: "10px" }}>
            <h6>Tenderness to palpitation</h6>
          </Grid>
          <Grid item xs={3} style={{ marginTop: "10px" }}>
            <div>
              <input
                type="radio"
                checked={palpitation ? true : false}
                onClick={(e) => {
                  console.log('checked value', e)
                  setValue("palpitation", true);
                  setPalpitation(true);
                }}
                style={{ marginLeft: "20px" }}
              />{" "}
              Yes
              <input
                type="radio"
                checked={palpitation ? false : true}
                onClick={(e) => {
                  setValue("palpitation", false);
                  setPalpitation(false);
                }}
                style={{ marginLeft: "20px" }}
              />{" "}
              No
            </div>
          </Grid>
          <Grid item xs={5}>
            {" "}
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={3} style={{ marginTop: "10px" }}>
            <h6>Pain on mandibular movement</h6>
          </Grid>
          <Grid item xs={3} style={{ marginTop: "10px" }}>
            <div>
              <input
                type="radio"
                checked={mandiMovement ? true : false}
                onClick={(e) => {
                  console.log('checked value', e)
                  setValue("mandibularMovement", true);
                  setMandiMovement(true);
                }}
                style={{ marginLeft: "20px" }}
              />{" "}
              Yes
              <input
                type="radio"
                checked={mandiMovement ? false : true}
                onClick={(e) => {
                  setValue("mandibularMovement", false);
                  setMandiMovement(false);
                }}
                style={{ marginLeft: "20px" }}
              />{" "}
              No
            </div>
          </Grid>
          <Grid item xs={5}>
            {" "}
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={3} style={{ marginTop: "10px" }}>
            <h6>Smooth movement</h6>
          </Grid>
          <Grid item xs={3} style={{ marginTop: "10px" }}>
            <div>
              <input
                type="radio"
                checked={smoothMovement ? true : false}
                onClick={(e) => {
                  console.log('checked value', e)
                  setValue("smoothMovement", true);
                  setSmoothMovement(true);
                }}
                style={{ marginLeft: "20px" }}
              />{" "}
              Yes
              <input
                type="radio"
                checked={smoothMovement ? false : true}
                onClick={(e) => {
                  setValue("smoothMovement", false);
                  setSmoothMovement(false);
                }}
                style={{ marginLeft: "20px" }}
              />{" "}
              No
            </div>
          </Grid>
          <Grid item xs={5}>
            {" "}
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={3} style={{ marginTop: "10px" }}>
            <h6>Locking and/or luxation</h6>
          </Grid>
          <Grid item xs={3} style={{ marginTop: "10px" }}>
            <div>
              <input
                type="radio"
                checked={luxation ? true : false}
                onClick={(e) => {
                  console.log('checked value', e)
                  setValue("luxation", true);
                  setLuxation(true);
                }}
                style={{ marginLeft: "20px" }}
              />{" "}
              Yes
              <input
                type="radio"
                checked={luxation ? false : true}
                onClick={(e) => {
                  setValue("luxation", false);
                  setLuxation(false);
                }}
                style={{ marginLeft: "20px" }}
              />{" "}
              No
            </div>
          </Grid>

          {/* </Grid> */}
          <Grid item xs={5}>
            {" "}
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={11} style={{ marginTop: "30px" }}>
            <h6 style={{ fontWeight: "bold" }}>
              CARE PATHWAY: If the following are reported a Sleep Study should
              be completed to determine if the patient has OSA &
            </h6>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={11}>
            <h6 style={{ fontWeight: "bold" }}>
              the Informed consent (next page) should be completed.
            </h6>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={1} style={{ marginTop: "3px" }}>
            <Checkbox
              name="epworthOfTenPlus"
              checked={tenPlus ? true : false}
              onClick={() => {
                setValue("epworthOfTenPlus", !tenPlus);
                setTenPlus(!tenPlus);
              }}
            />
          </Grid>
          <Grid item xs={10} style={{ marginTop: "10px" }}>
            <h6>Epworth of 10+</h6>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={1} style={{ marginTop: "3px" }}>
            <Checkbox
              name="feelingFatigued"
              checked={feelFatigued ? true : false}
              onClick={() => {
                setValue("feelingFatigued", !feelFatigued);
                setFeelFatigued(!feelFatigued);
              }}
            />
          </Grid>
          <Grid item xs={10} style={{ marginTop: "10px" }}>
            <h6>Feeling fatigued</h6>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={1} style={{ marginTop: "3px" }}>
            <Checkbox
              name="notUsingCPAP"
              checked={notUsingCPAP ? true : false}
              onClick={() => {
                setValue("notUsingCPAP", !notUsingCPAP);
                setNotUsingCPAP(!notUsingCPAP);
              }}
            />
          </Grid>
          <Grid item xs={10} style={{ marginTop: "10px" }}>
            <h6>Not using CPAP</h6>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={1} style={{ marginTop: "3px" }}>
            <Checkbox
              name="headaches"
              checked={headaches ? true : false}
              onClick={() => {
                setValue("headaches", !headaches);
                setHeadaches(!headaches);
              }}
            />
          </Grid>
          <Grid item xs={10} style={{ marginTop: "10px" }}>
            <h6>Headaches</h6>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={1} style={{ marginTop: "3px" }}>
            <Checkbox
              name="inappropriateDaytimeNapping"
              checked={inapprop ? true : false}
              onClick={() => {
                setValue("inappropriateDaytimeNapping", !inapprop);
                setInapprop(!inapprop);
              }}
            />
          </Grid>
          <Grid item xs={10} style={{ marginTop: "10px" }}>
            <h6>
              Inappropriate daytime napping (e.g., during driving, conversation,
              or eating)
            </h6>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={1} style={{ marginTop: "3px" }}>
            <Checkbox
              name="ahi"
              checked={ahi ? true : false}
              onClick={() => {
                setValue("ahi", !ahi);
                setAhi(!ahi);
              }}
            />
          </Grid>
          <Grid item xs={10} style={{ marginTop: "10px" }}>
            <h6>
              AHI of 5 or above (only if the patient has already had a sleep
              test in the past)
            </h6>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={3} style={{ marginTop: "50px" }}>
            <h5 style={{ fontWeight: "bold" }}>Provider of care Name:</h5>
          </Grid>
          <Grid item xs={4} style={{ marginTop: "40px", marginLeft: "-20px" }}>
            <Controller
              name="providerCareName"
              {...register("providerCareName")}
              control={control}
              render={({ field }) => (
                <TextField
                  size="small"
                  variant="standard"
                  {...field}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={3.5} style={{ marginTop: "50px" }}>
            <h5 style={{ fontWeight: "bold" }}>Provider of care Signature:</h5>
          </Grid>
          <Grid item xs={3} style={{ marginTop: "40px", marginLeft: "-30px" }}>
            <Controller
              name="providerCareSignature"
              {...register("providerCareSignature")}
              control={control}
              render={({ field }) => (
                <TextField
                  size="small"
                  variant="standard"
                  {...field}
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item xs={1} style={{ marginTop: "50px", marginLeft: "20px" }}>
            <h5 style={{ fontWeight: "bold" }}>Date:</h5>
          </Grid>
          <Grid item xs={2.5} style={{ marginTop: "40px", marginLeft: "5px" }}>
            <Controller
              name="careProviderDate"
              {...register("careProviderDate")}
              control={control}
              render={({ field }) => (
                <TextField
                  type="date"
                  size="small"
                  variant="standard"
                  {...field}
                  fullWidth
                  value={careProvDate || ''}
                  onChange={e => {
                    setValue('careProviderDate', e.target.value)
                    setCareProvDate(e.target.value)
                    setCareProvDateError(false)

                  }}
                />
              )}
            />
           
            {careProvDateError && <p style={{ color: 'red' }}>Please Select Date</p>}
          </Grid>
          
          <Grid item xs={1}></Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={7}></Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={2}>     <Button 
             className="Button"
              onClick={handleSubmit(onSubmit)}
              variant="gradient"
               style={{
                 
                marginTop: "0px",
                marginBottom: "10px",
                float: "right",
                marginLeft: "auto",
                width: "80px",
                height: "35px",
                backgroundColor: "#A574FD",
                color: "white",
                fontSize: "14px",
              }}
            >
              Save
            </Button></Grid>


     
          <Grid item xs={11} style={{ marginTop: "60px" }}>
            <h3 style={{ fontWeight: "bold" }}>
        
            
            </h3>
            
          </Grid>
     
          </Grid>
         
      </Grid>
    </Container>
  );
};

export default DentistUse;
