import React, { useState, useEffect, useRef } from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";
import axios from "axios";
import { Container } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Moment from 'moment'
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Checkbox from "@mui/material/Checkbox";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import RadioGroup from "@mui/material/RadioGroup";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import InputMask from 'react-input-mask';

import { useLocation, Link } from 'react-router-dom'
import Select from "react-select";
import SearchIcon from '@mui/icons-material/Search'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//Import Breadcrumb
import "./Button.css"
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { Phone } from "@mui/icons-material";
import InputAdornment from '@mui/material/InputAdornment'

// const history = useHistory(); 
const Tmdform = (props) => {
  const [Formdid, setFormdid] = useState(props.formDid !== undefined ? props.formDid : 0)
  const [TmdId, setcolumnID] = useState(props.columnID !== undefined ? props.columnID : 0)

  // const patientId = (props.callingFrom =="update"?props.patientid:props.patientid)
  // const patientNameee = props.PatientName;
  // const [patientName, setpatientName] = useState(props.patientName);
  // const [patName, setPatName] = useState(patientNameee);
  //  const [AutpattientID, setAutpattientID] = useState(props.AutpattientID)

  console.log('patieent id', Formdid)
  const [authid, setAuthId] = useState()
  const [patId, setPatId] = useState();
  const patientName = props.patientNametmd;
  const location = useLocation()
  // const TmdId = props.columnID
 const dateYMDs= props.PatientdateTMD
 const formatedDate=(newDate)=>{
  console.log(newDate,'patientName___1')
const date=new Date(newDate)
console.log(date,'patientName___2')
if(!isNaN(date)){
  return date.toISOString().split('T')[0];
}
return ''; // Return empty if date is invalid

 

 }
 const [dob, setDOB] = React.useState('');
 console.log('dateYMD____patientName___', dob)

  // const DOB = props.callingFrom=="Pateint"? props.DOB: dob; 
  const [headaches, setheadaches] = useState('');
  const [rightJawJoint, setrightJawJoint] = useState('');
  const [leftJawJoint, setleftJawJoint] = useState('');
  const [recentlyPainSevere, setrecentlyPainSevere] = useState('');
  const [painWorseTime, setpainWorseTime] = useState('');
  const [painType, setpainType] = useState('');
  const [painInEar, setpainInEar] = useState('');
  const [noiceRightJawJoint, setnoiceRightJawJoint] = useState('');
  const [noiceLeftJawJoint, setnoiceLeftJawJoint] = useState('');
  const [noiseBecomeMore, setnoiseBecomeMore] = useState('');
  const [hearingWorsened, sethearingWorsened] = useState('');
  const [jawProblemInterfere, setjawProblemInterfere] = useState('');
  const [takingMedication, settakingMedication] = useState('');
  const [severeBlow, setsevereBlow] = useState('');
  const [phone, setPhone] = useState('');
  const [difficultyChewing, setdifficultyChewing] = useState('');
  const [difficultyChewingBecauseOf, setdifficultyChewingBecauseOf] = useState('');
  const [mouthLock, setmouthLock] = useState('');
  const [mouthWide, setmouthWide] = useState('');
  const [clenchingTeeth, setclenchingTeeth] = useState('');
  const [grindTeeth, setgrindTeeth] = useState('');
  const [changeLifestyle, setchangeLifestyle] = useState('');
  const [thinkNervous, setthinkNervous] = useState('');
  const [problemsOtherJoints, setproblemsOtherJoints] = useState('');
  const [orthodonticTreatment, setorthodonticTreatment] = useState('');
  const [recentDentalTreatment, setrecentDentalTreatment] = useState('');
  const [xRays, setxRays] = useState('');
  const [heartProblems, setHeartProblems] = useState('');
  const [highBloodP, setHighBloodP] = useState('');
  const [diabetes, setDiabetes] = useState('');
  const [thyroidSynd, setThyroidSynd] = useState('');
  const [presMeds, setPresMeds] = useState('');
  const [sleepiness, setSleepiness] = useState('');
  const [daytimeSleepiness, setdaytimeSleepiness] = useState('');
  const [impairedCognition, setimpairedCognition] = useState('');
  const [moodDisorders, setmoodDisorders] = useState('');
  const [insomnia, setinsomnia] = useState('');
  const [hypertension, sethypertension] = useState('');
  const [ischemicHeartDisease, setischemicHeartDisease] = useState('');
  const [historyOfStroke, sethistoryOfStroke] = useState('');
  const [inappropriateDaytimeNapping, setinappropriateDaytimeNapping] = useState('');
  const [providerCareSignature, setproviderCareSignature] = useState('');
  const [providerSignature, setproviderSignature] = useState('');
  const [periodontalCondition, setperiodontalCondition] = useState('');
  const [tonsilsEnlarged, settonsilsEnlarged] = useState('');
  const [grindingOfTeeth, setgrindingOfTeeth] = useState('');
  const [palpitation, setpalpitation] = useState('');
  const [mandibularMovement, setmandibularMovement] = useState('');
  const [smoothMovement, setsmoothMovement] = useState('');
  const [luxation, setluxation] = useState('');
  const [providerCareDate, setproviderCareDate] = React.useState(Moment().format('YYYY-MM-DD'),)

  const [isLoading, setIsLoading] = useState(false); 
  const [patientData, setPatientData] = React.useState([])
  const [patient, setPatient] = React.useState(false)
  const [patientSelected, setPatientSelected] = React.useState(false)
  const [searchText, setSearchText] = useState('')
  
  const [patientID, setPatientID] = React.useState(props.patientId)
  const [selectpatientid, setselectpatientid] = useState('');

  const [patientNamee, setPatientNamee] = useState(patientName)
  const fields = [
      "id",
      "authId",
      "patientId",
      "patientName",
      "dateTMD",
      "referredBy",
      "drReferringOfficeNo",
      "frequentHeadaches",
      "rightJawJoint",
      "noticeJawPainText",
      "leftJawJoint",
      "noticeLeftJawJointText",
      "recentlyPainSevereText",
      "painWorseTime",
      "painType",
      "painTypeOtherText",
      "painInEar",
      "noiceRightJawJoint",
      "noiceLeftJawJoint",
      "firstNoticeNoise",
      "noiseBecomeMore",
      "hearingWorsened",
      "jawProblemInterfere",
      "takingMedication",
      "takingMedicationText",
      "severeBlow",
      "severeBlowText",
      "difficultyChewing",
      "difficultyChewingBecauseOf",
      "difficultyChewingSpecify",
      "mouthLock",
      "mouthLockWhenText",
      "mouthWide",
      "mouthWideExplainText",
      "clenchingTeeth",
      "clenchingTeethWhenText",
      "grindTeeth",
      "grindTeethWhen",
      "changeLifestyle",
      "changeLifestyleExplainText",
      "thinkNervous",
      "problemsOtherJoints",
      "orthodonticTreatment",
      "orthodonticTreatmentWhenText",
      "recentDentalTreatment",
      "recentDentalTreatmentWhenText",
      "recentDentalTreatmentWhereText",
      "recentDentalTreatmentWhyText",
      "xRays",
      "xRaysWhenText",
      "xRayesWhereText",
      "heartProblems",
      "heartProblemsText",
      "highBloodPressure",
      "highBloodPressureText",
      "diabetes",
      "diabetesText",
      "thyroidSyndrome",
      "thyroidSyndromeText",
      "prescribedMedicinesText",
      "prescribedMedicinesListText",
      "alcoholConsumption",
      "smokingLevelText",
      "sleepiness",
      "daytimeSleepiness",
      "impairedCognition",
      "moodDisorders",
      "insomnia",
      "hypertension",
      "ischemicHeartDisease",
      "historyOfStroke",
      "inappropriateDaytimeNapping",
      "periodontalCondition",
      "tonsilsEnlarged",
      "grindingOfTeeth",
      "maxLateralLeftMovements",
      "maxLateralRightMovements",
      "maxOpening",
      "maxProtrusion",
      "palpitation",
      "mandibularMovement",
      "smoothMovement",
      "luxation",
      "providerCareSignature",
      "inactive",
      "practiceId",
      // "createdBy",
      // "createdDate",
      "updatedBy",
      // "updatedDate",
      "firstNoticeNoiseText",
      "recentlyPainSevere",
      "providerSignature",
      "proceduresCompleted",
      "providerName",
    
  ];
  const defValues = {
      id: 0,
      authId:0,
      patientId:"",
      patientName: '',
      patientName: patientName,
      dateTMD:"",
      referredBy:"",
      drReferringOfficeNo:0,
      frequentHeadaches:"",
      rightJawJoint:"",
      noticeJawPainText:"",
      leftJawJoint:"",
      recentlyPainSevere:"",
      noticeLeftJawJointText:"",
      recentlyPainSevereText:"",
      painWorseTime:"",
      painType:"",
      painTypeOtherText:"",
      painInEar:"",
      noiceRightJawJoint:"",
      noiceLeftJawJoint:"",
      firstNoticeNoise:"",
      noiseBecomeMore:"",
      hearingWorsened:"",
      jawProblemInterfere:"",
      takingMedication:"",
      takingMedicationText:"",
      severeBlow:"",
      severeBlowText:"",
      difficultyChewing:"",
      difficultyChewingBecauseOf:"",
      difficultyChewingSpecify:"",
      mouthLock:"",
      mouthLockWhenText:"",
      mouthWide:"",
      mouthWideExplainText:"",
      clenchingTeeth:"",
      clenchingTeethWhenText:"",
      grindTeeth:"",
      grindTeethWhen:"",
      changeLifestyle:"",
      changeLifestyleExplainText:"",
      thinkNervous:"",
      problemsOtherJoints:"",
      orthodonticTreatment:"",
      orthodonticTreatmentWhenText:"",
      recentDentalTreatment:"",
      recentDentalTreatmentWhenText:"",
      recentDentalTreatmentWhereText:"",
      recentDentalTreatmentWhyText:"",
      xRays:"",
      xRaysWhenText:"",
      xRayesWhereText:"",
      heartProblems:"",
      heartProblemsText:"",
      highBloodPressure:"",
      highBloodPressureText:"",
      diabetes:"",
      diabetesText:"",
      thyroidSyndrome:"",
      thyroidSyndromeText:"",
      prescribedMedicinesText:"",
      prescribedMedicinesListText:"",
      alcoholConsumption:"",
      smokingLevelText:"",
      sleepiness:"",
      daytimeSleepiness:"",
      impairedCognition:"",
      moodDisorders:"",
      insomnia:"",
      hypertension:"",
      ischemicHeartDisease:"",
      historyOfStroke:"",
      inappropriateDaytimeNapping:"",
      periodontalCondition:"",
      tonsilsEnlarged:"",
      grindingOfTeeth:"",
      maxLateralLeftMovements:0,
      maxLateralRightMovements:0,
      maxOpening:0,
      maxProtrusion:0,
      palpitation:"",
      mandibularMovement:"",
      smoothMovement:"",
      luxation:"",
      providerCareSignature:"",
      inactive:"",
      practiceId:0,
      // createdBy:"",
      // createdDate:"",
      pdatedBy:"",
      // updatedDate:"",
      firstNoticeNoiseText:"",
      providerSignature:"",
      proceduresCompleted:"",
      providerName:"",
  };

  document.title = "Prior Auth & VOB | LuxBillingEHR";
   // API Authanticatiion
   const token = localStorage.getItem('Token')
   const auth = `Bearer ${token}`
   const headers = {
     Authorization: auth
   }
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);
  const validationSchema = Yup.object({
    // dateTMD: Yup.string().required('Date is required'),
    // emailAddress: Yup.string()
    //   .required('Email is invalid')
    //   .email('Email is invalid'),
    // patientName: Yup.string().required('PatientName Name is required'),
   
    // dob: Yup.date()
    //   .required('dob is required')
    //   .nullable()
    //   .transform(v => (v instanceof Date && !isNaN(v) ? v : null))
    //   .max(today, "You can't be born in the future!")
    //   .min(startDate, "You can't be that much old!")
  })
 
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues,
  });
  const [submitting, setSubmitting] = useState(false);

  const alert = useAlert();

  const alertStyle = {
    zIndex: 999999, // Set a higher z-index for the alert

  };
  useEffect(() => {
if (TmdId >0) {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/PatientNote/TMDisordersById?Id=${TmdId}&authId=${TmdId}`,
        { headers }
      )
      .then(response => {
        fields.forEach(field => {
          setValue(field, response[0][field])
        })

        console.log('dateYMD____response.dataaaaa: ', response)
       // Convert MM/DD/YYYY to YYYY-MM-DD manually
const dateTMD = response[0].dateTMD;
const dateYMDd = dateTMD
  ? `${dateTMD.split('/')[2]}-${dateTMD.split('/')[0]}-${dateTMD.split('/')[1]}` // Year-Month-Day format
  : '';

console.log(dateYMDd, 'dateYMD____1'); // Output: 2024-07-24

// Set the converted date to DOB state
setDOB(dateYMDd);

      // Set the initial date value
      // setDOB(dateYMDd);
 
        setPatId(response[0].id)
        // setPatName(response[0].patientName)
        // var dateYMD = Moment(response[0].dateTMD).format('YYYY-MM-DD')

        // setDOB(response[0].dateTMD)
        // console.log(dateYMD,'dateYMD____00')

        console.log(response[0].dateTMD,'dateYMD____11')

        var dateYMD = Moment(response[0].providerCareDate).format('YYYY-MM-DD')
        setproviderCareDate(dateYMD)
        setheadaches(response[0].frequentHeadaches)
        console.log(response[0],'response[0]___+')
        setrightJawJoint(response[0].rightJawJoint)
        setleftJawJoint(response[0].leftJawJoint)
        setrecentlyPainSevere(response[0].recentlyPainSevere)
        setpainWorseTime(response[0].painWorseTime)
        setpainType(response[0].painType)
        setpainInEar(response[0].painInEar)
        setnoiceRightJawJoint(response[0].noiceRightJawJoint)
        setnoiceLeftJawJoint(response[0].noiceLeftJawJoint)
        setnoiseBecomeMore(response[0].noiseBecomeMore)
        sethearingWorsened(response[0].hearingWorsened)
        setjawProblemInterfere(response[0].jawProblemInterfere)
        settakingMedication(response[0].takingMedication)
        setsevereBlow(response[0].severeBlow)
        setdifficultyChewing(response[0].difficultyChewing)
        setdifficultyChewingBecauseOf(response[0].difficultyChewingBecauseOf)
        setmouthLock(response[0].mouthLock)
        setmouthWide(response[0].mouthWide)
        setclenchingTeeth(response[0].clenchingTeeth)
        setgrindTeeth(response[0].grindTeeth)
        setchangeLifestyle(response[0].changeLifestyle)
        setthinkNervous(response[0].thinkNervous)
        setproblemsOtherJoints(response[0].problemsOtherJoints)
        setorthodonticTreatment(response[0].orthodonticTreatment)
        setrecentDentalTreatment(response[0].recentDentalTreatment)
        setxRays(response[0].xRays)
        setHeartProblems(response[0].heartProblems)
        setHighBloodP(response[0].highBloodPressure)
        setDiabetes(response[0].diabetes)
        setThyroidSynd(response[0].thyroidSyndrome)
        setPresMeds(response[0].prescribedMedicinesText)
        setSleepiness(response[0].sleepiness)
        setdaytimeSleepiness(response[0].daytimeSleepiness)
        setimpairedCognition(response[0].impairedCognition)
        setmoodDisorders(response[0].moodDisorders)
        setinsomnia(response[0].insomnia)
        sethypertension(response[0].hypertension)
        setischemicHeartDisease(response[0].ischemicHeartDisease)
        sethistoryOfStroke(response[0].historyOfStroke)
        setinappropriateDaytimeNapping(response[0].inappropriateDaytimeNapping)
        setproviderCareSignature(response[0].providerCareSignature)
        setproviderSignature(response[0].providerSignature)
        
        setperiodontalCondition(response[0].periodontalCondition)
        settonsilsEnlarged(response[0].tonsilsEnlarged)
        setgrindingOfTeeth(response[0].grindingOfTeeth)
        setpalpitation(response[0].palpitation)
        setmandibularMovement(response[0].mandibularMovement)
        setsmoothMovement(response[0].smoothMovement)
        setluxation(response[0].luxation)
        setPhone(response[0].drReferringOfficeNo)
        
     
      })

      .catch(error => {
        console.error('There was an error!', error)
      })
    }
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [TmdId,TmdId])
  const practiceId = localStorage.getItem('default_paractice')
  const onSubmit = (data) => {
    if(!dob){
      return
    }
    setSubmitting(true);
    
    const postData = data;
    postData.id = TmdId;

    postData.patientName= patientNamee;
    postData.patientId = patientID;
    // if (patientID > 0){
    //   postData.drReferringOfficeNo = phone;
    // }

    postData.drReferringOfficeNo = phone;
    
    postData.practiceId= practiceId
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/PatientNote/AddTMDisorders`,
        postData,
        {
          headers,
        }
      )
      .then((response) => {
        if (response.id) {
          console.log("check Data", response);
          setSubmitting(false);
          alert.success("Record saved successfully.", {
            type: "success",
            // containerStyle: alertStyle,
            onClose: () => {
              props.CloseModal()
              // navigate('/Authorization')
            },
          });
        } else {
          setSubmitting(false);
          // setdisable(false);

        }
      })
      .catch((error) => {
        setSubmitting(false);
        // setdisable(false);
        alert.success(`Error ${error.message}`, {
          type: "error",
        });
      });
  };
 
  function handleCancel() {
    props.CloseModal()
   
    // navigate('/Authorization')

  }
  
  const onChangeHandle = async value => {
    // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions
    setIsLoading(true);
    console.log(value)
    const sendData = {
      id: 0,
      lastName: value,
      firstName: '',
      ssn: '',
      status: '',
      cellPhone: '',
      address: '',
      dob: '',
      patientId:'',
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Patients/searchPatient`,
        sendData,
        { headers },
        setPatientSelected(false)
      )
      .then(response => {
        setPatientData(response)
        setPatient(true)
        setIsLoading(false);
      })
      .catch(error => {
        console.error('There was an error!', error)
        setIsLoading(false);
      })

  }
  
  const clearSelection = (e) => {
    setValue('patientName', '');
    setPatientData([]);
    setPatient(false);
    setPatientNamee('');
    setSearchText('');
    setPatientSelected(false)
    setselectpatientid('')
    setRefresh(true)
    onChangeHandle(e.target.value)
  };
  const PatientStyle = {
    cursor: 'pointer',
    hover: {
      background: '#3b3b3b !important',
      color: 'rgba(5, 152, 236, 0.637) !important'
    },
    color: 'green',
    selection: {
      background: '#D7624E',
      color: 'white'
    }
  }


  return (
    <React.Fragment>
    <div className="page-content">
      <Container>
      <Grid container columnSpacing={1} style={{ color: "black",marginTop:'-60px' }}>
   
        {/* <Grid item xs={12}>
        <Typography variant="h4" fontWeight="bold" style={{marginTop:'2rem'}}> Add Temporomandibular Disorders</Typography>
        </Grid>
      <Grid
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ width: '100%', marginTop: '1px', borderRadius: '20px', height: '40px', }}
              bgcolor={'#356F60'}
            >
              <Grid item xs={10.5}>
              <Button
                className="Button"
                onClick={handleCancel}
                variant='contained'
                color='info'
                size='small'
                style={{
                  width: '20px',
                  float: 'left',
                  marginLeft: '15px',
                  height: '23px',
                  marginTop: '8px',
                  
                }}
              >
                <ArrowBackIosIcon
                  fontSize='medium'
                  style={{
                    color: '#1a82ad',
                  }}
                ></ArrowBackIosIcon>
                Back
              </Button>

              </Grid>
              <Grid item xs={0.5}>
             
              </Grid>
              <Grid item xs={1}>
              
              </Grid>
              
              

            </Grid>  */}
      <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <h1
            style={{
              fontWeight: "bold",
              textAlign: "center",
              color: "black",
            }}
          >
            Temporomandibular Disorders (TMD)
          </h1>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <h4
            style={{
              textAlign: "center",
              color: "black",
            }}
          >
            PATIENT QUESTIONNAIRE
          </h4>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <h4
            style={{
              textAlign: "center",
              color: "black",
            }}
          >
            (Please Print Legibly)
          </h4>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={1.5}>
          <h6
            style={{
              color: "black",
              marginTop:'12px'
            }}
          >
            Patient Name:
          </h6>
        </Grid>
        <Grid item xs={3}  >

        <ClickAwayListener onClickAway={() => setPatient(false)}>
          <TextField
          autoComplete='off'
            // {...register('patientName')}
            size='small'
            value={patientNamee}
            fullWidth
            placeholder='Sreach Patient'
            variant="standard" 
            InputProps={{
              // readOnly: TmdId > 0,
              endAdornment:  TmdId == 0  && (
                <InputAdornment position='end'>
                   {selectpatientid> 0 &&(
        <CancelOutlinedIcon style={{ color: 'red', cursor: 'pointer' }} onClick={clearSelection} />
      )}
                </InputAdornment>
              )
            }}
            onKeyUp={ev => {
              console.log('ee:', ev.target.value)
              if (ev.target.value !== null && ev.target.value !== '' && ev.target.value.length > 0) {
                onChangeHandle(ev.target.value)
                setSearchText(ev.target.value)
              } else {
                setPatientData([])
                setPatientSelected(false)
                setPatient(false)
                setSearchText('')
              
              }
            }}
          />
          
        </ClickAwayListener>
        {isLoading && (
          <CircularProgress size={20} style={{ marginLeft: '-40px', marginTop: '7px' }} />
            )}
        {patient ? (
          <div
            className='col-xs-6'
            style={{
              padding: '14px 16px',
              fontSize: '0.875rem',
              color: '#67748e',
              borderRadius: '5px',
              background: 'white',
              textAlign: 'justify',
              textJustify: 'inter-word',
              position: 'absolute',
              zIndex: '99',
              width: '20%',
              border: '1px solid #6cb5f9'
            }}
          >

            {patient ? (
                 patientData.length > 0 ? (
              patientData.map((s, index) => {
                var pat = `${patientData[index].lastName}, ${patientData[index].firstName}`
                const searchTextLower = searchText.toLowerCase();
                const fullNameLower = pat.toLowerCase();
                const i = fullNameLower.indexOf(searchTextLower);
                if (i === -1) {
                  return null;
                }
                const p1 = pat.substring(0, i);
                const p = pat.substring(i, i + searchText.length);
                const p2 = pat.substring(i + searchText.length);
             
                return (
                  <p
                    style={PatientStyle}
                    onClick={ev => {
                      setValue('patientName', `${patientData[index].lastName}, ${patientData[index].firstName}`)
                      setValue('patientId', patientData[index].id)
                      setPatientID(patientData[index].id)
                      setPatientData([])
                      setPatientSelected(true)
                      setPatient(false)
                      setPatientNamee(`${patientData[index].lastName}, ${patientData[index].firstName}`)
                      setselectpatientid(patientData[index].id)
                    }}
                    onBlur={ev => {
                      setPatientData([])
                      setPatient(false)
                    }}
                    onChange={ev => {
                      setPatientData([])
                      setPatientSelected(false)
                      setPatient(false)
                      setPatientNamee('')
                    }}
                  >
                    <span>{p1}</span>
                    <span style={{ backgroundColor: 'yellow' }}>{p}</span>
                    <span>{p2}</span>
                  </p>
                )
              })
            ) : (
              <Typography>No Record found</Typography>
            )
            ): null}
          </div>
        ) : (
          ''
        )}
        </Grid>
        <Grid item xs={0.5}>
          <h6
            style={{
              color: "black",
              marginTop:'12px'
            }}
          >
            Date:
          </h6>
        </Grid>
        <Grid item xs={3} >
        <Controller
  name='dateTMD'
  control={control}
  render={({ field }) => (
    <TextField
      type='date'
      size='small'
      fullWidth
      variant="standard"
      {...field}
      placeholder='Enter dob'
      value={dob || field.value || ''} // Use dob or field value or fallback to empty
      onChange={e => {
        setValue('dateTMD', e.target.value); // Update form value
        setDOB(e.target.value); // Update local state
      }}
      error={errors.dateTMD}
    />
  )}
/>

               <p style={{ color: 'red', fontSize: '14px' }}>{errors.dateTMD?.message}</p>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={1.5}>
          <h6
            style={{
              color: "black",
              marginTop:'12px'
            }}
          >
          Referred by:
          </h6>
        </Grid>
        <Grid item xs={3.5} >
        <Controller
          name="referredBy"
          {...register("referredBy")}
          control={control}
          render={({ field }) => (
            <TextField size="small" type="text" variant="standard" {...field} fullWidth />
          )}
        />
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={2.5}>
          <h6
            style={{
              color: "black",
              marginTop:'12px'
            }}
          >
          PH# of Dr referring office:
          </h6>
        </Grid>
        <Grid item xs={3} >
               <InputMask
                                          mask='+1 999-999-9999'
                                            disabled={false}
                                            value={phone}
                                            style={{ height: '30px' }}
                                            onChange={e => {
                                                setValue('drReferringOfficeNo', e.target.value)
                                                setPhone(e.target.value)
                                            }}
                                        >
                                            {() => <TextField  variant="standard" fullWidth size='small' placeholder='Enter Phone' />}
                                        </InputMask>
        </Grid>
        <Grid item xs={3.5}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
        <h6
            style={{
              color: "black",
              marginTop:'12px'
            }}
          >
         Directions: Please answer all by checking, circling, or filling in the blank on all that apply.
          </h6>

        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
        <h6
            style={{
                fontWeight:'bold',
              color: "black",
              marginTop:'12px'
            }}
          >
         YES  NO
          </h6>

        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}>
        <div>
            <input
              type="radio"
              name="frequentHeadaches"
              checked={headaches ? true : false}
              onClick={(e) => {
                setValue('frequentHeadaches', true)
                setheadaches(true)

              }}

              // {...register("heartProblems")}
              // style={{ marginLeft: "20px", marginTop: "12px" }}
            />{" "}
            
            <input
              type="radio"
              checked={headaches ? false : true}
              onClick={(e) => {
                setValue("frequentHeadaches", false);
                setheadaches(false);
              }}
              style={{ marginLeft: "20px" }}
            />{" "}
           
          </div>
       

        </Grid>
        <Grid item xs={9}>
        <h6
            style={{
              
              color: "black",
         
            }}
          >
          1. Do you have frequent headaches?
          </h6>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1} style={{marginTop:'20px'}}>
        <div>
            <input
              type="radio"
              name="rightJawJoint"
              checked={rightJawJoint ? true : false}
              onClick={(e) => {
                setValue('rightJawJoint', true)
                setrightJawJoint(true)

              }}

            //   {...register("heartProblems")}
            //   style={{ marginLeft: "20px", marginTop: "12px" }}
            />{" "}
            <input
              type="radio"
              checked={rightJawJoint ? false : true}
              onClick={(e) => {
                setValue('rightJawJoint', false)
                setrightJawJoint(false)

              }}
              style={{ marginLeft: "20px" }}
            />{" "}
           
          </div>

        </Grid>
        <Grid item xs={9} style={{marginTop:'20px'}}>
        <h6
            style={{
              
              color: "black",
         
            }}
          >
          2. Do you have pain in or around the right jaw joint?
          </h6>
        </Grid>
        <Grid item xs={1}></Grid>


        <Grid item xs={2}></Grid>
        <Grid item xs={3.5}>
        <h6
            style={{
              
              color: "black",
              marginTop:'12px'
         
            }}
          >
          When did you first notice the jaw pain?
          </h6>
        </Grid>
        <Grid item xs={4} >
        <Controller
          name="noticeJawPainText"
          {...register("noticeJawPainText")}
          control={control}
          render={({ field }) => (
            <TextField size="small" type="text" variant="standard" {...field} fullWidth />
          )}
        />
        </Grid>
        <Grid item xs={2.5}></Grid>


        <Grid item xs={1}></Grid>
        <Grid item xs={1} style={{marginTop:'20px'}}>
        <div>
            <input
              type="radio"
              name="leftJawJoint"
              checked={leftJawJoint ? true : false}
              onClick={(e) => {
                setValue('leftJawJoint', true)
                setleftJawJoint(true)

              }}

            //   {...register("heartProblems")}
            //   style={{ marginLeft: "20px", marginTop: "12px" }}
            />{" "}
            <input
              type="radio"
              checked={leftJawJoint ? false : true}
              onClick={(e) => {
                setValue('leftJawJoint', false)
                setleftJawJoint(false)

              }}
              style={{ marginLeft: "20px" }}
            />{" "}
           
          </div>

        </Grid>
        <Grid item xs={9} style={{marginTop:'20px'}}>
        <h6
            style={{
              
              color: "black",
         
            }}
          >
          3. Do you have pain in or around the left jaw joint?
          </h6>
        </Grid>
        <Grid item xs={1}></Grid>


        <Grid item xs={2}></Grid>
        <Grid item xs={3.5}>
        <h6
            style={{
              
              color: "black",
              marginTop:'12px'
         
            }}
          >
          When did you first notice the jaw pain?
          </h6>
        </Grid>
        <Grid item xs={4} >
        <Controller
          name="noticeLeftJawJointText"
          {...register("noticeLeftJawJointText")}
          control={control}
          render={({ field }) => (
            <TextField size="small" type="text" variant="standard" {...field} fullWidth />
          )}
        />
        </Grid>
        <Grid item xs={2.5}></Grid>

        <Grid item xs={1}></Grid>
        <Grid item xs={1} style={{marginTop:'20px'}}>
        <div>
            <input
              type="radio"
              name="recentlyPainSevere"
              checked={recentlyPainSevere ? true : false}
              onClick={(e) => {
                setValue('recentlyPainSevere', true)
                setrecentlyPainSevere(true)

              }}

            //   {...register("heartProblems")}
            //   style={{ marginLeft: "20px", marginTop: "12px" }}
            />{" "}
            <input
              type="radio"
              checked={recentlyPainSevere ? false : true}
              onClick={(e) => {
                setValue('recentlyPainSevere', false)
                setrecentlyPainSevere(false)

              }}
              style={{ marginLeft: "20px" }}
            />{" "}
           
          </div>

        </Grid>
        <Grid item xs={5.5} style={{marginTop:'20px'}}>
        <h6
            style={{
              
              color: "black",
         
            }}
          >
          5. Has the pain recently become more severe? If so, when? 
          </h6>
        </Grid>
        <Grid item xs={3.5} style={{marginTop:'7px'}} >
        <Controller
          name="recentlyPainSevereText"
          {...register("recentlyPainSevereText")}
          control={control}
          render={({ field }) => (
            <TextField size="small" type="text" variant="standard" {...field} fullWidth />
          )}
        />
        </Grid>
        <Grid item xs={1}></Grid>

        <Grid item xs={1}></Grid>
        <Grid item xs={3.5} style={{marginTop:'20px'}}>
        <h6
            style={{
              
              color: "black",
         
            }}
          >
          Circle One: The pain is worse in the:  
          </h6>
        </Grid>
        <Grid item xs={7.5} style={{ marginTop: "20px" }}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <div>
              <input
                type="radio"
                value="Mornings"
                name="painWorseTime"
                checked={painWorseTime === "Mornings"}
                onClick={() => {
                  setValue("painWorseTime", "Mornings");
                  setpainWorseTime("Mornings");
                }}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "15px", fontWeight: "normal" }}>Mornings</label>
              <input
                type="radio"
                value="Evenings"
                name="painWorseTime"
                checked={painWorseTime === "Evenings"}
                onClick={() => {
                  setValue("painWorseTime", "Evenings");
                  setpainWorseTime("Evenings");
                }}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "15px", fontWeight: "normal" }}>Evenings</label>
              <input
                type="radio"
                value="AtMeals"
                name="painWorseTime"
                checked={painWorseTime === "AtMeals"}
                onClick={() => {
                  setValue("painWorseTime", "AtMeals");
                  setpainWorseTime("AtMeals");
                }}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "15px", fontWeight: "normal" }}>AtMeals</label>
              <input
                type="radio"
                value="NoSpecificTime"
                name="painWorseTime"
                checked={painWorseTime === "NoSpecificTime"}
                onClick={() => {
                  setValue("painWorseTime", "NoSpecificTime");
                  setpainWorseTime("NoSpecificTime");
                }}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "15px", fontWeight: "normal" }}>No Specific Time</label>
            </div>
          </RadioGroup>

        </Grid>

        <Grid item xs={1}></Grid>
        <Grid item xs={3} style={{marginTop:'20px'}}>
        <h6
            style={{
              
              color: "black",
         
            }}
          >
          Circle One:  The pain is?  
          </h6>
        </Grid>
        <Grid item xs={7} style={{ marginTop: "20px" }}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <div>
              <input
                type="radio"
                value="DullStabbing"
                name="painType"
                checked={painType === "DullStabbing"}
                onClick={() => {
                  setValue("painType", "DullStabbing");
                  setpainType("DullStabbing");
                }}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "15px", fontWeight: "normal" }}>Dull Stabbing</label>
              <input
                type="radio"
                value="Throbbing"
                name="painType"
                checked={painType === "Throbbing"}
                onClick={() => {
                  setValue("painType", "Throbbing");
                  setpainType("Throbbing");
                }}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "15px", fontWeight: "normal" }}>Throbbing</label>
              <input
                type="radio"
                value="Continuous"
                name="painType"
                checked={painType === "Continuous"}
                onClick={() => {
                  setValue("painType", "Continuous");
                  setpainType("Continuous");
                }}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "15px", fontWeight: "normal" }}>Continuous</label>
              <input
                type="radio"
                value="Intermittent"
                name="painType"
                checked={painType === "Intermittent"}
                onClick={() => {
                  setValue("painType", "Intermittent");
                  setpainType("Intermittent");
                }}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "15px", fontWeight: "normal" }}>Intermittent</label>
            </div>
          </RadioGroup>

        </Grid>
        <Grid item xs={1}></Grid>

        <Grid item xs={2}></Grid>
        <Grid item xs={1}>
        <h6
            style={{
              
              color: "black",
              marginTop:'12px'
         
            }}
          >
         Other: 
          </h6>
        </Grid>
        <Grid item xs={6} >
        <Controller
          name="painTypeOtherText"
          {...register("painTypeOtherText")}
          control={control}
          render={({ field }) => (
            <TextField size="small" type="text" variant="standard" {...field} fullWidth />
          )}
        />
        </Grid>
        <Grid item xs={3}></Grid>

        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
        <h6
            style={{
                fontWeight:'bold',
              color: "black",
              marginTop:'12px'
            }}
          >
         YES  NO
          </h6>

        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}>
        <div>
            <input
              type="radio"
              name="painInEar"
              checked={painInEar ? true : false}
              onClick={(e) => {
                setValue('painInEar', true)
                setpainInEar(true)

              }}

            //   {...register("heartProblems")}
            //   style={{ marginLeft: "20px", marginTop: "12px" }}
            />{" "}
            <input
              type="radio"
              checked={painInEar ? false : true}
              onClick={(e) => {
                setValue('painInEar', false)
                setpainInEar(false)

              }}
              style={{ marginLeft: "20px" }}
            />{" "}
           
          </div>

        </Grid>
        <Grid item xs={9}>
        <h6
            style={{
              
              color: "black",
         
            }}
          >
           8. Does the pain sometimes feel like it is in your ear?
          </h6>
        </Grid>
        <Grid item xs={1}></Grid>


        <Grid item xs={1}></Grid>
        <Grid item xs={1} style={{marginTop:'20px'}}>
        <div>
            <input
              type="radio"
              name="noiceRightJawJoint"
              checked={noiceRightJawJoint ? true : false}
              onClick={(e) => {
                setValue('noiceRightJawJoint', true)
                setnoiceRightJawJoint(true)

              }}

            //   {...register("heartProblems")}
            //   style={{ marginLeft: "20px", marginTop: "12px" }}
            />{" "}
            <input
              type="radio"
              checked={noiceRightJawJoint ? false : true}
              onClick={(e) => {
                setValue('noiceRightJawJoint', false)
                setnoiceRightJawJoint(false)

              }}
              style={{ marginLeft: "20px" }}
            />{" "}
           
          </div>

        </Grid>
        <Grid item xs={9} style={{marginTop:'20px'}}>
        <h6
            style={{
              
              color: "black",
         
            }}
          >
          9. Do you have clicking, popping, or grating noise in your right jaw joint?
          </h6>
        </Grid>
        <Grid item xs={1}></Grid>

        <Grid item xs={1}></Grid>
        <Grid item xs={1} style={{marginTop:'20px'}}>
        <div>
            <input
              type="radio"
              name="noiceLeftJawJoint"
              checked={noiceLeftJawJoint ? true : false}
              onClick={(e) => {
                setValue('noiceLeftJawJoint', true)
                setnoiceLeftJawJoint(true)

              }}

            //   {...register("heartProblems")}
            //   style={{ marginLeft: "20px", marginTop: "12px" }}
            />{" "}
            <input
              type="radio"
              checked={noiceLeftJawJoint ? false : true}
              onClick={(e) => {
                setValue('noiceLeftJawJoint', false)
                setnoiceLeftJawJoint(false)

              }}
              style={{ marginLeft: "20px" }}
            />{" "}
           
          </div>

        </Grid>
        <Grid item xs={9} style={{marginTop:'20px'}}>
        <h6
            style={{
              
              color: "black",
         
            }}
          >
          10. Do you have clicking, popping, or grating noise in your left jaw joint?
          </h6>
        </Grid>
        <Grid item xs={1}></Grid>

        <Grid item xs={2}></Grid>
        <Grid item xs={3.5}>
        <h6
            style={{
              
              color: "black",
              marginTop:'12px'
         
            }}
          >
          When did you first notice the noise?
          </h6>
        </Grid>
        <Grid item xs={4} >
        <Controller
          name="firstNoticeNoise"
          {...register("firstNoticeNoise")}
          control={control}
          render={({ field }) => (
            <TextField size="small" type="text" variant="standard" {...field} fullWidth />
          )}
        />
        </Grid>
        <Grid item xs={2.5}></Grid>


        <Grid item xs={1}></Grid>
        <Grid item xs={1} style={{marginTop:'20px'}}>
        <div>
            <input
              type="radio"
              name="noiseBecomeMore"
              checked={noiseBecomeMore ? true : false}
              onClick={(e) => {
                setValue('noiseBecomeMore', true)
                setnoiseBecomeMore(true)

              }}

            //   {...register("heartProblems")}
            //   style={{ marginLeft: "20px", marginTop: "12px" }}
            />{" "}
            <input
              type="radio"
              checked={noiseBecomeMore ? false : true}
              onClick={(e) => {
                setValue('noiseBecomeMore', false)
                setnoiseBecomeMore(false)

              }}
              style={{ marginLeft: "20px" }}
            />{" "}
           
          </div>

        </Grid>
        <Grid item xs={9} style={{marginTop:'20px'}}>
        <h6
            style={{
              
              color: "black",
         
            }}
          >
          12. Has the noise become more pronounced recently?
          </h6>
        </Grid>
        <Grid item xs={1}></Grid>


        <Grid item xs={1}></Grid>
        <Grid item xs={1} style={{marginTop:'20px'}}>
        <div>
            <input
              type="radio"
              name="hearingWorsened"
              checked={hearingWorsened ? true : false}
              onClick={(e) => {
                setValue('hearingWorsened', true)
                sethearingWorsened(true)

              }}

            //   {...register("heartProblems")}
            //   style={{ marginLeft: "20px", marginTop: "12px" }}
            />{" "}
            <input
              type="radio"
              checked={hearingWorsened ? false : true}
              onClick={(e) => {
                setValue('hearingWorsened', false)
                sethearingWorsened(false)

              }}
              style={{ marginLeft: "20px" }}
            />{" "}
           
          </div>

        </Grid>
        <Grid item xs={9} style={{marginTop:'20px'}}>
        <h6
            style={{
              
              color: "black",
         
            }}
          >
           13. Has your hearing worsened since your jaw problem began?
          </h6>
        </Grid>
        <Grid item xs={1}></Grid>



        <Grid item xs={1}></Grid>
        <Grid item xs={1} style={{marginTop:'20px'}}>
        <div>
            <input
              type="radio"
              name="jawProblemInterfere"
              checked={jawProblemInterfere ? true : false}
              onClick={(e) => {
                setValue('jawProblemInterfere', true)
                setjawProblemInterfere(true)

              }}

            //   {...register("heartProblems")}
            //   style={{ marginLeft: "20px", marginTop: "12px" }}
            />{" "}
            <input
              type="radio"
              checked={jawProblemInterfere ? false : true}
              onClick={(e) => {
                setValue('jawProblemInterfere', false)
                setjawProblemInterfere(false)

              }}
              style={{ marginLeft: "20px" }}
            />{" "}
           
          </div>

        </Grid>
        <Grid item xs={9} style={{marginTop:'20px'}}>
        <h6
            style={{
              
              color: "black",
         
            }}
          >
           14. Does your jaw problem interfere with your normal activities?
          </h6>
        </Grid>
        <Grid item xs={1}></Grid>


        <Grid item xs={1}></Grid>
        <Grid item xs={1} style={{marginTop:'20px'}}>
        <div>
            <input
              type="radio"
              name="takingMedication"
              checked={takingMedication ? true : false}
              onClick={(e) => {
                setValue('takingMedication', true)
                settakingMedication(true)

              }}

            //   {...register("heartProblems")}
            //   style={{ marginLeft: "20px", marginTop: "12px" }}
            />{" "}
            <input
              type="radio"
              checked={takingMedication ? false : true}
              onClick={(e) => {
                setValue('takingMedication', false)
                settakingMedication(false)

              }}
              style={{ marginLeft: "20px" }}
            />{" "}
           
          </div>

        </Grid>
        <Grid item xs={9} style={{marginTop:'20px'}}>
        <h6
            style={{
              
              color: "black",
         
            }}
          >
           15. Are you taking, or have you taken, medication for this condition?
          </h6>
        </Grid>
        <Grid item xs={1}></Grid>



        <Grid item xs={1}></Grid>
        <Grid item xs={1.5}>
        <h6
            style={{
              
              color: "black",
              marginTop:'12px'
         
            }}
          >
          If so, what?
          </h6>
        </Grid>
        <Grid item xs={8.5} >
        <Controller
          name="takingMedicationText"
          {...register("takingMedicationText")}
          control={control}
          render={({ field }) => (
            <TextField size="small" type="text" variant="standard" {...field} fullWidth />
          )}
        />
        </Grid>
        <Grid item xs={1}></Grid>



        <Grid item xs={1}></Grid>
        <Grid item xs={1} style={{marginTop:'20px'}}>
        <div>
            <input
              type="radio"
              name="severeBlow"
              checked={severeBlow ? true : false}
              onClick={(e) => {
                setValue('severeBlow', true)
                setsevereBlow(true)

              }}

            //   {...register("heartProblems")}
            //   style={{ marginLeft: "20px", marginTop: "12px" }}
            />{" "}
            <input
              type="radio"
              checked={severeBlow ? false : true}
              onClick={(e) => {
                setValue('severeBlow', false)
                setsevereBlow(false)

              }}
              style={{ marginLeft: "20px" }}
            />{" "}
           
          </div>

        </Grid>
        <Grid item xs={9} style={{marginTop:'20px'}}>
        <h6
            style={{
              
              color: "black",
         
            }}
          >
           16. Have you ever had a severe blow or trauma to the head, neck, or jaw?
          </h6>
        </Grid>
        <Grid item xs={1}></Grid>


        <Grid item xs={2}></Grid>
        <Grid item xs={1}>
        <h6
            style={{
              
              color: "black",
              marginTop:'12px'
         
            }}
          >
          Explain:
          </h6>
        </Grid>
        <Grid item xs={8} >
        <Controller
          name="severeBlowText"
          {...register("severeBlowText")}
          control={control}
          render={({ field }) => (
            <TextField size="small" type="text" variant="standard" {...field} fullWidth />
          )}
        />
        </Grid>
        <Grid item xs={1}></Grid>


        <Grid item xs={1}></Grid>
        <Grid item xs={1} style={{marginTop:'20px'}}>
        <div>
            <input
              type="radio"
              name="difficultyChewing"
              checked={difficultyChewing ? true : false}
              onClick={(e) => {
                setValue('difficultyChewing', true)
                setdifficultyChewing(true)

              }}

            //   {...register("heartProblems")}
            //   style={{ marginLeft: "20px", marginTop: "12px" }}
            />{" "}
            <input
              type="radio"
              checked={difficultyChewing ? false : true}
              onClick={(e) => {
                setValue('difficultyChewing', false)
                setdifficultyChewing(false)

              }}
              style={{ marginLeft: "20px" }}
            />{" "}
           
          </div>

        </Grid>
        <Grid item xs={9} style={{marginTop:'20px'}}>
        <h6
            style={{
              
              color: "black",
         
            }}
          >
           17. Do you have difficulty chewing?
          </h6>
        </Grid>
        <Grid item xs={1}></Grid>

        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
        <h6
            style={{
              
              color: "black",
              marginTop:'12px'
         
            }}
          >
          *If so, is this difficulty because of:
          </h6>
        </Grid>
       
        <Grid item xs={2}></Grid>


        <Grid item xs={2}></Grid>
        <Grid item xs={10} style={{ marginTop: "10px" }}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <div>
              <input
                type="radio"
                value="paininjoint"
                name="difficultyChewingBecauseOf"
                checked={difficultyChewingBecauseOf === "paininjoint"}
                onClick={() => {
                  setValue("difficultyChewingBecauseOf", "paininjoint");
                  setdifficultyChewingBecauseOf("paininjoint");
                }}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "15px", fontWeight: "normal" }}>pain in joint</label>
              <input
                type="radio"
                value="paininteeth"
                name="difficultyChewingBecauseOf"
                checked={difficultyChewingBecauseOf === "paininteeth"}
                onClick={() => {
                  setValue("difficultyChewingBecauseOf", "paininteeth");
                  setdifficultyChewingBecauseOf("paininteeth");
                }}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "15px", fontWeight: "normal" }}>pain in teeth</label>
              <input
                type="radio"
                value="clicking"
                name="difficultyChewingBecauseOf"
                checked={difficultyChewingBecauseOf === "clicking"}
                onClick={() => {
                  setValue("difficultyChewingBecauseOf", "clicking");
                  setdifficultyChewingBecauseOf("clicking");
                }}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "15px", fontWeight: "normal" }}>clicking</label>
              <input
                type="radio"
                value="limitedopening "
                name="difficultyChewingBecauseOf"
                checked={difficultyChewingBecauseOf === "limitedopening"}
                onClick={() => {
                  setValue("difficultyChewingBecauseOf", "limitedopening");
                  setdifficultyChewingBecauseOf("limitedopening");
                }}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "15px", fontWeight: "normal" }}>limited opening </label>
              <input
                type="radio"
                value="missingteeth "
                name="difficultyChewingBecauseOf"
                checked={difficultyChewingBecauseOf === "missingteeth"}
                onClick={() => {
                  setValue("difficultyChewingBecauseOf", "missingteeth");
                  setdifficultyChewingBecauseOf("missingteeth");
                }}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "15px", fontWeight: "normal" }}>missing teeth</label>
            </div>
          </RadioGroup>

        </Grid>



        <Grid item xs={1}></Grid>
        <Grid item xs={2}>
        <h6
            style={{
              
              color: "black",
              marginTop:'12px'
         
            }}
          >
          Other (specify):
          </h6>
        </Grid>
        <Grid item xs={8} >
        <Controller
          name="difficultyChewingSpecify"
          {...register("difficultyChewingSpecify")}
          control={control}
          render={({ field }) => (
            <TextField size="small" type="text" variant="standard" {...field} fullWidth />
          )}
        />
        </Grid>
        <Grid item xs={1}></Grid>


        <Grid item xs={1}></Grid>
        <Grid item xs={1} style={{marginTop:'20px'}}>
        <div>
            <input
              type="radio"
              name="mouthLock"
              checked={mouthLock ? true : false}
              onClick={(e) => {
                setValue('mouthLock', true)
                setmouthLock(true)

              }}

            //   {...register("heartProblems")}
            //   style={{ marginLeft: "20px", marginTop: "12px" }}
            />{" "}
            <input
              type="radio"
              checked={mouthLock ? false : true}
              onClick={(e) => {
                setValue('mouthLock', false)
                setmouthLock(false)

              }}
              style={{ marginLeft: "20px" }}
            />{" "}
           
          </div>

        </Grid>
        <Grid item xs={9} style={{marginTop:'20px'}}>
        <h6
            style={{
              
              color: "black",
         
            }}
          >
           18. Has your mouth ever locked open so you were unable to close it?
          </h6>
        </Grid>
        <Grid item xs={1}></Grid>
        

        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
        <h6
            style={{
              
              color: "black",
              marginTop:'12px'
         
            }}
          >
          If so, when?
          </h6>
        </Grid>
        <Grid item xs={7} >
        <Controller
          name="mouthLockWhenText"
          {...register("mouthLockWhenText")}
          control={control}
          render={({ field }) => (
            <TextField size="small" type="text" variant="standard" {...field} fullWidth />
          )}
        />
        </Grid>
        <Grid item xs={1}></Grid>

        <Grid item xs={1}></Grid>
        <Grid item xs={1} style={{marginTop:'20px'}}>
        <div>
            <input
              type="radio"
              name="mouthWide"
              checked={mouthWide ? true : false}
              onClick={(e) => {
                setValue('mouthWide', true)
                setmouthWide(true)

              }}

            //   {...register("heartProblems")}
            //   style={{ marginLeft: "20px", marginTop: "12px" }}
            />{" "}
            <input
              type="radio"
              checked={mouthWide ? false : true}
              onClick={(e) => {
                setValue('mouthWide', false)
                setmouthWide(false)

              }}
              style={{ marginLeft: "20px" }}
            />{" "}
           
          </div>

        </Grid>
        <Grid item xs={9} style={{marginTop:'20px'}}>
        <h6
            style={{
              
              color: "black",
         
            }}
          >
           19. Have you had problems opening your mouth wide?
          </h6>
        </Grid>
        <Grid item xs={1}></Grid>
        

        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
        <h6
            style={{
              
              color: "black",
              marginTop:'12px'
         
            }}
          >
          If so, please explain:
          </h6>
        </Grid>
        <Grid item xs={7} >
        <Controller
          name="mouthWideExplainText"
          {...register("mouthWideExplainText")}
          control={control}
          render={({ field }) => (
            <TextField size="small" type="text" variant="standard" {...field} fullWidth />
          )}
        />
        </Grid>
        <Grid item xs={1}></Grid>

        <Grid item xs={1}></Grid>
        <Grid item xs={1} style={{marginTop:'20px'}}>
        <div>
            <input
              type="radio"
              name="clenchingTeeth"
              checked={clenchingTeeth ? true : false}
              onClick={(e) => {
                setValue('clenchingTeeth', true)
                setclenchingTeeth(true)

              }}

            //   {...register("heartProblems")}
            //   style={{ marginLeft: "20px", marginTop: "12px" }}
            />{" "}
            <input
              type="radio"
              checked={clenchingTeeth ? false : true}
              onClick={(e) => {
                setValue('clenchingTeeth', false)
                setclenchingTeeth(false)

              }}
              style={{ marginLeft: "20px" }}
            />{" "}
           
          </div>

        </Grid>
        <Grid item xs={4.5} style={{marginTop:'20px'}}>
        <h6
            style={{
              
              color: "black",
         
            }}
          >
           20. Are you aware of clenching your teeth? When?
          </h6>
        </Grid>
        <Grid item xs={4.5} style={{marginTop:'8px'}} >
        <Controller
          name="clenchingTeethWhenText"
          {...register("clenchingTeethWhenText")}
          control={control}
          render={({ field }) => (
            <TextField size="small" type="text" variant="standard" {...field} fullWidth />
          )}
        />
        </Grid>
        <Grid item xs={1}></Grid>


        <Grid item xs={1}></Grid>
        <Grid item xs={1} style={{marginTop:'20px'}}>
        <div>
            <input
              type="radio"
              name="grindTeeth"
              checked={grindTeeth ? true : false}
              onClick={(e) => {
                setValue('grindTeeth', true)
                setgrindTeeth(true)

              }}

            //   {...register("heartProblems")}
            //   style={{ marginLeft: "20px", marginTop: "12px" }}
            />{" "}
            <input
              type="radio"
              checked={grindTeeth ? false : true}
              onClick={(e) => {
                setValue('grindTeeth', false)
                setgrindTeeth(false)

              }}
              style={{ marginLeft: "20px" }}
            />{" "}
           
          </div>

        </Grid>
        <Grid item xs={9} style={{marginTop:'20px'}}>
        <h6
            style={{
              
              color: "black",
         
            }}
          >
           21. Do you grind your teeth?
          </h6>
        </Grid>
        <Grid item xs={1}></Grid>
        

        <Grid item xs={2}></Grid>
        <Grid item xs={1.5}>
        <h6
            style={{
              
              color: "black",
              marginTop:'12px'
         
            }}
          >
          If so, when?
          </h6>
        </Grid>
        <Grid item xs={7.5} >
        <Controller
          name="grindTeethWhen"
          {...register("grindTeethWhen")}
          control={control}
          render={({ field }) => (
            <TextField size="small" type="text" variant="standard" {...field} fullWidth />
          )}
        />
        </Grid>
        <Grid item xs={1}></Grid>

        <Grid item xs={1}></Grid>
        <Grid item xs={1} style={{marginTop:'20px'}}>
        <div>
            <input
              type="radio"
              name="changeLifestyle"
              checked={changeLifestyle ? true : false}
              onClick={(e) => {
                setValue('changeLifestyle', true)
                setchangeLifestyle(true)

              }}

            //   {...register("heartProblems")}
            //   style={{ marginLeft: "20px", marginTop: "12px" }}
            />{" "}
            <input
              type="radio"
              checked={changeLifestyle ? false : true}
              onClick={(e) => {
                setValue('changeLifestyle', false)
                setchangeLifestyle(false)

              }}
              style={{ marginLeft: "20px" }}
            />{" "}
           
          </div>

        </Grid>
        <Grid item xs={9} style={{marginTop:'20px'}}>
        <h6
            style={{
              
              color: "black",
         
            }}
          >
           22. Has there been recent change in your lifestyle such as, a change in marital status, childbirth,
          </h6>
        </Grid>
        <Grid item xs={1}></Grid>


        <Grid item xs={1}></Grid>
        <Grid item xs={10} >
        <h6
            style={{
              
              color: "black",
         
            }}
          >
          change of employment, death in the immediate family, or other stressful events?
          </h6>
        </Grid>
        <Grid item xs={1}></Grid>
    
        <Grid item xs={1}></Grid>
        <Grid item xs={2}>
        <h6
            style={{
              
              color: "black",
              marginTop:'12px'
         
            }}
          >
         If so, please explain:
          </h6>
        </Grid>
        <Grid item xs={9} >
        <Controller
          name="changeLifestyleExplainText"
          {...register("changeLifestyleExplainText")}
          control={control}
          render={({ field }) => (
            <TextField size="small" type="text" variant="standard" {...field} fullWidth />
          )}
        />
        </Grid>
        {/* <Grid item xs={1}></Grid>
        <Grid item xs={10.5} >
        <Controller
          name="changeLifestyleExplainText"
          {...register("changeLifestyleExplainText")}
          control={control}
          render={({ field }) => (
            <TextField size="small" type="text" variant="standard" {...field} fullWidth />
          )}
        />
        </Grid>
        <Grid item xs={0.5}></Grid> */}


        <Grid item xs={1}></Grid>
        <Grid item xs={1} style={{marginTop:'20px'}}>
        <div>
            <input
              type="radio"
              name="thinkNervous"
              checked={thinkNervous ? true : false}
              onClick={(e) => {
                setValue('thinkNervous', true)
                setthinkNervous(true)

              }}

            //   {...register("heartProblems")}
            //   style={{ marginLeft: "20px", marginTop: "12px" }}
            />{" "}
            <input
              type="radio"
              checked={thinkNervous ? false : true}
              onClick={(e) => {
                setValue('thinkNervous', false)
                setthinkNervous(false)

              }}
              style={{ marginLeft: "20px" }}
            />{" "}
           
          </div>

        </Grid>
        <Grid item xs={9} style={{marginTop:'20px'}}>
        <h6
            style={{
              
              color: "black",
         
            }}
          >
           23. Do you think nervous tension seems to affect this problem?

          </h6>
        </Grid>
        <Grid item xs={1}></Grid>


        <Grid item xs={1}></Grid>
        <Grid item xs={1} style={{marginTop:'20px'}}>
        <div>
            <input
              type="radio"
              name="problemsOtherJoints"
              checked={problemsOtherJoints ? true : false}
              onClick={(e) => {
                setValue('problemsOtherJoints', true)
                setproblemsOtherJoints(true)

              }}

            //   {...register("heartProblems")}
            //   style={{ marginLeft: "20px", marginTop: "12px" }}
            />{" "}
            <input
              type="radio"
              checked={problemsOtherJoints ? false : true}
              onClick={(e) => {
                setValue('problemsOtherJoints', false)
                setproblemsOtherJoints(false)

              }}
              style={{ marginLeft: "20px" }}
            />{" "}
           
          </div>

        </Grid>
        <Grid item xs={9} style={{marginTop:'20px'}}>
        <h6
            style={{
              
              color: "black",
         
            }}
          >
           24. Have you had problems with other joints?

          </h6>
        </Grid>
        <Grid item xs={1}></Grid>


        <Grid item xs={1}></Grid>
        <Grid item xs={1} style={{marginTop:'20px'}}>
        <div>
            <input
              type="radio"
              name="orthodonticTreatment"
              checked={orthodonticTreatment ? true : false}
              onClick={(e) => {
                setValue('orthodonticTreatment', true)
                setorthodonticTreatment(true)

              }}

            //   {...register("heartProblems")}
            //   style={{ marginLeft: "20px", marginTop: "12px" }}
            />{" "}
            <input
              type="radio"
              checked={orthodonticTreatment ? false : true}
              onClick={(e) => {
                setValue('orthodonticTreatment', false)
                setorthodonticTreatment(false)

              }}
              style={{ marginLeft: "20px" }}
            />{" "}
           
          </div>

        </Grid>
        <Grid item xs={9} style={{marginTop:'20px'}}>
        <h6
            style={{
              
              color: "black",
         
            }}
          >
           25. Have you had orthodontic treatment?


          </h6>
        </Grid>
        <Grid item xs={1}></Grid>



        <Grid item xs={2}></Grid>
        <Grid item xs={1.5}>
        <h6
            style={{
              
              color: "black",
              marginTop:'12px'
         
            }}
          >
          If so, when?
          </h6>
        </Grid>
        <Grid item xs={7.5} >
        <Controller
          name="orthodonticTreatmentWhenText"
          {...register("orthodonticTreatmentWhenText")}
          control={control}
          render={({ field }) => (
            <TextField size="small" type="text" variant="standard" {...field} fullWidth />
          )}
        />
        </Grid>
        <Grid item xs={1}></Grid>


        <Grid item xs={1}></Grid>
        <Grid item xs={1} style={{marginTop:'20px'}}>
        <div>
            <input
              type="radio"
              name="recentDentalTreatment"
              checked={recentDentalTreatment ? true : false}
              onClick={(e) => {
                setValue('recentDentalTreatment', true)
                setrecentDentalTreatment(true)

              }}

            //   {...register("heartProblems")}
            //   style={{ marginLeft: "20px", marginTop: "12px" }}
            />{" "}
            <input
              type="radio"
              checked={recentDentalTreatment ? false : true}
              onClick={(e) => {
                setValue('recentDentalTreatment', false)
                setrecentDentalTreatment(false)

              }}
              style={{ marginLeft: "20px" }}
            />{" "}
           
          </div>

        </Grid>
        <Grid item xs={9} style={{marginTop:'20px'}}>
        <h6
            style={{
              
              color: "black",
         
            }}
          >
           26. Have you had recent dental treatment?


          </h6>
        </Grid>
        <Grid item xs={1}></Grid>


        <Grid item xs={2}></Grid>
        <Grid item xs={1.5}>
        <h6
            style={{
              
              color: "black",
              marginTop:'12px'
         
            }}
          >
          If so, when?
          </h6>
        </Grid>
        <Grid item xs={5.5} >
        <Controller
          name="recentDentalTreatmentWhenText"
          {...register("recentDentalTreatmentWhenText")}
          control={control}
          render={({ field }) => (
            <TextField size="small" type="text" variant="standard" {...field} fullWidth />
          )}
        />
        </Grid>
        <Grid item xs={3}></Grid>




        <Grid item xs={2}></Grid>
        <Grid item xs={0.5}>
        <h6
            style={{
              
              color: "black",
              marginTop:'12px'
         
            }}
          >
          Where?
          </h6>
        </Grid>
        <Grid item xs={4} >
        <Controller
          name="recentDentalTreatmentWhereText"
          {...register("recentDentalTreatmentWhereText")}
          control={control}
          render={({ field }) => (
            <TextField size="small" type="text" variant="standard" {...field} fullWidth style={{marginLeft:'25px'}} />
          )}
        />
        </Grid>
        <Grid item xs={0.5}>
        <h6
            style={{
              
              color: "black",
              marginTop:'12px',
              marginLeft:'25px'
         
            }}
          >
          Why?
          </h6>
        </Grid>
        <Grid item xs={4} >
        <Controller
          name="recentDentalTreatmentWhyText"
          {...register("recentDentalTreatmentWhyText")}
          control={control}
          render={({ field }) => (
            <TextField size="small" type="text" variant="standard" style={{marginLeft:'30px'}} {...field} fullWidth />
          )}
        />
        </Grid>
        <Grid item xs={1}></Grid>



        <Grid item xs={1}></Grid>
        <Grid item xs={1} style={{marginTop:'20px'}}>
        <div>
            <input
              type="radio"
              name="xRays"
              checked={xRays ? true : false}
              onClick={(e) => {
                setValue('xRays', true)
                setxRays(true)

              }}

            //   {...register("heartProblems")}
            //   style={{ marginLeft: "20px", marginTop: "12px" }}
            />{" "}
            <input
              type="radio"
              checked={xRays ? false : true}
              onClick={(e) => {
                setValue('xRays', false)
                setxRays(false)

              }}
              style={{ marginLeft: "20px" }}
            />{" "}
           
          </div>

        </Grid>
        <Grid item xs={9} style={{marginTop:'20px'}}>
        <h6
            style={{
              
              color: "black",
         
            }}
          >
           27. Have you had recent x-rays taken for this problem?


          </h6>
        </Grid>
        <Grid item xs={1}></Grid>


        <Grid item xs={2}></Grid>
        <Grid item xs={1.5}>
        <h6
            style={{
              
              color: "black",
              marginTop:'12px'
         
            }}
          >
          If so, when?
          </h6>
        </Grid>
        <Grid item xs={7.5} >
        <Controller
          name="xRaysWhenText"
          {...register("xRaysWhenText")}
          control={control}
          render={({ field }) => (
            <TextField size="small" type="text" variant="standard" {...field} fullWidth />
          )}
        />
        </Grid>
        <Grid item xs={1}></Grid>


        <Grid item xs={2}></Grid>
        <Grid item xs={1.5}>
        <h6
            style={{
              
              color: "black",
              marginTop:'12px'
         
            }}
          >
          Where?
          </h6>
        </Grid>
        <Grid item xs={7.5} >
        <Controller
          name="xRayesWhereText"
          {...register("xRayesWhereText")}
          control={control}
          render={({ field }) => (
            <TextField size="small" type="text" variant="standard" {...field} fullWidth />
          )}
        />
        </Grid>
        <Grid item xs={1}></Grid>


        <Grid item xs={0.5}></Grid>
        <Grid item xs={11} style={{ marginTop: "20px" }}>
          <h5 style={{ textAlign: "center",fontWeight:'bold',  color: "black", }}>
          The following questions relate to your lifestyle and general health. Please indicate whether you

          </h5>
        </Grid>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={10} >
          <h5 style={{ textAlign: "center",fontWeight:'bold',  color: "black", }}>
          suffer from any of the below, providing further details when the answer is yes.
          </h5>
        </Grid>
        <Grid item xs={1}></Grid>


        <Grid item xs={1}></Grid>
        <Grid item xs={3} style={{ marginTop: "10px" }}>
          <h6>Heart problems</h6>
        </Grid>
        <Grid item xs={2}>
          <div>
            <input
              type="radio"
              name="heartProblems"
              checked={heartProblems ? true : false}
              onClick={(e) => {
                setValue('heartProblems', true)
                setHeartProblems(true)

              }}

              // {...register("heartProblems")}
              style={{ marginLeft: "20px", marginTop: "12px" }}
            />{" "}
            Yes
            <input
              type="radio"
              checked={heartProblems ? false : true}
              onClick={(e) => {
                setValue("heartProblems", false);
                setHeartProblems(false);
              }}
              style={{ marginLeft: "20px" }}
            />{" "}
            No
          </div>
        </Grid>

        <Grid item xs={5} >
          <Controller
            name="heartProblemsText"
            {...register("heartProblemsText")}
            control={control}
            render={({ field }) => (
              <TextField size="small" variant="standard" {...field} fullWidth />
            )}
          />
        </Grid>
        <Grid item xs={1}></Grid>


        <Grid item xs={1}></Grid>
        <Grid item xs={3} style={{ marginTop: "10px" }}>
          <h6>High blood pressure</h6>
        </Grid>
        <Grid item xs={2}>
          <div>
            <input
              type="radio"
              checked={highBloodP ? true : false}
              onClick={(e) => {
                console.log('checked value', e)
                setValue("highBloodPressure", true);
                setHighBloodP(true);
              }}
              style={{ marginLeft: "20px", marginTop: "16px" }}
            />{" "}
            Yes
            <input
              type="radio"
              checked={highBloodP ? false : true}
              onClick={(e) => {
                setValue("highBloodPressure", false);
                setHighBloodP(false);
              }}
              style={{ marginLeft: "20px" }}
            />{" "}
            No
          </div>





        </Grid>

        <Grid item xs={5} >
          <Controller
            name="highBloodPressureText"
            {...register("highBloodPressureText")}
            control={control}
            render={({ field }) => (
              <TextField size="small" variant="standard" {...field} fullWidth />
            )}
          />
        </Grid>
        <Grid item xs={1}></Grid>


        <Grid item xs={1}></Grid>
        <Grid item xs={3} style={{ marginTop: "10px" }}>
          <h6>Diabetes </h6>
        </Grid>
       
        <Grid item xs={2}>
          <div>
            <input
              type="radio"
              checked={diabetes ? true : false}
              onClick={(e) => {
                console.log('checked value', e)
                setValue("diabetes", true);
                setDiabetes(true);
              }}
              style={{ marginLeft: "20px", marginTop: "16px" }}
            />{" "}
            Yes
            <input
              type="radio"
              checked={diabetes ? false : true}
              onClick={(e) => {
                setValue("diabetes", false);
                setDiabetes(false);
              }}
              style={{ marginLeft: "20px" }}
            />{" "}
            No
          </div>





        </Grid>
        <Grid item xs={5} >
          <Controller
            name="diabetesText"
            {...register("diabetesText")}
            control={control}
            render={({ field }) => (
              <TextField size="small" variant="standard" {...field} fullWidth />
            )}
          />
        </Grid>
        <Grid item xs={1}></Grid>


        <Grid item xs={1}></Grid>
        <Grid item xs={3} style={{ marginTop: "10px" }}>
          <h6>Thyroid syndrome</h6>
        </Grid>
        <Grid item xs={2}>
          <div>
            <input
              type="radio"
              checked={thyroidSynd ? true : false}
              onClick={(e) => {
                console.log('checked value', e)
                setValue("thyroidSyndrome", true);
                setThyroidSynd(true);
              }}
              style={{ marginLeft: "20px", marginTop: "16px" }}
            />{" "}
            Yes
            <input
              type="radio"
              checked={thyroidSynd ? false : true}
              onClick={(e) => {
                setValue("thyroidSyndrome", false);
                setThyroidSynd(false);
              }}
              style={{ marginLeft: "20px" }}
            />{" "}
            No
          </div>





        </Grid>
        <Grid item xs={5} >
          <Controller
            name="thyroidSyndromeText"
            {...register("thyroidSyndromeText")}
            control={control}
            render={({ field }) => (
              <TextField size="small" variant="standard" {...field} fullWidth />
            )}
          />
        </Grid>
        <Grid item xs={1}></Grid>


        <Grid item xs={1}></Grid>
        <Grid item xs={4} style={{ marginTop: "10px" }}>
          <h6>Do you take any prescribed medicines?</h6>
        </Grid>
        <Grid item xs={2}>
          <div>
            <input
              type="radio"
              checked={presMeds ? true : false}
              onClick={(e) => {
                console.log('checked value', e)
                setValue("prescribedMedicinesText", true);
                setPresMeds(true);
              }}
              style={{ marginLeft: "20px", marginTop: "16px" }}
            />{" "}
            Yes
            <input
              type="radio"
              checked={presMeds ? false : true}
              onClick={(e) => {
                setValue("prescribedMedicinesText", false);
                setPresMeds(false);
              }}
              style={{ marginLeft: "20px" }}
            />{" "}
            No
          </div>

        </Grid>
      
        <Grid item xs={5}></Grid>


        <Grid item xs={1}></Grid>
        <Grid item xs={1.5} style={{ marginTop: "10px" }}>
          <h6>Please list:</h6>
        </Grid>
        <Grid item xs={8.5} >
          <Controller
            name="prescribedMedicinesListText"
            {...register("prescribedMedicinesListText")}
            control={control}
            render={({ field }) => (
              <TextField size="small" variant="standard" {...field} fullWidth />
            )}
          />
        </Grid>
        <Grid item xs={1}></Grid>
        {/* <Grid item xs={1}></Grid>
        <Grid item xs={10} >
          <Controller
            name="prescribedMedicinesListText"
            {...register("prescribedMedicinesListText")}
            control={control}
            render={({ field }) => (
              <TextField size="small" variant="standard" {...field} fullWidth />
            )}
          />
        </Grid>
        <Grid item xs={1}></Grid> */}


        <Grid item xs={1}></Grid>
        <Grid item xs={2.5} style={{ marginTop: "23px" }}>
          <h6>Alcohol consumption</h6>
        </Grid>
        <Grid item xs={7} style={{ marginLeft: "-40px", marginTop: "10px"  }}>
          <Controller
            name="alcoholConsumption"
            {...register("alcoholConsumption")}
            control={control}
            render={({ field }) => (
              <TextField size="small" variant="standard" {...field} fullWidth />
            )}
          />
        </Grid>
        <Grid item xs={1} style={{ marginTop: "23px" }}>
          <h6>units/week</h6>
        </Grid>
        <Grid item xs={0.5}></Grid>


        <Grid item xs={1}></Grid>
        <Grid item xs={2} style={{ marginTop: "23px" }}>
          <h6>Smoking level</h6>
        </Grid>
        <Grid item xs={7.5} style={{ marginLeft: "-40px", marginTop: "10px"  }}>
        <Controller
            name="smokingLevelText"
            {...register("smokingLevelText")}
            control={control}
            render={({ field }) => (
              <TextField size="small" variant="standard" {...field} fullWidth />
            )}
          />
        </Grid>
        <Grid item xs={1.5} style={{ marginTop: "23px" }}>
          <h6>_packs per day</h6>
        </Grid>
      


        <Grid item xs={1}></Grid>
        <Grid item xs={10} style={{ marginTop: "20px" }}>
          <h6>
          ➔ Please indicate if you have suffered from any of the conditions below:
          </h6>
        </Grid>
        <Grid item xs={1}></Grid>


        <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            <Checkbox
              name="sleepiness"
              checked={sleepiness ? true : false}
              onClick={() => {
                setValue("sleepiness", !sleepiness);
                setSleepiness(!sleepiness);
              }}
            />
           
          </Grid>
          <Grid item xs={9} style={{ marginTop: "10px", marginLeft: "-30px" }}>
            <h6> Sleepiness that interferes with daily activities</h6>
          </Grid>
          <Grid item xs={1}></Grid>

          <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            <Checkbox
              name="daytimeSleepiness"
              checked={daytimeSleepiness ? true : false}
              onClick={() => {
                setValue("daytimeSleepiness", !daytimeSleepiness);
                setdaytimeSleepiness(!daytimeSleepiness);
              }}
            />
           
          </Grid>
          <Grid item xs={9} style={{ marginTop: "10px", marginLeft: "-30px" }}>
            <h6> Excessive daytime sleepiness</h6>
          </Grid>
          <Grid item xs={1}></Grid>

          <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            <Checkbox
              name="impairedCognition"
              checked={impairedCognition ? true : false}
              onClick={() => {
                setValue("impairedCognition", !impairedCognition);
                setimpairedCognition(!impairedCognition);
              }}
            />
           
          </Grid>
          <Grid item xs={9} style={{ marginTop: "10px", marginLeft: "-30px" }}>
            <h6>  Impaired cognition</h6>
          </Grid>
          <Grid item xs={1}></Grid>


          <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            <Checkbox
              name="moodDisorders"
              checked={moodDisorders ? true : false}
              onClick={() => {
                setValue("moodDisorders", !moodDisorders);
                setmoodDisorders(!moodDisorders);
              }}
            />
           
          </Grid>
          <Grid item xs={9} style={{ marginTop: "10px", marginLeft: "-30px" }}>
            <h6>Mood disorders</h6>
          </Grid>
          <Grid item xs={1}></Grid>

          <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            <Checkbox
              name="insomnia"
              checked={insomnia ? true : false}
              onClick={() => {
                setValue("insomnia", !insomnia);
                setinsomnia(!insomnia);
              }}
            />
           
          </Grid>
          <Grid item xs={9} style={{ marginTop: "10px", marginLeft: "-30px" }}>
            <h6>Insomnia</h6>
          </Grid>
          <Grid item xs={1}></Grid>


          <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            <Checkbox
              name="hypertension"
              checked={hypertension ? true : false}
              onClick={() => {
                setValue("hypertension", !hypertension);
                sethypertension(!hypertension);
              }}
            />
           
          </Grid>
          <Grid item xs={9} style={{ marginTop: "10px", marginLeft: "-30px" }}>
            <h6>Hypertension</h6>
          </Grid>
          <Grid item xs={1}></Grid>



          <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            <Checkbox
              name="ischemicHeartDisease"
              checked={ischemicHeartDisease ? true : false}
              onClick={() => {
                setValue("ischemicHeartDisease", !ischemicHeartDisease);
                setischemicHeartDisease(!ischemicHeartDisease);
              }}
            />
           
          </Grid>
          <Grid item xs={9} style={{ marginTop: "10px", marginLeft: "-30px" }}>
            <h6>Ischemic heart disease</h6>
          </Grid>
          <Grid item xs={1}></Grid>


          <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            <Checkbox
              name="historyOfStroke"
              checked={historyOfStroke ? true : false}
              onClick={() => {
                setValue("historyOfStroke", !historyOfStroke);
                sethistoryOfStroke(!historyOfStroke);
              }}
            />
           
          </Grid>
          <Grid item xs={9} style={{ marginTop: "10px", marginLeft: "-30px" }}>
            <h6>History of stroke</h6>
          </Grid>
          <Grid item xs={1}></Grid>


          <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            <Checkbox
              name="inappropriateDaytimeNapping"
              checked={inappropriateDaytimeNapping ? true : false}
              onClick={() => {
                setValue("inappropriateDaytimeNapping", !inappropriateDaytimeNapping);
                setinappropriateDaytimeNapping(!inappropriateDaytimeNapping);
              }}
            />
           
          </Grid>
          <Grid item xs={9} style={{ marginTop: "10px", marginLeft: "-30px" }}>
            <h6>Inappropriate daytime napping (e.g., during driving, conversation, or eating)</h6>
          </Grid>
          <Grid item xs={1}></Grid>


          <Grid item xs={1}></Grid>
        <Grid item xs={10} style={{ marginTop: "20px" }}>
          <h5>
          OTHER ATTEMPTED THERAPIES:
          </h5>
        </Grid>
        <Grid item xs={1}></Grid>


        <Grid item xs={1}></Grid>
        <Grid item xs={10} >
          <h6>
          Please comment about other therapy attempts/ any mouth/jaw-related procedures you have completed in the past.

          </h6>
        </Grid>
        <Grid item xs={1}></Grid>


        <Grid item xs={1}></Grid>
        <Grid item xs={10} >
        <Controller
            name="proceduresCompleted "
            {...register("proceduresCompleted")}
            control={control}
            render={({ field }) => (
              <TextField size="small" variant="standard" {...field} fullWidth />
            )}
          />
        </Grid>
        <Grid item xs={1}></Grid>

        <Grid item xs={1}></Grid>
        <Grid item xs={2} style={{ marginTop: "33px" }}>
          {/* <h5>
          Patient Name:
          </h5> */}
        </Grid>
        <Grid item xs={4} style={{ marginTop: "20px" }}>
        {/* <Controller
            name="patientName"
            {...register("patientName")}
            control={control}
            render={({ field }) => (
              <TextField size="small" variant="standard"  {...field} fullWidth />
            )}
          /> */}
        </Grid>
        <Grid item xs={5}></Grid>

        <Grid item xs={1}></Grid>
        <Grid item xs={1.5} style={{ marginTop: "23px" }}>
          <h5>
          Signature
          </h5>
        </Grid>
        <Grid item xs={4} style={{ marginTop: "10px" }}>
        <Checkbox
              name="providerSignature"
              checked={providerSignature ? true : false}
              onClick={() => {
                setValue("providerSignature", !providerSignature);
                setproviderSignature(!providerSignature);
              }}
            />
        {/* <TextField size="small" variant="standard"  fullWidth /> */}
        {/* <Controller
            name="providerCareSignature"
            {...register("providerCareSignature")}
            control={control}
            render={({ field }) => (
              <TextField size="small" variant="standard" {...field} fullWidth />
            )}
          /> */}
            
        </Grid>
        <Grid item xs={1} style={{ marginTop: "23px" }}>
          <h5>
          Date
          </h5>
        </Grid>
        <Grid item xs={4} style={{ marginTop: "10px" }}>
        <Controller
                name='providerCareDate'
                control={control}
                render={({ field }) => (
                  <TextField
                    type='date'
                    size='small'
                    fullWidth
                    variant="standard" 
                    {...field}
                    placeholder='Enter dob'
                    value={providerCareDate ? providerCareDate : ''}
                    onChange={e => {
                      setValue('providerCareDate', e.target.value)
                      setproviderCareDate(e.target.value)
                    }}
                    // error={errors.dateTMD}
                  />
                )}
              />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={10} style={{marginTop:"20px"}}>
        <h4 style={{color:'black', fontWeight:'bold'}}>
        DENTIST USE ONLY - Oral examination
          </h4>

        </Grid>
        <Grid item xs={1}></Grid>

        

        <Grid item xs={1}></Grid>
        <Grid item xs={8} style={{ marginTop: "30px" }}>
          <h6>
            Incisor relationship Class 1 Class 2 Div I Class 2 Div II Class 3
            Overjet ....... mm
          </h6>
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={11} style={{ marginTop: "10px" }}>
          <h6>Overbite ....... mm</h6>
        </Grid>
        <Grid item xs={1}></Grid>

        <Grid item xs={3} style={{ marginTop: "10px" }}>
          <h6>OH/Periodontal condition?</h6>
        </Grid>
        <Grid item xs={5} style={{ marginTop: "10px" }}>
        <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <div>
              <input
                type="radio"
                value="Good"
                name="periodontalCondition"
                checked={periodontalCondition === "Good"}
                onClick={() => {
                  setValue("periodontalCondition", "Good");
                  setperiodontalCondition("Good");
                }}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "15px", fontWeight: "normal" }}>Good</label>
              <input
                type="radio"
                value="Fair"
                name="periodontalCondition"
                checked={periodontalCondition === "Fair"}
                onClick={() => {
                  setValue("periodontalCondition", "Fair");
                  setperiodontalCondition("Fair");
                }}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "15px", fontWeight: "normal" }}>Fair</label>
              <input
                type="radio"
                value="Poor"
                name="periodontalCondition"
                checked={periodontalCondition === "Poor"}
                onClick={() => {
                  setValue("periodontalCondition", "Poor");
                  setperiodontalCondition("Poor");
                }}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "15px", fontWeight: "normal" }}>Poor</label>
             
            
            </div>
          </RadioGroup>
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={3} style={{ marginTop: "10px" }}>
          <h6>Tonsils - enlarged/inflamed</h6>
        </Grid>
        <Grid item xs={3}>
        <div>
            <input
              type="radio"
              name="tonsilsEnlarged"
              checked={tonsilsEnlarged ? true : false}
              onClick={(e) => {
                setValue('tonsilsEnlarged', true)
                settonsilsEnlarged(true)

              }}

              // {...register("heartProblems")}
              style={{ marginLeft: "20px", marginTop: "12px" }}
            />{" "}
            Yes
            <input
              type="radio"
              checked={tonsilsEnlarged ? false : true}
              onClick={(e) => {
                setValue("tonsilsEnlarged", false);
                settonsilsEnlarged(false);
              }}
              style={{ marginLeft: "20px" }}
            />{" "}
            No
          </div>

        </Grid>
        <Grid item xs={5}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={4} style={{ marginTop: "10px" }}>
          <h6>Bruxism/clenching/grinding of teeth</h6>
        </Grid>
        <Grid item xs={3}>
        <div>
            <input
              type="radio"
              name="grindingOfTeeth"
              checked={grindingOfTeeth ? true : false}
              onClick={(e) => {
                setValue('grindingOfTeeth', true)
                setgrindingOfTeeth(true)

              }}

              // {...register("heartProblems")}
              style={{ marginLeft: "20px", marginTop: "12px" }}
            />{" "}
            Yes
            <input
              type="radio"
              checked={grindingOfTeeth ? false : true}
              onClick={(e) => {
                setValue("grindingOfTeeth", false);
                setgrindingOfTeeth(false);
              }}
              style={{ marginLeft: "20px" }}
            />{" "}
            No
          </div>
        </Grid>

        <Grid container style={{ marginLeft: "80px" }}>
          <Grid item xs={1}>
            {" "}
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={11} style={{ marginTop: "10px" }}>
            <h5 style={{ fontWeight: "bold" }}>TMJ assessment:</h5>
          </Grid>


          <Grid item xs={1}></Grid>
          <Grid item xs={3} style={{ marginTop: "15px" }}>
            <h6>Max lateral movements </h6>
          </Grid>
          <Grid item xs={0.5} style={{ marginTop: "10px" }}>
            <h5>L</h5>
          </Grid>
          <Grid item xs={1} style={{ marginLeft: "-20px" }}>
          <Controller
            name="maxLateralLeftMovements"
            {...register("maxLateralLeftMovements")}
            control={control}
            render={({ field }) => (
              <TextField size="small" variant="standard" {...field} fullWidth />
            )}
          />
          </Grid>
          <Grid item xs={0.5} style={{ marginTop: "15px" }}>
            <h6>mm</h6>
          </Grid>

          <Grid item xs={0.5} style={{ marginTop: "10px" }}>
            <h5>R</h5>
          </Grid>
          <Grid item xs={1} style={{ marginLeft: "-20px" }}>
          <Controller
            name="maxLateralRightMovements"
            {...register("maxLateralRightMovements")}
            control={control}
            render={({ field }) => (
              <TextField size="small" variant="standard" {...field} fullWidth />
            )}
          />
          </Grid>
          <Grid item xs={0.5} style={{ marginTop: "15px" }}>
            <h6>mm</h6>
          </Grid>
          <Grid item xs={3}></Grid>

          <Grid item xs={1}></Grid>
          <Grid item xs={3} style={{ marginTop: "15px" }}>
            <h6>Max opening </h6>
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={1} style={{ marginLeft: "-20px" }}>
          <Controller
            name="maxOpening"
            {...register("maxOpening")}
            control={control}
            render={({ field }) => (
              <TextField size="small" variant="standard" {...field} fullWidth />
            )}
          />
          </Grid>
          <Grid item xs={0.5} style={{ marginTop: "15px" }}>
            <h6>mm</h6>
          </Grid>
          <Grid item xs={6}></Grid>


          <Grid item xs={1}></Grid>
          <Grid item xs={3} style={{ marginTop: "15px" }}>
            <h6>Max protrusion </h6>
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={1} style={{ marginLeft: "-20px" }}>
          <Controller
            name="maxProtrusion"
            {...register("maxProtrusion")}
            control={control}
            render={({ field }) => (
              <TextField size="small" variant="standard" {...field} fullWidth />
            )}
          />
          </Grid>
          <Grid item xs={0.5} style={{ marginTop: "15px" }}>
            <h6>mm</h6>
          </Grid>
          <Grid item xs={6}></Grid>

          <Grid item xs={1}></Grid>
          <Grid item xs={3} style={{ marginTop: "10px" }}>
            <h6>Tenderness to palpitation</h6>
          </Grid>
          <Grid item xs={3} >
          <div>
            <input
              type="radio"
              name="palpitation"
              checked={palpitation ? true : false}
              onClick={(e) => {
                setValue('palpitation', true)
                setpalpitation(true)

              }}

              // {...register("heartProblems")}
              style={{ marginLeft: "20px", marginTop: "12px" }}
            />{" "}
            Yes
            <input
              type="radio"
              checked={palpitation ? false : true}
              onClick={(e) => {
                setValue("palpitation", false);
                setpalpitation(false);
              }}
              style={{ marginLeft: "20px" }}
            />{" "}
            No
          </div>
          </Grid>
          <Grid item xs={5}>
            {" "}
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={3} style={{ marginTop: "10px" }}>
            <h6>Pain on mandibular movement</h6>
          </Grid>
          <Grid item xs={3} >
          <div>
            <input
              type="radio"
              name="mandibularMovement"
              checked={mandibularMovement ? true : false}
              onClick={(e) => {
                setValue('mandibularMovement', true)
                setmandibularMovement(true)

              }}

              // {...register("heartProblems")}
              style={{ marginLeft: "20px", marginTop: "12px" }}
            />{" "}
            Yes
            <input
              type="radio"
              checked={mandibularMovement ? false : true}
              onClick={(e) => {
                setValue("mandibularMovement", false);
                setmandibularMovement(false);
              }}
              style={{ marginLeft: "20px" }}
            />{" "}
            No
          </div>
          </Grid>
          <Grid item xs={5}>
            {" "}
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={3} style={{ marginTop: "10px" }}>
            <h6>Smooth movement</h6>
          </Grid>
          <Grid item xs={3} >
          <div>
            <input
              type="radio"
              name="smoothMovement"
              checked={smoothMovement ? true : false}
              onClick={(e) => {
                setValue('smoothMovement', true)
                setsmoothMovement(true)

              }}

              // {...register("heartProblems")}
              style={{ marginLeft: "20px", marginTop: "12px" }}
            />{" "}
            Yes
            <input
              type="radio"
              checked={smoothMovement ? false : true}
              onClick={(e) => {
                setValue("smoothMovement", false);
                setsmoothMovement(false);
              }}
              style={{ marginLeft: "20px" }}
            />{" "}
            No
          </div>
          </Grid>
          <Grid item xs={5}>
            {" "}
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={3} style={{ marginTop: "10px" }}>
            <h6>Locking and/or luxation</h6>
          </Grid>
          <Grid item xs={3} >
          <div>
            <input
              type="radio"
              name="luxation"
              checked={luxation ? true : false}
              onClick={(e) => {
                setValue('luxation', true)
                setluxation(true)

              }}

              // {...register("heartProblems")}
              style={{ marginLeft: "20px", marginTop: "12px" }}
            />{" "}
            Yes
            <input
              type="radio"
              checked={luxation ? false : true}
              onClick={(e) => {
                setValue("luxation", false);
                setluxation(false);
              }}
              style={{ marginLeft: "20px" }}
            />{" "}
            No
          </div>
          </Grid>

          {/* </Grid> */}
          <Grid item xs={5}>
            {" "}
          </Grid>
        
          <Grid item xs={1}></Grid>
          <Grid item xs={3} style={{ marginTop: "50px" }}>
            <h5 style={{ fontWeight: "bold" }}>Provider of care Name:</h5>
          </Grid>
          <Grid item xs={4} style={{ marginTop: "40px", marginLeft: "-10px" }}>
          <Controller
          name="providerName"
          {...register("providerName")}
          control={control}
          render={({ field }) => (
            <TextField size="small" variant="standard" {...field} fullWidth />
          )}
        />
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={3.5} style={{ marginTop: "20px" }}>
            <h5 style={{ fontWeight: "bold" }}>Provider of care Signature:</h5>
          </Grid>
          <Grid item xs={3} style={{ marginTop: "10px", marginLeft: "-10px" }}>
                 <Checkbox
              name="providerCareSignature"
              checked={providerCareSignature ? true : false}
              onClick={() => {
                setValue("providerCareSignature", !providerCareSignature);
                setproviderCareSignature(!providerCareSignature);
              }}
            />
          </Grid>

          <Grid item xs={1} style={{ marginTop: "20px", marginLeft: "20px" }}>
            <h5 style={{ fontWeight: "bold" }}>Date:</h5>
          </Grid>
          <Grid item xs={2.5} style={{ marginTop: "10px", marginLeft: "5px" }}>
          <Controller
                name='providerCareDate'
                control={control}
                render={({ field }) => (
                  <TextField
                    type='date'
                    size='small'
                    fullWidth
                    variant="standard" 
                    {...field}
                    placeholder='Enter dob'
                    value={providerCareDate ? providerCareDate : ''}
                    onChange={e => {
                      setValue('providerCareDate', e.target.value)
                      setproviderCareDate(e.target.value)
                    }}
                    // error={errors.dateTMD}
                  />
                )}
              />
          </Grid>
          <Grid item xs={1}></Grid>

          <Grid item xs={12} lg={12} sm={12}>
            <Button
              className="Button"
              onClick={handleCancel}
              variant='gradient'
              disabled={submitting}
              style={{
                marginTop: '0px',
                marginBottom: '10px',
                float: 'right',
                marginLeft: '10px',
                width: '90px',
                height: '35px',
                // backgroundColor: '#A574FD',
                color: 'white',
                fontSize: '14px'
              }}
            >
              <CancelOutlinedIcon
                fontSize="medium"
                style={{ color: "white", paddingRight: "5px" }}
              ></CancelOutlinedIcon>
              Cancel
            </Button>
            <Button
              className="Button"
              onClick={handleSubmit(onSubmit)}
              variant='gradient'
              disabled={submitting}
              style={{
                marginTop: '0px',
                marginBottom: '10px',
                float: 'right',
                marginLeft: 'auto',
                width: '90px',
                height: '35px',
                backgroundColor: '#A574FD',
                color: 'white',
                fontSize: '14px'
              }}
            >
              <SaveOutlinedIcon
                fontSize="medium"
                style={{ color: "white", paddingRight: "5px" }}
              ></SaveOutlinedIcon>
              Save
            </Button>
          </Grid>
        
          </Grid>











      </Grid>
      </Container>
    </div>
    </React.Fragment>
  );
};

export default Tmdform;
