import { useState, useEffect } from 'react'
import React from 'react'
import ReactSelect from 'react-select'
import axios from 'axios'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import md5 from 'md5'
import Breadcrumbs from "../components/Common/Breadcrumb";
import { TabPanel, TabContext, TabList } from '@mui/lab'
import Tab from '@mui/material/Tab'
import { Box, Button, Container, Stack, SvgIcon, Typography, Grid, Snackbar, TextField } from '@mui/material';
import Moment from 'moment'
import Avatar from '@mui/material/Avatar'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import { useAlert } from 'react-alert'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InputMask from 'react-input-mask'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
// import { shallowEqual, useSelector, useDispatch } from 'react-redux'

import { useLocation, Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';


function InsuranceplanRegistration() {
  document.title = "Register Insuranceplan | LuxBilling";
 // API Authanticatiion
 const token = localStorage.getItem('Token')
 const auth = `Bearer ${token}`
 const headers = {
   Authorization: auth
 }
   
    const fields = [
        'id',
        'name',
        'payerId',
        'phone',
        'fax',
        'email',
        'website',
        'address',
        'city',
        'state',
        'zip',
        'phq9Frequency',
        'phq9Number',
        'gad7Frequency',
        'gad7Number',
        'smokingCessationFrequency',
        'smokingCessationNumber',
        'inActive',
        'copay',
        'source',
      ]
      const defValues = {
        id:'',
        name: '',
        payerId: '',
        phone: '',
        fax: '',
        email: '',
        website: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        phq9Frequency:'',
        phq9Number:'',
        gad7Frequency: '',
        gad7Number: '',
        smokingCessationFrequency: '',
        smokingCessationNumber: '',
        inActive: false,
        copay:'',
        source:'',
      }
    const [submitting, setSubmitting] = useState(false)
  
    const navigate = useNavigate();
    const location = useLocation()
    const InsuranceplanId = location.state.Insuranceplanid
    const validationSchema = Yup.object({
        name: Yup.string().required('Carrier Name is required'),
        // email: Yup.string().email('Email is invalid').required('Email is required'),
      
        // copay: Yup.string().required('Copay is required'),


      })
    const {
        register,
        control,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        context: undefined,
        criteriaMode: 'firstError',
        shouldFocusError: true,
        shouldUnregister: false,
        shouldUseNativeValidation: false,
        delayError: undefined,
        resolver: yupResolver(validationSchema),
        defaultValues: defValues,
    })
    const alert = useAlert()
    const onSubmit = (data) => {
        setSubmitting(true)
        const postData = data
        console.warn('Data: ', postData)
        postData.practiceId=localStorage.getItem('default_paractice')
        // if (PracticeId !== undefined && PracticeId != null)
        //     postData.Id = PracticeId
        // else postData.Id = 0
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/Insurances/AddInsurances`,
                postData,
                { headers }
            )
            .then((response) => {
                setSubmitting(false)
                alert.success('Record saved successfully.', {
                    type: 'success',
                    onClose: () => {
                        navigate('/Insuranceplan')
                    },
                })
            })
            .catch((error) => {
                setSubmitting(false)
                alert.success(`Error ${error.message}`, {
                    type: 'error',
                })
            })
    }
    useEffect(() => {
        // POST request using axios inside useEffect React hook
        let isComponentMounted = true
        const fetchData = async () =>
            axios
                .get(
                    `${process.env.REACT_APP_API_URL}/Insurances/getInsurancesById?Id=${InsuranceplanId !== undefined ? InsuranceplanId : 0
                    }`,
                    { headers }
                )
                .then((response) => {
                    console.log('response.data: ', response)
                    if (isComponentMounted) {
                        // setUsersData(response.data);
                        fields.forEach((field) => setValue(field, response[field]))
                        /*  const petList = Object.entries(response.data).map(([key, value]) => {
                          console.log("Key: ", key, value);
                          register(`${key}`, `${value}`);
                          return [key, value];
                        }); */
                    }
                    setPhone(response.phone)
                    setFax(response.fax)
                })
                .catch((error) => {
                    console.error('There was an error!', error)
                })
        fetchData()
        return () => {
            isComponentMounted = false
        }

        // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, [])
    const [open, setOpen] = React.useState(false)
    const [options, setOptions] = React.useState([])
  

    React.useEffect(() => {
        if (!open) {
            setOptions([])
        }
    }, [open])

    function handleCancel() {
        navigate('/Insuranceplan')
    }
    const [phone, setPhone] = React.useState()
    const [Fax, setFax] = React.useState()
    const handleInput = (e) => {
        const value = e.target.value;
        const numericValue = value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
        // You can set the cleaned value back to the TextField or perform other actions as needed
        // For example, you can store it in your component state or use it in your form handling logic.
        // Assuming `field` is your form state or controlled input prop.
        fields.onChange(numericValue);
      };
    return (
        <React.Fragment>
            <div className="page-content" >
                <Container>
                <Typography variant='h5' color='black' fontWeight="bold">Add Carriers</Typography>
                    <Grid
                        container
                        spacing={1}
                    >
                        <Grid item  xs={12} sm={6} md={4} lg={3}>
                            <Typography component="label" fontSize="15px">
                            Carrier Name
                            </Typography>
                            <Controller
                                name='name'
                                {...register('name')}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        size='small'
                                        placeholder="Please enter Carrier Name"
                                        {...field}
                                        fullWidth
                                        error={errors.name}
                                    />
                                )}
                            />
                             <p style={{ color: "red", fontSize:"14px" }}>{errors.name?.message}</p>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Typography component="label" fontSize="15px">
                            Payer Id
                            </Typography>
                            <Controller
                                name='payerId'
                                {...register('payerId')}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        size='small'
                                        placeholder='Please enter payerId'
                                        {...field}
                                        fullWidth
                                        // error={errors.orgBusiness}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Typography component="label" fontSize="15px">
                            Phone No
                            </Typography>
                            <InputMask
                                mask="+1 999-999-9999"
                                disabled={false}
                                value={phone}
                                onChange={(e) => {
                                    setValue('phone', e.target.value)
                                    setPhone(e.target.value)
                                }}
                            >
                                {() => (
                                    <TextField
                                        id="outlined-basic"
                                        fullWidth
                                        size='small'
                                        placeholder="Enter phoneNo"
                                    />
                                )}
                            </InputMask>
                        </Grid>
                        <Grid item  xs={12} sm={6} md={4} lg={3}>
                            <Typography component="label" fontSize="15px">
                            Fax No
                            </Typography>
                            <InputMask
                                mask=" 999-9999999"
                                disabled={false}
                                value={Fax}
                                onChange={(e) => {
                                    setValue('fax', e.target.value)
                                    setFax(e.target.value)
                                }}
                            >
                                {() => (
                                    <TextField
                                        id="outlined-basic"
                                        fullWidth
                                        size='small'
                                        placeholder="Enter Fax"
                                    />
                                )}
                            </InputMask>
                        </Grid>
                        <Grid item  xs={12} sm={6} md={4} lg={3}>
                            <Typography component="label" fontSize="15px">
                            Email
                            </Typography>
                            <Controller
                                name='email'
                                {...register('email')}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                    type='email'
                                        size='small'
                                        placeholder='Please enter Email'
                                        {...field}
                                        fullWidth
                                    //  error={errors.email}
                                    />
                                )}
                            />
                             {/* <p style={{ color: "red", fontSize:"14px" }}>{errors.email?.message}</p> */}
                        </Grid>
                        <Grid item  xs={12} sm={6} md={4} lg={3}>
                            <Typography component="label" fontSize="15px">
                            WebSite
                            </Typography>
                            <Controller
                                name='website'
                                {...register('website')}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        size='small'
                                        placeholder='Please enter WebSite'
                                        {...field}
                                        fullWidth
                                   
                                    />

                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Typography component="label" fontSize="15px">
                              Address
                            </Typography>
  {/* <Controller
                                name='zipCode'
                                {...register('zipCode')}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        size='small'
                                        placeholder='Please enter zipCode'
                                        {...field}
                                        fullWidth

                                    />
                                )}
                            /> */}
                             <Controller
                                name='address'
                                {...register('address')}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        size='small'
                                        placeholder='Please enter Address'
                                        {...field}
                                        fullWidth

                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Typography component="label" fontSize="15px">
                                City
                            </Typography>
                            <Controller
                                name='city'
                                {...register('city')}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        size='small'
                                        placeholder='Please enter City'
                                        {...field}
                                        fullWidth

                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Typography component="label" fontSize="15px">
                                State
                            </Typography>
    {/* <Controller
                                name='state'
                                {...register('state')}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        size='small'
                                        placeholder='Please enter state'
                                        {...field}
                                        fullWidth

                                    />
                                )}
                            /> */}
                             <Controller
                        name='state'
                        {...register('state')}
                        control={control}
                        render={({ field }) => (
                        <ReactSelect
                            style={{ marginTop: 0, marginBottom: 0 }}

                            {...field}
                            options={[
                            { value: 'AL', label: 'ALABAMA'},
                            { value: 'AK', label: 'ALASKA'},
                            { value: 'AS', label: 'AMERICAN SAMOA'},
                            { value: 'AZ', label: 'ARIZONA'},
                            { value: 'AR', label: 'ARKANSAS'},
                            { value: 'CA', label: 'CALIFORNIA'},
                            { value: 'CO', label: 'COLORADO'},
                            { value: 'CT', label: 'CONNECTICUT'},
                            { value: 'DE', label: 'DELAWARE'},
                            { value: 'DC', label: 'DISTRICT OF COLUMBIA'},
                            { value: 'FL', label: 'FLORIDA'},
                            { value: 'GA', label: 'GEORGIA'},
                            { value: 'GU', label: 'GUAM'},
                            { value: 'HI', label: 'HAWAII'},
                            { value: 'ID', label: 'IDAHO'},
                            { value: 'IL', label: 'ILLINOIS'},
                            { value: 'IN', label: 'INDIANA'},
                            { value: 'IA', label: 'IOWA'},
                            { value: 'KS', label: 'KANSAS'},
                            { value: 'KY', label: 'KENTUCKY'},
                            { value: 'LA', label: 'LOUISIANA'},
                            { value: 'ME', label: 'MAINE'},
                            { value: 'MD', label: 'MARYLAND'},
                            { value: 'MA', label: 'MASSACHUSETTS'},
                            { value: 'MI', label: 'MICHIGAN'},
                            { value: 'MN', label: 'MINNESOTA'},
                            { value: 'MS', label: 'MISSISSIPPI'},
                            { value: 'MO', label: 'MISSOURI'},
                            { value: 'MT', label: 'MONTANA'},
                            { value: 'NE', label: 'NEBRASKA'},
                            { value: 'NV', label: 'NEVADA'},
                            { value: 'NH', label: 'NEW HAMPSHIRE'},
                            { value: 'NJ', label: 'NEW JERSEY'},
                            { value: 'NM', label: 'NEW MEXICO'},
                            { value: 'NY', label: 'NEW YORK'},
                            { value: 'NC', label: 'NORTH CAROLINA'},
                            { value: 'ND', label: 'NORTH DAKOTA'},
                            { value: 'MP', label: 'NORTHERN MARIANA IS'},
                            { value: 'OH', label: 'OHIO'},
                            { value: 'OK', label: 'OKLAHOMA'},
                            { value: 'OR', label: 'OREGON'},
                            { value: 'PA', label: 'PENNSYLVANIA'},
                            { value: 'PR', label: 'PUERTO RICO'},
                            { value: 'PI', label: 'RHODE ISLAND'},
                            { value: 'SC', label: 'SOUTH CAROLINA'},
                            { value: 'SD', label: 'SOUTH DAKOTA'},
                            { value: 'TN', label: 'TENNESSEE'},
                            { value: 'TX', label: 'TEXAS'},
                            { value: 'UT', label: 'UTAH'},
                            { value: 'VT', label: 'VERMONT'},
                            { value: 'VA', label: 'VIRGINIA'},
                            { value: 'VI', label: 'VIRGIN ISLANDS'},
                            { value: 'WA', label: 'WASHINGTON'},
                            { value: 'WV', label: 'WEST VIRGINIA'},
                            { value: 'WI', label: 'WISCONSIN'},
                            { value: 'WY', label: 'WYOMING'},

                            ]}
                            value={{ label: getValues('state') }}
                            onChange={value => setValue('state', value.label)}
                            size='small'
                
                  />
                )}
              />
                        </Grid>
                     
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Typography component="label" fontSize="15px">
                            Zip Code
                            </Typography>
                            <Controller
                                name='zip'
                                {...register('zip')}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        type='text'
                                        size='small'
                                        placeholder='Please enter ZipCode'
                                        {...field}
                                        fullWidth

                                    />
                                )}
                            />
                        </Grid>
                        {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Typography component="label" fontSize="15px">
                            Copay
                            </Typography>
                            <Controller
                                name='copay'
                                {...register('copay')}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                    type='text'
                                        size='small'
                                        placeholder='Please enter Copay'
                                        {...field}
                                        fullWidth
                                        // error={errors.copay}
                                        inputProps={{
                                            inputMode: 'numeric',
                                            pattern: '[0-9]*',
                                            onInput: (e) => {
                                              const inputValue = e.target.value.trim();
                                              if (inputValue === '' || /^[0-9]*$/.test(inputValue)) {
                                                e.target.value = inputValue !== '' ? Math.max(0, parseInt(inputValue)).toString().slice(0, 10) : ''; // Allow only positive integers
                                              } else {
                                                e.target.value = ''; // Set value to empty if non-numeric input
                                              }
                                              field.onChange(e);
                                            },
                                          }}


                                    />
                                )}
                            />
                             {/* <p style={{ color: "red", fontSize:"14px" }}>{errors.copay?.message}</p> 

                        </Grid> */}
                        <Grid item xs={12} sm={6} md={4} lg={3} mt={3}>
                            {/* <Typography component="label" fontSize="15px">
                            Active
                            </Typography>
                            <input
                                name='inActive'
                                size='small'
                                type='checkbox'
                                {...register('inActive')}
                                id='inActive'
                            /> */}
                        </Grid>
                       
                        <Grid item xs={12} lg={12} sm={12} style={{marginBottom:'20px'}}>
                                <Button
                                className= "Button"
                                    onClick={handleCancel}
                                    variant="gradient"
                                    disabled={submitting}
                                    style={{
                                        marginTop: '30px',
                                        float: 'right',
                                        marginLeft: '10px',
                                        width: '90px',
                                        height: '35px',
                                      
                                        color: 'white',
                                        fontSize: '14px',
                                    }}

                                >
                                    <CancelOutlinedIcon
                                        fontSize="medium"
                                        style={{ color: 'white', paddingRight: '5px' }}
                                    ></CancelOutlinedIcon>
                                    Cancel
                                </Button>
                                <Button
                                className= "Button"
                                    onClick={handleSubmit(onSubmit)}
                                    variant="gradient"
                                    disabled={submitting}
                                    style={{
                                        marginTop: '30px',
                                        float: 'right',
                                        marginLeft: '10px',
                                        width: '90px',
                                        height: '35px',
                                    
                                        color: 'white',
                                        fontSize: '14px',
                                    }}

                                >
                                    <SaveOutlinedIcon
                                        fontSize="medium"
                                        style={{
                                            color: 'white',
                                            paddingRight: '5px',
                                            paddingBottom: '2px',
                                        }}
                                    ></SaveOutlinedIcon>
                                    Save
                                </Button>

                            </Grid>
                    </Grid>
                </Container>
            </div>
        </React.Fragment>

    )

}

export default InsuranceplanRegistration