// @mui material components
import Card from '@mui/material/Card'

// Soft UI Dashboard React components
import { useAlert } from 'react-alert'
import ModeEditIcon from '@mui/icons-material/Edit'
import axios from 'axios'
import React, { useEffect, useReducer, useState, Fragment, useRef } from 'react'
import { Box, Button, Container, Stack, SvgIcon, Typography, Grid, Snackbar, TextField } from '@mui/material';
import { FileUploader } from "react-drag-drop-files";
import ReactSelect from 'react-select'
import LoadingButton from '@mui/lab/LoadingButton'
import { Input } from '@mui/material'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Backdrop } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress'
import { Controller, useForm } from 'react-hook-form'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
// import PlusIcon from '@heroicons/react/24/solid/PlusIcon';
import AddIcon from '@mui/icons-material/Add';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import Download from '@mui/icons-material/Download'
import { TabPanel, TabContext, TabList } from '@mui/lab'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import PermIdentity from '@mui/icons-material/PermIdentity'
import WorkOutline from '@mui/icons-material/WorkOutline'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import { DataGrid } from '@mui/x-data-grid';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DialogTitle from '@mui/material/DialogTitle';
// import Avatar from 'react-avatar'
import Avatar from '@mui/material/Avatar';
import fileDownload from 'js-file-download';
import Tooltip from '@mui/material/Tooltip';
import Moment from 'moment';
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Documents = (props) => {
  document.title = "Documents | LuxBillingEHR";

  // console.log('Documents List _________________________+++=', props)
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const fileTypes = ['JPEG', 'PNG', 'GIF']
  const alert = useAlert()
  const [tabValue, settabValue] = React.useState(0)
  const [docslist, setdocslist] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [open, setOpen] = React.useState(false)
  const [docError, setdocError] = useState(false)
  // const handleClosee = () => setOpen(false)
  const [submitting, setSubmitting] = useState(false)
  const [date, setdate] = useState(Moment().format('YYYY-MM-DD'))
  const [base64, setbase64] = useState('')
  const [patientId, setpatientId] = useState(props.patientid !== undefined ? props.patientid : 0)
  const [columnID, setcolumnID] = useState(props.columnID !== undefined ? props.columnID : 0)
  const [AuthorizationFormByCPTid, setAuthorizationFormByCPTid] = useState(props.AuthorizationFormByCPTid !== undefined ? props.AuthorizationFormByCPTid : 0)
  
  console.log('columnIDdoc', columnID)
  const [AutpattientID, setAutpattientID] = useState(props.AutpattientID)
  console.log('test',AutpattientID)
  // const columnID = props.columnID
  const type = props.type
  console.log('type', type)
  const [opendocs, setopendocs] = useState(false)
  const [urlFile, seturlFile] = useState('')
  const [urldownload, seturldownload] = useState('')
  const [DocName, setDocName] = useState('')
  const [FullDocName, setFullDocName] = useState('')
  const [spinner, setSpinner] = useState(false)
  const [file, setFile] = useState(null)

  const [filename, setfilename] = useState([])
  const [base64Data, setBase64Data] = useState([])
  const [totalProgress, setTotalProgress] = React.useState(0)
  const [savedFiles, setSavedFiles] = React.useState([])
  const [selectedFiles, setSelectedFiles] = useState([])
  const inputRef = useRef(null)
  const [openAlert, setOpenAlert] = useState(false);
  const [cellid, setcellid] = useState('');
  
  const handleClickOpen = () => {
 
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);

  };

  //Modal
  const handleDeleteById = () => {
    const postData = {
             
      id: cellid,
      deleted: true,
    };
   
  axios
  .post(
      `${process.env.REACT_APP_API_URL}/PatientDocument/PatientDocument`,
      postData,
      {
          headers,
      }
  )
  .then((response) => {
 
    setRefresh(!refresh);
      setOpenAlert(false);
  })
  .catch((error) => {
      console.error(
          "There was an error!",
          error
      );
  });
  };

  const fields = [
    'id',
    'documentName',
    'fileUrl',
    'patientId',
    'practiceId',
    'deleted',
    'date',
    'createdBy',
    'clientCreatedDate',
    'updatedBy',
    'clientUpdatedDate',
    'createdDate',
    'updatedDate',
    'base64',
    'authId',
    'authorizationcptId',
    'notificationId',
    'type',
    'referringProvider',
    'referringPhysiciansId'
  ]
  const defValues = {
    id: 0,
    documentName: '',
    fileUrl: '',
    patientId: 0,
    practiceId: '',
    date: '',
    createdBy: '',
    referringProvider:'',
    referringPhysiciansId:0,
    Deleted: false,
    clientCreatedDate: '',
    updatedBy: '',
    clientUpdatedDate: '',
    createdDate: '',
    updatedDate: '',
    base64: '',
    authorizationcptId:AuthorizationFormByCPTid,
    notificationId:'',
    authId: columnID,
    type: type
  }
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [Delbyid, setDelbyid] = useState(false);
  const handleDelete = (cell) => {
    // Perform your delete logic here
    const postData = {
      documentName: cell.row.documentName,
      date: cell.row.date,
      id: Delbyid,
      deleted: true,
    };
    axios
    .post(`${process.env.REACT_APP_API_URL}/PatientDocument/PatientDocument?patientId=${AutpattientID}`, postData, {
      headers,
    })
      .then((response) => {
        setRefresh(!refresh);
      })
      .catch((error) => {
        console.error('There was an error!', error);
      });
    // Close the dialog
    setDialogOpen(false);
  };

  const appointmentDocslist = [
    {
      field: 'documentName',
      headerName: 'Document Name',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 1,
      renderCell: cellValues => (
        <>
          <Tooltip title={`${cellValues.value}`} placement='left'>
            <div>
              <Button
                onClick={() => {
                  setopendocs(true)
                  // seturlFile(cellValues.row.fileUrl)
                  var url = cellValues.row.fileUrl
                  seturldownload(cellValues.row.fileUrl)
                  axios
                    .get(`${process.env.REACT_APP_API_URL}/PatientDocument/ViewPatientDocument?path=${url}`, {
                      headers
                    })
                    .then(response => {
                      // setisLoading(false)
                      seturlFile(response)

                    })
                    .catch(error => {
                      console.error('There was an error!', error)
                    })
                  var arr = cellValues.row.documentName.split('.')
                  console.log('CheckAAAAA', cellValues.row.fileUrl)
                  setDocName(arr.length > 0 ? arr[1].toLowerCase() : '')
                  setFullDocName(cellValues.row.documentName)
                  // console.log('pic flied::', cellValues.row.documentName)
                }}
              >{`${cellValues.value}`}</Button>
            </div>
          </Tooltip>
        </>
      )
    },
    // {
    //   field: 'physicianName',
    //   headerName: ' Referring Physician ',
    //   sortable: true,
    //   headerClassName: 'super-app-theme--header',
    //   flex: 0.5
    // },
    ...(type =='lomn'? [{
      field: 'physicianName',
      headerName: 'Referring Physician',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.5
    }] : []), 
    {
      field: 'date',
      headerName: 'Document Date',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.5
    },
    {
      field: 'delete',
      headerClassName: 'super-app-theme--header',
      headerName: 'Action',
      minWidth: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (cell) => (
       <>
      <DeleteIcon
        style={{
          marginRight: '5px',
          float: 'right',
          marginTop: '5px',
          color: 'red',
          cursor: 'pointer',
        }}
        color="black"
        onClick={() => {setDialogOpen(true)
          setDelbyid(cell.row.id)
        }}
      ></DeleteIcon>

      <Dialog
        open={isDialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="delete-dialog-title"
        BackdropComponent={Backdrop}
        BackdropProps={{
          invisible: true,
        }}
        PaperProps={{
          style: {
            marginRight: '5px',
          float: 'right',
          marginTop: '5px',
          color: 'red',
          cursor: 'pointer',
          },
        }}
      >
       <div style={{
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center', // Center horizontally
  justifyContent: 'center', // Center vertically
  borderRadius: '8px',
  width: '500px'
}}>
  
  <ErrorOutlineIcon style={{ fontSize: '100px', color: 'red' }} />
  <DialogTitle style={{ fontWeight: 'bold',color:'black',fontSize:' 1.25rem' }}>Are you sure?</DialogTitle>
  <h5 style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'normal',color:'#444444' }}>
    You won't be able to revert this!
  </h5>
  <DialogContent>
    <DialogContentText>

      <Grid style={{ marginTop: '1rem' }}>
        <Button onClick={() => setDialogOpen(false)} style={{ backgroundColor: '#6c7c84', color: 'white', marginBottom: '1rem' }}>No,cancel!</Button>
        <Button  onClick={() => handleDelete(cell)} style={{ backgroundColor: 'red', color: 'white', marginLeft: '1rem', marginBottom: '1rem',border:'1px solid #b4acec' }}>Yes,delete it!</Button>
      </Grid>
    </DialogContentText>
  </DialogContent>
</div>

      </Dialog>
    </>
      ),
    },


  ]
 
  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    clearErrors,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    defaultValues: defValues
  })

  const handleOpenn = () => {
    setOpen(true)
    setSubmitting(false)
    setbase64('')
    setfilename('')
  }

  const handleChange = (event, newValue) => {
    settabValue(newValue)
  }
  const navigate = useNavigate();
  // console.log('Reading------------------------------------', urlFile)
  const handleDetails = () => { }
  function applyRefresh() {
    setRefresh(!refresh)
    // setOpen(false)
  }
  const handleClose = () => {
    setopendocs(false)
    setFile(null)
    setOpen(false)
    setSubmitting(false)
    // setopenAppcreate(false)
    setRefresh(true)
  }

  // list of documents
  useEffect(() => {
    // window.alert('abc')
    if(patientId==undefined){
      return;
    }
    setSpinner(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/PatientDocument/PatientDocumentList?patientId=${patientId}&authId=${columnID}&type=${type}&notificationId=${0}&authorizationcptId=${AuthorizationFormByCPTid}`, { headers })
      .then((response) => {
        fields.forEach(field => {
          setValue(field, response[field])
        })
        setSpinner(false)
        setdocslist(response)
      })

      .catch(error => {
        setSpinner(false)
        console.error('There was an error!', error)
      })
  }, [refresh,columnID])

  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 2,
    background: 'drak',
    fontSize: '13.4px !important',
    color: 'drak !important',
    borderColor: '#326C1D',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#377562',
      borderRadius: 0.1,
      color: 'white',
      fontSize: '16px !important',
      
    }, 
    '& .MuiDataGrid-row': {
      height: 'auto',
    },'& .MuiDataGrid-main': {
      minHeight: '100%',
    },
  

  }
  const styleofmodal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 950,
    // height: 500,
    bgcolor: 'background.paper',
    border: '2px solid #377562',
    boxShadow: 24,
    p: 4
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "60%",
    // height: 600,
    bgcolor: 'background.paper',
    border: '2px solid #377562',
    boxShadow: 24,
    
    scroll:'auto'
  }

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }
  const [sizeError, setSizeError] = useState(false)
  const MAX_FILE_SIZE = 10 * 1024 * 1024 // 10 MB in bytes
  const handleChangeforfile = async (files) => {
    const newFilenames = []
    const newBase64Data = []

 
    setSizeError(false)

    for (let i = 0; i < files.length; i++) {
      const file = files[i]

      // Validate file size (10 MB limit)
      if (file.size > MAX_FILE_SIZE) {
        setSizeError(true)
        return // Stop processing if any file is too large
      }

      const base64 = await fileToBase64(file)
      newFilenames.push(file.name)
      newBase64Data.push(base64)
    }

    setfilename(newFilenames)
    setBase64Data(newBase64Data)
    setdocError(false)
  }


  // onSubmit for single and multiple files
  const [referringError, setReferringError] = useState(false);

  const onSubmit = data => {
    console.log(props.callingFrom,'callingFromcallingFrom')
    if (base64Data.length <= 0) {
      setdocError(true)
      return
    }
    if (type === 'lomn' && !referringid) {
      setReferringError(true);
      return; 
    }
    if(sizeError){
      setSizeError(true)
      return;
    }

    setdocError(false)
    setSubmitting(true)
    setSavedFiles([])

    const postData = []
    let totalLength = 0
    console.log('___________columnID: ',columnID)
    console.log('___________columnID Check: ',columnID>0)
    let _authId=columnID>0?columnID:  props.callingFrom=="test"?-10:-9;
    let callingFrom=props.callingFrom;
    if(callingFrom=="notifcation")
      {
        _authId=0;
      }
      if(callingFrom=="AuthorizationFormByCPT")
        {
          _authId=-9;
        }
    for (let i = 0; i < base64Data.length; i++) {
      const documentData = {
      
        patientId: (props.callingFrom === "Patient" || props.callingFrom === "Patient1" ? AutpattientID : props.callingFrom === "notifcation"  ? patientId : props.callingFrom === "HealthRequest" ? AutpattientID:patientId),
        // patientId:AutpattientID,
        date: date,
        documentName: filename[i],
        base64: base64Data[i],
        authId: _authId,
        type: type,
        referringPhysiciansId: referringid,
        // authId:(props.callingFrom=="notifcation"?:-9),
        notificationId:(props.callingFrom=="notifcation"?-9:0),
        // authId:(props.callingFrom==""?0:-9),
        authorizationcptId:(props.callingFrom=="AuthorizationFormByCPT"?-9:0),
       
        // authorizationcptId:( props.callingFrom=="AuthorizationFormByCPT"? AuthorizationFormByCPTid>0?AuthorizationFormByCPTid:-9
        //  :0),
      
      }

      postData.push(documentData)
      totalLength += base64Data[i].length
    }

    const requests = postData.map((documentData, index) => {

      const config = {
        onUploadProgress: function (progressEvent) {
          const progress = Math.round((progressEvent.loaded / totalLength) * 1.5)
          if (progress <= 100) {
            setTotalProgress(progress)
          } else {
            setTotalProgress(100)
          }
        },
        headers
      }
      return axios
        .post(`${process.env.REACT_APP_API_URL}/PatientDocument/PatientDocument?patientId=${patientId}`, documentData, config,)
        .then(response => {     
          //  window.alert('documentData')
          // Update the savedFiles state with the index of the saved file
          setSavedFiles(savedFiles => [...savedFiles, index])

          setSpinner(false)
          setOpen(false)
          setSubmitting(false)
          if (response) {
              // toast.success('Document uploaded successfully.', { 
              //   position: "top-center",
              //   hideProgressBar: false,
              //   closeOnClick: false,
              //   pauseOnHover: false,
              //   draggable: false,
              //   progress: undefined,
              //   theme: "light",
              alert.success('Document uploaded successfully.', {
                type: 'success',
              onClose: () => {
                if (props.callingFrom == 'Patient') {
                  setcolumnID(response.authId)
                  props.updateHealthHistory(response.id, response.authId)
                  console.log('checktype', response.authId)
                  props.handleClose222()
                }
                else if (props.callingFrom == 'HealthRequest') {
                  setcolumnID(response.authId)
                  props.updateHealthHistory(response.id, response.authId)
                  console.log('checktype', response.authId)
                  props.handleClose222()
                }
                else if (props.callingFrom == 'AuthorizationFormByCPT') {
                  setcolumnID(response.authId)
                  props.updateHealthHistory(response.id, response.authorizationcptId)
                  props.handleCloseAuthorizationFormByCPT()
                }
                else if (props.callingFrom == 'notifcation') {
                  setcolumnID(response.authId)
                  props.updateNotificaton(response.id,response.notificationId,response.createdBy)
                  props.handleCloseNotification()
                }
                
                applyRefresh()

              }
            })
          }
          else if(props.callingFrom=="update")
          {
            setcolumnID(response.authId)
            props.updateHealthHistory(response.id, response.authId, response.patientName)
            props.closevobpage()
          }
          else if(props.callingFrom =="dashboard")
          {
            setcolumnID(response.authId)
            props.updateHealthHistory(response.id, response.authId, response.patientName)
            props.closevobpage()}
          
          else {
            alert.error('Error uploading one or more documents.', {
              type: 'error'
            })
          }
        })
        .catch(error => {
          setSpinner(false)
          console.log(error)
          setSubmitting(false)
          toast.error(`Error ${error.message}`, {
            type: 'error'
          })
        })
      // return response

    })

    // axios
    //   .all(requests)
    //   .then(responses => {
    //     setSpinner(false)
    //     setOpen(false)
    //     setSubmitting(false)
    //     if (responses) {
    //       alert.success('Document uploaded successfully.', {
    //         type: 'success',

    //         onClose: () => {
    //           if(props.callingFrom == 'Pateint')
    //           {
    //             props.updateHealthHistory(responses.id, responses.authId )
    //             console.log('checktype',responses.id)
    //             props.handleClose2()
    //           }
    //           else if (props.callingFrom == 'Patient1')
    //           {
    //             props.updateHealthHistory(responses.id, responses.authId )
    //             console.log('checktype',responses.id)
    //             props.handleClose()
    //           }
    //           applyRefresh()

    //         }
    //       })
    //     } else {
    //       alert.error('Error uploading one or more documents.', {
    //         type: 'error'
    //       })
    //     }
    //   })
    //   .catch(error => {
    //     setSpinner(false)
    //     console.log(error)
    //     setSubmitting(false)
    //     alert.error(`Error ${error.message}`, {
    //       type: 'error'
    //     })
    //   })
  }

  const download = (url, filename) => {
    console.log(url)
    console.log(filename)
    setSpinner(true)
    setSubmitting(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/PatientDocument/DownloadAWSFile?path=${url}`, {
        headers,
        responseType: 'blob'
      })
      .then(res => {
        setopendocs(false)
        setSpinner(false)
        setSubmitting(false)
        console.log(res.data)
        fileDownload(res, filename)
      })
      .catch(err => {
        setSubmitting(false)
        console.log(err)
      })
  }
  const [referring, setReferring] = useState([]);
  const [referringid, setReferringid] = useState(0);
  const practiceID=localStorage.getItem('default_paractice')

  useEffect(() => {
   
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/ReferringPhysicians/ReferringPhysicians?parcticeid=${practiceID}`, { headers })
      .then(response => {
        var ref = [];
        response.forEach(field => {
          ref.push({
            label: `${field.lastName},${field.firstName} (NPI:${field.npi})`,
            value: field.id
          });
        });
        setReferring(ref);
     
      })
      .catch(error => {})

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [refresh])
  return (
    <div>

      <Grid container>
        <Grid item xs={12}>
          <Grid item spacing={1} xs={12} sm={12} md={12} lg={12}>
            <Button
              className="Button"
              sx={{ mb: 2 }}
              onClick={handleOpenn}
              variant='gradient'
              color='info'
              size='small'
              style={{
                width: '150px',
                float: 'right',
                height: '33px',
                marginTop:"10px",
                // backgroundColor: '#A574FD',
                color: 'white',
                fontSize: '14px',
                marginRight:'15px'
              }}
            >
              <AddOutlinedIcon
                fontSize='medium'
                style={{
                  color: 'white',
                  paddingRight: '4px',
                  paddingBottom: '2px'
                }}
              ></AddOutlinedIcon>
              Add New
            </Button>

            <Button
              className="Button"
              sx={{ mb: 2, marginRight: '20px',marginTop:"10px" }}
              onClick={event => {
                applyRefresh()
              }}
              style={{
                marginBottom: '10px',
                float: 'right',
                marginLeft: '20px',
                width: '150px',
                height: '35px',
                // backgroundColor: '#A574FD',
                color: 'white',
                fontSize: '14px'
              }}
            >
              <CachedOutlinedIcon
                fontSize='medium'
                style={{
                  color: 'white',
                  marginRight: '1px',
                  paddingRight: '0px',
                  paddingBottom: '2px',
                  
                }}
              ></CachedOutlinedIcon>
              Refresh
            </Button>
          </Grid>
          {/* {spinner ? (
              <FallbackSpinner
                style={{
                  width: '50px',
                  height: '50px',
                  position: 'absolute',
                  left: '50%',
                  top: '50%'
                  // marginLeft: '-25px'
                  // marginTop: '-25px'
                }}
              />
            ) : ( */}
          <>

            <Box mb={1}>
              <Grid container xs={12} sm={12} md={12} lg={12} style={{ height: 428, width: '100%' }}>
                {docslist === null || docslist.length === 0 ? (
                  "No Record Found"
                ) : (
                  <DataGrid
                    rows={docslist}
                    columns={appointmentDocslist}
                    headerHeight={37}
                    sx={gridRowStyle}
                    pagination
                  autoPageSize
                  />
                )}
              </Grid>
            </Box>
          </>
          {/* )} */}
        </Grid>
      </Grid>

      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={open}
        onClose={handleClose}
        closeAfterTransition
      // BackdropProps={{
      //   timeout: 500
      // }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Grid container columnSpacing={0}>
            <Grid container spacing={0}  style={{
                       
                       backgroundColor: '#377562',
                    
                     }} >
             <Grid item xs={9} style={{ fontWeight: 500,backgroundColor: '#377562', marginTop: "10px",  }}>
                     <Typography style={{ fontWeight: 'bold', marginLeft:"7px",fontSize: '18px', color: 'white' }}>
                     Upload patient document
                     </Typography>
                   </Grid>
                 <Grid item xs={3}>
                   <Button
                     onClick={handleClose}
                     variant="gradient"
                     // disabled={submitting}
                     style={{
                       marginTop: "10px",
                       float: "right",
                       marginBottom: "10px",
                       // width: "80px",
                       height: "35px",
                      
                       color: "black",
                       fontSize: "14px",
                       cursor: 'pointer'
                     }}

                   // startIcon={<img src={SaveIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
                   >
                     <CancelOutlinedIcon
                       fontSize="medium"
                       style={{
                         color: "red",
                         
                         paddingBottom: "2px",
                       }}
                     ></CancelOutlinedIcon>
                 
                   </Button>
                 </Grid>
                 
               </Grid>
                    <Grid item xs={11}  style={{ width: 250,  marginTop: 15,marginLeft:15}}> 
                    <Typography style={{  color: 'black', }}>
                      Date
                      </Typography>
                      <TextField
                type='date'
                size='small'
                value={date}
                onChange={e => {
                  setdate(e.target.value)
                }}
                width='auto'
                fullWidth
                placeholder='Select date'
              />
                    </Grid>
                    {type =='lomn'?(<>
                      <Grid item xs={8} style={{ width: 250,  marginTop: 15, marginLeft:15}}>
            <Typography style={{  color: 'black', }}>
                      Referring Provider
                      </Typography>
            </Grid>
                    
                    </>):(<>
                      <Grid item xs={6} style={{ width: 250,  marginTop: 15, marginLeft:15}}>
          
            </Grid>
                    </>)}
            {type =='lomn'?(<>
              <Grid item xs={11} style={{ width: 250, marginLeft:15}}>
            <Controller
              name="referringPhysiciansId"
              // {...register("practiceId")}
              control={control}
              render={({ field }) => (
              <ReactSelect
              {...field}
              default
              size="small"
              style={{
                     width: "22em",
                 }}
              value={referring.filter(function(option) {
                return option.value === getValues("referringPhysiciansId");
              })}
              onChange={e => {
                setValue("referringPhysiciansId", e.value);
                setReferringid(e.value);
                setReferringError(false);
                // setProvName("provider_id", value.value);
                // setProviders(value.value);
              }}
              options={referring}
            />
              )}
            />
            {referringError && (
      <Typography style={{ color: 'red', marginLeft: 15 }}>
        Please select a referring provider.
      </Typography>
    )}
            </Grid>
            </>):(<>
              <Grid item xs={6} style={{ width: 250,}}>
           
            </Grid>
            </>)}
           

            </Grid>
           
            <Grid item xs={12} style={{ marginTop: 15, marginLeft:15}}>
              <Button className="Button" variant='contained' component='label' >
                Upload
                <input
                  type='file'
                  name='file'
                  onInput={e => handleChangeforfile(e.target.files)}
                  style={{ display: 'none' }}
                  multiple 
                />
              </Button>
             
            </Grid>
           
            <Grid item xs={12}  style={{ marginTop: 15 ,marginLeft:15 }}>
            <FileUploader multiple={true} handleChange={handleChangeforfile}  name='file' />
            </Grid>
            {sizeError && (
        <Grid item xs={12} style={{ marginTop: 15, color: 'red' }}>
     Please select files smaller than 10 MB
        </Grid>
      )}
            <Grid item xs={12} style={{marginTop:'5px',marginLeft:15}}>
            <Typography> To Upload files Please click on upload button and then Save it</Typography>
            </Grid>
            <Grid item xs={12} style={{marginTop:'5px',marginLeft:15}}>
            <>
                {filename.length > 0 && (
                  <div>
                    <h2>Uploaded Files:</h2>
                    <div style={{ height: '100px', width: '100%', }}>
                      <ul>
                        {filename.map((name, index) => (
                          <>
                            {/* <li key={name}>{name} </li> */}
                            <li>
                              <Typography key={index} variant='body1' color='black'>
                                {name} {savedFiles.includes(index) && '✓'}
                              </Typography>
                            </li>
                          </>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
              </>
            </Grid>
            <Grid item xs={12} style={{marginTop:'5px'}}>
            {file && <p>Currently uploading: {file.name}</p>}
            </Grid>
            <Grid>
              {docError ? <Typography style={{ color: 'red' }}>Please select document and date</Typography> : null}
            </Grid>
            <Grid item xs ={12} style={{ fontWeight: 500, }}>
              {submitting ? (
                <Grid >
                  {/* <LoadingButton loading loadingIndicator='Saving…' color='primary' variant='contained'>
                    Fetch data
                  </LoadingButton> */}

                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                  </Box>

                  <Typography variant='bold' component='div' color='#ba000d'>
                    Please wait your data is being saved..
                  </Typography>
                </Grid>
              ) : ('')}
               <Button
                className="Button"
                variant='contained'
                style={{ float:'right',width:'80',marginBottom:'5px'} }
                onClick={handleClose}
              >
                Close
              </Button>
                <Button className="Button" variant='contained' color='primary' onClick={handleSubmit(onSubmit)} disabled={submitting}  
                style={{float:'right', width:'80px',marginRight:'5px',marginLeft:'5px' }}>
                  Save
                </Button>
         
             
            </Grid>
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        onClose={handleClose}
        open={opendocs}
      >
        <Box sx={styleofmodal}>
          <Grid container>
            <Grid item xs={9}>
              <Typography id='modal-modal-title' variant='h6' component='h2' style={{ fontWeight: 500,backgroundColor: '#377562',marginBottom:5,color:'white' }}>
                {FullDocName}
              </Typography>
            </Grid>
            <Grid item xs={2}>
            {submitting ? (
                <LoadingButton
                  loading
                  loadingIndicator='Downloading'
                  // style={{ position: 'absolute', right: '28px', bottom: '11px' }}
                  color='primary'
                  variant='contained'
                >
                  Fetch data
                </LoadingButton>
              ) : (
                <Button
                  className="Button"
                  size='small'
                  startIcon={<Download />}
                  onClick={() => {
                    download(`${urldownload}`, FullDocName)
                  }}
                  variant='contained'
                  disabled={submitting}
                  style={{
                    // backgroundColor: 'primary',
                    color: 'white',
                    float:'right'
                    // position: 'absolute',
                    // marginRight: '28px',
                    // bottom: '11px'
                  }}
                >
                  Download
                </Button>
              )}
            </Grid>
            <Grid item xs={1}>
              <CancelOutlinedIcon
                onClick={handleClose}
                fontSize='large'
                color='red'
                cursor='pointer'
                style={{ color: 'red', marginBottom: 5, float: 'right', }}
              ></CancelOutlinedIcon>
             
            </Grid>
            {/* <Grid item xs={2}></Grid> */}

            {(DocName && DocName == 'jpg') || DocName == 'png' || DocName == 'jpeg' ? (
              <>
                <Grid item xs={2}></Grid>
                <Grid item xs={8}>
                  <img
                    height='500px'
                    width='500px'
                    border='4px solid #000'
                    // frameborder='0'
                    // overflow='hidden'
                    src={`${urlFile}`}
                  // src={`https://images.unsplash.com/photo-1672394423014-e0354d75b123?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyfHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60`}
                  ></img>
                </Grid>
                <Grid item xs={2}></Grid>
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  <iframe
                    height='500px'
                    width='875px'
                    // frameborder='0'
                    // overflow='hidden'
                    src={`${urlFile}`}
                  // src={`https://images.unsplash.com/photo-1672394423014-e0354d75b123?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyfHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60`}
                  ></iframe>
                </Grid>
              </>
            )}
            {/* <Grid item xs={2}></Grid> */}
            <Grid item xs={12}></Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  )
}
export default Documents
