// Soft UI Dashboard React components
import { useAlert } from 'react-alert'
import { Grid, Snackbar, TextField } from '@mui/material'
import ModeEditIcon from '@mui/icons-material/Edit'
import axios from 'axios'
import React, { useEffect, useReducer, useMemo, useState, Fragment, useRef } from 'react'
import Box from '@mui/material/Box'
import moment from 'moment';

import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

import { Typography } from '@mui/material'

// import Avatar from 'react-avatar'

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
// import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const DisplayComments = (props) => {
  // const [hidePatientName, sethidepatientname] = useState(props.patientId > 0 ? true : false)
  //const comid = (commentId = null && commentId > 0 ? commentId : 0)
  const [errormessage, seterrormessage] = useState(false)
  const [rowsArchived, setRowsArchived] = useState(null)
  const [selectedRow, setselectedRow] = useState('')
  const [isShown, setIsShown] = useState(false)
  const [messagelist, setMessagelist] = useState('')
  const [commentOpen, setcommentOpen] = useState(false)

  const dat = [{ id: 1, firstname: 'Ummar', lastname: 'Gujjar' }]
  const [data, setData] = useState(dat)
  const [tabValue, settabValue] = React.useState(0)
  const [rows, setRows] = useState('')

  const alert = useAlert()

  // const actionButton = cellValues => {

  //   router.push({
  //     pathname: `/patients/patient_registration`,
  //     query: { pid: cellValues.row.patientId

  //     }
  //   })
  // }

  const handleChange1 = (event, newValue) => {
    settabValue(newValue)
  }


  const [submitting, setSubmitting] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [patientopen, setpatientopen] = useState('')
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const handleChange = (event, newValue) => {
    settabValue(newValue)
  }

  const fields = [
    'id',
    'patientId',
    'parentId',
    'patientNoteId',
    'name',
    'shortName',
    'type',
    'phoneNo',
    'fax',
    'emailAddress',
    'city',
    'state',
    'zipCode',
    'deleted',
    'createdBy',
    'createdDate',
    'parentId',
    'updatedBy',
    'updatedDate',
    'clientCreatedDate',
    'clientModifiedDate',
    'defaultPOS',
    'practice_id',
    'defaultFacility',
    'npi',
    'fullName',
    'address',
    'date',
    'patientName',
    'comments',
    'dueDate',
    'assignedTo',
    'archive'
  ]
  const defValues = {
    id: 0,
    patientName: '',
    patientId: props.patientId,
    parentId: props.parentId,
    patientNoteId: 0,
    address: '',
    fullName: '',
    shortName: '',
    type: '',
    phoneNo: '',
    fax: '',
    emailAddress: '',
    city: '',
    state: '',
    zipCode: '',
    deleted: false,
    createdBy: '',
    practice_id: '',
    createdDate: '',
    updatedBy: '',
    updatedDate: '',
    clientCreatedDate: '',
    clientModifiedDate: '',
    defaultPOS: '',
    defaultFacility: '',
    npi: '',
    fullName: '',
    address: '',
    date: '',
    comments: '',
    dueDate: '',
    parentId: '',
    assignedTo: '',
    archive: ''
  }
  const validationSchema = Yup.object({
    patientName: Yup.string().required('Patient Name is required'),
    comments: Yup.string().required('Messages are required')
  })
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues
  })
//   const onclickreply = () => {
//    props.replyFunc()
//    props.message
    
//   }

  // API for comments
  useEffect(() => {
    if (patientopen > 0) {
      handleOpen1()
    }
  }, [patientopen])
  useEffect(() => {
    var message = props.message
    fields.forEach(field => {
      setValue(field, message[field])
    })
  }, [])






  const [claimIds, setClaimIds] = useState(null)
  //API for saving comments

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      filter: true,
      sortable: true,
      resizable: true
    }
  }, [])
  const style = {
    // position: 'absolute',
    top: '50%',
    left: '50%',
    marginLeft: '800px',
    marginTop: '10px',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    // boxShadow: 24,
    p: 4
  }


  function applyRefresh() {
    setRefresh(!refresh)
  }

  const [open1, setOpen1] = React.useState(false)
  const handleOpen1 = () => setOpen1(true)
  const handleClose1 = () => {
    setpatientopen(0)
    setOpen1(false)
  }
  return (

    <div>
        <Card >
        <CardContent >
        <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={3.5}>
        <Typography style={{ fontWeight: 'bold', color: 'black' }}>Patient Name:</Typography>
        </Grid>
        <Grid item xs={8.5}>
        <Typography style={{ color: 'black' }}>{props.message.patientName}</Typography>
        </Grid>
        <Grid item xs={3}>
        <Typography style={{ fontWeight: 'bold', color: 'black',}}> Due Date:</Typography>
        </Grid>
        <Grid item xs={9}>
        <Typography style={{ color: 'black' }}>
          {/* {props.message.dueDate} */}
          {moment(props.message.dueDate).format('MM-DD-YYYY')}
          </Typography>
        </Grid>
        <Grid item xs={3}>
        <Typography style={{ fontWeight: 'bold', color: 'black' }}>AssignedTo:</Typography>
        </Grid>
        <Grid item xs={9}>
        <Typography style={{ color: 'black' }}>{props.message.assignedTo}</Typography>
        </Grid>
        <Grid item xs={3}>
        <Typography style={{ fontWeight: 'bold', color: 'black' }}>Messages:</Typography>
        </Grid>
        <Grid item xs={9}>
        <Typography style={{ color: 'black' }}>{props.message.comments}</Typography>
        </Grid>
      </Grid>
    </Box>




     
        </CardContent>
      </Card>

    </div>


  )
}

export default DisplayComments
