import { useState, useEffect } from 'react'
import React from 'react'
import ReactSelect from 'react-select'
import axios from 'axios'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import md5 from 'md5'
import Breadcrumbs from "../components/Common/Breadcrumb";
import { TabPanel, TabContext, TabList } from '@mui/lab'
import Tab from '@mui/material/Tab'
import { Box, Button, Container, Stack, SvgIcon, Typography, Grid, Snackbar, TextField } from '@mui/material';
import Moment from 'moment'
import Avatar from '@mui/material/Avatar'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import { useAlert } from 'react-alert'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InputMask from 'react-input-mask'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
// import { shallowEqual, useSelector, useDispatch } from 'react-redux'

import { useLocation, Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';


function PracticesRegistration() {
  document.title = "Register Practice | LuxBilling";
 // API Authanticatiion
 const token = localStorage.getItem('Token')
 const auth = `Bearer ${token}`
 const headers = {
   Authorization: auth
 }
    const fields = [
        'city',
        'createdBy',
        'createdDate',
        'id',
        'deleted',
        'state',
        'updatedBy',
        'updatedDate',
        'zipCode',
        'taxId',
        'phoneNo',
        'fax',
        'website',
        'emailAddress',
        'practiceName',
        'orgBusiness',
        'address',
        'billingEmailAddress',
    'npi',
    ]
    const defValues = {
        practiceName: '',
        orgBusiness: '',
        taxId: '',
        address: '',
        phoneNo: '',
        fax: '',
        emailAddress: '',
        city: '',
        website: '',
        createdBy: '',
        createdDate: '',
        id: 0,
        deleted: '',
        state: '',
        updatedBy: '',
        updatedDate: '',
        zipCode: '',
        billingEmailAddress: '',
        npi:'',
    }
    const [submitting, setSubmitting] = useState(false)
  
    const navigate = useNavigate();
    const location = useLocation()
    const PracticeId = location.state.PracticeId
    const validationSchema = Yup.object({
        practiceName: Yup.string().required('Name is required'),
        orgBusiness: Yup.string().required('org is required'),
        address: Yup.string().required('Address is required'),
    })
    const {
        register,
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        context: undefined,
        criteriaMode: 'firstError',
        shouldFocusError: true,
        shouldUnregister: false,
        shouldUseNativeValidation: false,
        delayError: undefined,
        resolver: yupResolver(validationSchema),
        defaultValues: defValues,
    })
    const alert = useAlert()
    const onSubmit = (data) => {
        setSubmitting(true)
        const postData = data
        console.warn('Data: ', postData)
        if (PracticeId !== undefined && PracticeId != null)
            postData.Id = PracticeId
        else postData.Id = 0
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/Practices/addPractice`,
                postData,
                { headers }
            )
            .then((response) => {
                setSubmitting(false)
                alert.success('Record saved successfully.', {
                    type: 'success',
                    onClose: () => {
                        navigate('/Practices')
                    },
                })
            })
            .catch((error) => {
                setSubmitting(false)
                alert.success(`Error ${error.message}`, {
                    type: 'error',
                })
            })
    }
    useEffect(() => {
        // POST request using axios inside useEffect React hook
        let isComponentMounted = true
        const fetchData = async () =>
            axios
                .get(
                    `${process.env.REACT_APP_API_URL}/Practices/getPracticeById?id=${PracticeId !== undefined ? PracticeId : 0
                    }`,
                    { headers }
                )
                .then((response) => {
                    console.log('response.data: ', response.data)
                    if (isComponentMounted) {
                        // setUsersData(response.data);
                        fields.forEach((field) => setValue(field, response[field]))
                        /*  const petList = Object.entries(response.data).map(([key, value]) => {
                          console.log("Key: ", key, value);
                          register(`${key}`, `${value}`);
                          return [key, value];
                        }); */
                    }
                    setPhone(response.phoneNo)
                    setFax(response.fax)
                })
                .catch((error) => {
                    console.error('There was an error!', error)
                })
        fetchData()
        return () => {
            isComponentMounted = false
        }

        // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, [])
    const [open, setOpen] = React.useState(false)
    const [options, setOptions] = React.useState([])
  

    React.useEffect(() => {
        if (!open) {
            setOptions([])
        }
    }, [open])

    function handleCancel() {
        navigate('/Practices')
    }
    const [phone, setPhone] = React.useState()
    const [Fax, setFax] = React.useState()
    return (
        <React.Fragment>
            <div className="page-content" >
                <Container>
                <Typography variant='h5' color='black' fontWeight="bold">Practice Registration</Typography>
                    <Grid
                        container
                        spacing={1}
                       marginTop={3}
                    >
                        <Grid item xs={12} sm={6} md={4} lg={4} style={{ width: '300px' }}>
                            <Typography component="label" fontSize="15px">
                                Practice Name
                            </Typography>
                            <Controller
                                name='practiceName'
                                {...register('practiceName')}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        size='small'
                                        placeholder='Please enter practiceName'
                                        {...field}
                                        fullWidth
                                        error={errors.practiceName}
                                    />
                                )}
                            />
                            {/* <p style={{ color: "red", fontSize: "14px" }}>{errors.practiceName?.message}</p> */}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} style={{ width: '300px' }}>
                            <Typography component="label" fontSize="15px">
                                Org Business
                            </Typography>
                            <Controller
                                name='orgBusiness'
                                {...register('orgBusiness')}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        size='small'
                                        placeholder='Please enter orgBusiness'
                                        {...field}
                                        fullWidth
                                        error={errors.orgBusiness}
                                    />
                                )}
                            />

                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} style={{ width: '300px' }}>
                            <Typography component="label" fontSize="15px">
                                Tax Id
                            </Typography>
                            <Controller
                                name='taxId'
                                {...register('taxId')}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        size='small'
                                        placeholder='Please enter taxId'
                                        {...field}
                                        fullWidth
                                        // error={errors.taxId}
                                    />
                                )}
                            />
                        </Grid>                    
                        <Grid item xs={12} sm={6} md={4} lg={4} style={{ width: '300px' }}>
                            <Typography component="label" fontSize="15px">
                                Address
                            </Typography>
                            <Controller
                                name='address'
                                {...register('address')}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        size='small'
                                        placeholder='Please enter address'
                                        {...field}
                                        fullWidth
                                        error={errors.address}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} style={{ width: '300px' }}>
                            <Typography component="label" fontSize="15px">
                                Phone No
                            </Typography>
                            <InputMask
                                mask="+1 999-999-9999"
                                disabled={false}
                                value={phone}
                                onChange={(e) => {
                                    setValue('phoneNo', e.target.value)
                                    setPhone(e.target.value)
                                }}
                            >
                                {() => (
                                    <TextField
                                        id="outlined-basic"
                                        fullWidth
                                        size='small'
                                        placeholder="Enter phoneNo"
                                    />
                                )}
                            </InputMask>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} style={{ width: '300px' }}>
                            <Typography component="label" fontSize="15px">
                                Fax
                            </Typography>
                            <InputMask
                                mask=" 999-9999999"
                                disabled={false}
                                value={Fax}
                                onChange={(e) => {
                                    setValue('fax', e.target.value)
                                    setFax(e.target.value)
                                }}
                            >
                                {() => (
                                    <TextField
                                        id="outlined-basic"
                                        fullWidth
                                        size='small'
                                        placeholder="Enter Fax"
                                    />
                                )}
                            </InputMask>
                        </Grid>
                       
                        <Grid item xs={12} sm={6} md={4} lg={4} style={{ width: '300px' }}>
                            <Typography component="label" fontSize="15px">
                                Zip Code
                            </Typography>
                            <Controller
                                name='zipCode'
                                {...register('zipCode')}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        size='small'
                                        placeholder='Please enter zipCode'
                                        {...field}
                                        fullWidth

                                    />
                                )}
                            />

                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} style={{ width: '300px' }}>
                            <Typography component="label" fontSize="15px">
                                City
                            </Typography>
                            <Controller
                                name='city'
                                {...register('city')}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        size='small'
                                        placeholder='Please enter City'
                                        {...field}
                                        fullWidth

                                    />
                                )}
                            />

                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} style={{ width: '300px' }}>
                            <Typography component="label" fontSize="15px">
                                State
                            </Typography>
                            <Controller
                                name='state'
                                {...register('state')}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        size='small'
                                        placeholder='Please enter state'
                                        {...field}
                                        fullWidth

                                    />
                                )}
                            />
                        </Grid>
                       
                        <Grid item xs={12} sm={6} md={4} lg={4} style={{ width: '300px' }}>
                            <Typography component="label" fontSize="15px">
                                Email Address
                            </Typography>
                            <Controller
                                name='emailAddress'
                                {...register('emailAddress')}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        type='email'
                                        size='small'
                                        placeholder='Please enter EmailAddress'
                                        {...field}
                                        fullWidth

                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} style={{ width: '300px' }}>
                            <Typography component="label" fontSize="15px">
                                Billing Email Address
                            </Typography>
                            <Controller
                                name='billingEmailAddress'
                                {...register('billingEmailAddress')}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        type='email'
                                        size='small'
                                        placeholder='Please enter BillingEmailAddress'
                                        {...field}
                                        fullWidth

                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} style={{ width: '300px' }}>
                            <Typography component="label" fontSize="15px">
                                Website
                            </Typography>
                            <Controller
                                name='website'
                                {...register('website')}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        type='email'
                                        size='small'
                                        placeholder='Please enter website'
              {...field}
                                        fullWidth

                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} style={{ width: '300px' }}>
                            <Typography component="label" fontSize="15px">
                                NPI
                            </Typography>
                            <Controller
                                name='npi'
                                {...register('npi')}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        type='text'
                                        size='small'
                                        placeholder='Please enter Npi'                                           
                                     {...field}
                                        fullWidth

                                    />
                                )}
                            />
                        </Grid>
                       
                        <Grid item xs={12} style={{ width: '300px' }}>
                            <Accordion
                                style={{
                                    marginBottom: '10px',
                                    border: '1px solid rgba(5, 152, 236, 0.637)',
                                    borderRadius: '20px',
                                    marginTop: '10px',
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Audit Log</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                                <Typography
                                                    fontSize="15px"
                                                    style={{ fontSize: '14px', width: '300px' }}
                                                >
                                                    Updated By
                                                </Typography>
                                                <Controller
                                                    name='updatedBy'
                                                    {...register('updatedBy')}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            size='small'
                                                            
                                                            placeholder='Please enter updatedBy'
                                                            {...field}
                                                            fullWidth
                                                            inputProps={
                                                                { readOnly: true, }
                                                            }

                                                        />
                                                    )}
                                                />

                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                                <Typography
                                                    fontSize="15px"
                                                    style={{ fontSize: '14px', width: '300px' }}
                                                >
                                                    Updated Date
                                                </Typography>
                                                <Controller
                                                    name='updatedDate'
                                                    {...register('updatedDate')}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            size='small'
                                                           
                                                            placeholder='Please enter updatedDate'
                                                            {...field}
                                                            fullWidth
                                                            inputProps={
                                                                { readOnly: true, }
                                                            }

                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                                <Typography
                                                    fontSize="15px"
                                                    style={{ fontSize: '14px', width: '300px' }}
                                                >
                                                    CreatedBy
                                                </Typography>
                                                <Controller
                                                    name='createdBy'
                                                    {...register('createdBy')}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            size='small'
                                                            
                                                            placeholder='Please enter createdBy'
                                                            {...field}
                                                            fullWidth
                                                            inputProps={
                                                                { readOnly: true, }
                                                            }

                                                        />
                                                    )}
                                                />
                                            </Grid>
                                           
                                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                                <Typography
                                                    fontSize="15px"
                                                    style={{ fontSize: '14px', width: '300px' }}
                                                >
                                                    Created Date
                                                </Typography>
                                                <Controller
                                                    name='createdDate'
                                                    {...register('createdDate')}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            size='small'
                                                        
                                                            placeholder='Please enter createdDate'
                                                            {...field}
                                                            fullWidth
                                                            inputProps={
                                                                { readOnly: true, }
                                                            }

                                                        />
                                                    )}
                                                />

                                            </Grid>
                                        </Grid>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Grid item xs={12} lg={12} sm={12}>
                                <Button
                                className= "Button"
                                    onClick={handleCancel}
                                    variant="gradient"
                                    disabled={submitting}
                                    style={{
                                        marginTop: '30px',
                                        float: 'right',
                                        marginLeft: '10px',
                                        width: '90px',
                                        height: '35px',
                                      
                                        color: 'white',
                                        fontSize: '14px',
                                    }}

                                >
                                    <CancelOutlinedIcon
                                        fontSize="medium"
                                        style={{ color: 'white', paddingRight: '5px' }}
                                    ></CancelOutlinedIcon>
                                    Cancel
                                </Button>
                                <Button
                                className= "Button"
                                    onClick={handleSubmit(onSubmit)}
                                    variant="gradient"
                                    disabled={submitting}
                                    style={{
                                        marginTop: '30px',
                                        float: 'right',
                                        marginLeft: '10px',
                                        width: '90px',
                                        height: '35px',
                                    
                                        color: 'white',
                                        fontSize: '14px',
                                    }}

                                >
                                    <SaveOutlinedIcon
                                        fontSize="medium"
                                        style={{
                                            color: 'white',
                                            paddingRight: '5px',
                                            paddingBottom: '2px',
                                        }}
                                    ></SaveOutlinedIcon>
                                    Save
                                </Button>

                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </React.Fragment>

    )

}

export default PracticesRegistration