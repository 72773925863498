// ** React Imports
import { useState, useEffect } from 'react'
import axios from 'axios'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import React from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import Button from '@mui/material/Button'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import { Grid, Typography } from '@mui/material'
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import ReactSelect from 'react-select'
import { Controller, useForm } from 'react-hook-form'
import "../Button.css"
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

const Provider = () => {
  document.title = "Provider | LuxBilling";

  const getRowSpacing = React.useCallback(params => {
    return {
      top: params.isFirstVisible ? 0 : 0.5,
      bottom: params.isLastVisible ? 0 : 0.8
    }
  }, [])
  const [isLoading, setLoading] = useState(false)
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const gridStyle = {
    color: '#8392ab !important',
    textTransform: 'lowercase',
    fontSize: '13.6px',
    fontWeight: '400',
    '&:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px'
    }
  }

  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 2,
    background: 'drak',
    fontSize: '13.4px !important',
    color: 'drak !important',
    borderColor: '#326C1D',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#377562',
      borderRadius: 0.1,
      color: 'white',
      fontSize: '16px !important',

    },

  }
  const gridClasses = {
    columnHeader: 'MuiDataGrid-columnHeaders',
  };
  const [refresh, setRefresh] = useState(false)
  const navigate = useNavigate();

  const columns = [
    {
      field: 'firstName',
      headerName: 'First Name',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 1,
      renderCell: cellValues => (
        <Button
          style={{ display: "flex", justifyContent: "flex-start" }}
          size='small'
          variant='text'
          color='info'
          onClick={(event) => {
            openProviderRegistration(event, cellValues.row.id)
          }}
        >{`${cellValues.row.firstName}`}</Button>
      )
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.4,
    },
    {
      field: 'type',
      headerName: 'Type',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.33,
    },
    {
      field: 'title',
      headerName: 'Title',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },
    {
      field: 'nPI',
      headerName: 'NPI',
      cellClassName: 'gridCell',
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },
    {
      field: 'phoneNo',
      headerName: 'PhoneNo',
      cellClassName: 'gridCell',
      headerClassName: 'super-app-theme--header',
      flex: 0.5,
    },
    {
      field: 'zipCode',
      headerName: 'Zip Code',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },
    {
      field: 'inactive',
      headerClassName: 'super-app-theme--header',
      headerName: 'Delete',
      sortable: true,
      flex: 0.2,
      // hide: true,
      // hide: Usertype == 'Admin'?false:true,

      renderCell: cell => (

        <DeleteIcon
          style={{
            marginRight: '5px',
            float: 'right',
            marginTop: '5px',
            color: 'red',
            cursor: 'pointer'
          }}

          onClick={cellValues => {

            confirmAlert({
              message: 'Do you want to delete the record.',
              closeOnEscape: true,
              closeOnClickOutside: true,
              overlayClassName: 'overlay-custom-class-name',

              buttons: [
                {
                  label: 'Yes',


                  onClick: () => {
                    const postData = {
                      id: cell.row.id,
                      inactive: true,

                    }

                    axios
                      .post(`${process.env.REACT_APP_API_URL}/Providers/addProvider`, postData, {
                        headers
                      })
                      .then(response => {
                        setRefresh(!refresh)
                        // setOpenNote(false)
                        // console.log('response.data: ', response.data)
                      })
                      .catch(error => {
                        console.error('There was an error!', error)
                      })
                  }
                },
                {
                  label: 'No'
                }
              ]
            })

          }}
        ></DeleteIcon>
      )
    }
  ]
  const [practiceID, setPracticeID] = useState("");
  console.log('testprac', practiceID)
  const [practicesList, setpracticesList] = useState([]);
  const Emailaddress = localStorage.getItem("Emailaddress")
  useEffect(() => {

    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Practices/getPractices`, {
        headers
      })
      .then(response => {
        var prac = [];
        response.forEach(field => {
          prac.push({
            label: field.practiceName,
            value: field.id,
            practiceId: field.practiceId,

          });
        });
        setpracticesList(prac);
        // response.forEach((field) => {
        //   practicesList.push({
        //     label: field.practiceName,
        //     value: field.id,
        //   });
        // });
      })
      .catch(error => { });

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    // resolver: yupResolver(validationSchema),
    // defaultValues: defValues
  })
  const [rows, setRows] = useState(null)
  useEffect(() => {
    setLoading(true)
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Providers/getProviders?practiceId=${practiceID}`, { headers })
      .then(response => {
        setLoading(false)
        setRows(response)
      })
      .catch(error => { })

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [refresh, practiceID])

  const openProviderRegistration = (event, id = 0) => {
    navigate('/ProviderRegistration', { state: { providerid: id } })
  }

  return (
    <div className="page-content">
      <Container >
        <Grid container>
          <Grid item xs={12} sm={9} md={6} lg={3}>
            <Button sx={{ mb: 1 }} className="Button" onClick={openProviderRegistration} variant='contained'>
              <AddOutlinedIcon
                fontSize='small'
                style={{
                  color: 'white',
                  paddingRight: '4px',
                  paddingBottom: '2px'
                }}
              ></AddOutlinedIcon>
              Add New
            </Button>
          </Grid>
          <Grid item xs={12} sm={9} md={6} lg={3} style={{ fontWeight: 500 }}>
            <Typography color='black' variant='caption' fontSize='15px'>
              Practice
            </Typography>
            <Controller
              name="practiceId"
              control={control}
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  options={practicesList}
                  value={practicesList.find(option => option.value == practiceID) || null}
                  onChange={e => {
                    // console.log(e, 'practiceId');
                    setPracticeID(e.value); 
                  }}
                  placeholder="Select Practice"
                />
              )}
            />

          </Grid>


        </Grid>
        {/* <Box mb={0} style={{ marginTop: 0 }}>
              {isLoading ? (
            <div style={{ margin:'5rem', textAlign:'center' }}>
               <CircularProgress /> 
            </div>
          ) : (
                <div mb={0} style={{ height: 400, width: '100%' }}>
                  {rows === null || rows.length === 0 ? null : (
                    <DataGrid
                      rows={rows}
                      headerHeight={20}
                      rowHeight={20}
                      columns={columns}
                      getRowSpacing={getRowSpacing}
                      //checkboxSelection
                      fullWidth
                      pagination
                  autoPageSize
                      sx={gridRowStyle}
                    />
                  )}
                </div>
          )}
              </Box> */}
        <Box mb={3} marginTop={2}>
          {isLoading ? (
            <div style={{ margin: '5rem', textAlign: 'center' }}>
              <CircularProgress />
            </div>
          ) : (
            <div style={{ height: 400, width: "100%" }}>
              {rows === null || rows.length === 0 ? (
                <Typography>No Record found</Typography>
              ) : (
                <DataGrid
                  rows={rows}
                  classes={gridClasses}
                  rowHeight={25}
                  columns={columns}
                  getRowSpacing={getRowSpacing}
                  //checkboxSelection
                  fullWidth
                  pagination
                  autoPageSize
                  sx={gridRowStyle}
                />
              )}
            </div>
          )}
        </Box>
      </Container>
    </div>


  )
}

export default Provider
