import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../Pages/Dashboard";
////Patient
import Patient from "../Pages/Patient";
import Demographics from "../Pages/Demographics";
import Documents from "../Pages/Documents";
import PatientRegistration from "../Pages/PatientRegistration";
import UserRegistration from "../Pages/UserRegistration";
import PracticeRegistration from "../Pages/PracticeRegistration";
import ProviderRegistration from "../Pages/ProviderRegistration";
import User from "../Pages/User";
import Practices from "../Pages/Practices";
import Todolist from "../Pages/Todolist";
import Email from "../Pages/Email";

import TmdRegistration from "../Pages/Tmdform";
import AuthorizationFormByCPT from "../Pages/AuthorizationFormByCPT";


// Import Authentication pages
import Login from "../Pages/Authentication/Login";
import ForgetPasswordPage from "../Pages/Authentication/ForgetPassword";
import Logout from "../Pages/Authentication/Logout";
import Register from "../Pages/Authentication/Register";
import UserProfile from "../Pages/Authentication/user-profile";
import OSAPAGE from "../Pages/Osapage";
import Letter from "../Pages/Letter"; 
import VOBPAGE from "../Pages/Vobpage";
import VOBPAGE1 from "../Pages/Vobpage1";
import Provider from "../Pages/Provider";
import Code from "../Pages/Code";
import Diagnosis from "../Pages/Diagnosis";
import Procedures from "../Pages/Procedures";
import Claim from "../Pages/Claim";
import Contants from "../Pages/Contants";





import Authorization from "../Pages/Authorization/Authorization";
import AuthorizationRegistration from "../Pages/AuthorizationRegistration";
import Fromstemp from "../Pages/FromsTemp";
import Insuranceplan from "../Pages/Insuranceplan";
import InsuranceplanRegistration from "../Pages/InsuranceplanRegistration";
import DiagnosisRegistration from "../Pages/DiagnosisRegistration";
import ProceduresRegistration from "../Pages/ProceduresRegistration";
import ClaimRegistration from "../Pages/ClaimRegistration";
import ContantsRegistration from "../Pages/ContantsRegistration";
import PaymentMethod from "../Pages/PaymentMethod";
import Schedular from "../Pages/Schedular/Schedular";






const authProtectedRoutes = [
  //dashboard
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/Schedular", component: <Schedular /> },
  { path: "/fromstemp", component: <Fromstemp /> },
  { path: "/Patient", component: <Patient /> },
  { path: "/Letter", component: <Letter /> },
  { path: "/VOBPAGE", component: <VOBPAGE /> },
  { path: "/OSAPAGE", component: <OSAPAGE /> },
  { path: "/VOBPAGE1", component: <VOBPAGE1 /> },
  { path: "/User", component: <User /> },
  { path: "/Provider", component: <Provider /> },
  { path: "/Insuranceplan", component: <Insuranceplan /> },
  { path: "/Practices", component: <Practices /> },
  { path: "/Todolist", component: <Todolist /> },
  { path: "/Email", component: <Email /> },
  { path: "/Code", component: <Code /> },
  { path: "/Demographics", component: <Demographics /> },
  { path: "/Documents", component: <Documents /> },
  { path: "/PatientRegistration", component: <PatientRegistration /> },
  { path: "/PracticeRegistration", component: <PracticeRegistration /> },
  { path: "/InsuranceplanRegistration", component: <InsuranceplanRegistration /> },
  { path: "/Tmdform", component: <TmdRegistration /> },
  { path: "/Diagnosis", component: <Diagnosis /> },
  { path: "/Procedures", component: <Procedures /> },
  { path: "/Claim", component: <Claim /> },
  { path: "/Contants", component: <Contants /> },
  { path: "/AuthorizationFormByCPT", component: <AuthorizationFormByCPT /> },


  

  

  
  { path: "/ProviderRegistration", component: <ProviderRegistration /> },
  { path: "/UserRegistration", component: <UserRegistration /> },
  { path: "/Authorization", component: <Authorization /> },
  { path: "/AuthorizationRegistration", component: <AuthorizationRegistration /> },
  { path: "/DiagnosisRegistration", component: <DiagnosisRegistration /> },
  { path: "/ProceduresRegistration", component: <ProceduresRegistration /> },
  { path: "/ClaimRegistration", component: <ClaimRegistration /> },
  { path: "/ContantsRegistration", component: <ContantsRegistration /> },
  { path: "/PaymentMethod", component: <PaymentMethod /> },

  
  
  

  
  // Profile
  { path: "/userprofile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },

];

const publicRoutes = [

  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },
 
];

export { authProtectedRoutes, publicRoutes };
