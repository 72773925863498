import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Container } from "reactstrap";
import { Box, Button, Stack, SvgIcon, Typography, Grid, Snackbar, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress'
import axios from 'axios'
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { Controller, useForm } from 'react-hook-form'
import InboxIcon from '@mui/icons-material/Inbox'
import Moment from 'moment'
import AddIcon from '@mui/icons-material/Add'
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined'
import SendIcon from '@mui/icons-material/Send'
// import { makeStyles } from '@material-ui/styles'
// import { useRouter, withRouter } from 'next/router'
import { useAlert } from 'react-alert'
import { DataGrid } from '@mui/x-data-grid'
import Tooltip from '@mui/material/Tooltip'
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
// import { createBrowserHistory } from 'history'
// import { useHistory } from "react-router-dom";
// import { Navigate } from "react-router-dom";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import { useNavigate } from 'react-router-dom';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import DeleteIcon from '@mui/icons-material/Delete'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import "../Button.css"
import ComItem from '../ComItem';
import { useLocation, Link } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import ReactSelect from 'react-select'
// const history = useHistory();
const Email = () => {
  
  const [isLoading, setLoading] = useState(false)
  document.title = "Email | LuxBilling";
//   const useStyles = makeStyles((theme) => ({
//     fab: {
//       position: 'fixed',
//       right: 20,
//       bottom: 20,
//     },
//     container: {
//       width: 900,
//       height: 'fit-content',
//       backgroundColor: 'white',
//       position: 'absolute',
//       display: 'flex',
//       top: 0,
//       left: 0,
//       bottom: 0,
//       right: 0,
//       padding: '10px',
//       margin: 'auto',
     
//     },
//     form: {
//       padding: theme.spacing(1),
//     },
//     item: {
//       marginBottom: theme.spacing(2),
//     },
//     text: {
//       display: 'inline',
//       width: '100%',
//     },
  
//     readhide: {
//       color: 'blue',
//       cursor: 'pointer',
//     },
//   }))
  const fields = [
    'id',
    'practiceId',
    'parentId',
    'sendBy',
    'sendDate',
    'inbox',
    'sentMessage',
    'isDraft',
    'subject',
    'bodyMessage',
    'isRead',
    'readDate',
    'isActive',
    'createdBy',
    'createdDate',
    'updatedBy',
    'updatedDate',
    'clientCreatedDate',
    'clientUpdatedDate',
    'messagesTos',
  ]
  const defValues = {
    id: 0,
    practiceId: '',
    parentId: '',
    sendBy: '',
    sendDate: '',
    inbox: '',
    sentMessage: '',
    isDraft: 'false',
    subject: '',
    bodyMessage: '',
    isRead: 'false',
    readDate: '',
    isActive: 'false',
    createdBy: '',
    createdDate: '',
    updatedBy: '',
    updatedDate: '',
    clientCreatedDate: '',
    clientUpdatedDate: '',
    messagesTos: [],
  }
  const styleDetails = {
    width: "80%",
    height: 'fit-content',
    bgcolor: "background.paper",
    border: "2px solid #3C7F6B",
    position: 'absolute',
    top: 30,
    left: 45,
    bottom: 30,
    right: 0,
    margin: 'auto',
    marginTop: '10px',
    paddingBottom: '10px',
    padding: '20px',
    // overflow: 'auto',
  }
  // document.title = "Dashboard | Upzet - React Admin & Dashboard Template";

  // const auth = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImN0eSI6IkpXVCJ9.eyJqdGkiOiIwZmRiNWIxMy0wYzExLTQ4ZTQtOTdmMy1hZjhlOGNmOTJlMjgiLCJ2YWxpZCI6IjEiLCJ1c2VyRW1haWwiOiJhbGlAZ21haWwuY29tIiwicHJhY3RpY2VJZCI6IjEiLCJleHBpcnlEYXRlIjoiNy8zLzIwMjgiLCJicm93c2VyIjoiR29vZ2xlIENocm9tZSIsImFwcGxpY2F0aW9uIjoiTHV4QmlsbGluZyIsIklzQWRtaW4iOiJGYWxzZSIsIlByb3ZpZGVySWQiOiIwIiwiZXhwIjoxODQ2MDgwNTE2LCJpc3MiOiJodHRwOi8vd3d3Lkx1eEJpbGxpbmcuY29tIiwiYXVkIjoiaHR0cDovL3d3dy5MdXhCaWxsaW5nLmNvbSJ9.2Uac6a-c-IPRJFzYSvjxpRych9icwhsebjvB2lgZaOs`
  const location = useLocation()
  const alert = useAlert()
//   const dispatch = useDispatch()
  // var messagesToss=[]
//   useEffect(() => {
//     dispatch(getAllUsers())
//   }, [dispatch])
  const [isReadMore, setIsReadMore] = useState(true)
  const ReadMore = ({ children }) => {
    const text = children

    return (
      <p >
        {isReadMore ? text.slice(0, 10) : text}
        {text.length > 10
          ? '.....'
          : // <span
            //   onClick={() => {
            //     setIsReadMore(!isReadMore)
            //   }}
            //   className={classes.readhide}
            // >
            //   {isReadMore ? '...read more' : '    show less'}
            // </span>
            null}
      </p>
    )
  }
  const [submitting, setSubmitting] = useState(true)
  // const [dataReceived, setdataReceived] = useState(false)
  const [openn, setOpenn] = useState(false)
  // API Authanticatiion
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const maill = localStorage.getItem('Emailaddress')
//   const classes = useStyles()
console.log('testemail',maill)
  const [rows, setRows] = useState('')
  const [selectedIndex, setSelectedIndex] = useState('')
  const [sentMessages, setSentMessages] = useState('')
  const [refresh, setRefresh] = useState(false)
  const [isDraft, setIsDraft] = useState(false)
  const [openNewMail, setOpenNewMail] = React.useState(false)
  const [userList, setuserList] = useState([]);
  const validationSchema = Yup.object({
     messagesTos: Yup.string().required('Please Select User Email.'),
    subject: Yup.string().required('Please Enter Subject.'),
  })
  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues,
  })
  const onSubmit = (data) => {
    setSubmitting(true)
    const postData = data
    // postData.messagesTos = messagesToss;
    if (messagesToss.length > 0) {
      postData.messagesTos = messagesToss
    }
    if (isDraft) {
      postData.isDraft = true
    }
    // postData.isNoteReview = false
    // postData.patientId = location.state.patientId
    // postData.patientNoteId = props.patientnoteId
    // console.log('Data: ', postData)
    // console.log(JSON.stringify(postData, null, 2))
    axios
      .post(`${process.env.REACT_APP_API_URL}/Messages/addMessage`, postData, {
        headers,
      })
      .then((response) => {
        setSubmitting(false)
        if (isDraft == false) {
          alert.success('Email Sent successfully.', {
            type: 'success',
            onClose: () => {
              settabValue(1)
              setRefresh(!refresh)
              clearAll()
            },
          })
        } else {
          alert.success('Saved as Draft.', {
            type: 'success',
            onClose: () => {
              settabValue(1)
              setOpenNewMail(false)
              clearAll()
            },
          })
        }
      })
      .catch((error) => {
        setSubmitting(false)
        alert.success(`Error ${error.message}`, {
          type: 'error',
        })
      })
  }
//   function handleCancel() {
//     history.push('/PatientSearch')
//   }
  const handleCloseMail = (reason) => {
    if (reason === 'clickaway') {
      return
    }
    clearAll()
  }
  const [id, setId] = useState('')
  const [inbox, setInbox] = useState('')
  const [inboxIndex, setInboxIndex] = useState('')
  var date = Moment(inbox.sendDate).format('L')
  date = Moment(date).format('MM-DD-YYYY')
  var time = Moment(inbox.sendDate).format('hh:mm:A')
  function handleDate(e) {
    var Mdate = Moment(e).format('L')
    Mdate = Moment(Mdate).format('MM-DD-YYYY')
    // console.log('date',Mdate)
    var Mtime = Moment(e).format('hh:mm:A')
    var TTime = `${Mdate}  ${Mtime}`
    return TTime
  }
  const [anchorEl, setAnchorEl] = useState(false)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (e) => {
    setAnchorEl(null)
    console.log('eventt:', e)
  }
  useEffect(() => {
    setSubmitting(true)
    // POST request using axios inside useEffect React hook
    // console.log('API: ', props)
    let isComponentMounted = true
    const fetchData = async () =>
      axios
        .get(`${process.env.REACT_APP_API_URL}/Messages/getInbox`, {
          headers,
        })
        .then((response) => {
          if (response != '') {
            setRows(response)
            setSubmitting(false)
            if (tabValue == 1) {
              setId(response[0].id)
            }
          } else {
            setSubmitting(false)
          }
        })
        .catch((error) => {
          setSubmitting(false)
          alert.success(`Error ${error.message}`, {
            type: 'error',
          })
        })
    fetchData()
    return () => {
      isComponentMounted = false
    }

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [refresh])
  useEffect(() => {
    setOpenn(false)
    setSubmitting(true)
    // POST request using axios inside useEffect React hook
    // console.log('API: ', props)
    let isComponentMounted = true
    const fetchData = async () =>
      axios
        .get(`${process.env.REACT_APP_API_URL}/Messages/getsentMessages`, {
          headers,
        })
        .then((response) => {
          if (response != '') {
            setSentMessages(response)
            setSubmitting(false)
            if (tabValue == 2) {
              setId(response[0].id)
            }
          } else {
            setSubmitting(false)
            setOpenn(false)
          }
        })
        .catch((error) => {
          setSubmitting(false)
          setOpenn(false)
          alert.success(`Error ${error.message}`, {
            type: 'error',
          })
        })
    fetchData()
    return () => {
      isComponentMounted = false
    }

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [refresh])
const practicesListid = localStorage.getItem('default_paractice')

  useEffect(() => {
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/UserPractices/users?tokenPracticeId=${practicesListid}`, {
        headers
      })
      .then(response => {
        var prac = [];
        response.forEach(field => {
          prac.push({
            label: `${field.fullName}<${field.email}>`,
            value: field.email
          });
        });
        setuserList(prac);
        // response.forEach((field) => {
        //   practicesList.push({
        //     label: field.practiceName,
        //     value: field.id,
        //   });
        // });
      })
      .catch(error => {});

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);
  //   const usersRedx = useSelector((state) =>
  //   (state.allusers.loading ? [] : state.allusers.NAMES), shallowEqual
  // )
//   const usersRedx = useSelector(
//     (state) => (state.allUsers.loading ? [] : state.allUsers.NAMES),
//     shallowEqual
//   )
//   useEffect(() => {
  
//     usersRedux = []
//     usersRedx.forEach((field) => {
//       usersRedux.push({
//         label: `${field.fullName}<${field.email}>`,
//         value: field.email,
//       })
//     })

//     // empty dependency array means this effect will only run once (like componentDidMount in classes)
//   }, [{ usersRedx }])

  useEffect(() => {
    // POST request using axios inside useEffect React hook
    // console.log('API: ', props)
    if (id) {
      setSubmitting(true)
      let isComponentMounted = true
      const fetchData = async () =>
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/Messages/getMeassageById?Id=${id}`,
            {
              headers,
            }
          )
          .then((response) => {
            setInbox(response)
            setOpenn(true)
            setSubmitting(false)
            // console.log('row', response.data)
          })
          .catch((error) => {
            setSubmitting(false)
            setOpenn(false)
            alert.success(`Error ${error.message}`, {
              type: 'error',
            })
          })
      fetchData()
      return () => {
        isComponentMounted = false
      }
    }

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [id])
  const clearAll = () => {
    //  setValue('sendBy', '')
    setValue('subject', '')
    setValue('bodyMessage', '')
    setValue('messagesTos', '')
    settabValue(1)
    setEmail('')
    setOpenNewMail(false)
  }
  const handleDraft = () => {
    setIsDraft(true)
    handleSubmit(onSubmit)
    clearAll()
  }
  const [tabValue, settabValue] = React.useState(0)
  const [email, setEmail] = React.useState('')
  const [messagesToss, setMessagesToss] = React.useState([])

  const handleChangeTab = (event, newValue) => {
    settabValue(newValue)
    setSelectedIndex('')
    setOpenn(false)
    if (newValue == 0) {
      if (rows.length > 0) {
        setId(rows[0].id)
      }
    }
    if (newValue == 1) {
      if (sentMessages.length > 0) {
        setId(sentMessages[0].id)
      }
    }
  }
  return (
     
      <div className="page-content">
        <Container  >
        <Modal
        open={openNewMail}
        onClose={handleCloseMail}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleDetails}>
          <form  autoComplete="off">
           <Grid container >
            <Grid item xs={12} style={{marginTop:'-10px'}}>
            <CancelOutlinedIcon
              onClick={() => {
                clearAll()
                // setMaill(`${localStorage.getItem('dtt_user_email')}`)
                // props.refresh()
              }}
              variant="contained"
              style={{
                float: 'right',
                color: 'red',
              }}
            ></CancelOutlinedIcon>
            </Grid>
           <Grid item xs={12} style={{backgroundColor: '#377562' }}>
                      <Typography style={{ fontWeight: 'bold', fontSize: '20px', color: 'white' }}>
                      New mail
                      </Typography>
                    </Grid>
           </Grid>
           <form>
            <Grid
              container
              spacing={1}
            
            >
              <Grid item xs={12} sm={9} md={6} lg={6}>
                <Typography>From:</Typography>
           
                            <Controller
                                name='sendBy'
                                {...register('sendBy')}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                    type='text'
                                    size='small'
                                        placeholder='Please enter Email'
                                        {...field}
                                        fullWidth
                                    value={maill}
                                    inputProps={
                                      { readOnly: true, }
                                  }
                                        

                                    />
                                )}
                            />
                        </Grid>
             
              <Grid item xs={12} sm={9} md={6} lg={6}>
                <Typography>To:</Typography>           
                <Controller
                      name="messagesTos"
                      control={control}
                      render={({ field }) => (
                        <ReactSelect
                          {...field}
                          placeholder="Select User Email"
                          value={userList.find(option => option.value === getValues("messagesTos"))}
                          onChange={(selectedOption) => {
                            // Set the messagesTos value (email)
                            setValue("messagesTos", selectedOption.value);

                            // Create the messageTo object with label and value
                            const messageToObj = {
                          
                              email: selectedOption.value,
                              name: selectedOption.label, // Assuming the label contains the name
                           
                            };

                            // Update messagesToss state with the new messageTo object
                            setMessagesToss([...messagesToss, messageToObj]);
                          }}
                          options={userList}
                        />
                      )}
                    />
                 <p style={{ color: 'red', fontSize: '13px' }}>
                  {errors.messagesTos?.message}
                </p>
              </Grid>
              {/* <Grid item xs={12}>
                <TextField
                  type="text"
                  value={email}
                  placeholder="Please Select Email"
                  fullWidth
                  size="small"
                  // margin="dense"
                  float="left"
                  error={errors.messagesTos}
                />
               
              </Grid> */}
             
              <Grid item xs={12}>
                <TextField
                  type="input"
                  placeholder="Subject"
                  {...register('subject')}
                  fullWidth
                  size='small'
                  margin="dense"
                  error={errors.subject}
                />
                <p style={{ color: 'red', fontSize: '13px' }}>
                  {errors.subject?.message}
                </p>
              </Grid>
              <Grid item xs={12}>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={5}
                  // placeholder="Enter comments"
                  style={{ width: '100%', padding: '5px' }}
                  {...register('bodyMessage')}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                className='Button'
                  onClick={handleSubmit(onSubmit)}
                  disabled={submitting}
                  variant="gradient"
                  style={{
                    marginTop: '10px',
                    marginBottom: '3px',
                    float: 'right',
                    marginRight: '5px',
                    marginLeft: 'auto',
                    // width: '80px',
                    height: '35px',
                    // backgroundColor: '#FCBE13',
                    color: 'white',
                    fontSize: '14px',
                  }}
                >
                  <SendIcon fontSize="medium" />
                  Send
                </Button>

                <Button
                  className='Button'
                  onClick={clearAll}
                  variant="gradient"
                  style={{
                    marginTop: '10px',
                    marginBottom: '3px',
                    float: 'right',
                    marginRight: '5px',
                    marginLeft: 'auto',
                    // width: '80px',
                    height: '35px',
                    // backgroundColor: '#FCBE13',
                    color: 'white',
                    fontSize: '14px',
                  }}
                >
                  <DeleteIcon fontSize="medium" />
                  Discard
                </Button>
                {/* <Button
                    onClick={()=>{  
                      setIsDraft(true)
                      handleSubmit(onSubmit)
                      clearAll()}}
                    variant="gradient"
                    style={{
                      marginTop: '10px',
                      marginBottom: '3px',
                      float: 'right',
                      marginRight: '5px',
                      marginLeft: 'auto',
                      // width: '80px',
                      height: '35px',
                      backgroundColor: '#FCBE13',
                      color: 'black',
                      fontSize: '14px',
                    }}
                  >
                    <DraftsIcon fontSize="medium" />
                    Save as Draft
                  </Button> */}
              </Grid>
            </Grid>
            </form>
          </form>
        </Box>
      </Modal>
      {submitting ? (
        <CircularProgress
          style={{
            width: '50px',
            height: '50px',
            position: 'absolute',
            left: '50%',
            top: '5%',
            marginLeft: '-25px',
            marginTop: '-25px',
            zIndex: 1000,
            color: '#FCBE13',
          }}
        />
      ) : null}

      <Grid
        spacing={1}
        alignContent="left"
        justifyContent="left"
        style={{
          width: '100% !important',
          marginLeft: '0px !important',
        }}
      >
        <div>
          <Button
          className="Button"
            onClick={() => setOpenNewMail(true)}
            variant="gradient"
            style={{
              
              marginBottom: '10px',
              marginLeft: 'auto',
              height: '35px',
              color: 'white',
              fontSize: '14px',
            }}
          >
            <AddIcon fontSize="medium" />
            New mail
          </Button>
          
          <Button
          className="Button"
              sx={{ mb: 2, marginRight: '20px' }}
              onClick={() => {
                setRefresh(!refresh)
                setSubmitting(true)
            
               }}
              style={{
                marginBottom: '10px',
                float: 'right',
                marginLeft: '20px',
                width: '120px',
                height: '35px',
                color: 'white',
                fontSize: '14px',
              }}
            >
              <CachedOutlinedIcon
                fontSize="medium"
                style={{
                  color: 'white',
                  marginRight: '1px',
                  paddingRight: '0px',
                  paddingBottom: '2px',
                }}
              ></CachedOutlinedIcon>
              Refresh
            </Button>
         
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '30%' }}>
              <TabList
                onChange={handleChangeTab}
                aria-label="lab API tabs example"
              >
                
                <Tab
                  value={0}
                  icon={<InboxIcon fontSize="medium" />}
                  label="Inbox"
                />
                <Tab
                  value={1}
                  icon={<SendIcon fontSize="medium" />}
                  label="Sent"
                />
                
              </TabList>
            </Box>
         
            <TabPanel value={0}>
     
              <Grid
                container
                spacing={1}
                alignContent="center"
                justifyContent="center"
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <Grid
                  xs={3}
                  sm={3}
                  md={3}
                  lg={3}
                  style={{
                    margin: '0 auto',
                    height: '500px',
                    width: '100%',
                    overflow: 'auto',
                  }}
                >
                  {rows != null && rows.length > 0
                    ? rows.map((item, i) => (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          style={{
                            marginBottom: '5px',
                            borderBottom: 'groove',
                            overFlow: 'auto',
                            padding: '5px',
                          }}
                          backgroundColor={selectedIndex == i ? '#B2E4F6' : ''}
                          onClick={() => {
                            setSelectedIndex(i);
                            setId(item.id)
                          }}
                        >
                          <Grid item xs={6} sm={12} md={12} lg={12}>
                            <Typography
                              style={{
                                paddingBottom: '1px',
                                fontSize: '17px',
                              }}
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              color="#32A6D6"
                            >
                              <ReadMore>{item.subject}</ReadMore>
                            </Typography>
                            <Typography
                              style={{
                                padding: '4px',
                                marginTop: '7px',
                                fontSize: '10px',
                                textAlign: 'right',
                                float: 'right',
                              }}
                              component="label"
                              variant="caption"
                            >
                              {handleDate(item.sendDate)}
                            </Typography>
                          </Grid>



                          <DeleteIcon
                            style={{
                              marginRight: '5px',
                              float: 'right',
                              marginTop: '5px',
                              color:'red',

                            }}
                            
                            onClick={() => {
                              confirmAlert({
                                message: 'Do you want to delete the record.',
                                closeOnEscape: true,
                                closeOnClickOutside: true,
                                overlayClassName: 'overlay-custom-class-name',
                                buttons: [
                                  {
                                    label: 'Yes',
                                    onClick: () => {
                                      const postData = {

                                        id:id,
                                        isActive: true,
                                
                                      }

                                      axios
                                        .post(
                                          `${process.env.REACT_APP_API_URL}/Messages/addMessage`,
                                          postData,
                                          {
                                            headers,
                                          }
                                        )
                                        // .then((response) => {
                                        //   console.log("deleted successfully!")
                                        // })

                                        .then((response) => {
                                          setRefresh(!refresh)
                                          // setOpenNote(false)
                                          // console.log('response.data: ', response.data)
                                        })
                                        .catch((error) => {
                                          console.error(
                                            'There was an error!',
                                            error
                                          )
                                        })
                                    },
                                  },
                                  {
                                    label: 'No',
                                  },
                                ],
                              })
                            }}
                          ></DeleteIcon>



                          <Grid item spacing={1} xs={6} sm={12} md={12} lg={12}>
                            <Typography
                              style={{
                                paddingBottom: '1px',
                                fontSize: '14px',
                              }}
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              color="#333333"
                            >
                              <ReadMore>{item.sentBy}</ReadMore>
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography
                              style={{
                                paddingBottom: '1px',
                                fontSize: '14px',
                              }}
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              color="#333333"
                            >
                              <ReadMore>{item.bodyMessage}</ReadMore>
                            </Typography>
                          </Grid>
                        </Grid>
                      ))
                    : 'No Record Found'}
                </Grid>
                <Grid item xs={9} sm={9} md={9} lg={9}>
                  {openn ? (
                    <Grid
                      container
                      style={{
                        backgroundColor: '#3C7F6B',
                       
                        position: 'relative',
                      }}
                    >
                      <Grid container xs={12} sm={12} md={12} lg={12}>
                        <Grid item xs={9} sm={9} md={9} lg={9}>
                          <Typography
                            style={{
                              padding: '4px',
                              fontSize: '20px',
                            }}
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            color="white"
                          >
                            {inbox.subject}
                          </Typography>
                          <Typography
                            style={{
                              padding: '4px',
                              fontSize: '12px',
                            }}
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            color="white"
                       
                          >
                            {inbox.sentBy}
                          </Typography>
                        </Grid>
                     
                        <Grid
                          item
                          xs={3}
                          sm={3}
                          md={3}
                          lg={3}
                          style={{ textAlign: 'right', float: 'right' }}
                        >
                          <Typography
                            style={{
                              padding: '4px',
                              fontSize: '10px',
                            }}
                            component="label"
                            variant="caption"
                            color="white"
                          >
                            {date}
                          </Typography>
                          <Typography
                            style={{
                              padding: '4px',
                              fontSize: '10px',
                            }}
                            component="label"
                            variant="caption"
                            color="white"
                          >
                            {time}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid>
                        <Typography
                          style={{
                            padding: '4px',
                            paddingLeft: '5px',
                            fontSize: '17px',
                          }}
                          fontWeight="300"
                          color="white"
                        >
                          {inbox.bodyMessage}
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={1}>
            <Grid
                container
                spacing={1}
                alignContent="center"
                justifyContent="center"
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <Grid
                  xs={3}
                  sm={3}
                  md={3}
                  lg={3}
                  style={{
                    margin: '0 auto',
                    height: '500px',
                    width: '100%',
                    overflow: 'auto',
                  }}
                >
                  {sentMessages != null && sentMessages.length > 0
                    ? sentMessages.map((item, i) => (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          style={{
                            marginBottom: '5px',
                            borderBottom: 'groove',
                            padding: '5px',
                          }}
                          backgroundColor={selectedIndex == i ? '#B2E4F6' : ''}
                          onClick={() => {
                            setSelectedIndex(i);
                            setId(item.id)
                          }}
                        >
                          <Grid item spacing={1} xs={6} sm={12} md={12} lg={12}>
                            {/* <Typography
                              style={{
                                paddingBottom: '1px',
                                fontSize: '14px',
                              }}
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              color="#333333"
                            >
                              {item.sentBy}
                            </Typography> */}
                          </Grid>
                          <Grid item xs={6} sm={12} md={12} lg={12}>
                            <Typography
                              style={{
                                paddingBottom: '1px',
                                fontSize: '17px',
                              }}
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              color="#32A6D6"
                            >
                              <ReadMore>{item.subject}</ReadMore>
                            </Typography>
                            <Typography
                              style={{
                                padding: '4px',
                                marginTop: '7px',
                                fontSize: '10px',
                                textAlign: 'right',
                                float: 'right',
                              }}
                              component="label"
                              variant="caption"
                              // color="#2188FE"sentBy
                            >
                              {handleDate(item.sendDate)}
                            </Typography>

                           

                           

                          </Grid>
                          <Grid item spacing={1} xs={6} sm={12} md={12} lg={12}>
                            <Typography
                              style={{
                                paddingBottom: '1px',
                                fontSize: '14px',
                              }}
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              color="#333333"
                            >
                              <ReadMore>{item.emailTo}</ReadMore>
                            </Typography>


                            <DeleteIcon
                            style={{
                              marginRight: '5px',
                              float: 'right',
                              marginTop: '5px',
                              color:'red',
                            }}
                            
                            onClick={() => {
                              // console.log('Cell: ', cell)
                              confirmAlert({
                                message: 'Do you want to delete the record.',
                                closeOnEscape: true,
                                closeOnClickOutside: true,
                                overlayClassName: 'overlay-custom-class-name',
                                buttons: [
                                  {
                                    label: 'Yes',
                                    onClick: () => {
                                      const postData = {

                                        id:item.id,
                                        deleted: true,
                                
                                      }
                                      axios
                                      .post(
                                        `${process.env.REACT_APP_API_URL}/Messages/addMessage`,
                                        postData,
                                        {
                                          headers,
                                        }
                                      )
                                      .then((response) => {
                                        // First API call successful, now make the second API call
                                        axios
                                          .get(`${process.env.REACT_APP_API_URL}/Messages/getsentMessages`, {
                                            headers,
                                          })
                                          .then((response) => {
                                            if (response !== '') {
                                              setSentMessages(response);
                                              setSubmitting(false);
                                              if (tabValue === 2) {
                                                setId(response[0].id);
                                              }
                                            } else {
                                              setSubmitting(false);
                                              setOpenn(false);
                                            }
                                          })
                                          .catch((error) => {
                                            console.error("Error in the second API call:", error);
                                            // Handle error for the second API call if needed
                                          })
                                          .finally(() => {
                                            // Additional actions after the second API call (optional)
                                          });
                                    
                                        // Additional actions after the first API call (optional)
                                        setRefresh(!refresh);
                                      })
                                      .catch((error) => {
                                        console.error("Error in the first API call:", error);
                                        // Handle error for the first API call if needed
                                      });
                                    
                                    },
                                  },
                                  {
                                    label: 'No',
                                  },
                                ],
                              })
                            }}
                          ></DeleteIcon>

                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography
                              style={{
                                paddingBottom: '1px',
                                fontSize: '14px',
                              }}
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              color="#333333"
                            >
                              <ReadMore>{item.bodyMessage}</ReadMore>
                            </Typography>
                          </Grid>
                        </Grid>
                      ))
                    : 'No Record Found'}
                </Grid>
                <Grid item xs={9} sm={9} md={9} lg={9}>
                  {openn ? (
                    <Grid
                      container
                      style={{
                        backgroundColor: '#3C7F6B',
                        // height: '100%',
                        position: 'relative',
                      }}
                    >
                      <Grid container xs={12} sm={12} md={12} lg={12}>
                        <Grid item xs={9} sm={9} md={9} lg={9}>
                          <Typography
                            style={{
                              padding: '4px',
                              fontSize: '20px',
                            }}
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            color="white"
                          >
                            {inbox.subject}
                          </Typography>
                          <Typography
                            style={{
                              padding: '4px',
                              fontSize: '12px',
                            }}
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            color="white"
                          >
                            {inbox.sentTo}
                          </Typography>
                        </Grid>
                        {/* <Grid item xs={2} sm={2} md={2} lg={2}></Grid> */}
                        <Grid
                          item
                          xs={3}
                          sm={3}
                          md={3}
                          lg={3}
                          textAlign="right"
                        >
                          <Typography
                            style={{
                              padding: '4px',
                              fontSize: '10px',
                            }}
                            component="label"
                            variant="caption"
                            color="white"
                          >
                            {date}
                          </Typography>
                          <Typography
                            style={{
                              padding: '4px',
                              fontSize: '10px',
                            }}
                            component="label"
                            variant="caption"
                            color="white"
                          >
                            {time}
                          </Typography>

                          {/* <Typography component="label" variant="caption">
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? 'long-menu' : undefined}
                              aria-expanded={open ? 'true' : undefined}
                              aria-haspopup="true"
                              onClick={handleClick}
                            >
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              id="long-menu"
                              MenuListProps={{
                                'aria-labelledby': 'long-button',
                              }}
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                              PaperProps={{
                                style: {
                                  maxHeight: ITEM_HEIGHT * 4.5,
                                  width: '20ch',
                                },
                              }}
                            >
                              {options.map((option) => (
                                <MenuItem
                                  key={option}
                                  selected={option === 'Pyxis'}
                                  onClick={handleClose}
                                >
                                  {option}
                                </MenuItem>
                              ))}
                            </Menu>
                          </Typography> */}
                        </Grid>
                      </Grid>
                      <Grid>
                        <Typography
                          style={{
                            padding: '4px',
                            paddingLeft: '5px',
                            fontSize: '17px',
                          }}
                          // component="label"
                          // variant="caption"
                          fontWeight="300"
                          color="white"
                        >
                          {inbox.bodyMessage}
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </TabPanel>
          
          </TabContext>
        </div>
      
      </Grid>
           
         
        </Container>
      </div>

  );
};

export default Email;
