
import React, { useCallback, useMemo, useState, useEffect,useRef} from "react";
import Chart from 'react-apexcharts';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Box, Button, Container, Stack, SvgIcon, Typography, Grid, Snackbar, TextField, Link } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Accordion from '@mui/material/Accordion';
import RadioGroup from "@mui/material/RadioGroup";
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Modal from "@mui/material/Modal";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Card from '@mui/material/Card';
import { useReactToPrint } from 'react-to-print'
import DialogTitle from '@mui/material/DialogTitle';
import { useAlert } from "react-alert";
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Checkbox from '@mui/material/Checkbox';
import Headroom from "react-headroom";
import AuthorizationRegistration from "../AuthorizationRegistration";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import VOBPAGE from "../DashForms/Vobpage";
import VOBPAGE1 from "../DashForms/Vobpage1";
import OSAPAGE from "../DashForms/Osapage";
import Letter from "../DashForms/Letter";
import Disclaimer from "../DashForms/Disclaimer";
import Prior_auth from "../DashForms/Prior_auth";
import PrintIcon from '@mui/icons-material/Print';
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { makeStyles } from "@material-ui/core/styles";
import Draggable from "react-draggable";

function FromsTemp(props) {

  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const fields = [
    'id',
    'documentName',
    'base64',
  ]
  
  const defValues = {
    id: 0,
    documentName:'',
    base64: '',
    
  }
  const alert = useAlert();
    const style = {
        position: "absolute",
        top: "45%",
        left: "55%",
        transform: "translate(-50%, -50%)",
        width: 1000,
        height: 550,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        // overflow: "auto",
        // overflows: "hide",
        // zIndex: "10",
        customHeader: {
            backgroundColor: 'black', // Apply your desired background color
            color: 'white', // Apply the desired text color
            fontWeight: 'bold',
        },
    };
    const style1 = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 1000,
        height: 500,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        // overflow: "auto",
        // overflows: "hide",
        // zIndex: "10",
        customHeader: {
            backgroundColor: 'black', // Apply your desired background color
            color: 'white', // Apply the desired text color
            fontWeight: 'bold',
        },
    };
    const useStyles = makeStyles((theme) => ({
      form: {
        padding: theme.spacing(1),
      },
    }));
    const classes = useStyles();
    const {
      register,
      control,
      handleSubmit,
      setValue,
      getValues,
      formState: { errors },
    } = useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: false,
      shouldUseNativeValidation: false,
      delayError: undefined,
      // defaultValues: defValues,
    });
    const [openModal, setOpenModal] = React.useState(false);
    const handleCloseModal = () => setOpenModal(false);
    const [osapage, setOsapage] = React.useState(false);
    const [OpenLetter, setOpenLetter] = React.useState(false);
    const [OpenpriorAuth, setOpenpriorAuth] = React.useState(false);
    const [vobpage, setVobpage] = React.useState(false);
    const [vobpagepatientid, setVobpagepatientid] = useState();
    const [vobpage1, setVobpage1] = React.useState(false);
    const [opennewDisclaimer, setOpenDisclaimer] = React.useState(false);
    const [file, setFile] = useState(false);
    const [providerNotes, setProviderNotes] = useState([]);
    const [docName, setDocName] = useState("");
    const [refresh, setRefresh] = React.useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [activeDocName, setActiveDocName] = useState('');
    const [doc, setDoc] = useState();
    const [showScreen, setShowScreen] = useState(false);
    const [show, setShow] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [images, setImages] = React.useState(null);
    const [base64Data, setBase64Data] = useState([])
    useEffect(() => {
      // POST request using axios inside useEffect React hook
  
      axios
        .get(`${process.env.REACT_APP_API_URL}/ProviderNotes/getProviderNotes`, {
          headers,
        })
        .then((response) => {
          // console.log('response.data: ', response.data)
          setProviderNotes(response);
          setFile(true);
          setDocName("");
          // const Doc = (response.data.documentName)
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
  
      // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, [refresh]);
    
    const setDocument = (ar) => {
      setDoc(ar);
    };
    const handleOpenScreen=() => {
      setShowScreen(true);
    }
   
    const onSubmit = (data) => {
      setSubmitting(true);
      const postData = data;
      
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/ProviderNotes/addProviderNotes`,
          postData,
          {
            headers,
          }
        )
        .then((response) => {
          alert.success("Record saved successfully.", {
            type: "success",
            onClose: () => {
              setRefresh(!refresh);
              setSubmitting(false);
  
              // if (Patientid != null || Patientid.length > 0) {
              //   props.refresh()
              // } else {
              //   history.push('/CareTeam')
              // }
            },
          });
        })
        .catch((error) => {
          setSubmitting(false);
          alert.success(`Error ${error.message}`, {
            type: "error",
          });
        });
    };
    // const onImageChange = (e) => {
    //   setImages(URL.createObjectURL(e.target.files[0]));
    //   const file = e.target.files[0];
    //   setValue("documentName", file.name);
    //   // console.log('ff:', file.name)
    //   setDocName(file.name);
    //   convertBase64(file);
     
    // }; 

    const onImageChange = (e) => {
      const file = e.target.files[0];
      setDocName(file.name);
      setValue("documentName", file.name);
      convertPdfToBase64(file);
      // convertPdfToBase64(file)
      //   .then((base64Data) => {
      //     setValue("base64", base64Data);
      //     setFile(true);
      //   })
      //   .catch((error) => {
      //     console.error('Error converting PDF to base64:', error);
      //   });
    };
  
    const convertPdfToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
    
        fileReader.onload = () => {
          const base64String = fileReader.result.split(',')[1];
          const fileType = file.type;
          const base64WithFileType = `data:${fileType};base64,${base64String}`;
          resolve(base64WithFileType);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      }).then((base64WithFileType) => {
        setValue('base64', base64WithFileType);
        console.log("base64", base64WithFileType);
      });
    };

      const handleChangeforfile = async files => {
        const newFilenames = []
        const newBase64Data = []
    
        for (let i = 0; i < files.length; i++) {
          const file = files[i]
          const base64 = await fileToBase64(file)
          newFilenames.push(file.name)
         
          newBase64Data.push(base64)
        }
    
        setDocName(newFilenames)
        // setValue("documentName", newFilenames);
        setBase64Data(newBase64Data)
        // setdocError(false)
      }
      const fileToBase64 = file => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => resolve(reader.result)
          reader.onerror = error => reject(error)
        })
      }
   
    
   


    const openvobpage = () => {
        setVobpage(true);
        // reset()
    };
    const openvobpage1 = () => {
        setVobpage1(true);
        // setPatId(patientId)
        // reset()
    };
    const openosapage = () => {
        setOsapage(true);
        // reset()
    };
    const openLetter = () => {
        setOpenLetter(true);
        // reset()
    };
    const openpriorAuth = () => {
        setOpenpriorAuth(true);
        setOpenpriorAuth(true);
        // reset()
    };
    const openDisclaimer = () => {
        setOpenDisclaimer(true);
        // reset()
    };


    const closevobpage = () => {
        setVobpage(false);
        // reset()
    };
    const closevobpage1 = () => {
        setVobpage1(false);
        // reset()
    };
    const closeosapage = () => {
        setOsapage(false);
        // reset()
    };
    const closeLetter = () => {
        setOpenLetter(false);
        // reset()
    };

    const closepriorAuth = () => {
        setOpenpriorAuth(false);
        // reset()
    };
    const closeDisclaimer = () => {
        setOpenDisclaimer(false);
        // reset()
    };
    // const handlePrint = () => {
    //     window.print();
    // }
    const styleDetailsPdf = {
      width: 900,
      height: 550,
      bgcolor: "white",
      position: "absolute",
      top: 0,
      left: 0,
      border: "2px solid #3C7F6B",
      bottom: 30,
      right: 0,
      margin: "auto",
      marginTop: "20px",
      paddingBottom: "10px",
      padding: "0px",
      overflow: "auto",
      zIndex: "10",
      // tabIndex: "-1",
    };
    const printRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });
  const printRef1= useRef()
  const handlePrint1 = useReactToPrint({
    content: () => printRef1.current,
  });
  const printRef2= useRef()
  const handlePrint2 = useReactToPrint({
    content: () => printRef2.current,
  });
  const printRef3= useRef()
  const handlePrint3 = useReactToPrint({
    content: () => printRef3.current,
  });
  const printRef4= useRef()
  const handlePrint4 = useReactToPrint({
    content: () => printRef4.current,
  });
  const printRef5= useRef()
  const handlePrint5 = useReactToPrint({
    content: () => printRef5.current,
  });
  const printRef6= useRef()
  const handlePrint6 = useReactToPrint({
    content: () => printRef6.current,
  });

 
  const border = {
    border: 2,
    background: '#356F60',
    fontSize: '13.6px !important',
    color: 'black !important',
    borderColor: '#C1C1C1',
    borderRadius:'10px'
  }

    return (
        <React.Fragment>
            <Grid style={{marginTop: '5rem', overflow:'auto', marginBottom:'4rem' }}>
            <Grid container rowSpacing={3} >
              <Grid item xs={0.5}> </Grid>
            <Grid  item xs={10.5}>
            <Typography variant='h5' color='black' fontWeight="bold">FORMS</Typography>
            </Grid>
            <Grid item xs={1}> </Grid>
            </Grid>
             {/* //////////  VOB CHECKLIST*/}
            <Grid container style={{marginTop:'20px'}}>
            <Grid item xs={0.5}> </Grid>
            <Grid item xs={11}> 
            <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                         <Grid container >
                          
                            <Grid xs={10}>
                            <h4>VOB CHECKLIST</h4>
                            </Grid>
                            <Grid xs={2}>
                        <PrintIcon
                             onClick={handlePrint2}
                              fontSize="large"
                             style={{
                                color: '#3C7F6B',
                                paddingRight: '5px',
                                 cursor: 'pointer',
                                float:'right'
                                }}
                              ></PrintIcon>
                                 </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Grid container columnSpacing={1} style={{ color: 'black',marginTop:'20px' }} ref={printRef2}>
                    <Grid container 
                    sx={border}
                    >
                   <Grid item xs={1}></Grid>
                   <Grid item xs={10}>
                     <h3 style={{textAlign:'center',fontWeight:'bold',color:'white'}}> DME VOB/ Prior-auth checklist (E0486)</h3>
                   </Grid>
                   <Grid item xs={1}></Grid>
                    </Grid>
                <Grid item xs={0.5}></Grid>
                <Grid item xs={11} >
                    <h4 style={{ textAlign: 'center', marginTop: '10px' }}>(Carrier will immediately begin a clinical review & all info will be needed to complete)

                    </h4>
                </Grid>
                <Grid item xs={0.5}></Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={0.3}>
                    <input
                        name='patMedIdCard'
                        size='small'
                        type='checkbox'
                        id='patMedIdCard'
                        style={{
                            marginTop: '30px'
                        }}

                    />
                </Grid>
                <Grid item xs={10.7} style={{ marginTop: '30px',  }}>
                    <h6 >  Patient Medical ID card (front & back)</h6>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={0.3}>
                    <input
                        name='vob'
                        size='small'
                        type='checkbox'
                        id='vob'
                        style={{
                            marginTop: '15px'
                        }}

                    />
                </Grid>
                <Grid item xs={10.7} style={{ marginTop: '10px',  }}>
                    <h6 > VOB/ Prior-auth Request form</h6>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={11}>
                    <h6 > ● Name, Date of Birth & address MUST be correct or the carrier may reject any submitted requests.</h6>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={0.3}>
                    <input
                        name='patientQuestionnaire'
                        size='small'
                        type='checkbox'
                        id='patientQuestionnaire'
                        style={{
                            marginTop: '15px'
                        }}

                    />
                </Grid>
                <Grid item xs={10.7} style={{ marginTop: '10px',  }}>
                    <h6 >The patient questionnaire/ screener must be completed in its entirety</h6>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={0.3}>
                    <input
                        name='financialAgreement'
                        size='small'
                        type='checkbox'
                        id='financialAgreement'
                        style={{
                            marginTop: '15px'
                        }}

                    />
                </Grid>
                <Grid item xs={10.7} style={{ marginTop: '10px',  }}>
                    <h6> Financial agreement (recommended for your records only)</h6>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={0.3}>
                    <input
                        name='referringProviderNotes'
                        size='small'
                        type='checkbox'
                        id='referringProviderNotes'
                        style={{
                            marginTop: '15px'
                        }}

                    />
                </Grid>
                <Grid item xs={10.7} style={{ marginTop: '10px',  }}>
                    <h6>Face-to-face referring provider notes before the Sleep test</h6>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={10}>
                    <h6>(Medicare and Medicare Advantage plans must be IN-Person)</h6>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={0.3}>
                    <input
                        name='baselineSleepTest'
                        size='small'
                        type='checkbox'
                        id='baselineSleepTest'
                        style={{
                            marginTop: '15px'
                        }}

                    />
                </Grid>
                <Grid item xs={10.7} style={{ marginTop: '10px',  }}>
                    <h6>The baseline sleep test needs to be signed</h6>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={11}>
                    <h6>● AHI must be between 5-29 (under 5 carriers will not approve as this is not considered valid sleep apnea)</h6>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={0.3}>
                    <input
                        name='cpap'
                        size='small'
                        type='checkbox'
                        id='cpap'
                        style={{
                            marginTop: '15px'
                        }}

                    />
                </Grid>
                <Grid item xs={10.7} style={{ marginTop: '10px',  }}>
                    <h6>AHI=30 or above must have tried CPAP</h6>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={11}>
                    <h6>● Patients with severe OSA must trial CPAP before an Oral appliance may be considered. If the patient can

                    </h6>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={11}>
                    <h6>
                        NOT tolerate CPAP, the treating physician MUST document this.
                    </h6>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={11}>
                    <h6>
                        ● Add to your progress notes that the patient has failed and WHY they failed CPAP.

                    </h6>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={0.3}>
                    <input
                        name='lomn'
                        size='small'
                        type='checkbox'
                        id='lomn'
                        style={{
                            marginTop: '15px'
                        }}

                    />
                </Grid>
                <Grid item xs={10.7} style={{ marginTop: '10px',  }}>
                    <h6>LOMN (Letter of Medical Necessity)</h6>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={11}>
                    <h6>● This may come from the sleep test facility Dr. or the patient's Primary care Dr.</h6>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={0.3}>
                    <input
                        name='progressNotes'
                        size='small'
                        type='checkbox'
                        id='progressNotes'
                        style={{
                            marginTop: '15px'
                        }}

                    />
                </Grid>
                <Grid item xs={10.7} style={{ marginTop: '10px',  }}>
                    <h6> Progress notes</h6>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={11}>
                    <h6>● Initial consult/ SOAP note with the patient.</h6>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={0.3}>
                    <input
                        name='additionalInformation'
                        size='small'
                        type='checkbox'
                        id='additionalInformation'
                        style={{
                            marginTop: '15px'
                        }}

                    />
                </Grid>
                <Grid item xs={10.7} style={{ marginTop: '10px',  }}>
                    <h6>  Any additional information you have or think would be helpful to obtain authorization?</h6>
                </Grid>
                <Grid item xs={12} lg={12} sm={12}>
                </Grid>

            </Grid>

                      

                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={0.5}> </Grid>

            </Grid>
          {/* //////////  AUTH-REQUEST*/}
            <Grid container style={{marginTop:'20px'}}>
            <Grid item xs={0.5}> </Grid>
            <Grid item xs={11}> 
            <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                         <Grid container>
                          
                            <Grid xs={10}>
                            <h4>AUTH-REQUEST</h4>
                            </Grid>
                            <Grid xs={2}>
                        <PrintIcon
                             onClick={handlePrint}
                              fontSize="large"
                             style={{
                                color: '#3C7F6B',
                                paddingRight: '5px',
                                 cursor: 'pointer',
                                float:'right'
                                }}
                              ></PrintIcon>
                                 </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Grid container  sx={{ flexGrow: 1, rowGap: "24px" }} style={{marginTop:'20px'}} ref={printRef}>
                    <Grid container 
                    sx={border}
                    >
                   <Grid item xs={1}></Grid>
                   <Grid item xs={10}>
                     <h3 style={{textAlign:'center',fontWeight:'bold',color:'white'}}> VOB/ Prior-auth Request</h3>
                   </Grid>
                   <Grid item xs={1}></Grid>
                    </Grid>
                  

    
      <Grid xs={12}>
        <Typography
          level="h2"
          style={{
            padding: "auto",
            textAlign: "center",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          Provider rendering services (name of Dr):
        </Typography>
      </Grid>
      <Grid item xs={4}></Grid>
      <Grid item xs={6}>
        <TextField
          variant="standard"
          placeholder="Type in here…"
          style={{
            width: "22em",
          }}
          size="small"
          fullWidth
          name="providerName"
          type="text"
        />


      </Grid>

      <Grid item xs={2}></Grid>

      <Grid item xs={1}></Grid>
      <Grid xs={2}>
        <Typography style={{ fontSize: "18px" }}>Patient Name:</Typography>
      </Grid>
      <Grid xs={4}>

        <TextField
          variant="standard"
          placeholder=" Input Name"
          style={{
            width: "22em",
          }}
          size="small"
          fullWidth
          name="patientName"
          type="text"
        />
      </Grid>
      <Grid xs={2}>
        <Typography style={{ fontSize: "18px" }}>DOB:</Typography>
      </Grid>
      <Grid xs={3}>
        <TextField
          variant="standard"
          style={{
            width: "75%",
          }}
          size="small"
          fullWidth
          name="dob"
          type="date"
        />

      </Grid>
      <Grid item xs={1}></Grid>
      <Grid xs={11}>
        <Typography style={{ fontSize: "18px" }}>Address:</Typography>
        {/* <Controller
          name="address"
          {...register("address")}
          // defaultValue={email}

          control={control}
          onChange={value => setAddress('address', value)}
          render={({ field }) => (
            <TextField
              variant="standard"
              placeholder=" Input Address"
              // value={email ? email : ""}
              style={{
                width: "75%",
              }}
              size="small"
              fullWidth
              
              name="address"
              type="text"
              value={aDDress}
              onChange={(e) => {
                setValue("address", e.target.value);
                setAddress(e.target.value);
              }}
            />
          )}
        /> */}

        <TextField size="small" variant="standard"  fullWidth />

      </Grid>

      {/* <Grid item xs={1}></Grid>
      <Grid xs={11}>
        <Typography style={{ fontSize: "18px" }}>#ID:</Typography>
        <Controller
          name="id"
          {...register("id")}
          onChange={value => setId('id', value)}
          control={control}
          render={({ field }) => (
            <TextField
              variant="standard"
              placeholder=" Input ID Code"
              style={{
                width: "30%",
              }}
              size="small"
              fullWidth
              
              name="id"
              type="text"
            />
          )}
        />
      </Grid> */}
      <Grid item xs={1}></Grid>
      <Grid item xs={11}>
        <Typography level="body 1">
          (Please send the insurance card as well in case we need to reference)
        </Typography>
      </Grid>
      <Grid xs={12}>
        <Typography
          level="h1"
          style={{
            padding: "auto",
            textAlign: "center",
            fontSize: "35px",
            fontWeight: "bold",
            textDecoration: "underline",
          }}
        >
          Codes you wish to have verified
        </Typography>
      </Grid>

      <Grid item xs={0.1} ></Grid>

      <Grid container >

        <Grid item xs={0.5} ></Grid>

        <Grid item xs={2}>
          <Typography style={{ fontSize: "16px", textDecoration: "underline", marginLeft: '1.5rem' }}>
            Procedure code:
          </Typography>
        </Grid>


        <Grid item xs={1}>
          <TextField
            size='small'
            styles={{ control: provided => ({ ...provided, width: '120px' }) }} // Set width here

          />

        </Grid>

        <Grid item xs={2.5}>
          <Typography style={{ fontSize: "16px", textDecoration: "underline", marginLeft: '70px' }}>
            Diagnosis code:
          </Typography>
        </Grid>


        <Grid item xs={1}>

          <TextField
            size='small'
            styles={{ control: provided => ({ ...provided, width: '120px' }) }} // Set width here

          />

        </Grid>

        <Grid item xs={0.5} ></Grid>


        <Grid item xs={1.5}>
          <Typography style={{ fontSize: "16px", textDecoration: "underline", marginLeft: '1.5rem' }}>
            Charge $
          </Typography>
        </Grid>

        <Grid item xs={1}>

          <TextField
            size='small'
            styles={{ control: provided => ({ ...provided, width: '120px' }) }} // Set width here
          />
        </Grid>




      </Grid>

      <Grid container >

        <Grid item xs={0.5} ></Grid>

        <Grid item xs={2}>
          <Typography style={{ fontSize: "16px", textDecoration: "underline", marginLeft: '1.5rem' }}>
            Procedure code:
          </Typography>
        </Grid>


        <Grid item xs={1}>

          <TextField
            size='small'
            styles={{ control: provided => ({ ...provided, width: '120px' }) }} // Set width here
          />
        </Grid>

        <Grid item xs={2.5}>
          <Typography style={{ fontSize: "16px", textDecoration: "underline", marginLeft: '70px' }}>
            Diagnosis code:
          </Typography>
        </Grid>


        <Grid item xs={1}>

          <TextField
            size='small'
            styles={{ control: provided => ({ ...provided, width: '120px' }) }} // Set width here
          />

        </Grid>

        <Grid item xs={0.5} ></Grid>


        <Grid item xs={1.5}>
          <Typography style={{ fontSize: "16px", textDecoration: "underline", marginLeft: '1.5rem' }}>
            Charge $
          </Typography>
        </Grid>

        <Grid item xs={1}>

          <TextField
            size='small'
            styles={{ control: provided => ({ ...provided, width: '120px' }) }} // Set width here
          />
        </Grid>




      </Grid>

      <Grid container >

        <Grid item xs={0.5} ></Grid>

        <Grid item xs={2}>
          <Typography style={{ fontSize: "16px", textDecoration: "underline", marginLeft: '1.5rem' }}>
            Procedure code:
          </Typography>
        </Grid>


        <Grid item xs={1}>
        
              <TextField
                size='small'
                styles={{ control: provided => ({ ...provided, width: '120px' }) }} // Set width here
              />
        </Grid>

        <Grid item xs={2.5}>
          <Typography style={{ fontSize: "16px", textDecoration: "underline", marginLeft: '70px' }}>
            Diagnosis code:
          </Typography>
        </Grid>


        <Grid item xs={1}>
              <TextField
                size='small'
                styles={{ control: provided => ({ ...provided, width: '120px' }) }} // Set width here
              />
        </Grid>

        <Grid item xs={0.5} ></Grid>


        <Grid item xs={1.5}>
          <Typography style={{ fontSize: "16px", textDecoration: "underline", marginLeft: '1.5rem' }}>
            Charge $
          </Typography>
        </Grid>

        <Grid item xs={1}>
              <TextField
                size='small'
                styles={{ control: provided => ({ ...provided, width: '120px' }) }} // Set width here
              />

        </Grid>




      </Grid>

      <Grid item xs={0.5}></Grid>
      <Grid item xs={11.5}>
        <Typography
          level="body 1"
          style={{
            fontSize: "18px",
            fontWeight: "bold",
            paddingTop: "12px",
          }}
        >
          *Please add any additional info (clinics, letters, questionnaires,s
          etc.) that may be useful for the approval process
        </Typography>
      </Grid>
      <Grid xs={12}>
        <Typography
          level="body 1"
          style={{
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Additional notes:
        </Typography>
      </Grid>
      <Grid item xs={0.5}></Grid>
      <Grid xs={11.5}>
            <TextareaAutosize
              aria-Typography="minimum height"
              minRows={5}
              style={{
                width: "100%",
                borderRadius: "8px",
                border: "2px solid #black",
                height: "100px", // set a fixed height here
                overflow: "scroll",
              }}
              size="small"
              fullWidth
              name="notes"
              type="text"
            />
      </Grid>
      <Grid item xs={0.5}></Grid>
      <Grid item xs={11.5}>
        <Typography
          level="body 1"
          style={{
            fontSize: "18px",
            fontWeight: "bold",
            paddingTop: "12px",
          }}
        >
          *Once completed please fax information to FX#844.791.7082 Or Email to
          our secure email address of:
          <Link> LuxBilling@protonmail.com </Link>
        </Typography>
      </Grid>
      <Grid xs={12}>
      </Grid>
    </Grid>

                      

                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={0.5}> </Grid>

            </Grid>
               
                {/* ////////// SCREENER */}
                <Grid container style={{marginTop:'20px'}}>
                <Grid item xs={0.5}> </Grid>
                <Grid item xs={11}> 
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Grid container>
                            <Grid xs={10}>
                            <h4>SCREENER</h4>
                            </Grid>
                            <Grid xs={2}>
                        <PrintIcon
                             onClick={handlePrint1}
                              fontSize="large"
                             style={{
                                color: '#3C7F6B',
                                paddingRight: '5px',
                                 cursor: 'pointer',
                                float:'right'
                                }}
                              ></PrintIcon>
                                 </Grid>
                        </Grid>
                       
                    
                    </AccordionSummary>
                    <AccordionDetails>
                    
                    <Grid container columnSpacing={1} style={{marginTop:'20px'}} ref={printRef1}>

                    <Grid container 
                    sx={border}
                    >
                   <Grid item xs={1}></Grid>
                   <Grid item xs={10}>
                     <h3 style={{textAlign:'center',fontWeight:'bold',color:'white'}}>  Snoring and obstructive sleep apnea (OSA) Screener{" "}</h3>
                   </Grid>
                   <Grid item xs={1}></Grid>
                    </Grid>
                    <Grid container columnSpacing={1}>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          {/* <h1
            style={{
              fontWeight: "bold",
              textAlign: "center",
              color: "black",
            }}
          >
            Snoring and obstructive sleep apnea (OSA)
          </h1> */}
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          {/* <h1
            style={{
              fontWeight: "bold",
              textAlign: "center",
              color: "black",
            }}
          >
            Screener{" "}
          </h1> */}
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={7}>
          <h6 style={{ textAlign: "center", marginTop:'2rem' }}>
            Snoring and daytime sleepiness can have a profound impact on quality
            of life:{" "}
          </h6>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={2} style={{ marginTop: "10px" }}>
          <h6 style={{ fontWeight: "bold" }}>• Daytime sleepiness </h6>
        </Grid>
        <Grid item xs={9} style={{ marginTop: "10px" }}>
          <h6 style={{ marginLeft: "-25px" }}>
            {" "}
            - less effectiveness at work and increased risk of accidents
          </h6>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={2.5}>
          <h6 style={{ fontWeight: "bold" }}>• Reduced energy-poor </h6>
        </Grid>
        <Grid item xs={8.5}>
          <h6 style={{ marginLeft: "-18px" }}>
            motivation to exercise and weight gain
          </h6>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={2}>
          <h6 style={{ fontWeight: "bold" }}>• Relationship issues </h6>
        </Grid>
        <Grid item xs={9}>
          <h6 style={{ marginLeft: "-25px" }}>
            - sleeping in different bedrooms, reduced sex life, and higher
            stress levels
          </h6>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={2}>
          <h6 style={{ fontWeight: "bold" }}>• Hypertension </h6>
        </Grid>
        <Grid item xs={9}>
          <h6 style={{ marginLeft: "-60px" }}>
            - those who snore or suffer from OSA have an elevated risk of high
            blood pressure
          </h6>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={11} style={{ marginTop: "10px" }}>
          <h6>
            OSA is a serious condition in which a person stops breathing (or
            suffers extremely low oxygen levels) while asleep. It often
          </h6>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={11}>
          <h6>occurs in conjunction with snoring.</h6>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={2} style={{ marginTop: "10px" }}>
          <h5>Patient name:</h5>
        </Grid>
        <Grid item xs={4} style={{ marginLeft: "-40px" }}>
        <TextField size="small" variant="standard"  fullWidth/>
        </Grid>
        <Grid item xs={5}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={2} style={{ marginTop: "10px" }}>
          <h5>Insurance:</h5>
        </Grid>
        <Grid item xs={4} style={{ marginLeft: "-40px" }}>
        <TextField
                  autoComplete="off"
                    variant="standard"
                    size='small'
                    placeholder=' Search Insurance Name'
                    
                    fullWidth
                  />
        </Grid>
        <Grid item xs={5} >
          
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1} style={{ marginTop: "10px" }}>
          <h5>Address:</h5>
        </Grid>
        <Grid item xs={2} style={{ marginLeft: "-6px" }}>
        {/* <Controller
          name="address"
          {...register("address")}
          // defaultValue={email}

          control={control}
          onChange={value => setAddresses('address', value)}
          render={({ field }) => (
            <TextField
              variant="standard"
              placeholder=" Input Address"
              // value={email ? email : ""}
              style={{
                width: "75%",
              }}
              size="small"
              fullWidth
              
              name="address"
              type="text"
              value={Address}
              onChange={(e) => {
                setValue("address", e.target.value);
                setAddresses(e.target.value);
              }}
            />
          )}
        /> */}
                    <TextField size="small" variant="standard"  fullWidth />

        </Grid>
        <Grid item xs={1} style={{ marginTop: "10px" }}>
          <h5>City</h5>
        </Grid>
        <Grid item xs={2} style={{ marginLeft: "-40px" }}>
              <TextField size="small" variant="standard"  fullWidth />
        </Grid>
        <Grid item xs={1} style={{ marginTop: "10px" }}>
          <h5>State</h5>
        </Grid>
        <Grid item xs={2} style={{ marginLeft: "-30px" }}>
        <TextField size="small" variant="standard"  fullWidth />
        </Grid>
        <Grid item xs={1} style={{ marginTop: "10px" }}>
          <h5>Zip</h5>
        </Grid>
        <Grid item xs={2} style={{ marginLeft: "-40px" }}>
        <TextField size="small" variant="standard"  fullWidth />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={2} style={{ marginTop: "10px" }}>
          <h5>Phone#</h5>
        </Grid>
        <Grid item xs={4} style={{ marginLeft: "-70px" }}>
        <TextField size="small" variant="standard"  fullWidth />
        </Grid>
        <Grid item xs={5}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={10} style={{ marginTop: "20px" }}>
          <h6 style={{ textAlign: "center" }}>
            The following questions relate to your lifestyle and general health.
            Please indicate whether you suffer from any of the{" "}
          </h6>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={7}>
          <h6 style={{ textAlign: "center" }}>
            below, providing further details when the answer is yes.{" "}
          </h6>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={2} style={{ marginTop: "10px" }}>
          <h5>Heart problems</h5>
        </Grid>
        <Grid item xs={3}>
          <div>
            <input
              type="radio"
              name="heartProblems"
              // {...register("heartProblems")}
              style={{ marginLeft: "20px", marginTop: "12px" }}
            />{" "}
            Yes
            <input
              type="radio"
             style={{ marginLeft: "20px" }}
            />{" "}
            No
          </div>
        </Grid>

        <Grid item xs={4} style={{ marginLeft: "-40px" }}>
        <TextField size="small" variant="standard"  fullWidth />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={2} style={{ marginTop: "10px" }}>
          <h5>High blood pressure</h5>
        </Grid>
        <Grid item xs={3}>
          <div>
            <input
              type="radio"
              style={{ marginLeft: "20px", marginTop: "16px" }}
            />{" "}
            Yes
            <input
              type="radio"
               style={{ marginLeft: "20px" }}
            />{" "}
            No
          </div>

        </Grid>

        <Grid item xs={4} style={{ marginLeft: "-40px" }}>
        <TextField size="small" variant="standard"  fullWidth />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={2} style={{ marginTop: "10px" }}>
          <h5>Diabetes </h5>
        </Grid>
        <Grid item xs={3}>
          <div>
            <input
              type="radio"
              style={{ marginLeft: "20px" }}
            />{" "}
            Yes
            <input
              type="radio"
              style={{ marginLeft: "20px" }}
            />{" "}
            No
          </div>
        </Grid>
        <Grid item xs={4} style={{ marginLeft: "-40px" }}>
        <TextField size="small" variant="standard"  fullWidth />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={2} style={{ marginTop: "10px" }}>
          <h5>Thyroid syndrome</h5>
        </Grid>
        <Grid item xs={3}>
          <div>
            <input
              type="radio"
              style={{ marginLeft: "20px" }}
            />{" "}
            Yes
            <input
              type="radio"
              style={{ marginLeft: "20px" }}
            />{" "}
            No
          </div>
        </Grid>
        <Grid item xs={4} style={{ marginLeft: "-40px" }}>
        <TextField size="small" variant="standard"  fullWidth />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={5} style={{ marginTop: "10px" }}>
          <h5>Do you take any prescribed medicines? </h5>
        </Grid>
        <Grid item xs={2}>
          {/* <div>
            <input type="radio" value="Yes" name="c5" 
            onChange={(e) => {setprescribedMedicines(e.target.value)
              console.log("Value ......." , e.target.value)}}/> Yes
            <input type="radio" value="No" name="c5" style={{marginLeft:'10px'}}
            onChange={(e) => {setprescribedMedicines(e.target.value)
              console.log("Value ......." , e.target.value)}}/> No
          </div> */}
        </Grid>
        <Grid item xs={3} style={{ marginLeft: "-30px" }}>
        <TextField size="small" variant="standard"  fullWidth />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={2} style={{ marginTop: "10px" }}>
          <h5>Please list:</h5>
        </Grid>
        <Grid item xs={5} style={{ marginLeft: "-40px" }}>
        <TextField size="small" variant="standard"  fullWidth />
        </Grid>
        <Grid item xs={2} style={{ marginTop: "10px" }}>
          {" "}
          <h5>(attach if needed)</h5>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={3} style={{ marginTop: "10px" }}>
          <h5>Alcohol consumption</h5>
        </Grid>
        <Grid item xs={4} style={{ marginLeft: "-40px" }}>
        <TextField size="small" variant="standard"  fullWidth />
        </Grid>
        <Grid item xs={2} style={{ marginTop: "10px" }}>
          {" "}
          <h5>units/week</h5>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={3} style={{ marginTop: "10px" }}>
          <h5>Smoking level</h5>
        </Grid>
        <Grid item xs={4} style={{ marginLeft: "-40px" }}>
        <TextField size="small" variant="standard"  fullWidth />
        </Grid>
        <Grid item xs={2} style={{ marginTop: "10px" }}>
          {" "}
          <h5>no/day</h5>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={7} style={{ marginTop: "10px" }}>
          <h6>
            Please indicate if you have suffered from any of the conditions
            below:
          </h6>
        </Grid>
        <Grid item xs={4}></Grid>

        <Grid container style={{ marginLeft: "8px", marginBottom: "15px" }}>
          <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            <Checkbox/>
            {/* <Checkbox/> /> */}
          </Grid>
          <Grid item xs={4} style={{ marginTop: "10px", marginLeft: "-30px" }}>
            <h6> Sleepiness that interferes with daily activities</h6>
          </Grid>
          <Grid item xs={0.6}></Grid>
          <Grid item xs={1} style={{ marginLeft: "-15px" }}>
            <Checkbox/>

          </Grid>
          <Grid item xs={4} style={{ marginTop: "10px", marginLeft: "-40px" }}>
            <h6> Excessive daytime sleepiness</h6>
          </Grid>
          <Grid item xs={0.5}></Grid>
        </Grid>

        <Grid item xs={1}></Grid>
        <Grid item xs={1}>
          <Checkbox/>
        </Grid>
        <Grid item xs={4} style={{ marginTop: "10px" }}>
          <h6> Impaired cognition </h6>
        </Grid>
        <Grid item xs={1}>
          <Checkbox/>
           
        </Grid>
        <Grid item xs={4} style={{ marginTop: "10px" }}>
          <h6>Mood disorders</h6>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}>
          <Checkbox/>
           
        </Grid>
        <Grid item xs={4} style={{ marginTop: "10px" }}>
          <h6>Insomnia</h6>
        </Grid>
        <Grid item xs={1}>
          <Checkbox/>
           
        </Grid>
        <Grid item xs={4} style={{ marginTop: "10px" }}>
          <h6>Hypertension</h6>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}>
          <Checkbox/>
          

        </Grid>
        <Grid item xs={4} style={{ marginTop: "10px" }}>
          <h6> Ischemic heart disease</h6>
        </Grid>
        <Grid item xs={1}>
          <Checkbox/>
          
        </Grid>
        <Grid item xs={4} style={{ marginTop: "10px" }}>
          <h6>History of stroke</h6>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}>
          <Checkbox/>
           
        </Grid>
        <Grid item xs={7} style={{ marginTop: "10px" }}>
          <h6>
            {" "}
            Inappropriate daytime napping (e.g., during driving, conversation,or
            eating)
          </h6>
        </Grid>

        <Grid item xs={3}></Grid>

        <Grid item xs={2}></Grid>
        <Grid item xs={8} style={{ marginTop: "30px" }}>
          <h4 style={{ fontWeight: "bold", textAlign: "center" }}>
            Have you tried a Nasal CPAP (circle one) YES - No{" "}
          </h4>
        </Grid>
        <Grid item xs={2}></Grid>

        <Grid item xs={1}></Grid>
        <Grid item xs={3.5} style={{ marginTop: "10px" }}>
          <h6>If Yes- Are you currently using CPAP?</h6>
        </Grid>

        <Grid item xs={2} style={{ marginTop: "10px" }}>
          <div>
            <input
              type="radio"
              style={{ marginLeft: "20px" }}
            />{" "}
            Yes
            <input
              type="radio"
               style={{ marginLeft: "20px" }}
            />{" "}
            No
          </div>
        </Grid>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={5} style={{ marginTop: "10px" }}>
          <h6>If you are NOT using CPAP Why? (checkbelow)</h6>
        </Grid>

        <Grid item xs={1}></Grid>
        <Grid item xs={4} style={{ marginTop: "8px" }}>
          <h6>If Yes using CPAP How many days a week?</h6>
        </Grid>
        <Grid item xs={1} style={{ marginLeft: "-30px" }}>
            <TextField size="small" variant="standard"  fullWidth />
          </Grid>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={1}>
        <TextField size="small" variant="standard"  fullWidth />
        </Grid>
        <Grid item xs={4.5} style={{ marginTop: "10px" }}>
          <h6>Device causing claustrophobia or panic attacks</h6>
        </Grid>

        <Grid item xs={1}></Grid>
        <Grid item xs={1}>
        <TextField size="small" variant="standard"  fullWidth />
        </Grid>
        <Grid item xs={3.8} style={{ marginTop: "10px" }}>
          <h6>
            The CPAP device irritated nasal passages causing sinus infections{" "}
          </h6>
        </Grid>
        <Grid item xs={0.2}></Grid>
        <Grid item xs={1}>
        <TextField size="small" variant="standard"  fullWidth />
        </Grid>
        <Grid item xs={5} style={{ marginTop: "10px" }}>
          <h6>GI/ Stomach/Intestinal issues</h6>
        </Grid>

        <Grid item xs={1}></Grid>
        <Grid item xs={3} style={{ marginTop: "10px" }}>
          <h6>Other (state reason):</h6>
        </Grid>
        <Grid item xs={2.5} style={{ marginLeft: "-80px" }}>
        <TextField size="small" variant="standard"  fullWidth />
        </Grid>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={1}>
        <TextField size="small" variant="standard"  fullWidth />
        </Grid>
        <Grid item xs={4} style={{ marginTop: "10px" }}>
          <h6>Latex allergy</h6>
        </Grid>

        <Grid item xs={1}></Grid>
        <Grid item xs={0.3} style={{ marginTop: "10px" }}>
          <h6 style={{ fontWeight: "bold" }}>➔</h6>
        </Grid>
        <Grid item xs={2.7} style={{ marginTop: "10px",  }}>
          <h6>Previous sleep study</h6>
        </Grid>
        <Grid item xs={3} style={{ marginTop: "10px" }}>
          <div>
            <input
              type="radio"
                style={{ marginLeft: "20px" }}
            />{" "}
            Yes
            <input
              type="radio"
                style={{ marginLeft: "20px" }}
            />{" "}
            No
          </div>
        </Grid>
        <Grid item xs={3} style={{ marginTop: "10px", marginLeft: "-70px" }}>
          <h6> If yes, note AHI score</h6>
        </Grid>
        <Grid item xs={2} style={{ marginLeft: "-80px" }}>
        <TextField size="small" variant="standard"  fullWidth />
        </Grid>

        <Grid item xs={12} style={{ marginTop: "10px", marginLeft: "100px" }}>
          <h6>
            (If you do not know & have a copy, please provide to the front desk)
          </h6>
        </Grid>

        <Grid item xs={1}></Grid>
        <Grid item xs={1} style={{ marginTop: "10px" }}>
          <h6 style={{ fontWeight: "bold" }}>➔</h6>
        </Grid>
        <Grid item xs={5} style={{ marginTop: "10px", marginLeft: "-60px" }}>
          <h6>Have you tried an Oral Sleep appliance in the past?</h6>
        </Grid>
        <Grid item xs={3} style={{ marginTop: "10px" }}>

          <div>
            <input
              type="radio"
               style={{ marginLeft: "20px" }}
            />{" "}
            Yes
            <input
              type="radio"
               style={{ marginLeft: "20px" }}
            />{" "}
            No
          </div>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2} style={{ marginTop: "10px" }}>
          <h6>● Yes what year?</h6>
        </Grid>
        <Grid item xs={1} style={{ marginLeft: "-40px" }}>
        <TextField size="small" variant="standard"  fullWidth />
        </Grid>

        <Grid item xs={7}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={11} style={{ marginTop: "10px" }}>
          <h4> OTHER ATTEMPTED THERAPIES:</h4>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={11} style={{ marginTop: "10px" }}>
          <h6>
            {" "}
            Please comment about other therapy attempts and how each impacted
            your snoring and apnea and sleep quality
          </h6>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={10} style={{ marginTop: "30px" }}>
          <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
            EPWORTH SLEEPINESS SCALE - TO BE COMPLETED BY THE PATIENT
          </h3>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={11} style={{ marginTop: "10px" }}>
          <h6>
            How likely are you to doze off or fall asleep in the following
            situations (in contrast to just feeling tired)? Even if you haven’t
            been
          </h6>
        </Grid>

        <Grid item xs={1}></Grid>
        <Grid item xs={11}>
          <h6>
            in some of these situations recently, try to work out how they may
            affect you. Choose the most appropriate number for each
          </h6>
        </Grid>

        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <h6>situation:</h6>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1.5}></Grid>
        <Grid item xs={2} style={{ marginTop: "10px" }}>
          <h6 style={{ fontWeight: "bold" }}>0 - NEVER doze</h6>
        </Grid>
        <Grid item xs={2} style={{ marginTop: "10px" }}>
          <h6 style={{ fontWeight: "bold" }}>1 - SLIGHT chance</h6>
        </Grid>
        <Grid item xs={2.5} style={{ marginTop: "10px" }}>
          <h6 style={{ fontWeight: "bold" }}>2 - MODERATE chance</h6>
        </Grid>
        <Grid item xs={2} style={{ marginTop: "10px" }}>
          <h6 style={{ fontWeight: "bold" }}>3 - HIGH chance</h6>
        </Grid>
        <Grid item xs={2}></Grid>

        <Grid item xs={1}></Grid>
        <Grid item xs={6} style={{ marginTop: "10px" }}>
          <h6>Sitting and reading</h6>
        </Grid>
        <Grid item xs={4} style={{ marginTop: "10px" }}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <div>
              <input
                type="radio"
                  style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>0</label>
              <input
                type="radio"
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>1</label>
              <input
                type="radio"
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>2</label>
              <input
                type="radio"
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>3</label>
            </div>
          </RadioGroup>

        </Grid>
        <Grid item xs={1}></Grid>

        <Grid item xs={1}></Grid>
        <Grid item xs={6} style={{ marginTop: "10px" }}>
          <h6>Watching TV</h6>
        </Grid>
        <Grid item xs={4} style={{ marginTop: "10px" }}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <div>
              <input
              type="radio"
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                0
              </label>
              <input
                type="radio"
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                1
              </label>
              <input
                type="radio"
              style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                2
              </label>
              <input
                type="radio"
                 style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                3
              </label>
            </div>
          </RadioGroup>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={6} style={{ marginTop: "10px" }}>
          <h6>Sitting, inactive in a public place (i.e. theatre, meeting)</h6>
        </Grid>
        <Grid item xs={4} style={{ marginTop: "10px" }}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <div>
              <input
                type="radio"
                 style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                0
              </label>
              <input
                type="radio"
                 style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                1
              </label>
              <input
                type="radio"
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                2
              </label>
              <input
                type="radio"
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                3
              </label>
            </div>
          </RadioGroup>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={6} style={{ marginTop: "10px" }}>
          <h6>As a passenger in a car for an hour, without break</h6>
        </Grid>

        <Grid item xs={4} style={{ marginTop: "10px" }}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <div>
              <input
                type="radio"
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                0
              </label>
              <input
                type="radio"
                 style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                1
              </label>
              <input
                type="radio"
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                2
              </label>
              <input
                type="radio"
                 style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                3
              </label>
            </div>
          </RadioGroup>
        </Grid>

        <Grid item xs={1}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={6} style={{ marginTop: "10px" }}>
          <h6>Laying to rest in the afternoon, when circumstances permit</h6>
        </Grid>

        <Grid item xs={4} style={{ marginTop: "10px" }}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <div>
              <input
                type="radio"
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                0
              </label>
              <input
                type="radio"
                 style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                1
              </label>
              <input
                type="radio"
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                2
              </label>
              <input
                type="radio"
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                3
              </label>
            </div>
          </RadioGroup>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={6} style={{ marginTop: "10px" }}>
          <h6>Sitting and talking to someone</h6>
        </Grid>
        <Grid item xs={4} style={{ marginTop: "10px" }}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <div>
              <input
                type="radio"
               style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                0
              </label>
              <input
                type="radio"
                 style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                1
              </label>
              <input
                type="radio"
                 style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                2
              </label>
              <input
                type="radio"
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                3
              </label>
            </div>
          </RadioGroup>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={6} style={{ marginTop: "10px" }}>
          <h6>Sitting quietly after lunch when NO alcoho</h6>
        </Grid>
        <Grid item xs={4} style={{ marginTop: "10px" }}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <div>
              <input
                type="radio"
                 style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                0
              </label>
              <input
                type="radio"
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                1
              </label>
              <input
                type="radio"
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                2
              </label>
              <input
                type="radio"
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                3
              </label>
            </div>
          </RadioGroup>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={6} style={{ marginTop: "10px" }}>
          <h6>has been consumed</h6>
        </Grid>
        <Grid item xs={4} style={{ marginTop: "10px" }}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <div>
              <input
                type="radio"
                 style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                0
              </label>
              <input
                type="radio"
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                1
              </label>
              <input
                type="radio"
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                2
              </label>
              <input
                type="radio"
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                3
              </label>
            </div>
          </RadioGroup>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={6} style={{ marginTop: "10px" }}>
          <h6>In a car, stationary for a few minutes in traffic</h6>
        </Grid>

        <Grid item xs={4} style={{ marginTop: "10px" }}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <div>
              <input
                type="radio"
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                0
              </label>
              <input
                type="radio"
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                1
              </label>
              <input
                type="radio"
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                2
              </label>
              <input
                type="radio"
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                3
              </label>
            </div>
          </RadioGroup>
        </Grid>
        <Grid item xs={1}></Grid>

        <Grid item xs={1}></Grid>
        <Grid item xs={1.5} style={{ marginTop: "10px" }}>
          <h6 style={{ fontWeight: "bold" }}>Total (0-24)</h6>
        </Grid>
        <Grid item xs={1}>
        <TextField size="small" variant="standard"  fullWidth />
        </Grid>
        <Grid item xs={8.5}></Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2} style={{ marginTop: "20px" }}>
          <h6 style={{ fontWeight: "bold" }}>Patient Signature:</h6>
        </Grid>
        <Grid item xs={3} style={{ marginLeft: "-30px", marginTop: '10px' }}>
        <TextField size="small" variant="standard"  fullWidth />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1} style={{ marginTop: "20px" }}>
          <h6 style={{ fontWeight: "bold" }}>Date:</h6>
        </Grid>
        <Grid item xs={3} style={{ marginLeft: "-40px",marginTop: '10px' }}>
          <TextField
                variant="standard"
                style={{
                  width: "75%",
                }}
                size="small"
                fullWidth
              />
        </Grid>
        
      </Grid>
                                
                                    
     
           </Grid>

                      

                    </AccordionDetails>
                </Accordion>
                </Grid>
                <Grid item xs={0.5}> </Grid>

                </Grid>
                {/* ////////// LETTER */}
                <Grid container style={{marginTop:'20px'}}>
            <Grid item xs={0.5}> </Grid>
            <Grid item xs={11}> 
            <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                         <Grid container >
                          
                            <Grid xs={10}>
                            <h4>LETTER</h4>
                            </Grid>
                            <Grid xs={2}>
                        <PrintIcon
                             onClick={handlePrint3}
                              fontSize="large"
                             style={{
                                color: '#3C7F6B',
                                paddingRight: '5px',
                                 cursor: 'pointer',
                                float:'right'
                                }}
                              ></PrintIcon>
                                 </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Grid container columnSpacing={1} style={{ color: "black",marginTop:'20px' }} ref={printRef3}>
                    <Grid container 
                    sx={border}
                    >
                   <Grid item xs={1}></Grid>
                   <Grid item xs={10}>
                     <h3 style={{textAlign:'center',fontWeight:'bold',color:'white'}}> LETTER OF MEDICAL NECESSITY</h3>
                   </Grid>
                   <Grid item xs={1}></Grid>
                    </Grid>
                 
          <Grid item xs={2}></Grid>
          <Grid item xs={8}>
            {/* <h1
              style={{
                marginTop: "1rem",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              LETTER OF MEDICAL NECESSITY
            </h1> */}
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={8}>
            <h5 style={{ marginTop: "10px", textAlign: "center", marginTop:'2rem' }}>
              Referring MD’s Written Order for Oral Appliance for OSA
            </h5>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={2}>
            <h6 style={{ marginTop: "20px" }}>Patient:</h6>
          </Grid>
          <Grid item xs={3} style={{ marginTop: "10px", marginLeft: "-100px" }}>
          <TextField
                  size="small"
                  variant="standard"
                  fullWidth
                />
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={2}>
            <h6 style={{ marginTop: "20px" }}>DOB:</h6>
          </Grid>
          <Grid item xs={3} style={{ marginTop: "10px", marginLeft: "-100px" }}>
          <TextField
                  type="date"
                  size="small"
                  variant="standard"
                  
                  fullWidth
                />
          </Grid>
          <Grid item xs={3}></Grid>
          
          <Grid item xs={2}></Grid>
          <Grid item xs={8}>
            <h5 style={{ marginTop: "30px", textAlign: "center" }}>
              Diagnosis: Obstructive Sleep Apnea, Adult Pediatric G47.33
            </h5>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <h6 style={{ marginTop: "30px", textAlign: "center" }}>
              Oral Appliance Type: Custom fabricated mandibular advancement
              device: E0486 - ORAL DEVICE/APPLIANCE USED TO REDUCE UPPER AIRWAY
              COLLAPSIBILITY, ADJUSTABLE OR NON-ADJUSTABLE, CUSTOM FABRICATED,
              INCLUDES FITTING AND ADJUSTMENTs
            </h6>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <h6 style={{ marginTop: "30px", textAlign: "center" }}>
              A custom-fabricated Oral Appliance for OSA is defined as one that
              is individually made for a specific patient (no other patient
              would be able to use this item) starting with basic materials. It
              involves substantial work to produce, usually by a specialized
              lab. It may involve the incorporation of some prefabricated
              components. It involves more than trimming, bending, or making
              other modifications to a substantially prefabricated item.
            </h6>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <h6 style={{ marginTop: "30px", textAlign: "center" }}>
              The above-named patient was diagnosed as indicated. Treatment of
              this condition is thus ordered as a medical necessity for the
              lifetime of the patient.
            </h6>
          </Grid>
          <Grid item xs={1}></Grid>

          <Grid item xs={2}></Grid>
          <Grid item xs={4}>
            <h5 style={{ marginTop: "20px" }}>
              Ordering Physician NAME (Printed):
            </h5>
          </Grid>
          <Grid item xs={4} style={{ marginTop: "10px", marginLeft: "-15px" }}>
          <TextField
                  size="small"
                  variant="standard"
                  
                  fullWidth
                />
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={3}>
            <h5 style={{ marginTop: "20px" }}>Physician's Address:</h5>
          </Grid>
          <Grid item xs={6} style={{ marginTop: "10px", marginLeft: "-60px" }}>
          <TextField
                  size="small"
                  variant="standard"
                  
                  fullWidth
                />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={3}>
            <h5 style={{ marginTop: "20px" }}>Physician's Signature:</h5>
          </Grid>
          <Grid item xs={3} style={{ marginTop: "10px", marginLeft: "-50px" }}>
          <TextField
                  size="small"
                  variant="standard"
                  
                  fullWidth
                />
          </Grid>
          <Grid item xs={1}>
            <h5 style={{ marginTop: "20px" }}>Date:</h5>
          </Grid>
          <Grid item xs={2} style={{ marginTop: "10px", marginLeft: "-20px" }}>
          <TextField
                  type="date"
                  size="small"
                  variant="standard"
                  
                  fullWidth
                />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={3}>
            <h5 style={{ marginTop: "20px" }}>Physician’s NPI Number:</h5>
          </Grid>
          <Grid item xs={4} style={{ marginTop: "10px", marginLeft: "-30px" }}>
          <TextField
                  size="small"
                  variant="standard"
                  
                  fullWidth
                />
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={8}>
            <h5
              style={{
                marginTop: "30px",
                textAlign: "center",
                marginBottom: "50px",
              }}
            >
              {/* Please fax this completed form to: */}
            </h5>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>

                      

                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={0.5}> </Grid>

            </Grid>

          
             {/* ////////// Dental */}

             <Grid container style={{marginTop:'20px'}}>
            <Grid item xs={0.5}> </Grid>
            <Grid item xs={11}> 
            <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                         <Grid container >
                          
                            <Grid xs={10}>
                            <h4>DENTIST</h4>
                            </Grid>
                            <Grid xs={2}>
                        <PrintIcon
                             onClick={handlePrint5}
                              fontSize="large"
                             style={{
                                color: '#3C7F6B',
                                paddingRight: '5px',
                                 cursor: 'pointer',
                                float:'right'
                                }}
                              ></PrintIcon>
                                 </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Grid container columnSpacing={1} style={{ color: "black" ,marginTop:'20px'}} ref={printRef5}>
                    <Grid container 
                    sx={border}
                    >
                   <Grid item xs={1}></Grid>
                   <Grid item xs={10}>
                     <h3 style={{textAlign:'center',fontWeight:'bold',color:'white'}}> DENTIST USE ONLY - Oral examination</h3>
                   </Grid>
                   <Grid item xs={1}></Grid>
                    </Grid>
               
                                   
        <Grid item xs={1}></Grid>
        <Grid item xs={8} style={{ marginTop: "30px" }}>
          <h6>
            Incisor relationship Class 1 Class 2 Div I Class 2 Div II Class 3
            Overjet ....... mm
          </h6>
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={11} style={{ marginTop: "10px" }}>
          <h6>Overbite ....... mm</h6>
        </Grid>
        <Grid item xs={1}></Grid>

        <Grid item xs={3} style={{ marginTop: "10px" }}>
          <h6>OH/Periodontal condition?</h6>
        </Grid>
        <Grid item xs={3}>
          <div>
            <input
              type="radio"
              value="Good"
              style={{ marginLeft: "20px" }}
            />{" "}
            Good
            <input
              type="radio"
              style={{ marginLeft: "20px" }}
            />{" "}
            Fair
            <input
              type="radio"
              style={{ marginLeft: "20px" }}
            />{" "}
            Poor
          </div>
        </Grid>
        <Grid item xs={5}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={3} style={{ marginTop: "10px" }}>
          <h6>Tonsils - enlarged/inflamed</h6>
        </Grid>
        <Grid item xs={3}>
          <div>
            <input
              type="radio"
              style={{ marginLeft: "20px" }}
            />{" "}
            Yes
            <input
              type="radio"
              style={{ marginLeft: "20px" }}
            />{" "}
            No
          </div>

        </Grid>
        <Grid item xs={5}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={4} style={{ marginTop: "10px" }}>
          <h6>Bruxism/clenching/grinding of teeth</h6>
        </Grid>
        <Grid item xs={3}>
          <div>
            <input
              type="radio"
               style={{ marginLeft: "20px" }}
            />{" "}
            Yes
            <input
              type="radio"
              style={{ marginLeft: "20px" }}
            />{" "}
            No
          </div>
        </Grid>

        <Grid container style={{ marginLeft: "80px" }}>
          <Grid item xs={1}>
            {" "}
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={11} style={{ marginTop: "10px" }}>
            <h5 style={{ fontWeight: "bold" }}>TMJ assessment:</h5>
          </Grid>


          <Grid item xs={1}></Grid>
          <Grid item xs={3} style={{ marginTop: "15px" }}>
            <h6>Max lateral movements </h6>
          </Grid>
          <Grid item xs={0.5} style={{ marginTop: "10px" }}>
            <h5>L</h5>
          </Grid>
          <Grid item xs={1} style={{ marginLeft: "-20px" }}>
          <TextField
                  type='number'
                  size="small"
                  variant="standard"
                  
                  fullWidth
                />
          </Grid>
          <Grid item xs={0.5} style={{ marginTop: "15px" }}>
            <h6>mm</h6>
          </Grid>

          <Grid item xs={0.5} style={{ marginTop: "10px" }}>
            <h5>R</h5>
          </Grid>
          <Grid item xs={1} style={{ marginLeft: "-20px" }}>
          <TextField
                  type='number'
                  size="small"
                  variant="standard"
                  
                  fullWidth
                />
          </Grid>
          <Grid item xs={0.5} style={{ marginTop: "15px" }}>
            <h6>mm</h6>
          </Grid>
          <Grid item xs={3}></Grid>

          <Grid item xs={1}></Grid>
          <Grid item xs={3} style={{ marginTop: "15px" }}>
            <h6>Max opening </h6>
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={1} style={{ marginLeft: "-20px" }}>
          <TextField
                  type='number'
                  size="small"
                  variant="standard"
                  
                  fullWidth
                />
          </Grid>
          <Grid item xs={0.5} style={{ marginTop: "15px" }}>
            <h6>mm</h6>
          </Grid>
          <Grid item xs={6}></Grid>


          <Grid item xs={1}></Grid>
          <Grid item xs={3} style={{ marginTop: "15px" }}>
            <h6>Max protrusion </h6>
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={1} style={{ marginLeft: "-20px" }}>
          <TextField
                  type='number'
                  size="small"
                  variant="standard"
                  
                  fullWidth
                />
          </Grid>
          <Grid item xs={0.5} style={{ marginTop: "15px" }}>
            <h6>mm</h6>
          </Grid>
          <Grid item xs={6}></Grid>

          <Grid item xs={1}></Grid>
          <Grid item xs={3} style={{ marginTop: "10px" }}>
            <h6>Tenderness to palpitation</h6>
          </Grid>
          <Grid item xs={3} style={{ marginTop: "10px" }}>
            <div>
              <input
                type="radio"
               style={{ marginLeft: "20px" }}
              />{" "}
              Yes
              <input
                type="radio"
                style={{ marginLeft: "20px" }}
              />{" "}
              No
            </div>
          </Grid>
          <Grid item xs={5}>
            {" "}
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={3} style={{ marginTop: "10px" }}>
            <h6>Pain on mandibular movement</h6>
          </Grid>
          <Grid item xs={3} style={{ marginTop: "10px" }}>
            <div>
              <input
                type="radio"
                  style={{ marginLeft: "20px" }}
              />{" "}
              Yes
              <input
                type="radio"
                style={{ marginLeft: "20px" }}
              />{" "}
              No
            </div>
          </Grid>
          <Grid item xs={5}>
            {" "}
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={3} style={{ marginTop: "10px" }}>
            <h6>Smooth movement</h6>
          </Grid>
          <Grid item xs={3} style={{ marginTop: "10px" }}>
            <div>
              <input
                type="radio"
                style={{ marginLeft: "20px" }}
              />{" "}
              Yes
              <input
                type="radio"
                style={{ marginLeft: "20px" }}
              />{" "}
              No
            </div>
          </Grid>
          <Grid item xs={5}>
            {" "}
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={3} style={{ marginTop: "10px" }}>
            <h6>Locking and/or luxation</h6>
          </Grid>
          <Grid item xs={3} style={{ marginTop: "10px" }}>
            <div>
              <input
                type="radio"
                  style={{ marginLeft: "20px" }}
              />{" "}
              Yes
              <input
                type="radio"
                  style={{ marginLeft: "20px" }}
              />{" "}
              No
            </div>
          </Grid>

          {/* </Grid> */}
          <Grid item xs={5}>
            {" "}
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={11} style={{ marginTop: "30px" }}>
            <h6 style={{ fontWeight: "bold" }}>
              CARE PATHWAY: If the following are reported a Sleep Study should
              be completed to determine if the patient has OSA &
            </h6>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={11}>
            <h6 style={{ fontWeight: "bold" }}>
              the Informed consent (next page) should be completed.
            </h6>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={1} style={{ marginTop: "3px" }}>
            <Checkbox/>
          </Grid>
          <Grid item xs={10} style={{ marginTop: "10px" }}>
            <h6>Epworth of 10+</h6>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={1} style={{ marginTop: "3px" }}>
            <Checkbox/>
             
          </Grid>
          <Grid item xs={10} style={{ marginTop: "10px" }}>
            <h6>Feeling fatigued</h6>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={1} style={{ marginTop: "3px" }}>
            <Checkbox/>
             
          </Grid>
          <Grid item xs={10} style={{ marginTop: "10px" }}>
            <h6>Not using CPAP</h6>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={1} style={{ marginTop: "3px" }}>
            <Checkbox/>
            
          </Grid>
          <Grid item xs={10} style={{ marginTop: "10px" }}>
            <h6>Headaches</h6>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={1} style={{ marginTop: "3px" }}>
            <Checkbox/>
             
          </Grid>
          <Grid item xs={10} style={{ marginTop: "10px" }}>
            <h6>
              Inappropriate daytime napping (e.g., during driving, conversation,
              or eating)
            </h6>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={1} style={{ marginTop: "3px" }}>
            <Checkbox/>
            
          </Grid>
          <Grid item xs={10} style={{ marginTop: "10px" }}>
            <h6>
              AHI of 5 or above (only if the patient has already had a sleep
              test in the past)
            </h6>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={3} style={{ marginTop: "50px" }}>
            <h5 style={{ fontWeight: "bold" }}>Provider of care Name:</h5>
          </Grid>
          <Grid item xs={4} style={{ marginTop: "40px", marginLeft: "-20px" }}>
          <TextField
                  size="small"
                  variant="standard"
                  
                  fullWidth
                />
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={3.5} style={{ marginTop: "20px" }}>
            <h5 style={{ fontWeight: "bold" }}>Provider of care Signature:</h5>
          </Grid>
          <Grid item xs={3} style={{ marginTop: "10px", marginLeft: "-30px" }}>
          <TextField
                  size="small"
                  variant="standard"
                  
                  fullWidth
                />
          </Grid>

          <Grid item xs={1} style={{ marginTop: "20px", marginLeft: "20px" }}>
            <h5 style={{ fontWeight: "bold" }}>Date:</h5>
          </Grid>
          <Grid item xs={2.5} style={{ marginTop: "10px", marginLeft: "5px" }}>
          <TextField
                  type="date"
                  size="small"
                  variant="standard"
                  
                  fullWidth
                />
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>


      </Grid>

                      

                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={0.5}> </Grid>

            </Grid>

                {/* //////////end */}
                   {/* ////////// Informed Consent start */}
             <Grid container style={{marginTop:'20px'}}>
            <Grid item xs={0.5}> </Grid>
            <Grid item xs={11}> 
            <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                         <Grid container >
                          
                            <Grid xs={10}>
                            <h4>Informed Consent </h4>
                            </Grid>
                            <Grid xs={2}>
                        <PrintIcon
                             onClick={handlePrint4}
                              fontSize="large"
                             style={{
                                color: '#3C7F6B',
                                paddingRight: '5px',
                                 cursor: 'pointer',
                                float:'right'
                                }}
                              ></PrintIcon>
                                 </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Grid container columnSpacing={1} style={{ color: "black" ,marginTop:'20px'}} ref={printRef4}>
                    <Grid container 
                    sx={border}
                    >
                   <Grid item xs={1}></Grid>
                   <Grid item xs={10}>
                     <h3 style={{textAlign:'center',fontWeight:'bold',color:'white'}}>  Informed Consent </h3>
                   </Grid>
                   <Grid item xs={1}></Grid>
                    </Grid>
                    <Grid item xs={1}></Grid>
          <Grid item xs={11} style={{ marginTop: "10px" }}>
            <h3 style={{ fontWeight: "bold" }}>
              Informed Consent for the Treatment of Sleep-Related Breathing
              Disorders
            </h3>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={11} style={{ marginTop: "30px" }}>
            <h6>
              You have been diagnosed by your physician as requiring treatment
              for a sleep-related breathing disorder, such as snoring and/or
              obstructive sleep apnea (OSA). OSA may pose serious health risks
              since it disrupts normal sleep patterns and can reduce normal
              blood oxygen levels. This condition can increase a person’s risk
              for excessive daytime sleepiness, driving and work-related
              accidents, high blood pressure, heart disease, stroke, diabetes,
              obesity, memory and learning problems, and depression.
            </h6>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={11} style={{ marginTop: "20px" }}>
            <h5 style={{ fontWeight: "bold" }}>
              What is Oral Appliance Therapy?
            </h5>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={11} style={{ marginTop: "30px" }}>
            <h6>
              Oral appliance therapy for snoring and/or OSA attempts to assist
              breathing by keeping the tongue and jaw in a forward position
              during sleeping hours. OAT has effectively treated many patients.
              However, there are no guarantees that it will be effective for
              you. Every patient’s case is different and there are many factors
              that influence the upper airway during sleep. It is important to
              recognize that even when the therapy is effective, there may be a
              period of time before the appliance functions maximally.During
              this time, you may still experience symptoms related to your
              sleep-related breathing disorder.
            </h6>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={11} style={{ marginTop: "30px" }}>
            <h6>
              A post-adjustment polysomnogram (sleep study) is necessary to
              objectively assure effective treatment. This must be obtained from
              your physician.
            </h6>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={11} style={{ marginTop: "20px" }}>
            <h5 style={{ fontWeight: "bold" }}>
              Side-Effects and Complications of Oral Appliance Therapy
            </h5>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={11} style={{ marginTop: "10px" }}>
            <h6>
              Published studies show that short-term side effects of oral
              appliance therapy may include excessive salivation, difficulty
              swallowing (with the appliance in place), sore jaws or teeth, jaw
              joint pain, dry mouth, gum pain, loosening of teeth, and
              short-term bite changes. There are also reports of dislodgement of
              ill-fitting dental restorations. Most of these side effects are
              minor and resolve quickly on their own or with minor adjustments
              of the appliance.
            </h6>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={11} style={{ marginTop: "30px" }}>
            <h6>
              Long-term complications include bite changes that may be permanent
              resulting from tooth movement or jaw joint repositioning. These
              complications may or may not be fully reversible once oral
              appliance therapy is discontinued. If not reversible, restorative
              treatment or orthodontic intervention may be required for which
              you will be responsible.
            </h6>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={11} style={{ marginTop: "30px" }}>
            <h6>
              Follow-up visits with the provider of your oral appliance are
              mandatory to ensure proper fit and healthy condition. If unusual
              symptoms or discomfort occur that fall outside the scope of this
              consent, or if pain medication is required to control discomfort,
              it is recommended that you cease using the appliance until you are
              evaluated further. As this office cannot ensure the success of any
              type of therapy and cannot guarantee that any patient will comply
              with the treatment for sleep apnea, I hereby waive any rights that
              I, my heirs, and my assigns might have to seek legal redress for
              any damage, physical or monetary, that I might sustain as a result
              of my treatment for sleep apnea or any failure on my part to
              comply with treatment.
            </h6>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={11} style={{ marginTop: "20px" }}>
            <h5 style={{ fontWeight: "bold" }}>
              Alternative Treatments for Sleep-Related Breathing Disorders
            </h5>
          </Grid>

          <Grid item xs={1}></Grid>
          <Grid item xs={11} style={{ marginTop: "30px" }}>
            <h6>
              Other accepted treatments for sleep-related breathing disorders
              include behavioral modification, Continuous Positive Airway
              Pressure (CPAP) and various surgeries. It is your decision to
              choose oral appliance therapy to treat your sleep-related
              breathing disorder and you are aware that it may not be completely
              effective for you. It is your responsibility to report the
              occurrence of side effects and to address any questions to this
              provider’s office. Failure to treat sleep-related breathing
              disorders may increase the likelihood of significant medical
              complications.
            </h6>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={11} style={{ marginTop: "30px" }}>
            <h6>
              If you understand the explanation of the proposed treatment, and
              have asked this provider any questions you may have about this
              form of treatment, please sign and date this form below. You will
              receive a copy.
            </h6>
          </Grid>

          <Grid item xs={1}></Grid>
          <Grid item xs={2} style={{ marginTop: "30px" }}>
            <h6>Signature:</h6>
          </Grid>
          <Grid item xs={3} style={{ marginTop: "20px", marginLeft: "-60px" }}>
          <TextField
                  size="small"
                  variant="standard"
                  
                  fullWidth
                />
          </Grid>
          <Grid item xs={2} style={{ marginTop: "30px" }}>
            <h6>Date:</h6>
          </Grid>
          <Grid item xs={3} style={{ marginTop: "20px", marginLeft: "-80px" }}>
          <TextField
                  type='date'
                  size="small"
                  variant="standard"
                  fullWidth
                />
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={1}></Grid>
        
          <Grid item xs={1.5} style={{ marginTop: "30px" }}>
            <h6>Print Name:</h6>
          </Grid>
          <Grid item xs={3} style={{ marginTop: "20px", }}>
          <TextField
                  size="small"
                  variant="standard"
                  
                  fullWidth
                />
          </Grid>
          <Grid item xs={7.5}></Grid>
          
        </Grid>

                      

                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={0.5}> </Grid>

            </Grid>
 {/* ////////// Informed Consent End */}

  {/* ////////// AFFIDAVIT FOR INTOLERANCE TO CPAP DEVICE start */}
  <Grid container style={{marginTop:'20px'}}>
            <Grid item xs={0.5}> </Grid>
            <Grid item xs={11}> 
            <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                         <Grid container >
                          
                            <Grid xs={10}>
                            <h4>AFFIDAVIT FOR INTOLERANCE TO CPAP DEVICE </h4>
                            </Grid>
                            <Grid xs={2}>
                        <PrintIcon
                             onClick={handlePrint6}
                              fontSize="large"
                             style={{
                                color: '#3C7F6B',
                                paddingRight: '5px',
                                 cursor: 'pointer',
                                float:'right'
                                }}
                              ></PrintIcon>
                                 </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Grid container columnSpacing={2} style={{ color: "black" ,marginTop:'20px'}} ref={printRef6}>
                    <Grid container 
                    sx={border}
                    >
                   <Grid item xs={1}></Grid>
                   <Grid item xs={10}>
                     <h3 style={{textAlign:'center',fontWeight:'bold',color:'white'}}> AFFIDAVIT FOR INTOLERANCE TO CPAP DEVICE </h3>
                   </Grid>
                   <Grid item xs={1}></Grid>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid xs={2}>
                      <Typography style={{ fontSize: "18px",marginTop:'15px' }}>Patient Name:</Typography>
                    </Grid>
                    <Grid xs={4}>

                      <TextField
                        variant="standard"
                        style={{
                          width: "22em",
                          marginTop:'15px'
                        }}
                        size="small"
                        fullWidth
                        name="patientName"
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={5}></Grid>
                    <Grid item xs={1}></Grid>
                    <Grid xs={2}>
                      <Typography style={{ fontSize: "18px",marginTop:'15px' }}>Date:</Typography>
                    </Grid>
                    <Grid xs={4}>

                      <TextField
                        variant="standard"
                        style={{
                          width: "22em",
                          marginTop:'10px'
                        }}
                        size="small"
                        fullWidth
                        name="patientName"
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={5}></Grid>
                    <Grid item xs={1}></Grid>
                    <Grid xs={10}>
                      <Typography style={{ fontSize: "18px",marginTop:'15px' }}>I have attempted to use a CPAP device to manage my sleep-related breathing disorder and find it
                        intolerable to use on a regular basis for the following reason(s):</Typography>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={1}></Grid>
                <Grid item xs={0.3}>
                    <input
                        size='large'
                        type='checkbox'
                        style={{
                            marginTop: '20px'
                        }}

                    />
                </Grid>
                <Grid item xs={10.7} >
                <Typography style={{ fontSize: "15px",marginTop:'15px' }}>Mask Leaks</Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={0.3}>
                    <input
                        size='large'
                        type='checkbox'
                        style={{
                            marginTop: '20px'
                        }}

                    />
                </Grid>
                <Grid item xs={10.7} >
                <Typography style={{ fontSize: "15px",marginTop:'15px' }}>Mask and/or device uncomfortable</Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={0.3}>
                    <input
                        size='large'
                        type='checkbox'
                        style={{
                            marginTop: '20px'
                        }}

                    />
                </Grid>
                <Grid item xs={10.7} >
                <Typography style={{ fontSize: "15px",marginTop:'15px' }}>Unable to sleep comfortably</Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={0.3}>
                    <input
                        size='large'
                        type='checkbox'
                        style={{
                            marginTop: '20px'
                        }}

                    />
                </Grid>
                <Grid item xs={10.7} >
                <Typography style={{ fontSize: "15px",marginTop:'15px' }}>Noise from the device disturbs me and/or my bed partner’s sleep</Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={0.3}>
                    <input
                        size='large'
                        type='checkbox'
                        style={{
                            marginTop: '20px'
                        }}

                    />
                </Grid>
                <Grid item xs={10.7} >
                <Typography style={{ fontSize: "15px",marginTop:'15px' }}>Restricts movement during sleep</Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={0.3}>
                    <input
                        size='large'
                        type='checkbox'
                        style={{
                            marginTop: '20px'
                        }}

                    />
                </Grid>
                <Grid item xs={10.7} >
                <Typography style={{ fontSize: "15px",marginTop:'15px' }}>Does not seem to be effective</Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={0.3}>
                    <input
                        size='large'
                        type='checkbox'
                        style={{
                            marginTop: '20px'
                        }}

                    />
                </Grid>
                <Grid item xs={10.7} >
                <Typography style={{ fontSize: "15px",marginTop:'15px' }}>Straps/headgear cause discomfort</Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={0.3}>
                    <input
                        size='large'
                        type='checkbox'
                        style={{
                            marginTop: '20px'
                        }}

                    />
                </Grid>
                <Grid item xs={10.7} >
                <Typography style={{ fontSize: "15px",marginTop:'15px' }}>Pressure on upper lip causes tooth-related problems</Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={0.3}>
                    <input
                        size='large'
                        type='checkbox'
                        style={{
                            marginTop: '20px'
                        }}

                    />
                </Grid>
                <Grid item xs={10.7} >
                <Typography style={{ fontSize: "15px",marginTop:'15px' }}>Latex allergy</Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={0.3}>
                    <input
                        size='large'
                        type='checkbox'
                        style={{
                            marginTop: '20px'
                        }}

                    />
                </Grid>
                <Grid item xs={10.7} >
                <Typography style={{ fontSize: "15px",marginTop:'15px' }}>Claustrophobia</Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={0.3}>
                    <input
                        size='large'
                        type='checkbox'
                        style={{
                            marginTop: '20px'
                        }}

                    />
                </Grid>
                <Grid item xs={0.7} >
                <Typography style={{ fontSize: "15px",marginTop:'15px' }}>Other:</Typography>
                </Grid>
                <Grid item xs={9} >
                <TextField
                  size="small"
                  variant="standard"
                  fullWidth
                />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={1}></Grid>
                    <Grid xs={10}>
                      <Typography style={{ fontSize: "18px",marginTop:'15px' }}>I have not attempted to use a CPAP device and would prefer to use an oral appliance for the
                      following reason(s):</Typography>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={1}></Grid>
                <Grid item xs={0.3}>
                    <input
                        size='large'
                        type='checkbox'
                        style={{
                            marginTop: '20px'
                        }}

                    />
                </Grid>
                <Grid item xs={10.7} >
                <Typography style={{ fontSize: "15px",marginTop:'15px' }}>I’m worried that the mask, straps/headgear will cause discomfort</Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={0.3}>
                    <input
                        size='large'
                        type='checkbox'
                        style={{
                            marginTop: '20px'
                        }}

                    />
                </Grid>
                <Grid item xs={10.7} >
                <Typography style={{ fontSize: "15px",marginTop:'15px' }}>I’m worried that the device will restrict movement during sleep</Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={0.3}>
                    <input
                        size='large'
                        type='checkbox'
                        style={{
                            marginTop: '20px'
                        }}

                    />
                </Grid>
                <Grid item xs={10.7} >
                <Typography style={{ fontSize: "15px",marginTop:'15px' }}>I have a latex allergy</Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={0.3}>
                    <input
                        size='large'
                        type='checkbox'
                        style={{
                            marginTop: '20px'
                        }}

                    />
                </Grid>
                <Grid item xs={10.7} >
                <Typography style={{ fontSize: "15px",marginTop:'15px' }}>I suffer from claustrophobia</Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={0.3}>
                    <input
                        size='large'
                        type='checkbox'
                        style={{
                            marginTop: '20px'
                        }}

                    />
                </Grid>
                <Grid item xs={10.7} >
                <Typography style={{ fontSize: "15px",marginTop:'15px' }}>I travel frequently and am worried that a CPAP device will be cumbersome to transport</Typography>
                </Grid>







                <Grid item xs={1}></Grid>
                <Grid item xs={0.3}>
                    <input
                        size='large'
                        type='checkbox'
                        style={{
                            marginTop: '20px'
                        }}

                    />
                </Grid>
                <Grid item xs={10.7} >
                <Typography style={{ fontSize: "15px",marginTop:'15px' }}>I’m worried that the noise from the device will disturb me and/or my bed partner’s sleep</Typography>
                </Grid>






                <Grid item xs={1}></Grid>
                <Grid item xs={0.3}>
                    <input
                        size='large'
                        type='checkbox'
                        style={{
                            marginTop: '20px'
                        }}

                    />
                </Grid>
                <Grid item xs={0.7} >
                <Typography style={{ fontSize: "15px",marginTop:'15px' }}>Other:</Typography>
                </Grid>
                <Grid item xs={9} >
                <TextField
                  size="small"
                  variant="standard"
                  fullWidth
                />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={1}></Grid>
                    <Grid xs={10}>
                      <Typography style={{ fontSize: "18px",marginTop:'15px' }}>Because of my inability to use a CPAP device, I wish to have an alternative method of treatment. I
                      would like to try an oral appliance in an attempt to control my snoring and obstructive sleep apnea.</Typography>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={5} >
                <TextField
                  size="small"
                  variant="standard"
                  fullWidth
                  // style={{marginTop:'20px',}}
                />
                </Grid>
                <Grid item xs={5} >
                <TextField
                  size="small"
                  variant="standard"
                  fullWidth
                  // style={{marginTop:'20px',}}
                />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={1}></Grid>
                    <Grid xs={5}>
                      <Typography style={{ fontSize: "18px",marginTop:'5px',textAlign:'center' }}>Patient’s Signature</Typography>
                    </Grid>
                    <Grid xs={5}>
                      <Typography style={{ fontSize: "18px",marginTop:'5px',textAlign:'center' }}>Date</Typography>
                    </Grid>
                    <Grid item xs={1}></Grid>


      
          
        </Grid>

                      

                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={0.5}> </Grid>

            </Grid>

   {/* ////////// AFFIDAVIT FOR INTOLERANCE TO CPAP DEVICE end */}

   <Grid container style={{marginTop:'30px'}}>
   <Grid container xs={12}>
          
            {/* {show && ( */}
                <Grid style={{ marginTop: "10px" }} item xs={12} >
                  <>
                    
                    {/* <Grid item xs={5}></Grid> */}
                    {providerNotes != null && providerNotes.length > 0
                      ? providerNotes.map((row, index) => {
                          return (
                            <Grid
                            item xs={12} sm={12} md={12} lg={12}
                              // style={{ textAlign: "center" }}
                            >
                              {row.documentName ? (
                                <Grid container spacing={1}>
                                  <Grid item xs={0.5}></Grid>
                                  <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5}>
                                    <Typography
                                      style={{ marginTop: "20px",fontWeight:'bold' }}
                                    >
                                      {index + 1}:
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={10}
                                    sm={10}
                                    md={10}
                                    lg={10}
                                    style={{ textAlign: "left" }}
                                  >
                                  
                                     <Button
                                      variant={
                                        selectedIndex == index
                                          ? "contained"
                                          : "link"
                                      }
                                      size="md"
                                      style={{
                                        color: "white",
                                        fontSize: 20,
                                        margin: "10px",
                                        fontWeight: "bold",
                                        fontFamily: "initial",
                                        padding: "5px",
                                        // backgroundGridor: "#FEB313",
                                        backgroundColor:"#3C7F6B",
                                        // textAlign: "left",
                                        // justifyContent: "left",
                                      }}
                                      onClick={() => {
                                        setActiveDocName(row.documentName)
                                        handleOpenScreen();
                                        setSelectedIndex(index);
                                          row.documentUrl
                                            ? 
                                           
                                              setDocument(
                                                `${
                                                  // var url = row.fileUrl
                                                  axios
                                                  .get(`${process.env.REACT_APP_API_URL}/PatientDocument/ViewPatientDocument?path=${row.documentUrl}`, {
                                                    headers
                                                  })
                                                  .then(response => {
                                                    // setisLoading(false)
                                                    setDoc(response)
                              
                                                  })
                                                  .catch(error => {
                                                    console.error('There was an error!', error)
                                                  })
                                                }/${(row.documentUrl)}`
                                              )
                                            : 
                                              confirmAlert({
                                                message:
                                                  "Please Contact Administrator! Document Not Found.",
                                                closeOnEscape: true,
                                                closeOnClickOutside: true,
                                              
                                                buttons: [
                                                  {
                                                    label: "Ok",
                                                  },
                                                ],
                                              });
                                      }}
                                    >
                                      {row.documentName}
                                    </Button> 
                                  </Grid>
                                  <Grid
                                    item
                                    xs={1}
                                    sm={1}
                                    md={1}
                                    lg={1}
                                    style={{
                                      // textAlign: "center",
                                      marginTop: "15px",
                                    }}
                                  >
                                    {row.documentName && row.userName != -1 ? (
                                      <Button
                                        disabled={submitting}
                                        onClick={(event) =>
                                          confirmAlert({
                                            message:
                                              "Do you want to delete the record.",
                                            closeOnEscape: true,
                                            closeOnClickOutside: true,
                                            overlayClassName:
                                              "overlay-custom-class-name",
                                            buttons: [
                                              {
                                                label: "Yes",
                                                onClick: () => {
                                                  const postData = {
                                                    documentName:
                                                      providerNotes[index]
                                                        .documentName,
                                                    documentUrl:
                                                      providerNotes[index]
                                                        .documentUrl,
                                                    id: providerNotes[index].id,
                                                    userName:
                                                      providerNotes[index]
                                                        .userName,
                                                    practiceId:
                                                      providerNotes[index]
                                                        .practiceId,
                                                    deleted: true,
                                                  };
                                                  setSubmitting(true);
                                                  axios
                                                    .post(
                                                      `${process.env.REACT_APP_API_URL}/ProviderNotes/addProviderNotes`,
                                                      postData,
                                                      {
                                                        headers,
                                                      }
                                                    )
                                                    .then((response) => {
                                                      setRefresh(!refresh);
                                                      setSubmitting(false);
                                                      setDocument("");
                                                      setSelectedIndex(null);
                                                      // setOpenNote(false)
                                                      // console.log('response.data: ', response.data)
                                                    })
                                                    .catch((error) => {
                                                      console.error(
                                                        "There was an error!",
                                                        error
                                                      );
                                                    });
                                                },
                                              },
                                              {
                                                label: "No",
                                              },
                                            ],
                                          })
                                        }
                                        variant="gradient"
                                        // disabled={submitting}
                                        style={{
                                          marginLeft: "5px",
                                          width: "20px",
                                          height: "33px",
                                          color: "black",
                                          fontSize: "14px",
                                        }}
                                        // startIcon={<img src={CancelIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
                                      >
                                        <DeleteOutlinedIcon
                                          fontSize="medium"
                                          style={{
                                            color: "red",
                                            marginBottom: "5px",
                                            marginRight:"15px"
                                          }}
                                        ></DeleteOutlinedIcon>
                                      </Button>
                                    ) : null}
                                  </Grid>
                                </Grid>
                              ) : null}
                            </Grid>
                          );
                        })
                      : "No Record Found"}
                  </>
                </Grid>
          
        
             
            {/* )} */}
           
            <Grid item xs={12} >
              <Button
              className="Button"
                onClick={() => {
                  document.getElementById("choosefile").click();
                }}
                
                variant="gradient"
                style={{
                  marginTop: "10px",
                  width: "100%",
                  height: "35px",
                  color: "black",
                  fontSize: "14px",
                 
                  
                }}
                
              >
                <FileUploadIcon
                  fontSize="medium"
                  style={{
                    color: "white",
                    // paddingRight: "5px",
                    paddingBottom: "2px",
                  }}
                ></FileUploadIcon>
             
                <Typography style={{ fontSize: "14px",fontWeight:'bold',color:'white' }}>
                  {/* &nbsp;&nbsp;{docName} */}   Upload Doc: &nbsp;&nbsp;{docName}
                </Typography>
               
              </Button>

              <label htmlFor="icon-button-file">
                <input
                  id="choosefile"
                  style={{ display: "none" }}
                  type="file"
                  name="upload"
                  accept="application/pdf"
                  onChange={onImageChange}
                />
              </label>
            </Grid>
            {/* <Grid item xs={6.5} style={{ marginTop: "8px" }}>
              <Button
                style={{ backgroundColor: "#3C7F6B", color:'white' }}
                onClick={() => setShow(!show)}
            
              >
                {show ? (
                  <ExpandLessIcon
                    style={{ color: "white", backgroundColor: "#3C7F6B" }}
                  ></ExpandLessIcon>
                ) : (
                  <ExpandMoreIcon
                    style={{ color: "white", backgroundColor: "#3C7F6B" }}
                  ></ExpandMoreIcon>
                )}
              </Button>
            </Grid> */}
            <Grid item xs={1.5}></Grid>
            <Grid item xs={10}>
            {docName ? (
                      <Grid item xs={12} >
                        <Button
                        className="Button"
                          onClick={handleSubmit(onSubmit)}
                          variant="gradient"
                          disabled={docName ? submitting : true}
                          style={{
                            marginTop: "10px",
                            float: 'right',
                            marginRight: "13px",
                            width: "80px",
                            height: "35px",
                            marginLeft: '5px',
                            // backgroundColor: "#FCBE13",
                            color: "white",
                            fontSize: "14px",
                          }}
                          
                        >
                          <SaveOutlinedIcon
                            fontSize="medium"
                            style={{
                              color: "white",
                              paddingRight: "5px",
                              paddingBottom: "2px",
                            }}
                          ></SaveOutlinedIcon>
                          Save
                        </Button>
                      </Grid>
                    ) : (
                      ""
                    )}
            </Grid>
            <Grid item xs={0.5}></Grid>
          </Grid>
          {showScreen ? (
         <Modal
          open={handleOpenScreen}
          closeOnEscape={false}
          closeOnClickOutside={false}
          onClose={() => {
            setShowScreen(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
       
       
          <Box>
             <Draggable bounds={{ left: -400, top: 0, right: 400, bottom: 500 }}>
              <Box sx={styleDetailsPdf}>
                <form className={classes.form} autoComplete="off">
               
                  <Grid container>
                   
                  <Grid item xs={11} style={{ fontWeight: 500,backgroundColor: '#377562', marginTop: '-35', }}>
                      <Typography style={{ fontWeight: 'bold', fontSize: '18px', color: 'white', }}>
                      {activeDocName}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                    <CancelOutlinedIcon
                    onClick={() => {
                      setShowScreen(false);
                    }}
                    variant="contained"
                    style={{
                      float: "right",
                      color: "red",
                      // marginTop:'-5px',
                     
                    }}
                  ></CancelOutlinedIcon>
                    </Grid>
                    <Grid item xs={12} style={{marginTop:'20px'}}>
                 {doc ? (
                    <Grid
                      container
                      columnSpacing={1}
                      alignContent="center"
                      justifyContent="center"
                    >
                      <div
                        id="iframe_container"
                        style={{
                          position: "absolute",
                          top: "6%",
                          bottom: "0%",
                          left: "0%",
                          right: "0%",
                          marginTop: "0px",
                          // marginBottom:"10px",
                        }}
                      >
                        <iframe
                          name="ifr"
                          scrolling="yes"
                          fullWidth
                          style={{
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                          }}
                          src={`${doc}`}
                          frameborder="0"
                          allowfullscreen
                        ></iframe>
                      </div>
                    </Grid>
                  ) : null}
                 </Grid>
                  </Grid>

                    {/* <>
                      
                      <span style={{ fontSize: "16px",fontWeight:'bold' }}>
                        {activeDocName}
                      
                        </span>
                    </> */}

              

                
                </form>
              </Box>
            </Draggable>
          </Box>
      
      
      </Modal>

): null}
   </Grid>


           </Grid>

        </React.Fragment>
    );
};
export default FromsTemp;
