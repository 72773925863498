// ** React Imports
import { useState, useEffect } from 'react'
import axios from 'axios'
import { Controller, useForm } from 'react-hook-form';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ClickAwayListener from '@mui/material/ClickAwayListener';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'

// import Box from '@mui/material/Box'
// import Container from '@mui/material/Container'
import {Modal, Box, Button, Container, Stack, SvgIcon, Typography, Grid, Snackbar, TextField } from '@mui/material';
import React from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
// import Button from '@mui/material/Button'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import Paper from '@mui/material/Paper'

// import { Grid, Typography } from '@mui/material'
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { Backdrop } from '@mui/material';
import "../Button.css"
import Moment from 'moment'
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import ClaimRegistration from '../ClaimRegistration';
import Checkbox from '@mui/material/Checkbox';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Dialog from '@mui/material/Dialog';
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ReactSelect from 'react-select'
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import Headroom from "react-headroom";
import DialogTitle from '@mui/material/DialogTitle';
import { useAlert } from 'react-alert'

const Claim = (props) => {
  document.title = "Claim | LuxBilling";
  const [patientId, setpatientId] = useState(props.PatientId !== undefined ? props.PatientId : 0)
  // const patientId = props.PatientId;
  const testpateint = props.PatientName  // comes From patient Registration
  const [patientName, setPatientName] = React.useState("");

  const [columnID, setColumnID] = React.useState("");
  const [columnIDpayment, setColumnIDpayment] = React.useState("");
  const [PatientNamepayemnt, setPatientNamepayemnt] = React.useState("");
  console.log('tetspateirnt',PatientNamepayemnt)
  
  const [writepayemnt, setwritepayemnt] = React.useState("");
  const [djustmentpayemnt, setadjustmentpayemnt] = React.useState("");
  const [paidpayemnt, setpaidpayemnt] = React.useState("");
  const [allowpayemnt, setallowpayemnt] = React.useState("");
  const [balance, setbalance] = React.useState("");

  
  
  
  console.log('testtttt',writepayemnt)
  
  const [colpatientID, setcolpatientID] = React.useState("");
  const [isChecked, setIsChecked] = useState(false)

  const [openModalpayment, setOpenModalpayment] = React.useState(false);
  const handleCloseModalpayment = () => {

    setColumnIDpayment(0)
    setOpenModalpayment(false);
  } 

  const handleOpenpayment = () => {
    reset();
    setOpenModalpayment(true);
    setColumnID(0)
    // setSelectedPatient("")
  };
  const [openModal, setOpenModal] = useState(false);
  const handleCloseModal = () => {
    // applyRefresh()
    setOpenModal(false)
  
  };

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [Delbyid, setDelbyid] = useState(false);

  const handleDelete = (cell) => {
    // Perform your delete logic here
     const postData = {
      id: Delbyid,
      inactive: true,
    };
    
    axios
    .post(`${process.env.REACT_APP_API_URL}/claims/addclaims`, postData, {
      headers,
    })
      .then((response) => {
        setRefresh(!refresh);
      })
      .catch((error) => {
        console.error('There was an error!', error);
      });
    // Close the dialog
    setDialogOpen(false);
  };

  const handleOpen1 = (value) => {
    reset();
    setOpenModal(true);
    setColumnID(0)
    setPatientName('')
    
    // if(testpateint == '' && patientName != '')
    // {
    //   setPatientName('')
    // }
    // else{
    //   setPatientName(testpateint)
    // }
  };
  const [groupName, setGroupName] = React.useState(false);
  const handleOpengroupName = () => setGroupName(true);
  const handleClosegroupName = () => setGroupName(false);
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.11
    },

    {
      field: 'patientName',
      headerName: 'Patient Name',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.2,
      renderCell: cellValues => (
        <Button
        style={{ display: "flex", justifyContent: "flex-start"}}
          size='small'
          variant='text'
          color='info'
          onClick={(event) => {
            setColumnID(cellValues.row.id)
           setcolpatientID(cellValues.row.patientId)
           setPatientName(cellValues.row.patientName)
            setOpenModal(true)
            // openClaimRegistration(event,cellValues.row.id )
          }}
        >{`${cellValues.row.patientName}`}</Button>
      )
    },
    
    {
      field: 'dos',
      headerName: 'DOS',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.19
    },
    {
      field: 'attendingPhysicianName',
      headerName: 'Rendering',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.23
    },
    {
      field: 'totalCharges',
      headerName: 'Charges',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.15
    },
    {
      field: 'amountAllowed',
      headerName: 'Allowed',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.2
    },
    {
      field: 'amountPaid',
      headerName: 'Paid',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.12
    },
    {
      field: 'adjustments',
      headerName: 'Adj',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.12
    },
    {
      field: 'writeOff',
      headerName: 'Write',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.1
    },
    {
      field: 'amountDue',
      headerName: 'Balance',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.2
    },
    
    
    {
      field: 'payment',
      headerName: 'Payment',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.2,
      renderCell: cellValues =>
        cellValues.row.payment ? (
          <CheckIcon style={{ color: "green" }}
          onClick={(e) => {
            setColumnIDpayment(cellValues.row.id)
            setPatientNamepayemnt(cellValues.row.patientName)
            handleOpenpayment()
            // setAuthRequestid(cell.row.patientId)
            // setPatientNamee(cell.row.PatientName)
            // setMarkaporoved(cell.row.approved)
            // setAuthRegistration(cell.row)
            // handleapprove()
          }}
          
          />
        ) : (
          <ClearIcon style={{ color: "red" }}   onClick={(e) => {
            setColumnIDpayment(cellValues.row.id)
            setPatientNamepayemnt(cellValues.row.patientName)
            handleOpenpayment()
            // setAuthRequestid(cell.row.patientId)
            // setPatientNamee(cell.row.PatientName)
            // setMarkaporoved(cell.row.approved)
            // setAuthRegistration(cell.row)
            // handleapprove()
          }} />
        ),
    },
    {
      field: 'practiceName',
      headerName: 'practice',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.3
    },
    // {
    //   field: 'refferringPhysicianName',
    //   headerName: 'Refferring Name',
    //   cellClassName: 'gridCell',
    //   sortable: true,
    //   headerClassName: 'super-app-theme--header',
    //   flex: 0.3
    // },
    {
      field: 'delete',
      headerClassName: 'super-app-theme--header',
      headerName: 'Delete',
      flex: 0.2,
      align: 'center',
      // headerAlign: 'center',
      renderCell: (cell) => (
       <>
      <DeleteIcon
        style={{
          // marginRight: '5px',
          // float: 'right',
          marginTop: '5px',
          color: 'red',
          cursor: 'pointer',
        }}
        color="black"
        onClick={() =>{ setDialogOpen(true)
setDelbyid(cell.row.id)

        }}
      ></DeleteIcon>

      <Dialog
        open={isDialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="delete-dialog-title"
        BackdropComponent={Backdrop}
        BackdropProps={{
          invisible: true,
        }}
        PaperProps={{
          style: {
            // marginRight: '5px',
          // float: 'right',
          marginTop: '5px',
          color: 'red',
          cursor: 'pointer',
          },
        }}
      >
       <div style={{
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center', // Center horizontally
  justifyContent: 'center', // Center vertically
  borderRadius: '8px',
  width: '500px'
}}>
  
  <ErrorOutlineIcon style={{ fontSize: '100px', color: 'red' }} />
  <DialogTitle style={{ fontWeight: 'bold',color:'black',fontSize:' 1.25rem' }}>Are you sure?</DialogTitle>
  <h5 style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'normal',color:'#444444' }}>
    You won't be able to revert this!
  </h5>
  <DialogContent>
    <DialogContentText>

      <Grid style={{ marginTop: '1rem' }}>
        <Button onClick={() => setDialogOpen(false)} style={{ backgroundColor: '#6c7c84', color: 'white', marginBottom: '1rem' }}>No,cancel!</Button>
        <Button  onClick={() => handleDelete(cell)} style={{ backgroundColor: 'red', color: 'white', marginLeft: '1rem', marginBottom: '1rem',border:'1px solid #b4acec' }}>Yes,delete it!</Button>
      </Grid>
    </DialogContentText>
  </DialogContent>
</div>

      </Dialog>
    </>
      ),
    },
  ]
  const getRowSpacing = React.useCallback(params => {
    return {
      top: params.isFirstVisible ? 0 : 0.5,
      bottom: params.isLastVisible ? 0 : 0.8
    }
  }, [])
 
  const [isLoading, setLoading] = useState(false)
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const gridStyle = {
    color: '#8392ab !important',
    textTransform: 'lowercase',
    fontSize: '13.6px',
    fontWeight: '400',
    '&:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px'
    }
  }

  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 2,
    background: 'drak',
    fontSize: '13.4px !important',
    color: 'drak !important',
    borderColor: '#326C1D',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#377562',
      borderRadius: 0.1,
      color: 'white',
      fontSize: '16px !important',
      
    }, 

  }
  const style1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    height: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    overflow: "auto",
    // overflows: "hide",
    // zIndex: "10",
    customHeader: {
      backgroundColor: 'black', // Apply your desired background color
      color: 'white', // Apply the desired text color
      fontWeight: 'bold',
    },
  };

  const [allowamount, setallowamount] = useState(0);
  const [paidamount, setpaidamount] = useState(0);
  const [adjustmentamount, setadjustmentamount] = useState(0);
  const [writeoff, setwriteoff] = useState(0);




  const [checkno, setcheckno] = useState("");
  const [payer, setpayer] = useState("");
  const [checkamount, setcheckamount] = useState("");
  const [checkdate, setcheckdate] = React.useState(Moment().format('YYYY-MM-DD'))
  const [refreshpayment, setRefreshpayment] = useState(false);
  function applyRefreshpayment() {
    setRefreshpayment(!refreshpayment)
  }
  const AddGroup = (event) => {
    console.log('data',event)
   
   
    const postData = {
      checkno: checkno,
      payer : payer,
      checkamount: checkamount,
      checkdate:checkdate,
      // base64: base64Data
     
    };
   
    axios
    .post(
      `${process.env.REACT_APP_API_URL}/claims/AddERA`,
      postData,
      { headers }
    )
    .then(response => {
      alert.success("Record saved successfully.", {
        type: "success",
        onClose: () => {
          setGroupName(false)
          applyRefreshpayment()
          
        }
      });
    })
    .catch(error => {
      // setSubmitting(false);
      // alert.error(`Error ${error.message}`, {
      //   type: "error"
      // });
    });

  }
  const [groupList, setGroupList] = useState([]);
  
  const [checkSearchResults, setcheckSearchResults] = React.useState(false);
  const [searchText, setSearchText] = useState('');
  const [patientNamee, setPatientNamee] = useState('');
  const [checkNoData, setCheckNoData] = React.useState([]);
  const [checkSearchIndex, setcheckSearchIndex] = React.useState([]);
  const UserType = localStorage.getItem('User_Type')

  const onChangeHandle = async (value) => {
    // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions
    console.log(value);
    setLoading(true);

    const sendData = {
      id: 0,
      checkno: value,
     
     
    };
    axios
      // .post(`${process.env.NEXT_PUBLIC_API_URL}/Patients/searchPatient`, sendData,
      // { headers },
      .post(
        `${process.env.REACT_APP_API_URL}/claims/SearchERA`,
        sendData,
        { headers },
      )

      .then((response) => {
        setCheckNoData(response);
        setcheckSearchResults(true);
        setLoading(false);


      })
      .catch((error) => {
        console.error('There was an error!', error);
        setLoading(false);

      });
  };
  const gridClasses = {
    columnHeader: 'MuiDataGrid-columnHeaders',
  };
  const [value, setvalue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setvalue(newValue);
  };
  const alert = useAlert();
  const [refresh, setRefresh] = useState(false)
  const navigate = useNavigate();
  const [fromDate, setfromDate] = React.useState('')
  const [toDate, settoDate] = useState('')
  const [isAllPractics, setisAllPractics] = useState(false)

  function applyRefresh() {
    setRefresh(!refresh)
  }

  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    // resolver: yupResolver(validationSchema),
    // defaultValues: defValues
  })
  useEffect(() => {
    // Set the default value of fromDate to the 1st date of the current month
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const formattedFromDate = formatDate(firstDayOfMonth);
    setfromDate(formattedFromDate);

    // Set the default value of toDate to the current date
    const formattedToDate = formatDate(today);
    settoDate(formattedToDate);
  }, []); // Empty dependency array ensures this effect runs only once on mount
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const Namee =props.Namee
  const [rows, setRows] = useState(null)
  useEffect(() => {
    if(isChecked){
      onFilterSearch()
    }

  
    },[isChecked,refresh, openModalpayment ])
    useEffect(() => {
        onFilterSearch()
      },[refresh, openModalpayment,isAllPractics ])
    const onFilterSearch = event => {
      if (!isChecked && (!fromDate || !toDate)) {
        console.log("No action needed: checkbox unchecked and no date range selected.");
        return; // Do nothing
      }
  
      setLoading(true);
      setRows(null);
      if (isChecked ) {
        axios.get(`${process.env.REACT_APP_API_URL}/claims/ClaimsSummary?Isall=${true}&patientId=${patientId}&showAllPractices=${isAllPractics}`, { headers })
          .then(response => {
            setRows(response)
            setLoading(false)
           
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
      }
      else {
        console.log("false check", isChecked)
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/claims/ClaimsSummary?dateFrom=${fromDate}&dateTo=${toDate}&patientId=${patientId}&showAllPractices=${isAllPractics}`,
            { headers }
          )
          .then(response => {
            console.log('response.data: ', response)
            setRows(response)
            setLoading(false)
           
          })
          .catch(error => {
            console.error('There was an error!', error)
          })
      }
  
    }
  // const onFilterSearch = event => {
  //   setLoading(true)

  //     axios.get(`${process.env.REACT_APP_API_URL}/claims/ClaimsSummary?dateFrom=${fromDate}&dateTo=${toDate}&patientId=${patientId}`, { headers })
  //       .then(response => {
  //         setLoading(false)
  //         setRows(response)
         
  //       })
  //       .catch(error => {
  //         console.error('Error fetching data:', error);
  //         setLoading(false)

  //       });


  // }
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [errorArray, setErrorArray] = useState([]);


  // console.log('PaymentMethod',paymentDetails)
  useEffect(() => {
            setSearchText('')
    if (columnIDpayment > 0) {
  
      let isComponentMounted = true
      const fetchData = async () =>
        axios
          .get(`${process.env.REACT_APP_API_URL}/claims/PaymentById?claimId=${columnIDpayment}`, {
            headers
          })
          .then((response) => {
            console.log('response.data: ', response)
            if (response) {
              setPaymentDetails(response)
            }

          })
          .catch((error) => {
            console.error('There was an error!', error)
          })
      fetchData()
      return () => {
        isComponentMounted = false
      }
    }
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [columnIDpayment])
  const saveData = data => {
    console.log('testttttt')
    var postData = paymentDetails
    let hasErrors = false;
    let tempErrorArray = paymentDetails.map((row) => !row.paidamount);

    setErrorArray(tempErrorArray);
    hasErrors = tempErrorArray.some((hasError) => hasError);

    if (hasErrors) {
      // If there are any errors, prevent form submission
      return;
    }
     
    
    // if(apptypeError)
    axios
      .post(`${process.env.REACT_APP_API_URL}/claims/addpayment`, postData, { headers })
      .then(response => { 
// alert('Saved Successfully !')
setColumnIDpayment(0)
            setOpenModalpayment(false);
              setRefresh(true)
        alert.success("Record saved successfully.", {
          type: "success",
          onClose: () => { 
            setOpenModalpayment(false);
          },
        });
 
      })
      .catch(error => {
       
        
     
        setRefresh(true)
      })
    setRefresh(true)

  }
  const openClaimRegistration = (event, id = 0) => {
    navigate('/ClaimRegistration', { state: { Claimid: id } })
  }
  const modalstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #356F60',
    boxShadow: 24,
    overflow: 'hidden',
    // p: 4,
    borderRadius: '8px',
    overflow:'auto',
  };
  const patientListStyle = {
    cursor: 'pointer',
    hover: {
      background: '#3b3b3b !important',
      color: 'rgba(5, 152, 236, 0.637) !important'
    },
    color: 'green',
    selection: {
      background: '#D7624E',
      color: 'white'
    }
  };
  const [filename, setfilename] = useState([])
  const [base64Data, setBase64Data] = useState([])
  const [docError, setdocError] = useState(false)
 
    // document Uplaod
    // const fileToBase64 = file => {
    //   return new Promise((resolve, reject) => {
    //     const reader = new FileReader()
    //     reader.readAsDataURL(file)
    //     reader.onload = () => resolve(reader.result)
    //     reader.onerror = error => reject(error)
    //   })
    // }
  
    ///////////////////////////////////
    //Single and multiple files
    // const handleChangeforfile = async files => {
    //   const newFilenames = ""
    //   const newBase64Data = ""
    //   const file = files
    //     const base64 = await fileToBase64(file)
    //     newFilenames.push(file.name)
    //     newBase64Data.push(base64)
  
    //   // for (let i = 0; i < files.length; i++) {
    //   //   const file = files[i]
    //   //   const base64 = await fileToBase64(file)
    //   //   newFilenames.push(file.name)
    //   //   newBase64Data.push(base64)
    //   // }
  
    //   setfilename(newFilenames)
    //   setBase64Data(newBase64Data)
    //   setdocError(false)
    // }
  return (
    <>
     <Modal
        open={groupName}
        onClose={handleClosegroupName}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalstyle}>
          <Grid container spacing={1}>
        
         
          <Grid item xs={12} sm={12} xl={12} > 
        <Headroom >
            <Grid
                  container
                  style={{ backgroundColor: '#356F60', position: 'sticky'}}
                >
                  <Grid item xs={12} sm={12} xl={12}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'left',
                        justifyContent: 'space-between',
                        height: '2rem'
                      }}
                    >
                      <DialogTitle
                        style={{
                          flex: 1,
                          // textAlign: 'left',
                        }}
                      >
                        <h1
                          style={{
                            fontWeight: 'bold',
                            // textAlign: 'left',
                            marginTop:'-10px', 
                            color: '#fff',
                            textDecoration: 'underline',
                            fontSize:'1rem'

                          }}
                        >
                          Check Add

                        </h1>
                      </DialogTitle>
                      <CancelOutlinedIcon
                       onClick={handleClosegroupName}
                        fontSize="large"
                        color="red"
                        style={{
                          color: 'red',
                          paddingRight: '5px',
                          cursor: 'pointer',
                          float:'right'
                          // marginLeft: 'auto',
                          // marginRight: '1.5rem'
                        }}
                      ></CancelOutlinedIcon>
                    </div>
                  </Grid>
                </Grid>
              </Headroom>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
          <Grid item xs={2} sm={2} xl={2}>
                <Typography style={{ fontSize: '14px',marginTop:'5px' }}>
                    Check No
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4} xl={4}>
                <Controller
                        name="FullName"
                        // {...register("userName")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                            size="small"
                            placeholder="Please enter No"
                            {...field}
                            fullWidth
                            value={checkno}
                            onChange={ev => {
                              console.log('ee:', ev.target.value)
                             setcheckno(ev.target.value)
                              
                              }
                            }
                           
                          />}
                      />
                
                </Grid>
                <Grid item xs={2} sm={2} xl={2}>
                <Typography style={{ fontSize: '14px',marginTop:'5px' }}>
             Payer
                  </Typography>
                </Grid>
                <Grid item xs={3} sm={3} xl={3}>
                <Controller
                        name="FullName"
                        // {...register("userName")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                         
                            size="small"
                            placeholder="Please enter Payer"
                            {...field}
                            fullWidth
                            value={payer}
                            onChange={ev => {
                              console.log('ee:', ev.target.value)
                             setpayer(ev.target.value)
                              
                              }
                            }
                           
                          />}
                      />
                
                </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
          <Grid item xs={2} sm={2} xl={2}>
                <Typography style={{ fontSize: '14px',marginTop:'5px' }}>
                    Check Date
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4} xl={4}>
                <Controller
                        name="FullName"
                        // {...register("userName")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                          type='date'
                            size="small"
                           
                            {...field}
                            fullWidth
                            value={checkdate}
                            onChange={ev => {
                              console.log('ee:', ev.target.value)
                             setcheckdate(ev.target.value)
                              
                              }
                            }
                           
                          />}
                      />
                
                </Grid>
                <Grid item xs={2} sm={2} xl={2}>
                <Typography style={{ fontSize: '14px',marginTop:'5px' }}>
             Check Amount
                  </Typography>
                </Grid>
                <Grid item xs={3} sm={3} xl={3}>
                <Controller
                        name="FullName"
                        // {...register("userName")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                         
                            size="small"
                            placeholder="Please enter Amount"
                            {...field}
                            fullWidth
                            value={checkamount}
                            onChange={ev => {
                              console.log('ee:', ev.target.value)
                             setcheckamount(ev.target.value)
                              
                              }
                            }
                           
                          />}
                      />
                
                </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              {/* <Grid item xs={8.5} sm={8.5} xl={8.5}></Grid>
              <Grid item xs={3.5} sm={3.5} xl={3.5} style={{marginTop:'10px'}}>
              <Button className="Button" variant='contained' component='label' >
                Upload Document
                <input
                  type='file'
                  name='file'
                  onInput={e => handleChangeforfile(e.target.files)}
                  style={{ display: 'none' }}
                  multiple 
                />
              </Button>
              </Grid> */}
             
              






            
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
            
                <Grid item xs={11} sm={11} xl={11} style={{marginTop:'10px'}}>
                <Button
          className="Button"
                    onClick={AddGroup}
                    // disabled={submitting}
                fullWidth
                 style={{borderRadius:'10px',color:'white',height:'40px',borderColor:"#EA7772",fontSize:'14px',fontWeight:'bold',float:'right',marginBottom:'10px'}}
                  type="submit"
                 >
                   Save
                   </Button>
                </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

          </Grid>
         
        </Box>
      </Modal>
       <Modal
            open={openModalpayment}
            onClose={handleCloseModalpayment}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style1}>
              <Headroom>
                <Grid
                  container
                  style={{ backgroundColor: '#356F60', position: 'sticky' }}
                >
                  <Grid item xs={3}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: '2rem'
                      }}
                    >
                      <DialogTitle
                        style={{
                          flex: 1,
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="standard" fontWeight="bold" color="#fff"> Add Payment</Typography>
                      </DialogTitle>
                 
                    </div>
                  </Grid>
                  <Grid item xs={2.9}>
              <Typography style={{color:'white', marginTop: '8px',float:'right',fontWeight:'bold'}}>Patient Name:</Typography>
              </Grid>
              <Grid item xs={2}>
              <Typography style={{color:'white', marginTop: '8px',}}>{PatientNamepayemnt}</Typography>

             
              </Grid>
              <Grid item xs={2.5}>
              <Typography style={{color:'white', marginTop: '8px',float:'right',fontWeight:'bold'}}>ID:</Typography>
              </Grid>
              <Grid item xs={1}>
              <Typography style={{color:'white', marginTop: '8px',}}>{columnIDpayment}</Typography>

             
              </Grid>
                  <Grid item xs={0.5} >
                  <CancelOutlinedIcon
                        onClick={handleCloseModalpayment}
                        fontSize="large"
                        color="red"
                        style={{float:'right',
                          color: 'red',
                          paddingRight: '5px',
                          cursor: 'pointer',
                          // marginLeft: 'auto',
                          // marginRight: '1.5rem'
                        }}
                      ></CancelOutlinedIcon>
                  </Grid>

                </Grid>
              </Headroom>
              <Grid style={{ backgroundColor: '#f3f3f4', margin: '1.3rem auto', border: '1px solid #326C1D', borderRadius: '10px', width:'98%'}}>
              <Grid container >
              {paymentDetails != null && paymentDetails.length > 0
                  ? paymentDetails.map((row, index) => {
                    

                    return (
                      <Paper
                        variant='outlined'
                        square
                        style={{
                          backgroundColor: '#F0F6F8',
                          borderRadius: '15px',
                          marginTop: '5px',
                          borderColor: '#377562',
                          maxWidth: '100%',
                          padding: '.9rem',
                          marginBottom: '1rem',
                          marginLeft: '0 .2rem'
                        }}
                      >
                        <Grid container spacing={2} item xs={12} sm={12} xl={12}>
                        
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography style={{ fontSize: "16px", textDecoration: "underline" }}>
                          Procedure code:
                        </Typography>
                        <TextField
                                  size='small'
                                  value={row.code}
                                  
                                  style={{ width: '99%' }}
                                  InputProps={{
                                    readOnly: true,
                                  }}

                                />
                      

 


                        </Grid>

                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Typography style={{ fontSize: "16px", textDecoration: "underline" }}>
                              Diagnosis code:
                            </Typography>
                            <TextField
                                  size='small'
                                  value={row.dxPointer1}
                                  
                                  style={{ width: '99%' }}
                                  InputProps={{
                                    readOnly: true,
                                  }}

                                />

                          </Grid>

                          <Grid item xs={12} sm={6} md={4} lg={3} >
                            <Typography style={{ fontSize: "16px", textDecoration: "underline"}}>
                              DOS
                            </Typography>


                            <TextField
                                // type='date'
                                  size='small'
                                  value={row.dos}
                                  
                                  style={{ width: '99%' }}
                                  InputProps={{
                                    readOnly: true,
                                  }}

                                />
                          </Grid>

                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Typography style={{ fontSize: "16px", textDecoration: "underline"}}>
                              Charge $
                            </Typography>
                            <TextField
                          
                                  size='small'
                                  value={row.charges}
                                  
                                  style={{ width: '99%' }}
                                  InputProps={{
                                    readOnly: true,
                                  }}

                                />

                          </Grid>
                          <Grid item xs={3} >
   <Typography style={{ fontSize: "16px", textDecoration: "underline" }}>
     Check#:
   </Typography>
 
 
 
   <ClickAwayListener onClickAway={() => setcheckSearchResults(false)}>
                 <TextField
                   size="small"
                   autoComplete="off"
                   style={{ width:'99%' }}
                   placeholder="Search Check No"
                  value={searchText.length>0 && checkSearchIndex!=index && !checkSearchResults?
                   searchText:row.checkno}
                   // inputProps={{
                   //   style: {
                   //     minHeight: '38px',
                   //     padding: '0 1px'
                   //   }
                   // }}
                   
                   //  value={patientNamee}
                  //  styles={{ control: provided => ({ ...provided, width: '99%' }) }}
                   onKeyUp={(ev) => {
                    // var _row=paymentDetails; 
                    // _row[checkSearchIndex].checkno= ev.target.value;  
                    // console.log('rows_________',_row)
                    //  setPaymentDetails([..._row])

                     console.log('ee:', ev.target.value);
                    //  row.checkno=ev.target.value
                     setSearchText(ev.target.value);
                     setCheckNoData([]);
                     setcheckSearchIndex(index)
                      //comehere
                     // dont fire API if the user delete or not entered anything
                     if (ev.target.value !== null && ev.target.value !== '' && ev.target.value.length > 0) {
                       onChangeHandle(ev.target.value);
                     } else {
                       setcheckSearchResults(false);
 
                     }
                   }}
                   // error={errors.patientName}
 
                 />
               </ClickAwayListener>
               {isLoading && index==checkSearchIndex ? (
                 <CircularProgress size={20} style={{ marginLeft: '-40px', }} />
               ):<></>}
               {/* <p style={{ color: "red", fontSize: "14px" }}>
                 {errors.patientName?.message}
               </p> */}
               {checkSearchResults && index==checkSearchIndex ? (
                 <div
                   className="col-xs-6"
                   style={{
                     height: 150,
                     overflowY: 'scroll',
                     padding: '14px 16px',
                     fontSize: '0.875rem',
                     color: '#67748e',
                     borderRadius: '5px',
                     background: 'white',
                     textAlign: 'justify',
                     textJustify: 'inter-word',
                     position: 'absolute',
                     zIndex: '99',
                     width: '39%',
                     border: '1px solid #6cb5f9'
                   }}
                 >
                   {checkSearchResults ? (
                     checkNoData.length > 0 ? (
                       checkNoData.map((s, index) => {
                         var pat = `${checkNoData[index].checkno}`
                         // var patLower = pat.toLowerCase();
                         var i = pat.indexOf(searchText)
                         console.log('i:', i)
                         var p1 = pat.substring(0, i)
                         var p = pat.substring(i, searchText.length)
                         console.log('p:', p)
                         var p2 = pat.substring(searchText.length, pat.length)
 
                         return (
                           <>
                             <p
                               style={patientListStyle}
                               onClick={(e) => {
                                //  setValue(
                                //    'checkno',
                                //    `${checkNoData[index].checkno}`
                                //  );
                                 console.log('selected check:____________ ',checkNoData[index])
                                 var _row=paymentDetails;
                                 _row[checkSearchIndex].eRAId=checkNoData[index].id;
                                 _row[checkSearchIndex].checkno=checkNoData[index].checkno;
                                 _row[checkSearchIndex].payer=checkNoData[index].payer;
                                 _row[checkSearchIndex].checkdate=checkNoData[index].checkdate;
                                 _row[checkSearchIndex].checkamount=checkNoData[index].checkamount;
                                 console.log('index_________',checkSearchIndex)
                                 console.log('rows_________',_row)
                                  setPaymentDetails([..._row])
                                //  setValue('eRAId', `${checkNoData[index].id}`)
                                //  setValue('payer', `${checkNoData[index].payer}`)
                                //  setValue('checkdate', `${checkNoData[index].checkdate}`)
                                //  setValue('checkamount', `${checkNoData[index].checkamount}`)  
                                 setCheckNoData([]);
                                 setSearchText('')
                                //  setPatient(false);
                                //  setPatientNamee(
                                //    `${checkNoData[index].checkno}`
                                //  );
                                 // setPatientNamee(
                                 //   `${patientData[index].lastName}, ${patientData[index].firstName}`
                                 // );
                                 // setselectpatientid(patientData[index].id)
                               }}
                               onBlur={(e) => {
                                 setCheckNoData([]);
                                 setcheckSearchResults(false);
                               }}
                               onChange={(e) => {
                                 setCheckNoData([]);
                                 setcheckSearchResults(false);
                                 // setPatientNamee('');
                               }}
                             >
                               <span>{p1}</span>
                               <span style={{ backgroundColor: 'yellow' }}>{p}</span>
                               <span>{p2}</span>
                               {/* {s.patientId} */}
                               {/* <Divider variant="hard" /> */}
                             </p>
                           </>
                         );
                       })
                     ) : (
                       <Typography>No Record found</Typography>
                     )
                   ) : null}
                 </div>
               ) : (
                 ''
               )}
 
 
  
                          </Grid>
                          <Grid item xs={0.5} style={{marginTop:'25px'}}>
                          <AddCircleOutlineRoundedIcon 
                              onClick={handleOpengroupName}
                            fontSize="large"
                            color="green"
                            style={{
                              color: 'green',
                              paddingRight: '5px',
                              cursor: 'pointer',
                            }}
                          >
            
                          </AddCircleOutlineRoundedIcon>
            
                          </Grid>
                          <Grid item xs={3} >
              <Typography style={{ fontSize: "16px", textDecoration: "underline" }}>
                Payer:
              </Typography>
            
            
              <Controller
                                    name="payer"
                                    {...register("payer")}
                                    control={control}
                                    render={({ field }) =>
                                      <TextField
                                        size="small"
                                        placeholder="Please enter payer"
                                        {...field}
                                        fullWidth
                                        value={row.payer} 
                                        InputProps={{
                                          readOnly: true,
                                        }}
                                        
                                      />}
                                  />
                          </Grid>
                          <Grid item xs={3} >
              <Typography style={{ fontSize: "16px", textDecoration: "underline" }}>
                Check Date:
              </Typography>
              <TextField
                                // type='date'
                                  size='small'
                                  value={row.checkdate}
                                  
                                  style={{ width: '99%' }}
                                  InputProps={{
                                    readOnly: true,
                                  }}

                                /> 
                          </Grid>
                          <Grid item xs={2.5} >
              <Typography style={{ fontSize: "16px", textDecoration: "underline" }}>
                Check Amount:
              </Typography>
            
            
              <Controller
                                    name="checkamount"
                                    {...register("checkamount")}
                                    control={control}
                                    render={({ field }) =>
                                      <TextField
                                      type='test'
                                        size="small"
                                        placeholder="Please Enter Check amount"
                                        {...field}
                                        fullWidth
                                        value={row.checkamount}
                                        
                                        InputProps={{
                                          readOnly: true,
                                        }}
                                        
                                      />}
                                  />
                          </Grid>
                                      
                          <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography style={{ fontSize: "16px", textDecoration: "underline" }}>
              Allowed Amount
              </Typography>
            
              
                                      <TextField
                                        size="small"
                                        placeholder="Please enter Allowed Amount"                                    
                                        fullWidth
                                        value={row.allowamount}
                                        onChange={ev => {
                                          console.log('ee:', ev.target.value)
                                        var _row = paymentDetails;
                                        _row[index].allowamount=(ev.target.value);
                                       
                                      

                                        setPaymentDetails([..._row])
                                          
                                          }
                                        }
                                        
                                      /> 
                               
            
            
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Typography style={{ fontSize: "16px", textDecoration: "underline" }}>
                            Paid Amount
                            </Typography>
                          
                            <Controller
                                                  name="paidmount"
                                                  // {...register("userName")}
                                                  control={control}
                                                  render={({ field }) =>
                                                    <TextField
                                                  
                                                      size="small"
                                                      placeholder="Please enter Paid Amount"
                                                      {...field}
                                                      fullWidth
                                                      value={row.paidamount}
                                                    onChange={ev => {
                                                      console.log('ee:', ev.target.value)
                                                    var _row = paymentDetails;
                                                    _row[index].paidamount=(ev.target.value);
                                                    _row[index].balance = row.charges-row.writeoff - row.adjustmentamount -row.paidamount ;

                                                    // setpaidpayemnt(ev.target.value)
                                                    setPaymentDetails([..._row])
                                                      
                                                      }
                                                    }
                                                                  
                                                    />}
                                                />
          {errorArray[index] && (
            <Typography style={{ color: 'red', marginLeft: 15 }}>
              Please fill the Paid Amount
            </Typography>
          )}
       
   
            
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Typography style={{ fontSize: "16px", textDecoration: "underline" }}>
                            Adjustment Amount
                            </Typography>
                          
                            <Controller
                                                  name="FullName"
                                                  // {...register("userName")}
                                                  control={control}
                                                  render={({ field }) =>
                                                    <TextField
                                                  
                                                      size="small"
                                                      placeholder="Please enter Adjustment Amount"
                                                      {...field}
                                                      fullWidth
                                                      value={row.adjustmentamount}
                                                    onChange={ev => {
                                                      console.log('ee:', ev.target.value)
                                                    var _row = paymentDetails;
                                                    _row[index].adjustmentamount=(ev.target.value);
                                                    // setadjustmentpayemnt(ev.target.value)
                                                    _row[index].balance = row.charges-row.writeoff - row.adjustmentamount -row.paidamount ;

                                                    setPaymentDetails([..._row])
                                                      
                                                      }
                                                    }
                                                      
                                                    />}
                                                />
                          
                          
                          </Grid>
                         
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Typography style={{ fontSize: "16px", textDecoration: "underline" }}>
                            Write Off
                            </Typography>
                          
                            <Controller
                                                  name="FullName"
                                                  // {...register("userName")}
                                                  control={control}
                                                  render={({ field }) =>
                                                    <TextField
                                                  
                                                      size="small"
                                                      placeholder="Please enter Write OFF Amount"
                                                      {...field}
                                                      fullWidth
                                                      value={row.writeoff}
                                                      onChange={ev => {
                                                       
                                                      var _row = paymentDetails;
                                                      _row[index].writeoff=(ev.target.value);
                                                      _row[index].balance = row.charges-row.writeoff - row.adjustmentamount -row.paidamount ;

                                                      
                                                      console.log('ee:', _row)
                                                      setPaymentDetails([..._row])
                                                        
                                                        }
                                                      }
                                                      
                                                    />}
                                                />
                          
                          
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={9}>
                          
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={1}>
                            <Typography style={{ fontSize: "16px", marginTop:'6px' }}>
                            Balance 
                            </Typography>
                          
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={2}>
                          <Controller
                                                  name="FullName"
                                              
                                                  control={control}
                                                  render={({ field }) =>
                                                    <TextField
                                                  
                                                      size="small"
                                                      {...field}
                                                      fullWidth
                                                      value={row.balance}
                                                      onChange={ev => {
                                                        console.log('ee:', ev.target.value)
                                                      var _row = paymentDetails;
                                                     
                                                      setPaymentDetails([..._row])
                                                      // setbalance(_row)
                                                        }
                                                      }
                                                      InputProps={{
                                                        readOnly: true,
                                                      }}
                                                      
                                                    />}
                                                />
                          </Grid>


                      
                        </Grid>
                      </Paper>
                    )
                  })
                  : 'No Record Found'}
 <Grid item xs={11} lg={11} sm={11}>
                                      <Button
                                       className='Button'
                                        onClick={handleSubmit(saveData)}
                                        variant='gradient'
                                      
                                        style={{
                                          marginTop: '15px',
                                          marginBottom: '10px',
                                          float: 'right',
                                          width: '87px',
                                          height: '28px',
                                          // backgroundColor: '#56CA00',
                                          color: 'white',
                                          fontSize: '12px',
                                          marginRight: '-50px'
                                        }}
                                      // disabled={submitting}
                                      >
                                        <SaveOutlinedIcon
                                          fontSize='medium'
                                          style={{
                                            color: 'white',
                                            paddingRight: '5px',
                                            paddingBottom: '2px'
                                          }}
                                        ></SaveOutlinedIcon>
                                        Save
                                      </Button>
                                    </Grid>
                                    <Grid item xs={1} lg={1} sm={1}>

                                    </Grid>

              </Grid>
             
             
              </Grid>
             
            

             
            </Box>
          </Modal>
     {openModal ? (<>
      <>
          
          <Grid
            container
            xs={12}
            spacing={1}
            style={{
              marginTop: '50px',
              marginBottom:'10px',
              // borderRadius: '20px',
              // border: '1px solid rgba(5, 152, 236, 0.637)',
              // overflow:'scroll'
            }}
          >
            <Grid  container >
            <Grid item xs={0.3}></Grid>
          
            <Grid item xs={11.2}>
            {patientId>0?(<></>):(
            <>
            <Typography variant="h5" fontWeight="bold" style={{marginTop:'2rem'}}>Claim Submission Request</Typography>

              
            
            </>)}
            </Grid>
            <Grid item xs={0.5}></Grid>
            </Grid>
           
            <Grid
              container
              
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ width: '100%', marginTop: '1px', borderRadius: '20px', height: '100%', }}
              bgcolor={'#356F60'}
            >
              
               
              <Grid item xs={12} sm={4} md={4} lg={4}>
              <Button
                className="Button"
                onClick={handleCloseModal}
                variant='contained'
                color='info'
                size='small'
                style={{
                  width: '20px',
                  float: 'left',
                  marginLeft: '15px',
                  //  marginRight: '10px',
                  height: '23px',
                  marginTop: '8px',
                  //  backgroundColor:'#E87426'
                }}
              >
                <ArrowBackIosIcon
                  fontSize='medium'
                  style={{
                    color: '#1a82ad',
                    //  padding: "5px",
                    //  marginLeft: '5px'
                  }}
                ></ArrowBackIosIcon>
                Back
              </Button>

              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
              <Typography style={{color:'white', marginTop: '8px',textAlign:'center',fontWeight:'bold'}}>Patient Name: <span style={{fontWeight:'normal'}}>{patientName}</span></Typography>
              </Grid>
          
              <Grid item xs={12} sm={4} md={4} lg={4}>
              <Typography style={{color:'white', marginTop: '8px',textAlign:'center',fontWeight:'bold'}}>ID: <span style={{fontWeight:'normal'}}>{columnID}</span></Typography>
              </Grid>
             
              
            </Grid> 
            <Grid item xs={12} >
              <ClaimRegistration  Namee={Namee} claimId={columnID} patientName={patientName} claimpatientId ={colpatientID} patientId={patientId}  CloseModal={handleCloseModal} applyRefresh={applyRefresh}></ClaimRegistration>
            

             
            </Grid>
          </Grid>
        </>
     
     
     </>):(
      <>
      {patientId>0?(<>
        <div >
              <Container >
              <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={4} lg={2}>
              <Checkbox
                value={isChecked}
                style={{ marginTop: '20px' }}
                onChange={e => {
                  setIsChecked(e.target.checked)
                 
                  console.log('Test', e.target.checked)
                }}
               
               
              />
              <Typography style={{ marginTop: '-33px', marginLeft: '40px' }}>All</Typography>

            </Grid>
             
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Typography> From Date </Typography>
                <Controller
                  name='fromDate'
                  // {...register('fromDate')}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      // isClearable
                      size='small'
                      type='date'
                      disabled={isChecked == true ? (true) : (false)}
                      {...field}
                      fullWidth
                      value={fromDate}
                      // error={errors.fromDate}
                      onChange={e => {
                        setValue('fromDate', e.target.value)
                        setfromDate(e.target.value)

                      }}
                    />
                  )}
                />

              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Typography> Date To </Typography>
                <Controller
                  name='toDate'
                  // {...register('toDate')}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      // isClearable
                      size='small'
                      type='date'
                      disabled={isChecked == true ? (true) : (false)}
                      {...field}
                      fullWidth
                      value={toDate}
                      // error={errors.fromDate}
                      onChange={e => {
                        setValue('toDate', e.target.value)
                        settoDate(e.target.value)

                      }}
                    />
                  )}
                />

              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Button
                  className="Button"
                  variant="contained"
                  onClick={onFilterSearch}
                  style={{
                    float: 'right',
                    width: '125px',
                    height: '35px',
                    marginTop: '25px',

                    fontSize: '14px',
                    color: 'white',
                    // backgroundColor:'#004cab'
                  }}
                >
                  <SearchOutlinedIcon
                    fontSize='medium'
                    style={{ color: 'white', paddingBottom: '1px' }}
                  ></SearchOutlinedIcon>
                  Search
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Button
                  className="Button"
                  variant="contained"
                  onClick={handleOpen1}
                  style={{
                    float: 'right',
                    width: '125px',
                    height: '35px',
                    marginTop: '25px',
                    marginLeft:'50px',
                    fontSize: '14px',
                    color: 'white',
                    // backgroundColor:'#004cab'
                  }}
                >
                  <AddOutlinedIcon
                    fontSize='medium'
                    style={{ color: 'white', paddingBottom: '1px' }}
                  ></AddOutlinedIcon>
                  Add new
                </Button>
              </Grid>
              </Grid>
              {/* <div style={{ width: 200, marginTop: '5px', marginBottom: '0px' }}>

                  <Button sx={{ mb: 1 }} className="Button" onClick={openClaimRegistration} variant='contained'>
                  <AddOutlinedIcon
                  fontSize='small'
                  style={{
                    color: 'white',
                    paddingRight: '4px',
                    paddingBottom: '2px'
                  }}
                ></AddOutlinedIcon>
                    Add New
                  </Button>
                </div> */}
              
                <Box mb={3} marginTop={2}>
            {isLoading ? (
              <div style={{ margin:'5rem', textAlign:'center' }}>
                <CircularProgress /> 
              </div>
            ) : (
              <div style={{ height: 400, width: "100%" }}>
                {rows === null || rows.length === 0 ? (
              <Typography>No Record found</Typography>
            ) : (
                  <DataGrid
                  rows={rows}
                  classes={gridClasses}
                  rowHeight={20}
                  columns={columns}
                  getRowSpacing={getRowSpacing}
                  //checkboxSelection
                  fullWidth
                  pagination
              autoPageSize
                  sx={gridRowStyle}
                />
                )}
              </div>
              )}
            </Box>
                
              </Container>
            </div>

      </>):(<>
        <div className="page-content">
              <Container >
              <Grid container spacing={1}>
              <Grid item xs={1}  >
              <Checkbox
                value={isChecked}
                style={{ marginTop: '20px' }}
                onChange={e => {
                  setIsChecked(e.target.checked)
                 
                  console.log('Test', e.target.checked)
                }}
               
               
              />
              <Typography style={{ marginTop: '-33px', marginLeft: '40px' }}>All</Typography>

            </Grid>
            {UserType !== 'Front Office' ? (
            <Grid item xs={1.8}>
              <Checkbox
                                  value={isAllPractics}
                                  style={{ marginTop: '20px' }}
                onChange={e => {
                  setisAllPractics(e.target.checked)
                }}
               
               
              />
              <Typography style={{ marginTop: '-33px', marginLeft: '40px' }}>All Practices</Typography>

            </Grid>
            ) :
           ( <Grid item xs={1.8}></Grid>)
            }





            
              <Grid item xs={12} sm={6} md={4} lg={2.5}>
                <Typography> From Date </Typography>
                <Controller
                  name='fromDate'
                  // {...register('fromDate')}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      // isClearable
                      size='small'
                      type='date'
                      disabled={isChecked == true ? (true) : (false)}
                      {...field}
                      fullWidth
                      value={fromDate}
                      // error={errors.fromDate}
                      onChange={e => {
                        setValue('fromDate', e.target.value)
                        setfromDate(e.target.value)

                      }}
                    />
                  )}
                />

              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={2.5}>
                <Typography>To Date </Typography>
                <Controller
                  name='toDate'
                  // {...register('toDate')}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      // isClearable
                      size='small'
                      type='date'
                      disabled={isChecked == true ? (true) : (false)}
                      {...field}
                      fullWidth
                      value={toDate}
                      // error={errors.fromDate}
                      onChange={e => {
                        setValue('toDate', e.target.value)
                        settoDate(e.target.value)

                      }}
                    />
                  )}
                />

              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Button
                  className="Button"
                  variant="contained"
                  onClick={onFilterSearch}
                  style={{
                    float: 'right',
                    width: '140px',
                    height: '35px',
                    marginTop: '25px',

                    fontSize: '14px',
                    color: 'white',
                    // backgroundColor:'#004cab'
                  }}
                >
                  <SearchOutlinedIcon
                    fontSize='medium'
                    style={{ color: 'white', paddingBottom: '1px' }}
                  ></SearchOutlinedIcon>
                  Search
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Button
                  className="Button"
                  variant="contained"
                  onClick={handleOpen1}
                  style={{
                    float: 'right',
                    width: '140px',
                    height: '35px',
                    marginTop: '25px',
                    marginLeft:'50px',
                    fontSize: '14px',
                    color: 'white',
                    // backgroundColor:'#004cab'
                  }}
                >
                  <AddOutlinedIcon
                    fontSize='medium'
                    style={{ color: 'white', paddingBottom: '1px' }}
                  ></AddOutlinedIcon>
                  Add new
                </Button>
              </Grid>
              </Grid>
              {/* <div style={{ width: 200, marginTop: '5px', marginBottom: '0px' }}>

                  <Button sx={{ mb: 1 }} className="Button" onClick={openClaimRegistration} variant='contained'>
                  <AddOutlinedIcon
                  fontSize='small'
                  style={{
                    color: 'white',
                    paddingRight: '4px',
                    paddingBottom: '2px'
                  }}
                ></AddOutlinedIcon>
                    Add New
                  </Button>
                </div> */}
              
                <Box mb={3} marginTop={2}>
            {isLoading ? (
              <div style={{ margin:'5rem', textAlign:'center' }}>
                <CircularProgress /> 
              </div>
            ) : (
              <div style={{ height: 400, width: "100%" }}>
                {rows === null || rows.length === 0 ? (
              <Typography>No Record found</Typography>
            ) : (
                  <DataGrid
                  rows={rows}
                  classes={gridClasses}
                  rowHeight={20}
                  columns={columns}
                  getRowSpacing={getRowSpacing}
                  //checkboxSelection
                  fullWidth
                  pagination
              autoPageSize
                  sx={gridRowStyle}
                />
                )}
              </div>
              )}
            </Box>
                
              </Container>
            </div>

      </>)}
      </>
     )}
   
      
          </>
     
  )
}

export default Claim
