import Card from '@mui/material/Card'

// Soft UI Dashboard React components
import { useAlert } from 'react-alert'

import { Grid, Snackbar, TextField } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import axios from 'axios'
import React, { useEffect, useReducer, useMemo, useState, Fragment, useRef } from 'react'
import Box from '@mui/material/Box'
import { Controller, useForm } from 'react-hook-form'

import Button from '@mui/material/Button'
// import Avatar from 'react-avatar'

import "./Button.css"
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import AddComents from './AddComents'
import DisplayComents from './DisplayComents'

const ComItem = (props) => {
  const [messagelist, setMessagelist] = useState([])
  const [commentId, setcommentId] = useState(props.commentId)
  const [refresh, setrefresh] = useState(false)
  const [apiError, setApiError] = useState('')
  const [showbtn, setshowbtn] = useState(true)
  const [selectedRow, setselectedRow] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [patientID, setPatientID] = React.useState(props.patientId)
  const [patientName, setpatientName] = React.useState(props.patientName)
  const [rows, setRows] = useState('')
  console.log('set patient id', patientID)
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
//   const router = useRouter()
  const alert = useAlert()
  const headers = {
    Authorization: auth
  }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 520,
    padding:'5px',
    height: '520px',
    bgcolor: '#F3F3F4',
    border: '2px solid #3c7f6b',
    boxShadow: 24,
    overflowY: 'auto',
    p: 4,
    borderRadius: '8px',
    scrollbarWidth: 'thin', // For Firefox
  scrollbarColor: '#3c7f6b #F3F3F4'

  }
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined
    // resolver: yupResolver(validationSchema),
    // defaultValues: defValues
  })
  function successFuncc(id) {
    setrefresh(!refresh)
  }
  function AddNewMessage() {
    var obj = {
      id: 0,
      patientName: patientName,
      patientId: patientID,
      parentId: props.commentId,
      patientNoteId: 0,
      comments: '',
      dueDate: '',
      assignedTo: '',
      archive: false
    }

    var arr = messagelist
    arr.push(obj)
    setMessagelist([...arr])
  }
  function applyRefresh() {
    setrefresh(!refresh)
  }
  const onSave = data => {
    setApiError('')
    setSubmitting(true)
    setselectedRow()
    const postData = data
    postData.archive = true
    postData.id = props.commentId
    console.log('🚀 ~ file: CommentsList.js:387 ~ onSubmit ~ postData-----', postData)
    //postData.id = 0
    // if (commentId <= 0) postData.id = 0

    axios
      .post(`${process.env.REACT_APP_API_URL}/PatientToDoList/AddPatientToDoList`, postData, {
        headers
      })
      .then(response => {
        if (response.id) {
          setshowbtn(true)
          setSubmitting(false)
          setrefresh(true)
          applyRefresh()
          props.handleClose()
          setRows(response)
          alert.success('Record saved successfully.', {
            type: 'success',
            onClose: () => {
              props.handleClose()
            }
          })
        } else {
          setApiError(response)
          setSubmitting(false)
          // props.handleOpen(false)
          // setOpens(false)
          props.handleClose()
        }
      })
      .catch(error => {
        setSubmitting(false)
        // props.handleOpen(false)
        // setOpens(false)
        alert.success(`Error ${error.message}`, {
          type: 'error'
        })
      })
  }
  const onclickreply = () => {
    AddNewMessage()
  }

  useEffect(() => {
    console.log('🚀 ~ file:  commentId _______ ', commentId)
    if (commentId > 0) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/PatientToDoList/getPatientToDoListById?Id=${commentId}`, {
          headers
        })
        .then(response => {
          setMessagelist(response)
         
        })
        .catch(error => {
          console.error('There was an error!', error)
        })
    }
  }, [commentId, refresh])



  return (
    <Box sx={style}>
      {/* <Grid item xs={12}> */}
       
      {/* </Grid> */}

      <Grid container >
          <Grid item xs={12} style={{marginTop:'-20px'}}> 
          <ClearIcon
          onClick={props.handleClose}
          fontSize='small'
          style={{
            cursor: 'pointer',
            color: 'red',
            float: 'right',
            marginBottom: '10px',
            width: '20.57px',
            height: '20.57px',
            // top: '1.71px',
            left: '1.71px',
            border: '1.5px'
          }}
        ></ClearIcon>
       </Grid> 
      
      {messagelist&&
        messagelist.map(item => (
          <>
            <Grid item xs={12}>
              {item.id > 0 && showbtn? (
                <DisplayComents
                  replyFunc={AddNewMessage}
                  commentId={commentId}
                  patientId={patientID}
                  message={item}
                  handleClose={props.handleClose}
                ></DisplayComents>
              ) : (
                <AddComents
                  replyFunc={AddNewMessage}
                  successFunc={successFuncc}
                  commentId={commentId}
                  patientId={patientID}
                  patientName={patientName}
                  message={item}
                  handleClose={props.handleClose}
                ></AddComents>
              )}
            </Grid>
          </>
        ))}

        </Grid>
      {messagelist.length == 0 ? (
        <AddComents
          handleClose={props.handleClose}
          successFunc={successFuncc}
          patientName={patientName}
          patientId={patientID}
          commentId={commentId}
        ></AddComents>
      ) : null}
{props.resolved==false ?(<>
  {messagelist.length > 0 ? (
        <>
          {}
          {showbtn?( <Grid item xs={12} lg={12} sm={12}>
         <Button
         className='Button'
              onClick={()=>{onclickreply()
                setshowbtn(false)}
              }
              variant='gradient'
              style={{
                marginTop: '10px',
                marginBottom: '10px',
                float: 'right',
                marginLeft: '10px',
                width: '90px',
                height: '35px',
                // background: 'linear-gradient(180deg, rgb(209, 52, 80) 0%, rgb(209, 52, 80) 0%, rgb(240, 130, 49) 100%)',
                color: 'white',
                fontSize: '14px'
              }}
            >
              Reply
            </Button>
            {commentId > 0 ? (
              <Button
              className='Button'
                variant='gradient'
                onClick={
                  handleSubmit(onSave)
                  //setselectedRow()
                }
                style={{
                  marginTop: '10px',
                  marginBottom: '10px',
                  float: 'right',
                  width: '180px',
                  height: '35px',
                  // background: 'linear-gradient(180deg, rgb(209, 52, 80) 0%, rgb(209, 52, 80) 0%, rgb(240, 130, 49) 100%)',
                  color: 'white',
                  fontSize: '12px',
                  marginRight: 10
                }}
              >
                Mark AS Completed
              </Button>
            ) : null}
          </Grid>):('')}  
         
        </>
      ) : null}


</>):(<></>)}

      
    </Box>
  )
}

export default ComItem
