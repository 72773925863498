import Card from '@mui/material/Card'

// Soft UI Dashboard React components
import { useAlert } from 'react-alert'
import Container from '@mui/material/Container'
import { Grid, Snackbar, TextField } from '@mui/material'

import ClearIcon from '@mui/icons-material/Clear'
import CircularProgress from "@mui/material/CircularProgress";
import axios from 'axios'
import React, { useEffect, useReducer, useMemo, useState, Fragment, useRef } from 'react'
import Box from '@mui/material/Box'
import ReactSelect from 'react-select'
import { Controller, useForm } from 'react-hook-form'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

import { Typography } from '@mui/material'

import Button from '@mui/material/Button'

import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'

import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import ClickAwayListener from '@mui/material/ClickAwayListener';




const AddComment = (props) => {
  const [hidePatientName, sethidepatientname] = useState(props.patientId > 0 ? true : false)
  //const comid = (commentId = null && commentId > 0 ? commentId : 0)
  const [errormessage, seterrormessage] = useState(false)
  const [rowsArchived, setRowsArchived] = useState(null)
  const [selectedRow, setselectedRow] = useState('')
  const [isShown, setIsShown] = useState(false)
  const [messagelist, setMessagelist] = useState('')
  const [commentOpen, setcommentOpen] = useState(false)

  const dat = [{ id: 1, firstname: 'Ummar', lastname: 'Gujjar' }]
  const [data, setData] = useState(dat)
  const [tabValue, settabValue] = React.useState(0)
  const [rows, setRows] = useState('')

  const alert = useAlert()

  // const actionButton = cellValues => {

  //   router.push({
  //     pathname: `/patients/patient_registration`,
  //     query: { pid: cellValues.row.patientId

  //     }
  //   })
  // }

  const handleChange1 = (event, newValue) => {
    settabValue(newValue)
  }

  const [submitting, setSubmitting] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [apiError, setApiError] = useState('')
  const [selectedRowData, setSelectedRowsData] = React.useState([])
  const [isLoading, setLoading] = useState(true)
  const [isLoading1, setLoading1] = useState(false)
  const [CurrentProviderId, setcurrentProviderId] = useState('')
  const [commentId, setcommentId] = useState(0)

  const [dob, setdob] = useState('')
  const [patientID, setPatientID] = React.useState(props.patientId)
  console.log('patientID____________________', props.patientId)
  const [callingFrom, setcallingFrom] = React.useState('')
  const [patientopen, setpatientopen] = useState('')
  const [patientName, setPatientName] = useState(props.patientName)
  console.log('____________________', props.patientName)
  const [patientData, setPatientData] = React.useState([])
  const [patient, setPatient] = React.useState(false)
  const [patientSelected, setPatientSelected] = React.useState(false)
  const [searchText, setSearchText] = useState('')

//   const [userRedux, setassignedToRedux] = useState([])
//   const usersRedx = useSelector(state => state.users.NAMES, shallowEqual)

  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const handleChange = (event, newValue) => {
    settabValue(newValue)
  }

  const fields = [
    'id',
    'patientId',
    'parentId',
    'patientNoteId',
    'name',
    'shortName',
    'type',
    'phoneNo',
    'fax',
    'emailAddress',
    'city',
    'state',
    'zipCode',
    'deleted',
    'createdBy',
    'createdDate',
    'parentId',
    'updatedBy',
    'updatedDate',
    'clientCreatedDate',
    'clientModifiedDate',
    'defaultPOS',
    'practice_id',
    'defaultFacility',
    'npi',
    'fullName',
    'address',
    'date',
    'patientName',
    'comments',
    'dueDate',
    'assignedTo',
    'archive'
  ]
  const defValues = {
    id: 0,
    patientName: '',
    patientId: props.patientId,
    parentId: '',
    patientNoteId: 0,
    address: '',
    fullName: '',
    shortName: '',
    type: '',
    phoneNo: '',
    fax: '',
    emailAddress: '',
    city: '',
    state: '',
    zipCode: '',
    deleted: false,
    createdBy: '',
    practice_id: '',
    createdDate: '',
    updatedBy: '',
    updatedDate: '',
    clientCreatedDate: '',
    clientModifiedDate: '',
    defaultPOS: '',
    defaultFacility: '',
    npi: '',
    fullName: '',
    address: '',
    date: '',
    comments: '',
    dueDate: '',
    parentId: '',
    assignedTo: '',
    archive: ''
  }
  const validationSchema = Yup.object({
    patientName: Yup.string().required('Patient Name is required'),
    // accountNumber: Yup.string().required('Account number is required'),
    // emailAddress: Yup.string().required('Email is required').email('Email is invalid'),
    //date: Yup.date(),
    //   .required('date is required')
    //   .nullable()
    //   .transform(v => (v instanceof Date && !isNaN(v) ? v : null)),
    comments: Yup.string().required('Messages are required'),
    dueDate: Yup.string().required('Date is required'),
  })
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues
  })
  // API for comments
  useEffect(() => {
    if (patientopen > 0) {
      handleOpen1()
    }
  }, [patientopen])
  useEffect(() => {
    var message = props.message
    if (message)
      fields.forEach(field => {
        setValue(field, message[field])
      })
    else {
      setValue('patientId', props.patientId)
      setValue('patientName', props.patientName)
    }
  }, [])
//   useEffect(() => {
//     var userRedux = []
//     usersRedx.forEach(field => {
   
//       userRedux.push({
//         label: field.fullName,
      
//         value: field.email
//       })
//     })
//     setassignedToRedux(userRedux)
//     setreduxLoaded(true)
//   }, [])
  const [claimIds, setClaimIds] = useState(null)
  //API for saving comments

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      filter: true,
      sortable: true,
      resizable: true
    }
  }, [])
  const onSubmit = data => {
    if (data.dueDate != undefined && data.dueDate != null) {
      data.dueDate = data.dueDate.toLocaleString()
    }
    setApiError('')
    setSubmitting(true)
    const postData = data
    postData.patientId = patientID
    postData.id = 0
    postData.date = ''
    postData.patientNoteId = 0
    postData.patientName = props.patientName
    postData.practiceId=localStorage.getItem('default_paractice')
    // postData.patientId = props.patientId
    // postData.date = ''
    console.log('🚀 ~ file: CommentsList.js:387 ~ onSubmit ~ postData-----', postData)
    //postData.id = 0
    // if (commentId <= 0) {
    //   postData.id = 0
    //   postData.date = ''
    //   postData.patientNoteId = 0
    //   postData.patientName = props.patientName
    //   postData.patientId = props.patientId
    // }

    axios
      .post(`${process.env.REACT_APP_API_URL}/PatientToDoList/AddPatientToDoList`, postData, {
        headers
      })
      .then(response => {
        if (response.id) {
          setSubmitting(false)
          setRefresh(true)
          applyRefresh()
          setOpens(false)
          props.successFunc(response.id)
          setRows(response.data)
          alert.success('Record saved successfully.', {
            type: 'success',
            onClose: () => {
                props.handleClose()
              
            //   router.push({
            //     close
            //   })
            }
          })
        } else {
          setApiError(response.data)
          setSubmitting(false)
          setOpens(false)
        }
      })
      .catch(error => {
        setSubmitting(false)
        setOpens(false)
        alert.success(`Error ${error.message}`, {
          type: 'error'
        })
      })
  }

 

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',

    // boxShadow: 24,
    p: 4
  }
  const style1 = {
    position: 'absolute',
    top: '50%%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 420,
    height:'460px',
    bgcolor: 'background.paper',

    // boxShadow: 24,
    p: 4
  }

  const [open, setOpen] = React.useState(false)
  const [opens, setOpens] = React.useState(false)
  const handleOpen = () => setOpens(true)

  const [openn, setOpenn] = React.useState(false)

  const handleClosee = () => setOpen(false)
  const [date, setDate] = React.useState()
  const [dueDate, setdueDate] = React.useState()

  //Provider
  const [bpRedux, setbpRedux] = useState([])
  const [reduxLoaded, setreduxLoaded] = useState(false)
  const [practicesList, setpracticesList] = useState([]);

  const practicesListid = localStorage.getItem('default_paractice')
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/UserPractices/users?tokenPracticeId=${practicesListid}`, {
        headers
      })
      .then(response => {
        var prac = [];
        response.forEach(field => {
          prac.push({
            label: `${field.fullName}<${field.email}>`,
            value: field.email
          });
        });
        setpracticesList(prac);
        // response.forEach((field) => {
        //   practicesList.push({
        //     label: field.practiceName,
        //     value: field.id,
        //   });
        // });
      })
      .catch(error => {});

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);
  // useEffect(() => {
   
  //   axios
  //     .get(`${process.env.REACT_APP_API_URL}/Users/getUsers`, {
  //       headers
  //     })
  //     .then(response => {
  //       var prac = [];
  //       response.forEach(field => {
  //         prac.push({
  //           label: `${field.fullName}<${field.email}>`,
  //           value: field.email
  //         });
  //       });
  //       setpracticesList(prac);
        
  //     })
  //     .catch(error => {});

   
  // }, []);
  //   const providersR = useSelector(state => state.billings.NAMES, shallowEqual)
  //   useEffect(() => {
  //     var bpRedux = []
  //     providersR.forEach(field => {
  //       bpRedux.push({
  //         label: field.name,
  //         value: field.nPI
  //       })
  //     })
  //     setbpRedux(bpRedux)
  //     setreduxLoaded(true)
  //   }, [])
  const styleDetails = {
    width: '90%',
    height: '95%',
    bgcolor: '#F0FFFF',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 30,
    right: 0,
    margin: 'auto',
    marginTop: '10px',
    paddingBottom: '10px',
    padding: '20px',
    overflow: 'auto'
  }
  const patientListStyle = {
    cursor: 'pointer',
    hover: {
      background: '#3b3b3b !important',
      color: 'rgba(5, 152, 236, 0.637) !important'
    },
    color: 'green',
    selection: {
      background: '#D7624E',
      color: 'white'
    }
  }
  function applyRefresh() {
    setRefresh(!refresh)
  }
 
  const onChangeHandle = async value => {
    setLoading1(true);
    // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions
    console.log(value)
    const sendData = {
      id: 0,
      lastName: value,
      firstName: '',
      ssn: '',
      status: '',
      cellPhone: '',
      address: ''
    }

    // API For search Patients
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Patients/searchPatient`,
        sendData,
        { headers },
        setPatientSelected(false)
      )
      .then(response => {
        setPatientData(response)
        setPatient(true)
        setLoading1(false);
      })
      .catch(error => {
        console.error('There was an error!', error)
        setLoading1(false);
      })

    // const response = await fetch(
    //   "https://country.register.gov.uk/records.json?page-size=5000"
    // );

    // const countries = await response.json();
    // setOptions(Object.keys(countries).map(key => countries[key].item[0]));
  }
  const [open1, setOpen1] = React.useState(false)
  const handleOpen1 = () => setOpen1(true)
  const handleClose1 = () => {
    setpatientopen(0)
    setOpen1(false)
  }
  return (
    <Box style={{ zIndex: 9999 }}>
      <div>
        <Grid container>
          <Box sx={style}>
            <Grid
              container
              columnSpacing={1}
              style={{
                Top: '117.5px',
                Left: '455px',
                borderRadius: '8px',
                Padding: '30px, 41px, 30px, 40px',
                Gap: '20px'
              }}
            >
              <Grid container xs={12}></Grid>
              <Grid item xs={12}>
              <Typography style={{ fontWeight: 'bold', color: 'black' }}>Patient Name:</Typography>
              </Grid>
              
            </Grid>

            <Grid container>
            <Grid xs={12} style={{ marginTop: '4px' }}>
              <ClickAwayListener onClickAway={() => setPatient(false)}>
                <Controller
                  name='patientName'
                  {...register('patientName')}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      autoComplete='off'
                      // isClearablefield
                      size='small'
                      fullWidth
                      placeholder='Patient'
                      {...field}
                      onKeyUp={ev => {
                        console.log('ee:', ev.target.value)
                        setSearchText(ev.target.value)
                        setPatientData([])
                        // dont fire API if the user delete or not entered anything
                        if (ev.target.value !== null && ev.target.value !== '' && ev.target.value.length > 0) {
                          onChangeHandle(ev.target.value)
                        } else {
                          setPatientSelected(false)
                          setPatient(false)
                          //setSearchText('')
                        }
                      }}
                      error={errors.patientName}
                      helperText={errors.patientName ? errors.patientName.message : ''}
                    />
                  )}
                />
          </ClickAwayListener>
          {isLoading1 && (
                <CircularProgress size={20} style={{ marginLeft: '-40px', marginTop: '10px' }} />
                  )}
                {patient ? (
                  <div
                    className='col-xs-6'
                    style={{
                      padding: '14px 16px',
                      fontSize: '0.875rem',
                      color: '#67748e',
                      borderRadius: '5px',
                      background: 'white',
                      textAlign: 'justify',
                      textJustify: 'inter-word',
                      position: 'absolute',
                      zIndex: '99',
                      width: '39%',
                      border: '1px solid #6cb5f9'
                    }}
                  >
                    {patient
                      ? patientData.map((s, index) => {
                          var pat = `${s.lastName}, ${s.firstName}`
                          var patLower = pat.toLowerCase()
                          var i = patLower.indexOf(searchText)
                          // console.log('pat:', patLower)
                          console.log('searchText:', searchText)
                          // console.log('i:', i)
                          var p1 = pat.substring(0, i)
                          // console.log('p1:', p1)
                          var p = pat.substring(i, searchText.length + i)
                          // console.log('p::::::: ', p)
                          // console.log('p:',p)
                          var p2 = pat.substring(i + p.length, patLower.length)
                          // console.log('p:',p2)
                          return (
                            <p
                              style={patientListStyle}
                              onClick={ev => {
                                // var sel=ev.target.attributes['attribIndex']
                                // console.log('Selected: ', patientData[index])
                                setValue(
                                  'patientName',
                                  `${patientData[index].lastName}, ${patientData[index].firstName}`
                                )

                                setValue('patientId', patientData[index].id)

                                setPatientID(patientData[index].id)
                                setPatientData([])
                                setPatientSelected(true)
                                setPatient(false)
                                setPatientName(`${patientData[index].lastName}, ${patientData[index].firstName}`)
                              }}
                              onBlur={ev => {
                                setPatientData([])
                                setPatient(false)
                              }}
                              onChange={ev => {
                                setPatientData([])
                                setPatientSelected(false)
                                setPatient(false)
                                setPatientName('')
                              }}
                            >
                              <span>{p1}</span>
                              <span style={{ backgroundColor: 'yellow' }}>{p}</span>
                              <span>{p2}</span>
                            </p>
                          )
                        })
                      : null}
                  </div>
                ) : (
                  ''
                )}
              </Grid>
              <Grid item xs={12} style={{ marginTop: '10px' }}>
                <Typography
                 style={{ fontWeight: 'bold', color: 'black' }}
                >
               
                  Assigned To
                </Typography>
                <Grid item xs={12} style={{ marginTop: '4px' }}>
                {/* <Controller
                  name='assignedTo'
                   {...register('assignedTo')}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type='text'
                      size='small'
                      fullWidth
                      {...field}
                      placeholder='Enter Assigned To'
                      
                    />
                  )}
                /> */}
                 <Controller
                    name="assignedTo"
                    {...register("assignedTo")}
                    control={control}
                    render={({ field }) =>
                      <ReactSelect
                        {...field}
                        fullWidth
                        value={practicesList.filter(function(option) {
                          return (
                            option.value === getValues("assignedTo")
                          );
                        })}
                        onChange={e => {
                            setValue("assignedTo", e.value);
                            // setpracticesList("assignedTo",e.value);
                            // setDefaultPracticeErrorId(e.value);
                            // setDefaultPracticeError(false);
                          }}
                        options={practicesList}
                      />
                      // <ReactSelect
                      //   onChange={(e) => setUserPractice(e.value)}
                      //   options={practicesList}
                      // />
                    }
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  style={{
                    fontWeight: 'bold', color: 'black'
                  }}
                >
                  {' '}
                  Due Date
                </Typography>

                <Controller
                  name='dueDate'
                  //  {...register('date')}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type='date'
                      size='small'
                      fullWidth
                      {...field}
                      placeholder='Enter due date'
                      value={dueDate}
                      onChange={e => {
                        setValue('dueDate', e.target.value)
                        setdueDate(e.target.value)
                      }}
                      error={errors.dueDate}
                    />
                  )}
                />
              <p style={{ color: 'red', fontSize: '12px' }}>{errors.dueDate?.message}</p>

              </Grid>
            </Grid>

            <Grid xs={12} style={{ marginTop: '8px' }}>
              <Typography
                style={{
                  fontWeight: 'bold', color: 'black'
                }}
              >
                Message
              </Typography>
            </Grid>

            <Grid xs={12}>
              <Controller
                name='comments'
                {...register('comments')}
                control={control}
                render={({ field }) => (
                  <TextareaAutosize
                    // isClearable
                    aria-label='minimum height'
                    placeholder='Enter comments'
                    style={{
                      width: '100%',
                      borderRadius: '8px',

                      height: '100px', // set a fixed height here
                      overflow: 'hidden'
                    }}
                    {...field}
                    fullWidth
                    error={errors.comments}
                  />
                )}
              />
              <p style={{ color: 'red', fontSize: '12px' }}>{errors.comments?.message}</p>
            </Grid>
            <Grid item xs={12} lg={12} sm={12}>
              <Grid container xs={12}>
                <Grid item xs={6}></Grid>
                <Grid item xs={3}>
                  {' '}
                  <Button
                    onClick={props.handleClose}
                    variant='gradient'
                    // disabled={submitting}
                    style={{
                      marginTop: '30px',
                      marginBottom: '10px',
                      float: 'right',
                      marginLeft: '10px',
                      width: '90px',
                      height: '35px',

                      color: '#5B5367',
                      fontSize: '14px'
                    }}
                  >
                    <ClearIcon
                      fontSize='medium'
                      style={{
                        color: '#5B5367',
                        height: '35px',
                        width: '1.6rem',
                        height: '27px',
                        top: '5px',
                        left: '5px',
                        border: '1.5px',

                        paddingRight: '5px'
                      }}
                    ></ClearIcon>
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  {' '}
                  <Button
                  className='Button'
                    onClick={handleSubmit(onSubmit)}
                    variant='gradient'
                    disabled={submitting}
                    style={{
                      marginTop: '30px',
                      marginBottom: '10px',
                      float: 'right',
                      marginLeft: 'auto',
                      width: '100px',
                      height: '35px',
                      // background: 'linear-gradient(180deg, rgb(209, 52, 80) 0%, rgb(209, 52, 80) 0%, rgb(240, 130, 49) 100%)',
                      color: 'white',
                      fontSize: '14px',
                      marginRight: '0px'
                    }}
                  >
                    <SaveOutlinedIcon
                      fontSize='medium'
                      style={{
                        color:
                          'linear-gradient(180deg, rgb(209, 52, 80) 0%, rgb(209, 52, 80) 0%, rgb(240, 130, 49) 100%) !important',
                        paddingRight: '5px',
                        paddingBottom: '2px'
                      }}
                    ></SaveOutlinedIcon>
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </div>
    </Box>
  )
}

export default AddComment
