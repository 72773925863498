import { useState, useEffect,useRef } from "react";
import React from "react";
import ReactSelect from "react-select";
import axios from "axios";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Breadcrumbs from "../components/Common/Breadcrumb";
import DeleteIcon from '@mui/icons-material/Delete'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import md5 from "md5";
import Moment from 'moment'
import InputMask from 'react-input-mask';
import Headroom from "react-headroom";
import DialogTitle from '@mui/material/DialogTitle';




import {
  Box,
  Button,
  Container,
  Stack,
  SvgIcon,
  Typography,
  Grid,
  Snackbar,
  TextField,
  Select
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { useAlert } from "react-alert";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
// import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { TabPanel, TabContext, TabList } from "@mui/lab";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from '@mui/material/Paper'

import { useLocation, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Multiselect from "multiselect-react-dropdown";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import Modal from '@mui/material/Modal';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { FileUploader } from "react-drag-drop-files";
import TextareaAutosize from '@mui/material/TextareaAutosize';




const ContantsRegistration = props => {
  const fields = [
    "id",
    "lastName",
    "midInitialName",
    "firstName",
    "address",
    "city",
    "state",
    "zipCode",
    "phone",
    "fax",
    "contactPerson",
    "taxId",
    "license",
    "upin",
    "ssn",
    "taxonomyCode",
    "npi",
    "refferingType",
  ];
  const defValues = {
    id: 0,
    lastName: "",
    midInitialName: "",
    firstName: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    phone: "",
    fax: "",
    contactPerson: "",
    taxId: "",
    license: "",
    upin: "",
    ssn: "",
    taxonomyCode: "",
    npi: "",
    refferingType: "",
   
  };
  const [date, setDate] = React.useState(Moment().format('YYYY-MM-DD'))
 
  const alert = useAlert();
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();


  const location = useLocation();
  const Contantsid = location.state.Contantsid;
  

  // API Authanticatiion
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const UserType = localStorage.getItem("User_Type")
  console.log('UserType',UserType)
  const validationSchema = Yup.object({
    // fullName: Yup.string().required("Full Name is required"),
    lastName: Yup.string().required("LastName is required"),
    firstName: Yup.string().required("FirstName is required"),
    address: Yup.string().required("Address is required"),
    npi: Yup.string().required("NPI is required"),
    
   
    // defaultPracticeId: Yup.array().required("Practice Name is required")
  });
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors }
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues
  });

  const onSubmit = data => {
    
    let postData = data;
    setSubmitting(true);
    postData.practiceId=localStorage.getItem('default_paractice')
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/ReferringPhysicians/addReferringPhysicians`,
        postData,
        { headers }
      )
      .then(response => {
        if (response.id > 0) {
          setSubmitting(false);

          alert.success("Record saved successfully.", {
            type: "success",
            onClose: () => {
              navigate("/Contants");
            }
          });
        } 
      })
      .catch(error => {
        setSubmitting(false);
        alert.error(`Error ${error.message}`, {
          type: "error"
        });
      });
  };
 

 

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/ReferringPhysicians/ReferringPhysiciansById?id=${Contantsid}`, {
        headers
      })
      .then(response => {
        // setEmergencycontantid(response.id)
        fields.forEach(field => {
          setValue(field, response[field]);
          var dateYMD = Moment(response.dateTime).format('YYYY-MM-DD')
        setDate(dateYMD)
        });
       
      })
      .catch(error => {});
  }, []);

  function handleCancel() {
    navigate("/Contants");
  }
 
  const style = {
    position: 'absolute',
    top: '50%',
    padding:'10px',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 500,
    height: 410,
    bgcolor: 'white',
    border: '2px solid #3C7F6B',
    boxShadow: 24,
    overflow: 'hidden',
    // p: 4,
    borderRadius: '8px',
    overflow:'auto',
    /* WebKit (Chrome, Safari) */
'&::-webkit-scrollbar': {
width: 8, /* Width of vertical scrollbar */
height: 10,
},
'&::-webkit-scrollbar-thumb': {
backgroundColor: '#3C7F6B',
borderRadius: 10,
},
'&::-webkit-scrollbar-track': {
backgroundColor: 'transparent',
},
/* Adjust the width for the horizontal scrollbar */
'&::-webkit-scrollbar-thumb:horizontal': {
width: 4, /* Width of horizontal scrollbar */
},
/* For Firefox */
'&*': {
scrollbarWidth: 'thin',
},
  }
 
  return (
    <React.Fragment>
    <Grid container>
     <Box sx={style}>
      
     <Grid container  spacing={1} >
        <Grid item xs={12} sm={12} xl={12}   >
        
                    <Grid item xs={12} sm={12} xl={12} >
          <Headroom >
            <Grid
              container
              style={{ backgroundColor: '#356F60', position: 'sticky', padding: '6px',borderRadius:'6px' }}
            >
              <Grid item xs={11} sm={11} xl={11} >

                <Typography style={{ color: 'white', fontWeight: '600', textDecoration: 'underline', fontSize: '18px', }}>
                  Add Contacts
                </Typography>

              </Grid>
              <Grid item xs={1} sm={1} xl={1} >

                <CancelOutlinedIcon style={{ color: 'red', cursor: 'pointer',float:'right' }} onClick={handleCancel} />
              </Grid>
            </Grid>
          </Headroom>
        {/* </Grid> */}
                </Grid>
     </Grid>
            
             <Grid item xs={12} sm={6} md={6} lg={6}>
             <Typography style={{ fontSize: '14px' }}>
              First Name
               </Typography>
             <Controller
                     name="firstName"
                     {...register("firstName")}
                     control={control}
                     render={({ field }) =>
                       <TextField
                       type="text"
                         size="small"
                         placeholder="Please Enter First Name"
                         {...field}
                         fullWidth
                         error={errors.firstName}
                       />}
                   />
               <p style={{ color: 'red', fontSize: '14px' }}>{errors.firstName?.message}</p>

             </Grid>
             <Grid item xs={12} sm={6} md={6} lg={6}>
             <Typography style={{ fontSize: '14px' }}>
                 Last Name
               </Typography>
             
             <Controller
                     name="lastName"
                     {...register("lastName")}
                     control={control}
                     render={({ field }) =>
                       <TextField
                         size="small"
                         placeholder="Please Enter Last Name"
                         {...field}
                         fullWidth
                         error={errors.lastName}
                       />}
                   />
               <p style={{ color: 'red', fontSize: '14px' }}>{errors.lastName?.message}</p>
             </Grid>
             <Grid item xs={12} sm={6} md={6} lg={6}>
             <Typography style={{ fontSize: '14px' }}>
                  NPI 
               </Typography>
             <Controller
                     name="npi"
                     {...register("npi")}
                     control={control}
                     render={({ field }) =>
                       <TextField
                         size="small"
                         placeholder="Please Enter NPI"
                         {...field}
                         fullWidth
                         error={errors.npi}
                       
                       />}
                   />
               <p style={{ color: 'red', fontSize: '14px' }}>{errors.npi?.message}</p>
            
             </Grid>
             <Grid item xs={12} sm={6} md={6} lg={6}>
             <Typography style={{ fontSize: '14px' }}>
             Address
               </Typography>
             <Controller
                     name="address"
                     {...register("address")}
                     control={control}
                     render={({ field }) =>
                       <TextField
                         size="small"
                         placeholder="Please Enter Address"
                         {...field}
                         fullWidth
                         error={errors.address}

                       />}
                   />
            <p style={{ color: 'red', fontSize: '14px' }}>{errors.address?.message}</p>

             </Grid>
             <Grid item xs={12} sm={6} md={6} lg={6}>
             <Typography style={{ fontSize: '14px' }}>
             City 
               </Typography>
             <Controller
                     name="city"
                     {...register("city")}
                     control={control}
                     render={({ field }) =>
                       <TextField
                       type="text"
                         size="small"
                         placeholder="Please Enter City "
                         {...field}
                         fullWidth
                       
                       />}
                   />
            
             </Grid>
             <Grid item xs={12} sm={6} md={6} lg={6}>
             <Typography style={{ fontSize: '14px' }}>
             State 
               </Typography>
             <Controller
                     name="state"
                     {...register("state")}
                     control={control}
                     render={({ field }) =>
                       <TextField
                         size="small"
                         placeholder="Please Enter State"
                         {...field}
                         fullWidth
                       
                       />}
                   />
             </Grid>
             <Grid item xs={12} sm={6} md={6} lg={6}>
             <Typography style={{ fontSize: '14px' }}>
             Zip Code   
               </Typography>
             <Controller
                     name="zipCode"
                     {...register("zipCode")}
                     control={control}
                     render={({ field }) =>
                       <TextField
                       type="text"
                         size="small"
                         placeholder="Please Enter Zip Code"
                         {...field}
                         fullWidth
                       
                       />}
                   />
            
             </Grid>

<Grid item xs={6}></Grid>
             <Grid item xs={12} sm={6} xl={6} style={{marginTop:'10px'}}>
             <Button
       className="Button"
                 onClick={handleSubmit(onSubmit)}
                 disabled={submitting}
             fullWidth
              style={{borderRadius:'10px',color:'white',height:'40px',borderColor:"#EA7772",fontSize:'14px',fontWeight:'bold'}}
               type="submit"
              >
                Save
                </Button>
             </Grid>
               <Grid item xs={12} sm={6} xl={6} style={{marginTop:'10px'}}>
       <Button
       className="Button"
           onClick={handleCancel}
           disabled={submitting}
           fullWidth
           style={{borderRadius:'10px',color:'white',height:'40px',borderColor:"#EA7772",fontSize:'14px',fontWeight:'bold'}}

         >
          
           Cancel
         </Button>
                </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

           </Grid>
          

       
     </Box>
   </Grid>
 </React.Fragment>
  );
};
export default ContantsRegistration;
