import React, { useState, useEffect, useRef } from "react";
import Routes from "./Routes/index";
import './assets/scss/theme.scss';
import { Link } from "react-router-dom";
import fakeBackend from "./helpers/AuthType/fakeBackend";
fakeBackend();

const App = () => {
  const timerRef = useRef(null);
 
  const resetTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Set a new timer
    timerRef.current = setTimeout(logout, 300000);
  };

  const logout = () => {
    // Perform logout actions here
    window.location.href = "/login";
 
  };

  useEffect(() => {
    // Initial setup
    resetTimer();

    // Event listeners for user activity
    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keydown', resetTimer);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keydown', resetTimer);
      clearTimeout(timerRef.current);
    };
  }, []); // Empty dependency array to ensure effect only runs once on mount

  return (
    <React.Fragment>
      <Routes />
    </React.Fragment>
  );
}

export default App;