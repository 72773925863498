import React, { useCallback, useMemo, useState, useEffect,useRef } from "react";
import { Container } from "reactstrap";
import { Button, Grid, TextField } from "@mui/material";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { useAlert } from "react-alert";
import Checkbox from "@mui/material/Checkbox";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import { useReactToPrint } from 'react-to-print'
import PrintIcon from '@mui/icons-material/Print';
import { useNavigate } from "react-router-dom";
import RadioGroup from "@mui/material/RadioGroup";
import Moment from 'moment'
import Select from "react-select";
import SearchIcon from '@mui/icons-material/Search'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./Button.css"
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Typography from '@mui/material/Typography'
import Headroom from "react-headroom";
import DialogTitle from '@mui/material/DialogTitle';


const Disclaimer = (props) => {
    const [Formdid, setFormdid] = useState(props.formDid !== undefined ? props.formDid : 0)
    const [columnID, setcolumnID] = useState(props.columnID !== undefined ? props.columnID : 0)
    const [AutpattientID, setAutpattientID] = useState(props.AutpattientID)
    // const patientId = props.callingFrom === "update" ? props.patientid : props.callingFrom === "patient" ? props.patID : props.patientId;
    const patientId = (props.callingFrom == "update" ? props.patientid : props.patID)
    const [patientName, setpatientName] = useState(props.patientName);
    const pname = props.PatientName
    const [newiD, setnewId] = useState()
    const [submitting, setSubmitting] = useState(false);
    const [posDate, setPofserviceDate] = React.useState(false)
    const [patDatee, setPatientDate] = React.useState(false)
    const [wittnessData, setWittDate] = React.useState(false)
    const [patSign, setpatientSign] = React.useState("")
    const [providerService, setPofservice] = React.useState("")
    const [providerName, setProvider] = React.useState("")
    const [disable, setdisable] = React.useState(false)

    const [iD, setId] = useState()
    const gridStyle = {
        margin: '1rem', // You can adjust the margin size as needed

    };
    const fields = [
        "id",
        "patientId",
        "authId",
        "pos",
        "posDate",
        "patientSignature",
        "patientDate",
        "witness",
        "witnessDate",
        "patientId",
        "inactive",
        "practiceId",
        "createdBy",
        "createdDate",
        "updatedBy",
        "updatedDate",
        "clientCreatedDate",
        "clientUpdatedDate",
        "providerName",
        "dosDate",
        "patientAmount",

    ];
    const defValues = {
        id: 0,
        patientId: patientId,
        authId: 0,
        pos: "",
        posDate: "",
        patientSignature: "",
        patientDate: "",
        witness: "",
        witnessDate: "",
        patientId: 0,
        inactive: true,
        practiceId: 0,
        createdBy: "",
        createdDate: "",
        updatedBy: "",
        updatedDate: "",
        clientCreatedDate: "",
        clientUpdatedDate: "",
        providerName: "",
        dosDate:"",
        patientAmount:"",


    };
    const token = localStorage.getItem('Token')
    const auth = `Bearer ${token}`
    const headers = {
      Authorization: auth
    }
    const {
        register,
        control,
        handleSubmit,
        getValues,
        setValue,
        formState: { errors },
    } = useForm({
        mode: "onBlur",
        reValidateMode: "onChange",
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
        shouldUnregister: false,
        shouldUseNativeValidation: false,
        delayError: undefined,
        // resolver: yupResolver(validationSchema),
        defaultValues: defValues,
    });
    const paragraphStyle = {
        textIndent: '3rem', // Adjust the indentation as needed
    };


    useEffect(() => {

        axios
            .get(
                `${process.env.REACT_APP_API_URL}/AutherizationRequest/PatientResponsibilityDisclaminerById?Id=${Formdid}&authId=${columnID}`,
                { headers }
            )
            .then(response => {
                console.log('response.dataaaaa: ', response)
                fields.forEach(field => {
                    setValue(field, response[field])
                })
                setId(response.patientId)
                // setFormDataId(response.formId)
                setnewId(response.id)
                setPofservice(response.pos)
                setProvider(response.providerName)
                var dosDate = Moment(response.dosDate).format('YYYY-MM-DD')
                setPofserviceDate(dosDate)
                var patientDate = Moment(response.patientDate).format('YYYY-MM-DD')
                setPatientDate(patientDate)
                var wittDate = Moment(response.witnessDate).format('YYYY-MM-DD')
                setWittDate(wittDate)
                setpatientSign(response.patientSignature)
            })

            .catch(error => {
                console.error('There was an error!', error)
            })

        // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, [Formdid, columnID])
    const onSubmit = (data) => {
        setSubmitting(true);
        setdisable(true)
        const postData = data;
        // postData.id=0
        { props.callingFrom == "Patient" ? postData.patientName = patientName : postData.patientName = pname }
        { props.callingFrom == "Patient" ? postData.patientId = AutpattientID : postData.patientId = patientId }
        { props.callingFrom == "Patient" ? postData.id = newiD : postData.id = newiD }


        // postData.patientId = AutpattientID; 
        postData.formId = Formdid;
        postData.authId = columnID;
        // postData.address = aDDress;
        // postData.patientName= patientName
        console.log('postData', postData)
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/AutherizationRequest/AddPatientResponsibilityDisclaminer`,
                postData, { headers, }
            )
            .then((response) => {
                if (response.id) {
                    console.log('addNew Forme', response)
                    setSubmitting(false);
                    toast.success('Record saved successfully', {
                        position: "bottom-center",
                        // autoClose: 2500,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                        onClose: () => {
                            if (props.callingFrom == 'Patient') {
                                props.updateHealthHistory(response.id, response.authId, response.patientName)
                                props.closedisclaimer()
                                console.log('checkid', response.id)
                                setdisable(false);

                            }
                            else if (props.callingFrom == "update") {
                                // props.updateHealthHistory(response.id, response.authId, response.patientName)
                                props.closevobpage()
                            }
                            else if (props.callingFrom == "dashboard") {
                                props.updateHealthHistory(response.id, response.authId, response.patientName)
                                props.closevobpage()

                            }
                            else if (props.callingFrom == "Pateint") {
                                // props.updateHealthHistory(response.id, response.authId, response.patientName)
                                props.closeDisclaimer()

                            }
                            else {
                                props.closevobpage1()
                            }


                        },
                    });
                } else {
                    setSubmitting(false);
                    setdisable(false)

                }
            })
            .catch((error) => {
                setSubmitting(false);
                setdisable(false)
                alert.success(`Error ${error.message}`, {
                    type: "error",
                });
            });
    };
    const printRef1= useRef()
    const handlePrint1 = useReactToPrint({
      content: () => printRef1.current,
    });
    return (
        <>
        <Grid container spacing={1} ref={printRef1} >
            <Grid tem xs={12}>
            <Headroom>
              <Grid
                container
                style={{ backgroundColor: '#356F60', }}
              >
                <Grid item xs={12}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      height: '3rem'
                    }}
                  >
                    <DialogTitle
                      style={{
                        flex: 1,
                        textAlign: 'center',
                      }}
                    >
                      <h1
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          color: '#fff',
                          textDecoration: 'underline',
                          fontSize:'2rem'

                        }}
                      >
                       Patient Responsibility Disclaimer

                      </h1>
                    </DialogTitle>
                    <PrintIcon
                             onClick={handlePrint1}
                              fontSize="large"
                             style={{
                                color: 'white',
                                paddingRight: '5px',
                                 cursor: 'pointer',
                                // float:'right'
                                }}
                              ></PrintIcon>
                    <CancelOutlinedIcon
                      onClick={props.closedisclaimer}
                      fontSize="large"
                      color="red"
                      style={{
                        color: 'red',
                        // paddingRight: '5px',
                        cursor: 'pointer',
                        // marginLeft: 'auto',
                        // marginRight: '1.5rem'
                      }}
                    ></CancelOutlinedIcon>
                  </div>
                </Grid>
              </Grid>
            </Headroom>
            </Grid>
            <Grid item xs={0.5}></Grid>
            <Grid item xs={11}>
                <Typography style={paragraphStyle}>
                    Every effort will be made by this office to have services reimbursed by the health insurance company. Payment of benefits are subject to all terms, conditions, limitations, and exclusions of the member’s contract at the time of service.
                </Typography>
            </Grid>
            <Grid item xs={0.5}></Grid>
            <Grid item xs={0.5}></Grid>
            <Grid item xs={11} style={{marginTop:"10px"}}>
            <Typography > I understand and agree that I am responsible for   charges for the Custom Oral Appliance (Mandibular Advancement Device). Follow-up visits for adjustment will be included for the following 90-days. I understand and agree that I am financially responsible for any additional medical service or visit, routine examination, testing, and any other screening ordered by the doctor or staff.  By signing this form, I consent to the use and disclosure of protected health information about me for treatment, payment and health care operations, and/or as required by law. I have the right to revoke this Consent, in writing, signed by me. However, such revocation shall not affect any disclosures already made in compliance with my prior Consent. provides this form to comply with the Health Insurance Portability and Accountability Act of 1996 (HIPAA).</Typography>
            </Grid>
            {/* <Grid item xs={1.8} style={{marginLeft:'-10px'}}>

            <Controller
                            name="patientAmount"
                            {...register("patientAmount")}
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    size="small"
                                    variant="standard"
                                    {...field}
                                    fullWidth
                                    inputProps={{
                                        inputMode: 'numeric',
                                        pattern: '[0-9]*',
                                        onInput: (e) => {
                                          const inputValue = e.target.value.trim();
                                          if (inputValue === '' || /^[0-9]*$/.test(inputValue)) {
                                            e.target.value = inputValue !== '' ? Math.max(0, parseInt(inputValue)).toString().slice(0, 10) : ''; // Allow only positive integers
                                          } else {
                                            e.target.value = ''; // Set value to empty if non-numeric input
                                          }
                                          field.onChange(e);
                                        },
                                      }}
                                />
                            )}
                        />
            </Grid> */}
            {/* <Grid item xs={5} style={{marginTop:"10px"}}>
            <Typography > (only collected if insurance does not pay)in charges for </Typography>
            </Grid> */}
              
               
            {/* Continue adding Grid items and Typography components for the remaining text */}
            {/* ... */}
            {/* Continue adding Grid items and Typography components for the remaining text */}
            {/* ... */}
            

            {/* <Grid item xs={11}>
                <Typography style={paragraphStyle}>
                charges for the Custom Oral Appliance (Mandibular Advancement Device). Follow-up visits for adjustment will be included for the following 90-days. I understand and agree that I am financially responsible for any additional medical service or visit, routine examination, testing, and any other screening ordered by the doctor or staff.  By signing this form, I consent to the use and disclosure of protected health information about me for treatment, payment and health care operations, and/or as required by law. I have the right to revoke this Consent, in writing, signed by me. However, such revocation shall not affect any disclosures already made in compliance with my prior Consent. provides this form to comply with the Health Insurance Portability and Accountability Act of 1996 (HIPAA).
                </Typography>
            </Grid> */}
            <Grid item xs={0.5}></Grid>
            <Grid item xs={0.5}></Grid>

            <Grid item xs={11}>
                <Typography variant="h6">Please sign & Date:</Typography>
            </Grid>
            <Grid item xs={0.5}></Grid>
            <Grid item xs={0.5}></Grid>

            <Grid item xs={11}>
                <Typography>
                    I agree to the above and give permission to communicate my Private Healthcare Information to:
                </Typography>
            </Grid>
            <Grid item xs={0.5}></Grid>
            <Grid item xs={0.5}></Grid>

            <Grid item xs={3} style={{marginTop:'10px'}}>
                <Typography> Provider/ Office-Name:</Typography>
            </Grid>
            <Grid item xs={4} >
            <Controller
                            name="providerName"
                            {...register("providerName")}
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    size="small"
                                    variant="standard"
                                    {...field}
                                    fullWidth
                                />
                            )}
                        />
            </Grid>
            <Grid item xs={4.5}>
            </Grid>

          
            <Grid item xs={0.5}></Grid>
            <Grid item xs={11} style={{marginTop:'10px'}}>
                <Typography>
                Relationship: Provider of Service
                </Typography>
            </Grid>
            <Grid item xs={0.5}></Grid>
            <Grid item xs={0.5}></Grid>

            <Grid item xs={7}>
            <Controller
                            name="pos"
                            {...register("pos")}
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <TextField
                                    size="small"
                                    variant="standard"
                                    {...field}
                                    fullWidth
                                />
                            )}
                        />
            </Grid>
            <Grid item xs={0.5} style={{marginTop:'10px'}}>
            <Typography >
               Date
                </Typography>
            </Grid>
            <Grid item xs={3}>
            <Controller
                            name="date"
                            control={control}
                            {...register("dosDate")}
                            defaultValue=""
                            render={({ field }) => (
                                <TextField
                                    type="date"
                                    size="small"
                                    variant="standard"
                                    {...field}
                                    fullWidth
                                    value={posDate}
                onChange={e => {
                  setValue('dosDate', e.target.value)
                  setPofserviceDate(e.target.value)
                }}
                                />
                            )}
                        />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={0.5}></Grid>
         
            <Grid item xs={11}>
                <Typography >
                    Printed: Patient Name (and Guardian Name if applicable)
                </Typography>
            </Grid>
            <Grid item xs={0.5}></Grid>
            <Grid item xs={0.5}></Grid>

            <Grid item xs={2.8} style={{marginTop:'10px'}}>
                <Typography >
                Patient or Guardian Signature
                </Typography>
            </Grid>
            <Grid item xs={4.8}>
            <Controller
                            name="patientSignature"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <TextField
                                    size="small"
                                    variant="standard"
                                    {...field}
                                    fullWidth
                                />
                            )}
                        />
            </Grid>
            <Grid item xs={0.5} style={{marginTop:'10px'}}>
            <Typography >
               Date
                </Typography>
            </Grid>
            <Grid item xs={3}>
            <Controller
                            name="patientDate"
                            {...register("patientDate")}
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <TextField
                                    type="date"
                                    size="small"
                                    variant="standard"
                                    {...field}
                                    fullWidth
                                    value={patDatee}
                                onChange={e => {
                                  setValue('patientDate', e.target.value)
                                  setPatientDate(e.target.value)
                                }}
                                />
                                
                            )}
                        />
            </Grid>
            <Grid item xs={0.4}></Grid>
            <Grid item xs={0.5}></Grid>

            <Grid item xs={2.8} style={{marginTop:'10px'}}>
                <Typography >
                Witness (if applicable)
                </Typography>
            </Grid>
            <Grid item xs={4.8}>
            <Controller
                            name="witness"
                            {...register("witness")}
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <TextField
                                    size="small"
                                    variant="standard"
                                    {...field}
                                   fullWidth
                                />
                            )}
                        />
            </Grid>
            <Grid item xs={0.5} style={{marginTop:'10px'}}>
            <Typography >
               Date
                </Typography>
            </Grid>
            <Grid item xs={3}>
            <Controller
                            name="witnessDate"
                            {...register("witnessDate")}
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <TextField
                                    type="date"
                                    size="small"
                                    variant="standard"
                                    {...field}
                                    fullWidth
                                    value={wittnessData}
                                    onChange={e => {
                                        setValue('witnessDate', e.target.value)
                                        setWittDate(e.target.value)
                                      }}
                                />
                               
                            )}
                        />
            </Grid>
            <Grid item xs={0.4}></Grid>

           
           
        </Grid >
        <Grid container spacing={1}>
 <Grid xs={12}>
                <Button
                    className="Button"
                    onClick={handleSubmit(onSubmit)}
                    // onClick={save}
                    variant="gradient"
                    disabled={disable == true ? true : false}
                    style={{
                        marginTop: "30px",
                        marginBottom: "10px",
                        float: "right",
                        marginRight: "10px",
                        width: "250px",
                        height: "35px",
                        backgroundColor: "#1273de",
                        color: "white",
                        fontSize: "14px",
                    }}
                >
                    Save
                </Button>
            </Grid>
        </Grid>
        </>
    )
}

export default Disclaimer