import { useState, useEffect,useRef } from "react";
import React from "react";
import ReactSelect from "react-select";
import axios from "axios";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Breadcrumbs from "../components/Common/Breadcrumb";
import DeleteIcon from '@mui/icons-material/Delete'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import md5 from "md5";
import Moment from 'moment'
import InputMask from 'react-input-mask';


import {
  Box,
  Button,
  Container,
  Stack,
  SvgIcon,
  Typography,
  Grid,
  Snackbar,
  TextField,
  Select
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { useAlert } from "react-alert";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
// import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { TabPanel, TabContext, TabList } from "@mui/lab";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from '@mui/material/Paper'

import { useLocation, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Multiselect from "multiselect-react-dropdown";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import Modal from '@mui/material/Modal';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { FileUploader } from "react-drag-drop-files";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Headroom from "react-headroom";
import DialogTitle from '@mui/material/DialogTitle';



const DiagnosisRegistration = props => {
  const fields = [
    "id",
  "code",
  "description",
  "dafault_charges",
  "long_description",
  "primaryDiagnosis",
  "isexpiered",
  "editable",
  "practiceId",
  ];
  const defValues = {
    id: 0,
  code: "",
  description: "",
  dafault_charges: 0,
  long_description: "",
  primaryDiagnosis: "",
  isexpiered: "",
  editable: "",
  practiceId: 0,
   
  };
  const [date, setDate] = React.useState(Moment().format('YYYY-MM-DD'))
 
  const alert = useAlert();
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();


  const location = useLocation();
  const Diagnosisid = location.state.Diagnosisid;
  

  // API Authanticatiion
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const UserType = localStorage.getItem("User_Type")
  console.log('UserType',UserType)
  const validationSchema = Yup.object({
    // fullName: Yup.string().required("Full Name is required"),
    code: Yup.string().required("Code is required"),
    
   
    // defaultPracticeId: Yup.array().required("Practice Name is required")
  });
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors }
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues
  });

  const onSubmit = data => {
    
    let postData = data;
    setSubmitting(true);
    postData.practiceId=localStorage.getItem('default_paractice')
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Diagnosis/AddDiagnosis`,
        postData,
        { headers }
      )
      .then(response => {
        if (response.id > 0) {
          setSubmitting(false);

          alert.success("Record saved successfully.", {
            type: "success",
            onClose: () => {
              navigate("/Diagnosis");
            }
          });
        } 
      })
      .catch(error => {
        setSubmitting(false);
        alert.error(`Error ${error.message}`, {
          type: "error"
        });
      });
  };
 

 

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Diagnosis/DiagnosisById?id=${Diagnosisid}`, {
        headers
      })
      .then(response => {
        // setEmergencycontantid(response.id)
        fields.forEach(field => {
          setValue(field, response[field]);
          var dateYMD = Moment(response.dateTime).format('YYYY-MM-DD')
        setDate(dateYMD)
        });
       
      })
      .catch(error => {});
  }, []);

  function handleCancel() {
    navigate("/Diagnosis");
  }
 
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 500,
    height: 400,
    bgcolor: 'white',
    border: '2px solid #3C7F6B',
    boxShadow: 24,
    overflow: 'hidden',
    // p: 4,
    borderRadius: '8px',
    overflow:'auto',
    /* WebKit (Chrome, Safari) */
'&::-webkit-scrollbar': {
width: 8, /* Width of vertical scrollbar */
height: 10,
},
'&::-webkit-scrollbar-thumb': {
backgroundColor: '#3C7F6B',
borderRadius: 10,
},
'&::-webkit-scrollbar-track': {
backgroundColor: 'transparent',
},
/* Adjust the width for the horizontal scrollbar */
'&::-webkit-scrollbar-thumb:horizontal': {
width: 4, /* Width of horizontal scrollbar */
},
/* For Firefox */
'&*': {
scrollbarWidth: 'thin',
},
  }
 
  return (
    <React.Fragment>
       <Grid container>
        <Box sx={style}>
         
        <Grid container  spacing={1}>
        <Grid item xs={12} sm={12} xl={12}  >
        
        <Headroom>
         <Grid
              container
              style={{ backgroundColor: '#356F60', position: 'sticky' }}
            >
             <Grid item xs={12} sm={12} xl={12}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    height: '100%'
                  }}
                >
                <DialogTitle
                    style={{
                      flex: 1,
                      textAlign: 'center',
                    }}
                  >
                    <h1
                      style={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                        color: '#fff',
                        // textDecoration: 'underline',
                        fontSize:'1.5rem'

                      }}
                    >
                     Diagnosis Registration

                    </h1>
                  </DialogTitle>
                  <CancelOutlinedIcon
                 onClick={handleCancel}
                 fontSize="large"
                 color="red"
                 style={{
                   color: 'red',
                   paddingRight: '5px',
                   cursor: 'pointer',
                   float:'right'
                   // marginLeft: 'auto',
                   // marginRight: '1.5rem'
                 }}
               ></CancelOutlinedIcon>

                </div>
                </Grid>
            </Grid>
 </Headroom>
 </Grid>
     <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
        
                <Grid item xs={11} sm={11} xl={11}>
                <Typography style={{ fontSize: '14px' }}>
                    Code
                  </Typography>
                </Grid>
     <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
     <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

                <Grid item xs={11} sm={11} xl={11}>
                <Controller
                        name="code"
                        {...register("code")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                            size="small"
                            placeholder="Please enter Code"
                            {...field}
                            fullWidth
                            error={errors.code}
                          />}
                      />
                  <p style={{ color: 'red', fontSize: '14px' }}>{errors.code?.message}</p>
                </Grid>
     <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
     <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

                 <Grid item xs={11} sm={11} xl={11}>
                <Typography style={{ fontSize: '14px' }}>
                Description
                  </Typography>
                </Grid>
     <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
     <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

                <Grid item xs={11} sm={11} xl={11}>
                <Controller
                name='description'
                {...register('description')}
                control={control}
                render={({ field }) => (
                  <TextareaAutosize
                    aria-label='minimum height'
                    minRows={2}
                    placeholder='Enter Details  Description'
                    style={{
                      width: '100%',
                      borderRadius: '8px',
                      border: '2px solid #black',
                      height: '50px', // set a fixed height here
                    //   overflow: 'scroll'
                    }}
                    {...field}
                    fullWidth
                  />
                )}
              />
                </Grid>
     <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
     <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

                <Grid item xs={11} sm={11} xl={11}>
                <Typography style={{ fontSize: '14px' }}>
                Long Description
                  </Typography>
                </Grid>
     <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
     <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

                <Grid item xs={11} sm={11} xl={11}>
                <Controller
                name='long_description'
                {...register('long_description')}
                control={control}
                render={({ field }) => (
                  <TextareaAutosize
                    aria-label='minimum height'
                    minRows={3}
                    placeholder='Enter Details Long Description'
                    style={{
                      width: '100%',
                      borderRadius: '8px',
                      border: '2px solid #black',
                      height: '60px', // set a fixed height here
                    //   overflow: 'scroll'
                    }}
                    {...field}
                    fullWidth
                  />
                )}
              />
                </Grid>
             
     <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
     <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
               
                <Grid item xs={5.5} sm={5.5} xl={5.5} style={{marginTop:'10px',marginBottom:'10px'}}>
                <Button
          className="Button"
                    onClick={handleSubmit(onSubmit)}
                    disabled={submitting}
                fullWidth
                 style={{borderRadius:'10px',color:'white',height:'40px',borderColor:"#EA7772",fontSize:'14px',fontWeight:'bold'}}
                  type="submit"
                 >
                   Save
                   </Button>
                </Grid>
                  <Grid item xs={5.5} sm={5.5} xl={5.5} style={{marginTop:'10px',marginBottom:'10px'}}>
          <Button
          className="Button"
              onClick={handleCancel}
              disabled={submitting}
              fullWidth
              style={{borderRadius:'10px',color:'white',height:'40px',borderColor:"#EA7772",fontSize:'14px',fontWeight:'bold'}}

            >
             
              Cancel
            </Button>
          </Grid>
     <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

              </Grid>
             

          
        </Box>
      </Grid>
    </React.Fragment>
  );
};
export default DiagnosisRegistration;
