import React, { useState, useEffect, useRef } from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";
import axios from "axios";
import { Container } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Moment from 'moment'
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Checkbox from "@mui/material/Checkbox";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import RadioGroup from "@mui/material/RadioGroup";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import { useLocation, Link } from 'react-router-dom'
import Select from "react-select";
import SearchIcon from '@mui/icons-material/Search'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//Import Breadcrumb
import "./Button.css"
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Documents from './Documents'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box'
import ReactSelect from 'react-select'
import SendIcon from '@mui/icons-material/Send';
import InputAdornment from '@mui/material/InputAdornment'



// const history = useHistory(); 
const AuthorizationFormByCPT = (props) => {
  const [Formdid, setFormdid] = useState(props.formDid !== undefined ? props.formDid : 0)
  const [TmdId, setcolumnID] = useState(props.columnID !== undefined ? props.columnID : 0)
  const [patientId, setpatientId] = useState(props.AutpattientIcd > 0  ? props.AutpattientIcd : props.patientId )



// const [patientId, setpatientId] = useState(props.patientIdcpt !== undefined ? props.patientIdcpt : 0)
  // const [pattientIcd, setpattientIcd] = useState(props.AutpattientIcd !== undefined ? props.AutpattientIcd : 0)
 
  
  // const patientId = (props.callingFrom =="update"?props.patientid:props.patientid)
  // const patientNameee = props.PatientName;
  // const [patientName, setpatientName] = useState(props.patientName);
  // const [patName, setPatName] = useState(patientNameee);
  //  const [AutpattientID, setAutpattientID] = useState(props.AutpattientID)

  console.log('patieent id', patientId)
  const [authid, setAuthId] = useState()
  const [patId, setPatId] = useState();
  const patientName = props.patientNametmd;
  const location = useLocation()
  // const TmdId = props.columnID
  console.log('patientName___', patientName)
  const [dob, setDOB] = React.useState(Moment().format('YYYY-MM-DD'),)
  // const DOB = props.callingFrom=="Pateint"? props.DOB: dob; 
  const [Charge, setCharge] = useState(props.Charge)
const [Charge1, setCharge1] = useState(props.Charge1)
const [Address, setAddress] = useState(props.Address)
const [Charges, setCharges] = useState(props.Charges)
const [selectpatientid, setselectpatientid] = useState('');

useEffect(() => {
  if (selectpatientid > 0) {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/Patients/SearchInsuranceName?patientId=${selectpatientid}`,
        {
          headers,
        }
      )
      .then((response) => {

        setpatientinsurancename(response.planName)
        setpatientiaddress(response.address)
        setpatientDOB(Moment(response.dob).format('YYYY-MM-DD'));
        setmemeberid(response.memberId)
        setPatientDetails({
          address: response.address,
          city: response.city,
          state: response.state,
          zipcode: response.zipCode,
        });

      })

      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
}, [selectpatientid]);

useEffect(() => {
  if (patientId > 0) {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/Patients/SearchInsuranceName?patientId=${patientId}`,
        {
          headers,
        }
      )
      .then((response) => {

        setpatientinsurancename(response.planName)
        setpatientiaddress(response.address)
        setpatientDOB(Moment(response.dob).format('YYYY-MM-DD'));
        setmemeberid(response.memberId)
        setPatientDetails({
          address: response.address,
          city: response.city,
          state: response.state,
          zipcode: response.zipCode,
        });

      })

      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
}, [patientId]);
const patientListStyle = {
  cursor: 'pointer',
  hover: {
    background: '#3b3b3b !important',
    color: 'rgba(5, 152, 236, 0.637) !important'
  },
  color: 'green',
  selection: {
    background: '#D7624E',
    color: 'white'
  }
};


console.log('Charge', Charge)
console.log('Charge1:', Charge1)
const practiceID = localStorage.getItem('default_paractice')
const [providerr, setProvider] = useState('')

const [providers, setProviders] = useState([]);

useEffect(
  () => {
    // setProviders([]);
    // setValue("provider_id", null);
    // if (userPractice != null || userPractice != undefined || userPractice > 0) {
    // POST request using axios inside useEffect React hook
    axios
      .get(
        `${process.env
          .REACT_APP_API_URL}/Providers/getPracticeProvider?parcticeid=${practiceID}`,
        { headers }
      )
      .then(response => {
        var prov = [];
        response.forEach(field => {
          prov.push({
            label: field.name,
            value: field.id
          });
        });
        setProviders(prov);
      })
      .catch(error => { });
    // }
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  },
  [practiceID]
);

  const [isLoading, setIsLoading] = useState(false); 
  const [patientData, setPatientData] = React.useState([])
  const [patient, setPatient] = React.useState(false)
  const [patientSelected, setPatientSelected] = React.useState(false)
  const [searchText, setSearchText] = useState('')
  
  // const [patientID, setPatientID] = React.useState(props.patientId)
  // const [dateofSumbission, setdateofSumbission] = React.useState(Moment().format('MM/DD/YYYY'),)
  const [patientNamee, setPatientNamee] = useState(patientName)
  const [nameError, setNameError] = useState(false);
  const [MessageName, setMessageName] = useState('')
  const [Messageprovider, setMessageprovider] = useState('')

  const fields = [
      "id",
      "authId",
      "patientId",
      "patientName",
      "providerId",
      "cptcode",
      "cptcharges",
      "date",
      "dateofSumbission",
      "charges",
      "notes",
      "practiceId",
      "inactive",
    "address",
    "iCD",
    'dob',
  ];
  const defValues = {
      id:0,
      authId:0,
      patientId:0,
      patientName: '',
      patientName: patientName,
      providerId: 0,
      cptcode: Charge1,
      cptcharges: Charge,
      date: "",
      charges:"",

      notes: "",
      practiceId: 0,
      inactive:"",
      address:"",
      iCD:"",
      dateofSumbission:"",
      dob:"",
  };

  document.title = "AuthorizationFormByCPT | LuxBillingEHR";
   // API Authanticatiion
   const token = localStorage.getItem('Token')
   const auth = `Bearer ${token}`
   const headers = {
     Authorization: auth
   }
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);
  
  const validationSchema = Yup.object({
    // emailAddress: Yup.string().required('Email is required'),
    // emailAddress: Yup.string()
    //   .required('Email is invalid')
    //   .email('Email is invalid'),
    // patientName: Yup.string().required('PatientName Name is required'),
   
    // dob: Yup.date()
    //   .required('dob is required')
    //   .nullable()
    //   .transform(v => (v instanceof Date && !isNaN(v) ? v : null))
    //   .max(today, "You can't be born in the future!")
    //   .min(startDate, "You can't be that much old!")
  })
 
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues,
  });
  const [submitting, setSubmitting] = useState(false);

  const alert = useAlert();

  const alertStyle = {
    zIndex: 999999, // Set a higher z-index for the alert

  };
  const [openDocument, setOpenDocument] = React.useState(false);
  const handleCloseDocument = () => {
    setOpenDocument(false)};
    const handleOpen = () => {
      setOpenDocument(true)};
      const checkstyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "70%",
        height: 500,
        bgcolor: "background.paper",
        border: "2px solid #377562",
        boxShadow: 24,
        
      };
  useEffect(() => {

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/PatientNote/AuthorizationFormByCPTById?Id=${TmdId}&authId=${TmdId}`,
        { headers }
      )
      .then(response => {
        fields.forEach(field => {
          setValue(field, response[field])
        })

        console.log('response.dataaaaa: ', response)
        setPatId(response.id)
        // setPatName(response.patientName)
        var dateYMD = Moment(response.date).format('YYYY-MM-DD')
        setDOB(dateYMD)
        
        
     
      })

      .catch(error => {
        console.error('There was an error!', error)
      })

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [])
  const [patientinsurancename, setpatientinsurancename] = useState("");
  const [patientiaddress, setpatientiaddress] = useState("");
  const [patientDOB, setpatientDOB] = React.useState('')
  

  const [patientDetails, setPatientDetails] = useState({
    address: "",
    city: "",
    state: "",
    zipcode: "",
  });
console.log(patientiaddress,'patientiaddress,')
  const [memberid, setmemeberid] = useState('');
//   useEffect(() => {
//     if (patientId > 0) {
//       axios
//         .get(
//           `${process.env.REACT_APP_API_URL}/Patients/SearchInsuranceName?patientId=${patientId}`,
//           {
//             headers,
//           }
//         )
//         .then((response) => {
// console.log(response,'response')
//           setpatientinsurancename(response.planName)
//           setpatientiaddress(response.address)
//           setpatientDOB(Moment(response.dob).format('YYYY-MM-DD'));
//           setmemeberid(response.memberId)
//           setPatientDetails({
//             address: response.address,
//             city: response.city,
//             state: response.state,
//             zipcode: response.zipCode,
//           });

//         })

//         .catch((error) => {
//           console.error("There was an error!", error);
//         });
//     }
//   }, [patientId]);
const approvedcpt= props.approvedcpt
console.log('approvedcpt',approvedcpt)
  const onSubmit = (data) => {
    if(!patientNamee){
      setMessageName('Please enter your name')
      return
      
    } 
    setMessageName('')
    if(!data.providerId){
 setMessageprovider('Please enter provider name')
 return
 }
   setMessageprovider('')
     setSubmitting(true);
    // setdisable(true)
    const postData = data;
    // postData.id=0
     postData.patientName= patientNamee;
     
    // postData.patientId = patientID;
    postData.cptcode = Charge1
    postData.cptcharges = Charge
    postData.patientId = (patientId > 0 ? patientId : selectpatientid)
    postData.approved = approvedcpt
    // postData.dateofSumbission = dateofSumbission


   
    
    postData.practiceId=localStorage.getItem('default_paractice')
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/PatientNote/AddAuthorizationFormByCPT`,
        postData,
        {
          headers,
        }
      )
      .then((response) => {
        if (response.id) {
          console.log("check Data", response);
          setSubmitting(false);
          alert.success("Record saved successfully.", {
            type: "success",
            // containerStyle: alertStyle,
            onClose: () => {
              props.CloseModal()
              // navigate('/Authorization')
            },
          });
        } else {
          setSubmitting(false);
          // setdisable(false);

        }
      })
      .catch((error) => {
        setSubmitting(false);
        // setdisable(false);
        alert.success(`Error ${error.message}`, {
          type: "error",
        });
      });
  };
  const onSubmitlux = (data) => {
     if(!patientNamee){
      setMessageName('Please enter your name')
      return     
    } 
    
    setMessageName('')
 if(!data.providerId){
 setMessageprovider('Please enter provider name')
 return
 }
   setMessageprovider('')
     setSubmitting(true);
    // setdisable(true)
    const postData = data;
    console.log('postData',postData)
    // postData.id=0
     postData.patientName= patientNamee;
     postData.dob= patientDOB;

    // postData.patientId = patientID;
    postData.patientId = (patientId > 0 ? patientId : selectpatientid)
    // postData.patientId = (patientId > 0 ? patientId :pattientIcd)
    postData.cptcode = Charge1
    postData.cptcharges = Charge
    postData.submitted= true
    postData.approved = approvedcpt
    // postData.dateofSumbission = dateofSumbission


    postData.practiceId=localStorage.getItem('default_paractice')
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/PatientNote/AddAuthorizationFormByCPT`,
        postData,
        {
          headers,
        }
      )
      .then((response) => {
        if (response.id) {
          console.log("check Data", response);
          setSubmitting(false);
          alert.success("Record saved successfully.", {
            type: "success",
            // containerStyle: alertStyle,
            onClose: () => {
              props.CloseModal()
              // navigate('/Authorization')
            },
          });
        } else {
          setSubmitting(false);
          // setdisable(false);

        }
      })
      .catch((error) => {
        setSubmitting(false);
        // setdisable(false);
        alert.success(`Error ${error.message}`, {
          type: "error",
        });
      });
  };
  function handleCancel() {
    props.CloseModal()
   
    // navigate('/Authorization')

  }
  
  const onChangeHandle = async value => {
    // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions
    setIsLoading(true);
    console.log(value)
    const sendData = {
      id: 0,
      lastName: value,
      firstName: '',
      ssn: '',
      status: '',
      cellPhone: '',
      address: '',
      dob: '',
      patientId:'',
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Patients/searchPatient`,
        sendData,
        { headers },
        setPatientSelected(false)
      )
      .then(response => {
        setPatientData(response)
        setPatient(true)
        setIsLoading(false);
      })
      .catch(error => {
        console.error('There was an error!', error)
        setIsLoading(false);
      })

  }
  const PatientStyle = {
    cursor: 'pointer',
    hover: {
      background: '#3b3b3b !important',
      color: 'rgba(5, 152, 236, 0.637) !important'
    },
    color: 'green',
    selection: {
      background: '#D7624E',
      color: 'white'
    }
  }
  const [diagnosisID, setDiagnosisID] = useState("");
  const [diagnosisList, setDiagnosisList] = useState([]);
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Diagnosis/Diagnosis`, {
        headers
      })
      .then(response => {
        var prac = [];
        response.forEach(field => {
          prac.push({
            label: field.code,
            value: field.code
          });
        });
        setDiagnosisList(prac);
        
      })
      .catch(error => {});

  }, []);

  const clearSelection = (e) => {
    setValue('patientName', '');
    setPatientData([]);
    setPatient(false);
    setPatientNamee('');
    setSearchText('');
    setPatientSelected(false)
    setselectpatientid('')
    setRefresh(true)
    onChangeHandle(e.target.value)
  };
  return (
    <React.Fragment>
    <div className="page-content">
      <Container>
      <Modal
        id="hello-world"
          open={openDocument}
          onClose={handleCloseDocument}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          visible={true} title={'Title'} footer={'Footer'}
        >
          <Box sx={checkstyle}>

      
              <Grid container spacing={0}  style={{
                       
                        backgroundColor: '#377562',
                      
                      }} >
              <Grid item xs={9} style={{ fontWeight: 500,backgroundColor: '#377562', marginTop: "10px",  }}>
                      <Typography style={{ fontWeight: 'bold', marginLeft:"7px",fontSize: '18px', color: 'white' }}>
                      Add patient document
                      </Typography>
                    </Grid>
                  <Grid item xs={3}>
                    <Button
                      onClick={handleCloseDocument}
                      variant="gradient"
                      // disabled={submitting}
                      style={{
                        marginTop: "10px",
                        float: "right",
                        marginBottom: "10px",
                        // width: "80px",
                        height: "35px",
                       
                        color: "black",
                        fontSize: "14px",
                        cursor: 'pointer'
                      }}

                    // startIcon={<img src={SaveIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
                    >
                      <CancelOutlinedIcon
                        fontSize="medium"
                        style={{
                          color: "red",
                          
                          paddingBottom: "2px",
                        }}
                      ></CancelOutlinedIcon>
                  
                    </Button>
                  </Grid>
                  
                </Grid>
                   <Grid item xs={12}>
              <Documents callingFrom={"AuthorizationFormByCPT"}
               updateHealthHistory={(id, authId) => {
                setcolumnID(authId)
                setValue('id',authId)

               }}
              AuthorizationFormByCPTid={TmdId} handleCloseAuthorizationFormByCPT={handleCloseDocument}  ></Documents>

                 </Grid>


          </Box>
        </Modal>
      <Grid container spacing={1} style={{ color: "black",marginTop:'-90px' }}>
        <Grid item xs={12}>
        <Button
              className="Button"
              onClick={handleOpen}
              variant='gradient'
         
              style={{
                marginTop: '0px',
                marginBottom: '10px',
                float: 'right',
                marginLeft: '10px',
                width: '120px',
                height: '35px',
                // backgroundColor: '#A574FD',
                color: 'white',
                fontSize: '14px'
              }}
            >
            
              Documents
            </Button>
    
        </Grid>
       
        <Grid item xs={12} sm={6} md={6} lg={6} style={{
              color: "black",
              marginTop:'6px'
            }}>
       
          <Typography>
            Provider: 
             </Typography>
             <Controller
                name="providerId"
                onChange={value => setProvider('providerId', value)}
                {...register("providerId")}
                control={control}
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    default
                    size="small"
                    style={{
                      width: "22em",
                    }}
                    value={providers.filter(function (option) {
                      return option.value === getValues("providerId");
                    })}
                    onChange={value => {
                      setValue("providerId", value.value);
                      // setProviders(value.value);
                    }}
                    options={providers}
                  />
                )}
              />
               <p style={{ color: "red", fontSize: "14px" }}>
                {Messageprovider}
              </p>
             </Grid>
        <Grid item xs={6}> </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}   style={{
              color: "black",
              marginTop:'6px'
            }}>
         <Typography> Patient Name:</Typography>
        <ClickAwayListener onClickAway={() => setPatient(false)}>
          <TextField
          autoComplete='off'
            // {...register('patientName')}
            size='small'
            value={patientNamee}
            fullWidth
            InputProps={{
              // readOnly: TmdId > 0,
              endAdornment:  TmdId == 0  && (
                <InputAdornment position='end'>
                   {selectpatientid> 0 &&(
        <CancelOutlinedIcon style={{ color: 'red', cursor: 'pointer' }} onClick={clearSelection} />
      )}
                </InputAdornment>
              )
            }}
            placeholder='Search Patient'
            onKeyUp={ev => {
              console.log('ee:', ev.target.value)
              if (ev.target.value !== null && ev.target.value !== '' && ev.target.value.length > 0) {
                onChangeHandle(ev.target.value)
                setSearchText(ev.target.value)
              } else {
                setPatientData([])
                setPatientSelected(false)
                setPatient(false)
                setSearchText('')
              }
            }}
          />
          
        </ClickAwayListener>
        {isLoading && (
                <CircularProgress size={20} style={{ marginLeft: '-40px', }} />
              )}
              <p style={{ color: "red", fontSize: "14px" }}>
                {MessageName}
              </p>
              {patient ? (
                <div
                  className="col-xs-6"
                  style={{
                    height: 150,
                    overflowY: 'scroll',
                    padding: '14px 16px',
                    fontSize: '0.875rem',
                    color: '#67748e',
                    borderRadius: '5px',
                    background: 'white',
                    textAlign: 'justify',
                    textJustify: 'inter-word',
                    position: 'absolute',
                    zIndex: '99',
                    width: '26%',
                    border: '1px solid #6cb5f9'
                  }}
                >
                  {patient ? 
                  (
                    patientData.length > 0 ?
                     (
                      patientData.map((s, index) => {
                        var pat = `${patientData[index].lastName}, ${patientData[index].firstName}`
             
                        const searchTextLower = searchText.toLowerCase();
                        const fullNameLower = pat.toLowerCase();
                        const i = fullNameLower.indexOf(searchTextLower);
                  
                        if (i === -1) {
                          return null;
                        }
                  
                        const p1 = pat.substring(0, i);
                        const p = pat.substring(i, i + searchText.length);
                        const p2 = pat.substring(i + searchText.length);

                        return (
                          <>
                            <p
                              style={patientListStyle}
                              onClick={(e) => {
                                setValue(
                                  'patientName',
                                  `${patientData[index].lastName}, ${patientData[index].firstName}`
                                );
                                setValue('patientName', `${patientData[index].lastName}, ${patientData[index].firstName}`)
                                setPatientData([]);
                                setPatient(false);
                                setPatientNamee(
                                  `${patientData[index].lastName}, ${patientData[index].firstName}`
                                );
                                setselectpatientid(patientData[index].id)
                              }}
                              onBlur={(e) => {
                                setPatientData([]);
                                setPatient(false);
                              }}
                              onChange={(e) => {
                                setPatientData([]);
                                setPatient(false);
                                setPatientNamee('');
                              }}
                            >
                              <span>{p1}</span>
                              <span style={{ backgroundColor: 'yellow' }}>{p}</span>
                              <span>{p2}</span>
                              {/* {s.patientId} */}
                              {/* <Divider variant="hard" /> */}
                            </p>
                          </>
                        );
                      })
                    ) : (
                      <Typography>No Record found</Typography>
                    )
                  ) : null}
                </div>
              ) : (
                ''
              )}
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}  style={{
              color: "black",
              marginTop:'6px'
            }}>
            <Typography>DOB:</Typography>
        <Controller
                name="dob"
                {...register("dob")}
                control={control}
                render={({ field }) => (
                  <TextField
                   

                    size="small"
                    fullWidth

                    {...field}
                    name="dob"
                    type="date"
                    value={patientDOB}
                    onChange={e => {
                      setValue('dob', e.target.value)
                      setpatientDOB(e.target.value)
                    }}

                    inputProps={{
                      readOnly: true,  // Corrected prop name
                    }}
                  />
                )}
              />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}  style={{
              color: "black",
              marginTop:'6px'
            }}>
            <Typography>Member ID:</Typography>
         <Controller
                name='memberid'
                {...register("memberid")}
                control={control}
                render={({ field }) => (
                  <TextField
                  type='text'
                    size='small'
                    fullWidth
                    
                    {...field}
                    value={memberid}
                    // onChange={e => {
                    //   setValue('id', e.target.value)
                    // }}
                    // error={errors.dateTMD}
                  />
                )}
              />

         </Grid>
      

<Grid item xs={12} sm={6} md={4} lg={4} style={{
              color: "black",
              marginTop:'6px'
            }}>
         <Typography>Address:</Typography>
         <Controller
                name="address"
                // {...register("dob")}
                control={control}
                render={({ field }) => (
                  <TextField
                    
                    style={{
                      width: "100%",
                    }}
                    size="small"
                    fullWidth
                    {...field}
                    name="address"

                    // type="date"
                    value={patientiaddress}
                    // value={`${patientDetails.address}, ${patientDetails.city}, ${patientDetails.state}, ${patientDetails.zipcode}`}
                    // onChange={e => {
                    //   setValue('address', e.target.value)
                    //   setpatientaddress(e.target.value)
                    // }}
                    inputProps={{
                      readOnly: true,  // Corrected prop name
                    }}
                  />
                )}
              />


</Grid>

        <Grid item xs={12} sm={6} md={4} lg={4}  style={{
              color: "black",
              marginTop:'12px'
            }}>
         <Typography>  Procedure code:</Typography> 
        <Controller
                name='cptcode'
            {...register('cptcode')}
                control={control}
                render={({ field }) => (
                  <TextField
                    type='text'
                    size='small'
                    value={Charge1}
                    onChange={e => {
                      setValue('cptcode', e.target.value)
                      setCharge1('cptcode',e.target.value)
                    }}
                    inputProps={{
                      readOnly: true,  // Corrected prop name
                    }}
                    
                    fullWidth
                  
                    {...field}
                    placeholder='Enter Procedure'
                  />
                )}
              />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} style={{
              color: "black",
              marginTop:'12px'
            }}>
        <Typography> Charges:</Typography>
        <Controller
                name='cptcharges'
            {...register('cptcharges')}
                control={control}
                render={({ field }) => (
                  <TextField
                    type='text'
                    size='small'
                    fullWidth
                    value={Charge}
                    onChange={e => {
                      setValue('cptcharges', e.target.value)
                      setCharge(e.target.value)
                    }}
                    inputProps={{
                      readOnly: true,  // Corrected prop name
                    }}
                    
                    {...field}
                    placeholder='Enter Cptcharges'
                  />
                )}
              />
        </Grid>
        <Grid xs={12} sm={6} md={4} lg={4}  style={{
              color: "black",
              marginTop:'19px',
              marginLeft:'4px'
            }}> 
            <Typography> ICD:</Typography>
        <Controller
                    name="iCD"
                    {...register("iCD")}
                    control={control}
                    render={({ field }) =>
                      <ReactSelect
                        {...field}
                        fullWidth
                        value={diagnosisList.filter(function(option) {
                          return (
                            option.value === getValues("iCD")
                          );
                        })}
                        onChange={e => {
                            setValue("iCD", e.value);
                            setDiagnosisID(e.value);
                           
                          }}
                       
                        options={diagnosisList}
                      />
                     
                    }
                  />
        {/* <Controller
                name='iCD'
            {...register('iCD')}
                control={control}
                render={({ field }) => (
                  <TextField
                    type='text'
                    size='small'
                    fullWidth
                    value={Charge}
                    onChange={e => {
                      setValue('iCD', e.target.value)
                      setCharge(e.target.value)
                      setCharges(e.target.value)

                    }}
                    inputProps={{
                      readOnly: false,  // Corrected prop name
                    }}
                    variant="standard" 
                    {...field}
                    placeholder='Enter ICD'
                  />
                )}
              /> */}


        </Grid>
     
        <Grid item xs={12}  style={{
              color: "black",
              marginTop:'12px'
            }}>
         <Typography>Note:</Typography>
        <Controller
                name='notes'
                {...register('notes')}
                control={control}
                render={({ field }) => (
                  <TextareaAutosize
                  aria-label='minimum height'
                  minRows={5} // increase the initial number of rows
                  maxRows={10} // add maxRows to limit how much it can expand
                  placeholder='Enter notes'
                  style={{
                    width: '100%',
                    borderRadius: '8px',
                    border: '2px solid black',
                    overflow: 'auto', // allow the textarea to expand
                  }}
                  {...field}
                  fullWidth
                  />
                )}
              />
        </Grid>



          <Grid item xs={12} lg={12} sm={12}>

        <Button
              className="Button"
              onClick={handleSubmit(onSubmitlux)}

            
              variant='gradient'
              disabled={submitting}
              style={{
                marginTop: '0px',
                marginBottom: '10px',
                float: 'right',
                marginLeft: '10px',
                width: '230px',
                height: '35px',
                // backgroundColor: '#A574FD',
                color: 'white',
                fontSize: '14px'
              }}
            >
              <SendIcon
                fontSize="medium"
                style={{ color: "white", paddingRight: "5px" }}
              ></SendIcon>
              Submit To LuxBilling
            </Button>
 

        


            <Button
              className="Button"
              onClick={handleCancel}
              variant='gradient'
              disabled={submitting}
              style={{
                marginTop: '0px',
                marginBottom: '10px',
                float: 'right',
                marginLeft: '10px',
                width: '110px',
                height: '35px',
                // backgroundColor: '#A574FD',
                color: 'white',
                fontSize: '14px'
              }}
            >
              <CancelOutlinedIcon
                fontSize="medium"
                style={{ color: "white", paddingRight: "5px" }}
              ></CancelOutlinedIcon>
              Cancel
            </Button>
            <Button
              className="Button"
              onClick={handleSubmit(onSubmit)}
              variant='gradient'
              disabled={submitting}
              style={{
                marginTop: '0px',
                marginBottom: '10px',
                float: 'right',
                marginLeft: 'auto',
                width: '110px',
                height: '35px',
                backgroundColor: '#A574FD',
                color: 'white',
                fontSize: '14px'
              }}
            >
              <SaveOutlinedIcon
                fontSize="medium"
                style={{ color: "white", paddingRight: "5px" }}
              ></SaveOutlinedIcon>
              Save
            </Button>
          </Grid>
        
          </Grid>
      </Container>
    </div>
    </React.Fragment>
  );
};

export default AuthorizationFormByCPT;
