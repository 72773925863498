import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Container } from "reactstrap";
import { Box, Button, Stack, SvgIcon, Typography, Grid, Snackbar, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress'
import axios from 'axios'
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { Controller, useForm } from 'react-hook-form'
import VOBPAGE1 from './Vobpage1'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
// import { useRouter, withRouter } from 'next/router'
import { useAlert } from 'react-alert'
import AddIcon from '@mui/icons-material/Add';
import { DataGrid } from '@mui/x-data-grid'
import Tooltip from '@mui/material/Tooltip'
import Headroom from "react-headroom";
import DialogTitle from '@mui/material/DialogTitle';
import Card from '@mui/material/Card';
import { ToastContainer, toast } from 'react-toastify';
// import { createBrowserHistory } from 'history'
// import { useHistory } from "react-router-dom";
// import { Navigate } from "react-router-dom";
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from 'react-router-dom';
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
//Import Breadcrumb

import { HTML5_FMT } from 'moment';
import { useLocation, Link } from "react-router-dom";

// const history = useHistory();
const VOBPAGE = (props) => {
    const location = useLocation();
    const patientId = props.PatientId;
    const Formdid = props.formDid;
    // const patientName = location.state.patientName;
    console.log('patientId uuoiurwuoe', patientId)
    const [rows, setRows] = useState([])
    const [iD, setId] = useState()
    const [dos, setDos] = useState()
    const [vob, setVob] = useState(false)
    const [AuthCheckList, setAuthCheckList] = useState(false)
    
    const [medid, setMedId] = useState(false)
    console.log('medid',medid)
    const [patquestion, setPatQuestion] = useState(false)
    const [fagreement, setFagreement] = useState(false)
    const [recurnotes, setRecurrNotes] = useState()
    const [bsleeptest, setBsleepTest] = useState(false)
    const [cpap, setCpap] = useState(false)
    const [lomn, setLomn] = useState(false)
    const [reffprovider, setReffprovider] = useState(false)
    const [progrNotes, setProgNotes] = useState(false)
    const [addinfo, setAddinfo] = useState(false)
    const [patName, setPatName] = useState()

    const fields = [
        'id',
        'patientId',
        'patMedIdCard',
        'vob',
        'patientQuestionnaire',
        'financialAgreement',
        'referringProviderNotes',
        'baselineSleepTest',
        'cpap',
        'lomn',
        'progressNotes',
        'additionalInformation',
        'dos',
        'practiceId',
        'inactive',
        'createdBy',
        'createdDate',
        'updatedBy',
        'updatedDate',
        'clientCreatedDate',
        'clientUpdatedDate',
        'authCheckList',

    ]
    const defValues = {
        id: 0,
        patientId: 0,
        patMedIdCard: true,
        vob: true,
        patientQuestionnaire: true,
        financialAgreement: true,
        referringProviderNotes: true,
        baselineSleepTest: true,
        cpap: true,
        lomn: true,
        progressNotes: true,
        additionalInformation: true,
        dos: '',
        practiceId: 0,
        inactive: true,
        createdBy: '',
        createdDate: '',
        updatedBy: '',
        updatedDate: '',
        clientCreatedDate: '',
        clientUpdatedDate: '',
        authCheckList:'',

    }
    document.title = "VOB Checklist | LuxBillingEHR";

    // const auth = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImN0eSI6IkpXVCJ9.eyJqdGkiOiIwZmRiNWIxMy0wYzExLTQ4ZTQtOTdmMy1hZjhlOGNmOTJlMjgiLCJ2YWxpZCI6IjEiLCJ1c2VyRW1haWwiOiJhbGlAZ21haWwuY29tIiwicHJhY3RpY2VJZCI6IjEiLCJleHBpcnlEYXRlIjoiNy8zLzIwMjgiLCJicm93c2VyIjoiR29vZ2xlIENocm9tZSIsImFwcGxpY2F0aW9uIjoiTHV4QmlsbGluZyIsIklzQWRtaW4iOiJGYWxzZSIsIlByb3ZpZGVySWQiOiIwIiwiZXhwIjoxODQ2MDgwNTE2LCJpc3MiOiJodHRwOi8vd3d3Lkx1eEJpbGxpbmcuY29tIiwiYXVkIjoiaHR0cDovL3d3dy5MdXhCaWxsaW5nLmNvbSJ9.2Uac6a-c-IPRJFzYSvjxpRych9icwhsebjvB2lgZaOs`
    const navigate = useNavigate();
    const [refresh, setRefresh] = useState(false)
    const alert = useAlert()
    const [patId, setPatId] = useState()
    // API Authanticatiion
    const token = localStorage.getItem('Token')
    const auth = `Bearer ${token}`
    const headers = {
      Authorization: auth
    }

    const {
        register,
        control,
        handleSubmit,
        getValues,
        setValue,
        formState: { errors }
    } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        context: undefined,
        criteriaMode: 'firstError',
        shouldFocusError: true,
        shouldUnregister: false,
        shouldUseNativeValidation: false,
        delayError: undefined,
        // resolver: yupResolver(validationSchema),
        // defaultValues: defValues
    })
    const [submitting, setSubmitting] = useState(false)
  const [disable, setdisable] = React.useState(false)

    const onSubmit = data => {
        setSubmitting(true)
        setdisable(true)

        const postData = data
        data.patientId = patientId
        axios
            .post(`${process.env.REACT_APP_API_URL}/AutherizationRequest/AddPriorAuthChecklist`, postData, {
                headers
            })
            .then(response => {

                if (response.id) {
                    console.log('check Data', response)
                    setSubmitting(false)
                    toast.success('Record saved successfully', {
                        position: "top-center",
                        // autoClose: 2500,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                        onClose: () => {            
                            // navigate('/Patient')
                            setdisable(false);
                            props.closevobpage()

                        }
                    })
                } else {
                    setSubmitting(false)
                setdisable(false);
                }
            })
            .catch(error => {
                setSubmitting(false)
                setdisable(false);
                alert.success(`Error ${error.message}`, {
                    type: 'error'
                })
            })
    }
    useEffect(() => {

        axios
            .get(
                `${process.env.REACT_APP_API_URL}/AutherizationRequest/PriorAuthChecklistById?Id=${Formdid}`,
                { headers }
            )
            .then(response => {
                fields.forEach(field => {
                    setValue(field, response[field])
                })

                console.log('response.dataaaaa: ', response)
                setPatId(response.patientId)
                setVob(response.vob)
                setAuthCheckList(response.authCheckList)
                setMedId(response.patMedIdCard)
                setPatQuestion(response.patientQuestionnaire)
                setFagreement(response.financialAgreement)
                setBsleepTest(response.baselineSleepTest)
                setCpap(response.cpap)
                setLomn(response.lomn)
                setReffprovider(response.referringProviderNotes)
                setProgNotes(response.progressNotes)
                setAddinfo(response.additionalInformation)
                setPatName(response.patientName)
            })

            .catch(error => {
                console.error('There was an error!', error)
            })

        // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, [patientId])
    const checkstyle1 = {
        position: "absolute",
        top: "50%",
        left: "60%",
        transform: "translate(-50%, -50%)",
        width: 1000,
        height: 500,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        // overflow: "auto",
        overflows: "hide",
        // zIndex: "10",
      };
      const [open3, setOpen3] = React.useState(false);
      const handleOpen3 = () => setOpen3(true);
      const handleClose3 = () => setOpen3(false);
    return (

        <div>
              <Modal
          open={open3}
          onClose={handleClose3}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          visible={true} title={'Title'} footer={'Footer'}
        >
          <Box sx={checkstyle1}>

            <Headroom>
              <Grid
                container
                style={{ backgroundColor: '#356F60', position: 'sticky' }}
              >
                <Grid item xs={12}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <DialogTitle
                      style={{
                        flex: 1,
                        textAlign: 'center',
                      }}
                    >
                      <h1
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          color: '#fff',

                        }}
                      >
                        VOB/ Prior-auth Request
                      </h1>
                    </DialogTitle>
                    <CancelOutlinedIcon
                      onClick={handleClose3}
                      fontSize="large"
                      color="red"
                      style={{
                        color: 'red',
                        paddingRight: '5px',
                        cursor: 'pointer',
                        // marginLeft: 'auto',
                        marginRight: '1.5rem'
                      }}
                    ></CancelOutlinedIcon>
                  </div>
                </Grid>
              </Grid>
            </Headroom>


            <Grid item xs={12}>
              <Card bordered={false} style={{ overflow: 'auto', height: '70vh' }}>
                <VOBPAGE1 callingFrom={"Patientvob"} columnID={Formdid}  handleClose3={handleClose3}
                  updateHealthHistory={(id, authId) => {
                    console.log('setting', id)
                    setValue('AuthCheckList', true)
                    // setauthRequestid(authId)
                  }}
                ></VOBPAGE1>

              </Card>
              <ToastContainer
                position="top-center"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
            </Grid>


          </Box>
        </Modal>
            <Grid container columnSpacing={1} style={{ color: 'black' }}>
                <Grid item xs={1}></Grid>
                <Grid item xs={10} >
                    {/* <h1 style={{ fontWeight: 'bold', textAlign: 'center', color: 'black' }}>DME VOB/ Prior-auth checklist (E0486)
                    </h1> */}
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={11} >
                    <h4 style={{ textAlign: 'center', marginTop: '10px' }}>(Carrier will immediately begin a clinical review & all info will be needed to complete)

                    </h4>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={1}>
                    <input
                        name='patMedIdCard'
                        size='small'
                        type='checkbox'
                        onChange={e => {
                            setMedId('patMedIdCard',e.target.checked)
                          
                              }}
                        
                              {...register('patMedIdCard')}
                        id='patMedIdCard'
                        style={{
                            marginTop: '30px'
                        }}

                    />
                </Grid>
                <Grid item xs={10} style={{ marginTop: '30px', marginLeft: '-60px' }}>
                    <h6 >  Patient Medical ID card (front & back)</h6>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={1}>
                    <input
                        name='vob'
                        size='small'
                        type='checkbox'
                        onChange={value => {
                            setVob('vob', value.target.checked)
                           
                              }}
                        // onChange={value => setVob('vob', value.target.checked)}
                        {...register('vob')}
                        id='vob'
                        style={{
                            marginTop: '15px'
                        }}

                    />
                </Grid>
                <Grid item xs={10} style={{ marginTop: '10px', marginLeft: '-60px' }}>
                    <h6 > VOB/ Prior-auth Request form</h6>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={11}>
                    <h6 > ● Name, Date of Birth & address MUST be correct or the carrier may reject any submitted requests.</h6>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={1}>
                    <input
                        name='patientQuestionnaire'
                        size='small'
                        type='checkbox'
                        onChange={value => setPatQuestion('patientQuestionnaire', value.target.checked)}
                        {...register('patientQuestionnaire')}
                        id='patientQuestionnaire'
                        style={{
                            marginTop: '15px'
                        }}

                    />
                </Grid>
                <Grid item xs={10} style={{ marginTop: '10px', marginLeft: '-60px' }}>
                    <h6 >The patient questionnaire/ screener must be completed in its entirety</h6>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={1}>
                    <input
                        name='financialAgreement'
                        size='small'
                        type='checkbox'
                        onChange={value => setFagreement('financialAgreement', value.target.checked)}
                        {...register('financialAgreement')}
                        id='financialAgreement'
                        style={{
                            marginTop: '15px'
                        }}

                    />
                </Grid>
                <Grid item xs={10} style={{ marginTop: '10px', marginLeft: '-60px' }}>
                    <h6> Financial agreement (recommended for your records only)</h6>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={1}>
                    <input
                        name='referringProviderNotes'
                        size='small'
                        type='checkbox'
                        onChange={value => setReffprovider('referringProviderNotes', value.target.checked)}
                        {...register('referringProviderNotes')}
                        id='referringProviderNotes'
                        style={{
                            marginTop: '15px'
                        }}

                    />
                </Grid>
                <Grid item xs={10} style={{ marginTop: '10px', marginLeft: '-60px' }}>
                    <h6>Face-to-face referring provider notes before the Sleep test</h6>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={10}>
                    <h6>(Medicare and Medicare Advantage plans must be IN-Person)</h6>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={1}>
                    <input
                        name='baselineSleepTest'
                        size='small'
                        type='checkbox'
                        onChange={value => setBsleepTest('baselineSleepTest', value.target.checked)}
                        {...register('baselineSleepTest')}
                        id='baselineSleepTest'
                        style={{
                            marginTop: '15px'
                        }}

                    />
                </Grid>
                <Grid item xs={10} style={{ marginTop: '10px', marginLeft: '-60px' }}>
                    <h6>The baseline sleep test needs to be signed</h6>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={11}>
                    <h6>● AHI must be between 5-29 (under 5 carriers will not approve as this is not considered valid sleep apnea)</h6>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={1}>
                    <input
                        name='cpap'
                        size='small'
                        type='checkbox'
                        onChange={value => setCpap('cpap', value.target.checked)}
                        {...register('cpap')}
                        id='cpap'
                        style={{
                            marginTop: '15px'
                        }}

                    />
                </Grid>
                <Grid item xs={10} style={{ marginTop: '10px', marginLeft: '-60px' }}>
                    <h6>AHI=30 or above must have tried CPAP</h6>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={11}>
                    <h6>● Patients with severe OSA must trial CPAP before an Oral appliance may be considered. If the patient can

                    </h6>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={11}>
                    <h6>
                        NOT tolerate CPAP, the treating physician MUST document this.
                    </h6>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={11}>
                    <h6>
                        ● Add to your progress notes that the patient has failed and WHY they failed CPAP.

                    </h6>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={1}>
                    <input
                        name='lomn'
                        size='small'
                        type='checkbox'
                        onChange={value => setLomn('lomn', value.target.checked)}
                        {...register('lomn')}
                        id='lomn'
                        style={{
                            marginTop: '15px'
                        }}

                    />
                </Grid>
                <Grid item xs={10} style={{ marginTop: '10px', marginLeft: '-60px' }}>
                    <h6>LOMN (Letter of Medical Necessity)</h6>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={11}>
                    <h6>● This may come from the sleep test facility Dr. or the patient's Primary care Dr.</h6>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={1}>
                    <input
                        name='progressNotes'
                        size='small'
                        type='checkbox'
                        onChange={value => setProgNotes('progressNotes', value.target.checked)}
                        {...register('progressNotes')}
                        id='progressNotes'
                        style={{
                            marginTop: '15px'
                        }}

                    />
                </Grid>
                <Grid item xs={10} style={{ marginTop: '10px', marginLeft: '-60px' }}>
                    <h6> Progress notes</h6>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={11}>
                    <h6>● Initial consult/ SOAP note with the patient.</h6>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={1}>
                    <input
                        name='additionalInformation'
                        size='small'
                        type='checkbox'
                        onChange={value => setAddinfo('additionalInformation', value.target.checked)}
                        
                        {...register('additionalInformation')}
                        id='additionalInformation'
                        style={{
                            marginTop: '15px'
                        }}

                    />
                </Grid>
                <Grid item xs={10} style={{ marginTop: '10px', marginLeft: '-60px' }}>
                    <h6>  Any additional information you have or think would be helpful to obtain authorization?</h6>
                </Grid>
                <Grid item xs={1} style={{ marginTop: '25px' }}></Grid >
          <Grid item xs={5} style={{ marginTop: '25px' }}>
              <Grid container columnSpacing={1}>
                <input
                  name="authCheckList"
                  size="small"
                  type="checkbox"
                  disabled={disable == true ? true : false}
                  {...register("authCheckList")}
                  id="authCheckList"
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                />
                {medid == true && vob== true && patquestion == true && reffprovider == true && bsleeptest == true && lomn == true ?(<>
                    <Typography style={{ fontWeight: "bold", color: "green" }}>
                  VOB/ Prior-auth Request form
                </Typography>
                
                </>):(<>
                    <Typography style={{ fontWeight: "bold", color: "red" }}>
                  VOB/ Prior-auth Request form
                </Typography>
                </>)}
                
              </Grid>

            </Grid>
            <Grid item xs={1} style={{ marginTop: '25px' }}>
                {AuthCheckList == true ? (
                 <Tooltip title="View">
                <Button
                  style={{
                    backgroundColor: medid && vob && patquestion  && reffprovider && bsleeptest && lomn ? 'green' : 'red',
                    color: 'white',

                  }}
                  size='small'
                  disabled={medid == true && vob== true && patquestion == true && reffprovider == true && bsleeptest == true && lomn == true ? false : true}
                  onClick={handleOpen3}>
                  <RemoveRedEyeIcon
                    fontSize="medium"
                    style={{ color: "white", }}
                  ></RemoveRedEyeIcon>

                </Button>
                </Tooltip>
              ) : (
                <>
                 <Tooltip title="Add New">
                  <Button
                    style={{
                      backgroundColor: medid && vob && patquestion && reffprovider && bsleeptest && lomn  ? 'green' : 'red',
                      color: 'white',

                    }}
                    size='small'
                    disabled={medid == true && vob== true && patquestion == true && reffprovider == true  && bsleeptest == true && lomn == true ? false : true}
                    onClick={handleOpen3}>
                    <AddIcon
                      fontSize="medium"
                      style={{ color: "white", paddingRight: "5px" }}
                    ></AddIcon>

                  </Button>
                  </Tooltip>
                </>

                )}
               
            </Grid>
                <Grid item xs={5} lg={5} sm={5} style={{ marginTop: '25px' }}>
                    <Button
                    className="Button"
                        onClick={handleSubmit(onSubmit)}
                        variant='gradient'
                        disabled={disable == true?true:false }
                        style={{
                            marginTop: '0px',
                            marginBottom: '10px',
                            float: 'right',
                            marginLeft: 'auto',
                            width: '80px',
                            height: '35px',
                            // backgroundColor: '#A574FD',
                            color: 'white',
                            fontSize: '14px'
                        }}
                    >
                        Save
                    </Button>
                </Grid>

            </Grid>
        </div>

    );
};

export default VOBPAGE;
