import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Container } from "reactstrap";
import { Box, Button, Stack, SvgIcon, Typography, Grid, Snackbar, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress'
import axios from 'axios'
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { Controller, useForm } from 'react-hook-form'
// import { useRouter, withRouter } from 'next/router'
// import { useAlert } from 'react-alert'
import { DataGrid } from '@mui/x-data-grid'
import Tooltip from '@mui/material/Tooltip'
// import { createBrowserHistory } from 'history'
// import { useHistory } from "react-router-dom";
// import { Navigate } from "react-router-dom";

import { useNavigate } from 'react-router-dom';

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import DeleteIcon from '@mui/icons-material/Delete'
import "../Button.css"
// const history = useHistory();
const Practices = () => {
  
  const [isLoading, setLoading] = useState(false)
  document.title = "Practices | LuxBilling";

  // document.title = "Dashboard | Upzet - React Admin & Dashboard Template";
  document.title = "LuxBillingEHR";
  // const auth = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImN0eSI6IkpXVCJ9.eyJqdGkiOiIwZmRiNWIxMy0wYzExLTQ4ZTQtOTdmMy1hZjhlOGNmOTJlMjgiLCJ2YWxpZCI6IjEiLCJ1c2VyRW1haWwiOiJhbGlAZ21haWwuY29tIiwicHJhY3RpY2VJZCI6IjEiLCJleHBpcnlEYXRlIjoiNy8zLzIwMjgiLCJicm93c2VyIjoiR29vZ2xlIENocm9tZSIsImFwcGxpY2F0aW9uIjoiTHV4QmlsbGluZyIsIklzQWRtaW4iOiJGYWxzZSIsIlByb3ZpZGVySWQiOiIwIiwiZXhwIjoxODQ2MDgwNTE2LCJpc3MiOiJodHRwOi8vd3d3Lkx1eEJpbGxpbmcuY29tIiwiYXVkIjoiaHR0cDovL3d3dy5MdXhCaWxsaW5nLmNvbSJ9.2Uac6a-c-IPRJFzYSvjxpRych9icwhsebjvB2lgZaOs`
  const navigate = useNavigate();
  const [rows, setRows] = useState(null) 
  const [refresh, setRefresh] = useState(false)

  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'gridCell',
      sortable: false,
      flex: 0.1,
    },
    
    {
        field: 'practiceName',
        headerName: 'Practice Name',
        headerClassName: 'super-app-theme--header',
        cellClassName: 'gridCell',
        sortable: false,
        flex: 1.5,
        renderCell: (cellValues) => (
            <Button
            style={{ display: "flex", justifyContent: "flex-start"}}
              size="small"
              variant="text"
              color="info"
              onClick={(event) => {
                openPracticesRegistration(event,cellValues.row.id )
              }}
            >{`${cellValues.row.practiceName}`}</Button>
          ),
      },
      {
        field: 'taxId',
        headerName: 'Tax Id',
        headerClassName: 'super-app-theme--header',
        cellClassName: 'gridCell',
        sortable: false,
        flex: 0.3,
      },
      {
        field: 'address',
        headerClassName: 'super-app-theme--header',
        headerName: 'Address',
        cellClassName: 'gridCell',
        sortable: false,
        flex: 1,
      },
      {
        field: 'city',
        headerClassName: 'super-app-theme--header',
        headerName: 'City',
        cellClassName: 'gridCell',
        sortable: false,
        flex: 0.5,
      },
      {
        field: 'state',
        headerClassName: 'super-app-theme--header',
        headerName: 'State',
        cellClassName: 'gridCell',
        sortable: false,
        flex: 0.3,
      },
      {
        field: 'phoneNo',
        headerClassName: 'super-app-theme--header',
        headerName: 'PhoneNo',
        sortable: true,
        flex: 0.3,
      },
      {
        field: 'deleted',
        headerClassName: 'super-app-theme--header',
        headerName: 'Delete',
        sortable: true,
        flex: 0.3,
        // hide: true,
        // hide: Usertype == 'Admin'?false:true,
        
        renderCell: cell => (
        
          <DeleteIcon
            style={{
              marginRight: '5px',
              float: 'right',
              marginTop: '5px',
              color: 'red',
              cursor: 'pointer'
            }}
               
            onClick={cellValues => {
            
              confirmAlert({
                message: 'Do you want to delete the record.',
                closeOnEscape: true,
                closeOnClickOutside: true,
                overlayClassName: 'overlay-custom-class-name',
  
                buttons: [
                  {
                    label: 'Yes',
                   
                    
                    onClick: () => {
                      const postData = {
                        id: cell.row.id,
                        deleted: true,
                      
                      }
                     
                      axios
                        .post(`${process.env.REACT_APP_API_URL}/Practices/addPractice`, postData, {
                          headers
                        })
                        .then(response => {
                          setRefresh(!refresh)
                          // setOpenNote(false)
                          // console.log('response.data: ', response.data)
                        })
                        .catch(error => {
                          console.error('There was an error!', error)
                        })
                    }
                  },
                  {
                    label: 'No'
                  }
                ]
              })
             
            }}
          ></DeleteIcon>
        )
      }

  ]
  
  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 2,
    background: 'drak',
    fontSize: '13.4px !important',
    color: 'drak !important',
    borderColor: '#326C1D',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#377562',
      borderRadius: 0.1,
      color: 'white',
      fontSize: '16px !important',
      
    }, 

  }
  const gridClasses = {
    columnHeader: 'MuiDataGrid-columnHeaders',
  };
   const openPracticesRegistration = (event, id) => {
    navigate('/PracticeRegistration', { state: { PracticeId: id } })
   
  }
  useEffect(() => {
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/Practices/getPractices`, {
        headers
      }
      )
      .then((response) => { 
        setRows(response) 
        setLoading(false)
      })
     
      .catch((error) => {
        console.error('There was an error!', error)
      })

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [refresh])
 
  return (
     
      <div className="page-content">
        <Container  >
        <div style={{ width: 200, marginTop: '5px', marginBottom: '0px' }}>
                  <Grid>
                    <Button
                    sx={{ mb: 1 }} className="Button" 
                      onClick={openPracticesRegistration}
                      variant="contained"
                    >
                        <AddOutlinedIcon
                fontSize='small'
                style={{
                  color: 'white',
                  paddingRight: '4px',
                  paddingBottom: '2px'
                }}
              ></AddOutlinedIcon>
                      Add New
                    </Button>
                  </Grid>
                  </div>
            
                <Box mb={0} marginTop={0}>
                {isLoading ? (
            <div style={{ margin:'5rem', textAlign:'center' }}>
               <CircularProgress /> 
            </div>
          ) : (
              <div style={{ height: 400, width: '100%' }}>
                {rows === null || rows.length === 0 ? null : (
                  <DataGrid
                    rows={rows}
                    rowHeight={28}
                    columns={columns}
                   classes={gridClasses}
                    pagination
                    autoPageSize
                    sx={gridRowStyle}
                  />
                )}
              </div>
          )}
            </Box>
         
        </Container>
      </div>

  );
};

export default Practices;
