// ** React Imports
import { useState, useEffect } from 'react'
import axios from 'axios'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import React from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import Button from '@mui/material/Button'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import { Grid, Typography } from '@mui/material'
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import ReactSelect from 'react-select'
import { Controller, useForm } from 'react-hook-form'
import "../Button.css"
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

const Insuranceplan = () => {
  document.title = "Insuranceplan | LuxBilling";

  const getRowSpacing = React.useCallback(params => {
    return {
      top: params.isFirstVisible ? 0 : 0.5,
      bottom: params.isLastVisible ? 0 : 0.8
    }
  }, [])
  const [isLoading, setLoading] = useState(false)
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const gridStyle = {
    color: '#8392ab !important',
    textTransform: 'lowercase',
    fontSize: '13.6px',
    fontWeight: '400',
    '&:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px'
    }
  }

  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 2,
    background: 'drak',
    fontSize: '13.4px !important',
    color: 'drak !important',
    borderColor: '#326C1D',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#377562',
      borderRadius: 0.1,
      color: 'white',
      fontSize: '16px !important',
      
    }, 

  }
  const gridClasses = {
    columnHeader: 'MuiDataGrid-columnHeaders',
  };
  const [refresh, setRefresh] = useState(false)
  const navigate = useNavigate();

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.2,
    },
    {
      field: 'name',
      headerName: 'Carriers Name',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 1.5,
      renderCell: (cellValues) => (
        <Button
        style={{ display: "flex", justifyContent: "flex-start"}}
          size="small"
          variant="text"
          color="info"
          onClick={(event) => {
            openInsuranceplanRegistration(event,cellValues.row.id )
          }}
        >{`${cellValues.row.name}`}</Button>
      ),
    },
    {
      field: 'payerId',
      headerName: 'Payer ID',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.5,
    },
    {
      field: 'phone',
      headerName: 'Phone',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.5,
    },
    {
      field: 'city',
      headerName: 'City',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.5,
    },
    {
      field: 'state',
      headerName: 'State',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.5,
    },
    {
      field: 'delete',
      headerName: 'Delete',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.5,
      renderCell: (cell) => (
        <DeleteIcon
          style={{
            marginRight: '3px',
            float: 'right',
            marginTop: '5px',
            color:'red',
            marginleft: '4px',
          
          }}
          color="black"
          onClick={(cellValues) => {
            // console.log('Cell: ', cell)
            confirmAlert({
              message: 'Do you want to delete the record.',
              closeOnEscape: true,
              closeOnClickOutside: true,
              overlayClassName: 'overlay-custom-class-name',
              buttons: [
                {
                  label: 'Yes',
                  onClick: () => {
                    const postData = {
                      name: cell.row.name,
                      phone: cell.row.phone,
                      payerId: cell.row.payerId,
                      city: cell.row.city,
                      id: cell.row.id,
                      inactive: true,
                      state: cell.row.state,

                    }

                    axios
                      .post(
                        `${process.env.REACT_APP_API_URL}/Insurances/AddInsurances`,
                        postData,
                        {
                          headers,
                        }
                      )
                      .then((response) => {
                        setRefresh(!refresh)
                        // setOpenNote(false)
                        // console.log('response.data: ', response.data)
                      })
                      .catch((error) => {
                        console.error('There was an error!', error)
                      })
                  },
                },
                {
                  label: 'No',
                },
              ],
            })
          }}
        ></DeleteIcon>
      ),
    },
  ]
  
  const [rows, setRows] = useState(null)
  useEffect(() => {
    setLoading(true)
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Insurances/getInsurance`, { headers })
      .then(response => {
        setLoading(false)
        setRows(response)
      })
      .catch(error => {})

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [refresh])
 
  const openInsuranceplanRegistration = (event, id = 0) => {
    navigate('/InsuranceplanRegistration', { state: {Insuranceplanid: id } })
  }

  return (
      <div className="page-content">
            <Container >
            <Grid container>
                  <Grid item xs={2}>
                  <Button sx={{ mb: 1 }} className="Button" onClick={openInsuranceplanRegistration} variant='contained'>
                <AddOutlinedIcon
                fontSize='small'
                style={{
                  color: 'white',
                  paddingRight: '4px',
                  paddingBottom: '2px'
                }}
              ></AddOutlinedIcon>
                  Add New
                </Button>
                  </Grid>
                </Grid>
            
              <Box mb={3} marginTop={2}>
          {isLoading ? (
            <div style={{ margin:'5rem', textAlign:'center' }}>
               <CircularProgress /> 
            </div>
          ) : (
            <div style={{ height: 400, width: "100%" }}>
              {rows === null || rows.length === 0 ? (
            <Typography>No Record found</Typography>
          ) : (
                <DataGrid
                rows={rows}
                classes={gridClasses}
                rowHeight={25}
                columns={columns}
                // getRowSpacing={getRowSpacing}
                //checkboxSelection
                fullWidth
                pagination
            autoPageSize
                sx={gridRowStyle}
              />
              )}
            </div>
             )}
          </Box>
            </Container>
          </div>
      
     
  )
}

export default Insuranceplan
